import { removeSpaces } from 'utils/parsers';
import { TableHeadInterface } from 'types';

export const transactionTableColumns: TableHeadInterface[] = [
    {
        title: 'Transaction Id',
        dataIndex: 'transactionId',
        key: 'transactionId'
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: 'Address from',
        dataIndex: 'from',
        key: 'from'
    },

    {
        title: 'Address to',
        dataIndex: 'to',
        key: 'to'
    },
    {
        title: 'Created at',
        dataIndex: 'utcCreated',
        key: 'utcCreated'
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: {
            compare: (a: any, b: any) => removeSpaces(a.value) - removeSpaces(b.value)
        }
    }
];
