import toggleBg from 'assets/toggle_bg.png';
import styled from 'styled-components';

export const StyledToggleButton = styled.input`
    appearance: none;
    position: relative;
    width: 34px;
    height: 18px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 9px;
    cursor: pointer;
    outline: none;

    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        height: '14px';
        width: '14px';
        top: '2px';
        left: 2px;
        background-color: #8f8f90;
        border-radius: 50%;

        transform: translateX(0);
    }

    &:checked::after {
        transform: translateX(calc(100% + '2px'));
        background-color: #fff;
    }

    &:checked {
        background: url(${toggleBg}) no-repeat center;
    }

    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
`;
