import { createGlobalStyle } from 'styled-components';
import { backgroundColor, primaryColor, white } from './colors';

export const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  ::selection {
    background: ${primaryColor};
    color: ${white};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(66 89 255 / 44%);
  }

  #root {
    background-color: ${backgroundColor};
    width: 100%;
    min-height: 100%;
  }

  .ant-slider {
    width: 100%;
  }

  .ant-descriptions-item-content {
    background: ${white};
  }

  .ant-tag {
    margin-bottom: 8px;
  }

  .wrapper {
    height: auto !important;
    min-height: 100vh;
  }
`;
