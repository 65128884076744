import commentsIcon from 'assets/img/egagement_comments_icon.svg';
import likesIcon from 'assets/img/egagement_likes_icon.svg';
import savesIcon from 'assets/img/egagement_saves_icon.svg';
import sharesIcon from 'assets/img/egagement_shares_icon.svg';
import viewsIcon from 'assets/img/egagement_viewed_icon.svg';

interface EngagementIcon {
    alt: string;
    icon: string;
    key: keyof Pick<WOM.EngagementStatistics, 'commentCount' | 'viewCount' | 'saveCount' | 'shareCount' | 'likeCount'>;
}

export const engagementsIcons: EngagementIcon[] = [
    {
        alt: 'viewed',
        icon: viewsIcon,
        key: 'viewCount'
    },
    {
        alt: 'liked',
        icon: likesIcon,
        key: 'likeCount'
    },
    {
        alt: 'saved',
        icon: savesIcon,
        key: 'saveCount'
    },
    {
        alt: 'commented',
        icon: commentsIcon,
        key: 'commentCount'
    },
    {
        alt: 'shared',
        icon: sharesIcon,
        key: 'shareCount'
    }
];

export const copyIdMessage = 'You successfully copied Video id!';
export const copyUserIdMessage = 'You successfully copied User id!';
export const copyProductIdMessage = 'You successfully copied Primary product id!';
export const copyUrlMessage = 'You successfully copied Video url!';
export const copyRemoteContentIdMessage = 'You successfully copied remote content id';

/**
 * validationEndedReason
 * <br/><br/>Values:<br/>0 = None<br/>1 = CompletedNormally<br/>2 = ConsensusNotReached<br/>3 = TerminatedInHold<br/>4 = Deleted
 */
export const endedReasonValue = [
    'None',
    'Completed Normally',
    'Consensus Not Reached',
    'Terminated In Hold',
    'Deleted'
];
