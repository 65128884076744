import React, { FC } from 'react';
import { ButtonLinkProps, StyledNavLink } from './styles';

export interface Props extends ButtonLinkProps {
    href: string;
}

export const ButtonLink: FC<Props> = ({ buttonType = 'navy', children, disabled, href, noWrap }) => (
    <StyledNavLink buttonType={buttonType} disabled={disabled} noWrap={noWrap} to={disabled ? '' : href}>
        {children}
    </StyledNavLink>
);
