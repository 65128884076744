import { sortModeTagsValues, sortTagsValues } from '../filters/sorts';
import { defaultLimit, defaultPage } from './common';

export const sortModeTagsValuesDefault = sortModeTagsValues[1];
export const sortTagsValuesDefault = sortTagsValues[1];

export const defaultVideosValues = {
    pageIndex: defaultPage,
    limit: defaultLimit,
    returnQueryCount: true
};
