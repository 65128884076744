import { PropertyBlock } from 'components/common/dividers/PropertyBlock';
import { VideoPlayer } from 'components/common/dynamic/VideoPlayer';
import { Tag } from 'components/common/tags/Tag';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { homeLink } from 'constants/routes';
import { copyRemoteContentIdMessage } from 'pages/Videos/VideoCard/constants';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Activity } from './Activity';
import { copyIdMessage } from './constants';
import { Engagements } from './Engagements';

export const VideoCard: FC<WOM.ContentItemResponse> = ({
    womContentId,
    streamDetails,
    uriPrimary,
    womQualityScore,
    engagement,
    tags,
    remoteContentId,
    validationState,
    source
}) => {
    const history = useHistory();
    const moreInfoHandleClick = () => history.push(homeLink + '/' + womContentId);
    const endedReason = validationState?.endedReason;

    return (
        <Card>
            <CardBody>
                <div className="position-relative" style={{ height: '270px' }}>
                    <VideoPlayer source={source} streamDetails={streamDetails} uriPrimary={uriPrimary} />
                    <Activity endedReason={endedReason} womQualityScore={womQualityScore} />
                </div>
                <div>
                    <Row className="mt-2 position-relative content-line">
                        <Col className="mb-2">
                            <Engagements engagement={engagement} />
                        </Col>
                    </Row>
                    <div className="d-flex flex-row overflow-auto mt-2 mb-1">
                        {tags?.map(tag => (
                            <MarginWrapper key={tag} marginBottom="9px" marginRight="12px">
                                <Tag color="danger">{tag}</Tag>
                            </MarginWrapper>
                        ))}
                    </div>
                    <Row className="mb-2">
                        <Col className="mb-2" xl="6">
                            <PropertyBlock copyable subtitle={womContentId} success={copyIdMessage} title="Video ID" />
                        </Col>
                        <Col xl="6">
                            <PropertyBlock
                                copyable
                                subtitle={remoteContentId || ''}
                                success={copyRemoteContentIdMessage}
                                title="Remote Content ID"
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                        <Col className="col-auto">
                            <Button size="lg" onClick={moreInfoHandleClick}>
                                More Info
                            </Button>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    );
};
