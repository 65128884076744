import * as adminUsers from './admin-users';
import * as adminVideos from './admin-videos';
import * as catalogue from './catalogue';
import * as locaiton from './location';
import * as organizations from './organizations';
import * as performancePayments from './performancePayments';
import * as transactions from './transactions';
import * as user from './user';
import * as wallet from './wallet';
import * as walletAdmin from './wallet-admin';
import * as withdrawalLimits from './withdrawal-limits';

export const API = {
    user,
    adminVideos,
    adminUsers,
    organizations,
    wallet,
    transactions,
    performancePayments,
    catalogue,
    walletAdmin,
    withdrawalLimits,
    locaiton
};
