import delimiterIcon from 'assets/img/arrow_right.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { Column, Row, Section } from 'components/grid/wrappers/flexWrapper';
import { md, xs } from 'constants/styles';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatetime, { DatetimepickerProps } from 'react-datetime';
import { useMediaQuery } from 'react-responsive';

interface DataPickerProps
    extends Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange'>,
        Pick<DatetimepickerProps, 'isValidDate' | 'renderDay'> {
    onChange: (value: Moment) => void;
    value: Date;
}

export const DataPicker = ({ onChange, value, isValidDate, renderDay, ...inputProps }: DataPickerProps) => (
    <ReactDatetime
        dateFormat="DD.MM.YYYY"
        inputProps={{
            className: 'form-control',
            placeholder: 'Datetime Picker Here',
            ...inputProps
        }}
        isValidDate={isValidDate}
        renderDay={renderDay}
        timeFormat={false}
        value={value}
        onChange={onChange as (value: Moment | string) => void}
    />
);

interface DataRangePickerProps {
    onChange: (value: [Moment, Moment]) => void;
    value: [Date, Date];
    disableJustifyBetween?: boolean;
}

export const DataRangePicker = ({ onChange, value, disableJustifyBetween }: DataRangePickerProps) => {
    const [[dateFrom, dateTo], setDate] = useState(value);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const isTablet = useMediaQuery({ query: `(max-width: ${md})` });

    const onFromChange = (value: Moment | string) => {
        if (typeof value !== 'string') {
            setDate([value.toDate(), dateTo]);
            onChange([value, moment(dateTo)]);
        }
    };

    const onToChange = (value: Moment | string) => {
        if (typeof value !== 'string') {
            setDate([dateFrom, value.toDate()]);
            onChange([moment(dateFrom), value]);
        }
    };

    const isValidToDate = (currentDate: Moment) => moment(currentDate).isAfter(dateFrom);

    const renderDateToDay = (props: any, currentDate: Moment) => {
        if (currentDate.isSame(dateFrom, 'day')) {
            return (
                <td {...props} className={props.className + ' rdtDateTo'}>
                    {currentDate.date()}
                </td>
            );
        }
        return <td {...props}>{currentDate.date()}</td>;
    };

    const renderDateFromDay = (props: any, currentDate: Moment) => {
        if (currentDate.isSame(dateTo, 'day')) {
            return (
                <td {...props} className={props.className + ' rdtDateFrom'}>
                    {currentDate.date()}
                </td>
            );
        }
        return <td {...props}>{currentDate.date()}</td>;
    };

    useEffect(() => setDate(value), [value]);

    return !isMobile ? (
        <Section alignCenter noWrap justifyBetween={!isTablet && !disableJustifyBetween}>
            <Row marginRight="7px">
                <DataPicker renderDay={renderDateFromDay} value={dateFrom} onChange={onFromChange} />
            </Row>
            <Row marginRight="7px">
                <CustomImg alt="delimiter" src={delimiterIcon} />
            </Row>
            <Row>
                <DataPicker
                    isValidDate={isValidToDate}
                    renderDay={renderDateToDay}
                    value={dateTo}
                    onChange={onToChange}
                />
            </Row>
        </Section>
    ) : (
        <Column alignCenter justifyCenter width="100%">
            <Section>
                <DataPicker renderDay={renderDateFromDay} value={dateFrom} width="100%" onChange={onFromChange} />
            </Section>
            <Section justifyCenter margin="10px 0">
                <CustomImg alt="delimiter" src={delimiterIcon} />
            </Section>
            <Section>
                <DataPicker
                    isValidDate={isValidToDate}
                    renderDay={renderDateToDay}
                    value={dateTo}
                    onChange={onToChange}
                />
            </Section>
        </Column>
    );
};
