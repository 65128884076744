import { ClearIcon } from 'assets/img';
import { StyledButton } from 'components/common/buttons/ClearInputButton/styles';
import React, { FC } from 'react';
import { ReactClick } from 'types/interfaces/react';

export const ClearInputButton: FC<ReactClick<HTMLButtonElement>> = ({ onClick }) => (
    <StyledButton onClick={onClick}>
        <ClearIcon />
    </StyledButton>
);
