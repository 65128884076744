import { defaultLimit, defaultPage } from 'constants/defaults';

export const getDateBeforeAndReturnISO = (beforeDays = 1) =>
    new Date(Date.now() - beforeDays * 24 * 60 * 60 * 1000).toISOString();

export const defaultPerformancePaymentsValues: WOM.PerformancePaymentsQueryRequest = {
    pageIndex: defaultPage,
    limit: defaultLimit,
    returnQueryCount: true,
    dateFrom: getDateBeforeAndReturnISO(8),
    dateTo: getDateBeforeAndReturnISO()
};

export const defaultValuesEntries: WOM.ValidationEntriesRequest = {
    pageIndex: 0,
    limit: 100
};
