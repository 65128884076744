import { CopyButton, CopyButtonProps } from 'components/common/buttons/CopyButton';
import { Span } from 'components/common/typography/Span';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Href } from 'types';

interface InternalCopyLinkProps extends Href, CopyButtonProps {
    color?: string;
}

export const InternalCopyLink: FC<InternalCopyLinkProps> = ({ color, success, subject, href, children }) => (
    <Section justifyBetween noWrap>
        <Link to={href}>
            <Span color={color} fontWeight="400">
                {children}
            </Span>
        </Link>
        <MarginWrapper marginLeft="8px">
            <CopyButton subject={subject} success={success} />
        </MarginWrapper>
    </Section>
);
