import ErrorIcon from 'assets/img/error.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { Span } from 'components/common/typography/Span';
import { Flex } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { grey2 } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useRef, useState } from 'react';
import { modalEvents, modalStores } from 'stores/modal';
import { notificationEvents } from 'stores/notification';
import { triggerCopy } from 'utils/usefulFunctions';
import { Modal } from '../Modal';
import { ErrorInfo, ErrorInfoItem, OpenErrorButton } from './styles';
import { Button } from 'components/common/buttons/Button';
import { CopyIcon } from 'assets/img';

const { closeErrorModal } = modalEvents;
const { errorModal } = modalStores;

export const ErrorModal: FC = () => {
    const { informationText, error, onOkHandler, visible, path, entityId } = useStore(errorModal);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const errorTextRef = useRef<HTMLDivElement>(null);

    const handleErrorCollapse = () => {
        setIsCollapsed(prev => !prev);
    };

    const handleCopyError = () => {
        if (errorTextRef.current) {
            triggerCopy(errorTextRef.current.innerText);
            notificationEvents.setNotification({ message: 'Text was copied!', place: 'tc' });
        }
    };

    if (!visible) {
        return null;
    }

    return (
        <Modal onModalClose={() => closeErrorModal()}>
            <MarginWrapper marginBottom="24px" marginLeft="24px" marginRight="24px" marginTop="60px">
                <AbsoluteWrapper height="24x" left="24px" top="24px" width="100%">
                    <Flex alignCenter gap="12px">
                        <CustomImg height="20px" src={ErrorIcon} width="20px" />
                        <Span color={grey2} fontSize="17px" fontWeight="600">
                            Error
                        </Span>
                    </Flex>
                </AbsoluteWrapper>
                <MarginWrapper marginBottom="16px">
                    <Span color={grey2} fontSize="14px" fontWeight="500">
                        {informationText}
                    </Span>
                </MarginWrapper>
                <Flex width="100%">
                    <MarginWrapper marginBottom="12px">
                        <OpenErrorButton collapsed={isCollapsed} onClick={handleErrorCollapse}>
                            See error code
                        </OpenErrorButton>
                    </MarginWrapper>
                    <ErrorInfo ref={errorTextRef} collapsed={isCollapsed}>
                        <ErrorInfoItem>entity id: {entityId}</ErrorInfoItem>
                        <ErrorInfoItem>path: {path}</ErrorInfoItem>
                        <ErrorInfoItem>error: {error}</ErrorInfoItem>
                    </ErrorInfo>
                </Flex>
                <Flex alignCenter justifyBetween width="100%">
                    <Button buttonType="white" size="small" onClick={handleCopyError}>
                        <MarginWrapper marginRight="10px">
                            <CopyIcon height="20px" width="20px" />
                        </MarginWrapper>
                        Copy error text
                    </Button>

                    <Button buttonType="blue" onClick={onOkHandler}>
                        OK
                    </Button>
                </Flex>
            </MarginWrapper>
        </Modal>
    );
};
