import React, { FC } from 'react';
import { HTMLButtonType } from 'types';
import { ReactClick } from 'types/interfaces/react';
import { ButtonProps, StyledButton } from './styles';

export interface Props extends ButtonProps, ReactClick<HTMLButtonElement>, HTMLButtonType {}

export const Button: FC<Props> = ({ type = 'button', buttonType = 'navy', children, onClick, ...buttonProps }) => (
    <StyledButton buttonType={buttonType} type={type} onClick={onClick} {...buttonProps}>
        {children}
    </StyledButton>
);
