import { Loader } from 'components/common/dynamic/Loader';
import { InternalCopyLink } from 'components/common/links/InternalCopyLink';
import { SimpleTable } from 'components/common/tables/SimpleTable';
import { defaultSortValue } from 'constants/messages';
import { noContentMessage } from 'constants/notifications';
import { transactionsLink, usersLink } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Button } from 'components/common/buttons/Button';
import { loadingStores } from 'stores/loading';
import { walletEffects } from 'stores/wallet';
import { currencyToStandardForm } from 'utils/usefulFunctions';
import { performancePaymentsContentSingleColumns } from './constants';

export const PerformancePaymentsContentSingleTable: FC<WOM.PerformancePaymentsContentPaymentsResponse> = ({
    items
}) => {
    const loading = useStore(loadingStores.initialLoading);

    const goToWallet = (addressId: string) => walletEffects.getIdAndRedirectToSinglePage({ publicAddress: addressId });

    const performancePaymentContentData = (items as WOM.PerformancePaymentsContentPaymentsItem[]).map(
        ({ name, userId, address, transactionId, value }) => ({
            data: {
                name: name || <span>{noContentMessage}</span>,
                address: address ? (
                    <Button onClick={() => goToWallet(address)}>{loading ? <Loader /> : 'Go to wallet'}</Button>
                ) : (
                    <span>{noContentMessage}</span>
                ),
                userId: userId ? (
                    <InternalCopyLink
                        href={usersLink + '/' + userId}
                        subject={userId}
                        success="You successfully copied user id"
                    >
                        {userId}
                    </InternalCopyLink>
                ) : (
                    <span>{noContentMessage}</span>
                ),
                transactionId: transactionId ? (
                    <InternalCopyLink
                        href={transactionsLink + '/' + transactionId}
                        subject={transactionId}
                        success="You successfully copied user id"
                    >
                        {transactionId}
                    </InternalCopyLink>
                ) : (
                    <span>{noContentMessage}</span>
                ),
                value: {
                    jsx: <>{value ? currencyToStandardForm(value) : defaultSortValue}</>,
                    value: value || defaultSortValue
                }
            },
            className: 'text-start'
        })
    );

    return (
        <>
            <SimpleTable
                responsive
                tbody={performancePaymentContentData}
                thead={performancePaymentsContentSingleColumns}
            />
        </>
    );
};
