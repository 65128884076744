import { blue2, grey2, primaryColor } from 'constants/styles/colors';
import styled from 'styled-components';
import { BackgroundColor, BorderRadius, Sizes } from 'types';

export interface CardWrapperProps extends BackgroundColor, BorderRadius, Sizes {
    isSelected?: boolean;
}

export const CardWrapper = styled.div<CardWrapperProps>`
    position: relative;
    width: 100%;
    ${({ height }) => height && `height: ${height}`};
    padding: 22px 15px;
    border-radius: ${({ borderRadius }) => borderRadius || '8px'};
    background-color: ${({ backgroundColor }) => backgroundColor || grey2};
    border: 1px solid ${({ isSelected }) => (isSelected ? primaryColor : 'transparent')};
    margin-bottom: 30px;
    z-index: 0;

    &:hover {
        border-color: ${blue2};
    }
`;
