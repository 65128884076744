import { useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import { CopyButton } from 'components/common/buttons/CopyButton';
import { StyledLink, SubtitleWrapper } from 'components/common/dividers/PropertyBlock/styles';
import React from 'react';
import { BackgroundColor } from 'types';
import { Copyable, Success, Title } from 'types/interface';
import { getEllipsisAddress } from 'utils/usefulFunctions';

interface PropertyBlockProps extends Title, Partial<Success>, Copyable, BackgroundColor {
    backgroundColor?: 'primary' | 'secondary';
    link?: string;
}
export const PropertyBlock = ({
    title,
    subtitle,
    success,
    copyable,
    backgroundColor = 'primary',
    link
}: PropertyBlockProps) => {
    const isTablet = useMediaQuery(`(max-width: 1070px)`);
    const newSubtitle = getEllipsisAddress(subtitle as string, 9);

    const Subtitle = () => (
        <SubtitleWrapper className="subtitle">
            {isTablet && newSubtitle.length > 0 ? newSubtitle : subtitle}
        </SubtitleWrapper>
    );

    return (
        <div className={classNames('property-block', `property-block-${backgroundColor}`)}>
            <div>
                <div className="title">
                    <span>{title}</span>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                {link ? (
                    <StyledLink href={link} rel="noopener noreferrer" target="_blank">
                        <Subtitle />
                    </StyledLink>
                ) : (
                    <Subtitle />
                )}

                {copyable && <CopyButton subject={subtitle} success={success} />}
            </div>
        </div>
    );
};
//
