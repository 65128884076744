import { blue, pink, white } from 'constants/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TagColorType } from './types';

interface Props {
    color?: TagColorType;
}

const getTagBackground = (color?: TagColorType) => {
    switch (color) {
        case 'info':
        default:
            return blue;
        case 'danger':
            return pink;
    }
};

export const StyledTag = styled.span<Props>`
    border-radius: 12px;
    background-color: ${({ color }) => color && getTagBackground(color)};
    color: ${white};
    padding: 3px 10px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-right: 12px;
    }
`;

export const StyledLink = styled(Link)`
    color: ${white};

    &:hover {
        color: rgba(255, 255, 255, 0.8);
        text-decoration: underline;
    }
`;

export const CloseIcon = styled.b`
    cursor: pointer;
    height: 18px;

    &:before {
        font-family: 'Nucleo';
        content: '\\ea48';
        padding-left: 2px;
    }
`;
