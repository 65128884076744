import { Loader } from 'components/common/dynamic/Loader';
import { Span } from 'components/common/typography/Span';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/layouts/Empty';
import { white, xs } from 'constants/styles';
import { useStore } from 'effector-react';
import { videoNotFoundMessage } from 'pages/Videos/constants';
import { VideoDescription } from 'pages/Videos/Video/VideoDescription';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { videosEffects, videosStores } from 'stores/videos';

interface ParamsProps {
    videoId: string;
}

export const Video = () => {
    const { videoId } = useParams<ParamsProps>();
    const item = useStore(videosStores.item);
    const loading = useStore(videosEffects.getItemById.pending);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    useEffect(() => {
        videosEffects.getItemById(videoId);
    }, [videoId]);

    return (
        <MainLayout>
            <MarginWrapper marginTop={isMobile ? '15px' : '0'}>
                <Breadcrumb />
            </MarginWrapper>

            <MarginWrapper marginBottom="15px" marginTop={isMobile ? '15px' : '0'}>
                <Span color={white} fontSize="17px" lineHeight="25px">
                    Video info
                </Span>
            </MarginWrapper>

            {loading ? (
                <Loader />
            ) : item.womContentId ? (
                <VideoDescription {...item} />
            ) : (
                <Section alignCenter justifyCenter marginTop="48px">
                    <Empty description={videoNotFoundMessage} />
                </Section>
            )}
        </MainLayout>
    );
};
