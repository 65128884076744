import { flexStart } from 'constants/styles';
import styled from 'styled-components';

export const TagsWrapper = styled.div`
    ${flexStart};
    max-width: 100%;
    height: 40px;
    overflow-y: hidden;
    overflow-x: auto;

    margin-bottom: 10px;

    ::-webkit-scrollbar {
        width: 24px;
        height: 8px;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #40556f;
        border-radius: 10px;
    }

    scrollbar-color: #40556f transparent;
    scrollbar-width: auto;
`;
