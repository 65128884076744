import { Button } from 'components/common/buttons/Button';
import { Loader } from 'components/common/dynamic/Loader';
import { Text } from 'components/common/typography/Text';
import { Form } from 'components/formComponents/Form';
import { FormButton } from 'components/formComponents/FormButton';
import { FormInput } from 'components/formComponents/FormInput';
import { ErrorFormTitle } from 'components/formComponents/FormTitle';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { formName } from 'constants/global';
import { errorEmptyMessage, requiredFieldMessage } from 'constants/notifications';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo } from 'react';
import { loadingStores } from 'stores/loading';
import { notificationEvents } from 'stores/notification';
import { walletEffects, walletStores } from 'stores/wallet';
import { triggerCopy } from 'utils/usefulFunctions';
import { ButtonsWrapper, FormWrapper } from './styles';

export const CreateTransaction = () => {
    const loading = useStore(loadingStores.loading);
    const { walletId, items } = useStore(walletStores.item);
    const error = useStore(walletStores.transferError);

    const womAddress = useMemo(() => (items?.length && items[0]?.address !== null ? items[0].address : ''), [items]);
    const rpAddress = useMemo(() => (items?.length && items[1]?.address !== null ? items[1].address : ''), [items]);

    const onFinish = (values: WOM.TransactionCreateRequest) => walletEffects.transferMoney(values);

    const onFinishFailed = () => {
        notificationEvents.setNotification({
            place: 'tc',
            message: errorEmptyMessage,
            type: 'danger',
            icon: 'tim-icons icon-bell-55',
            autoDismiss: 5
        });
    };

    const copy = (subject?: string, success?: string) => () => {
        if (subject) {
            triggerCopy(subject);

            notificationEvents.setNotification({
                place: 'tc',
                message: success,
                type: 'primary',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });
        }
    };

    useEffect(() => {
        walletEffects.getItem();
    }, []);

    return (
        <MainLayout>
            <Section justifyCenter marginBottom="50px">
                <Text alignTextCenter size="h1">
                    Create transaction page
                </Text>
            </Section>
            {loading ? (
                <Section>
                    <Loader size="small" />
                </Section>
            ) : (
                <>
                    <ButtonsWrapper>
                        <Button onClick={copy(walletId, 'You successfully copied wallet id!')}>
                            Copy your wallet id
                        </Button>
                        <Button onClick={copy(womAddress, 'You successfully copied wom address')}>
                            Copy wom address
                        </Button>
                        <Button onClick={copy(rpAddress, 'You successfully copied RP address')}>Copy RP address</Button>
                    </ButtonsWrapper>
                </>
            )}
            <FormWrapper>
                <Section>
                    <ErrorFormTitle>{error}</ErrorFormTitle>
                </Section>
                <Form
                    initialValues={{ remember: true }}
                    labelCol={{ span: 12, offset: 1 }}
                    name={formName}
                    wrapperCol={{ offset: 1, span: 24 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <FormInput
                        label={'Wallet address from'}
                        name={'addressFrom'}
                        rules={[{ required: true, message: requiredFieldMessage }]}
                    />
                    <FormInput
                        label={'Wallet address to'}
                        name={'addressTo'}
                        rules={[{ required: true, message: requiredFieldMessage }]}
                    />
                    <FormInput label={'Value'} name={'value'} rules={[{ required: true }]} type="number" />
                    <FormButton disabled={loading}>{loading ? <Loader size="small" /> : 'Transfer Money'}</FormButton>
                </Form>
            </FormWrapper>
        </MainLayout>
    );
};
