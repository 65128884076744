import { useMediaQuery } from '@material-ui/core';
import { Button, InfoButton, SimpleTable, Span, Text } from 'components/common';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Row, Section } from 'components/grid/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Tooltip } from 'components/modals/Tooltip';
import { textDisabled, white, xs } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { airdropEffects, airdropEvent, airdropStores } from 'stores/airdrop';
import { modalEvents } from 'stores/modal';
import { notificationEvents } from 'stores/notification';
import { AirdropSelectModal } from './AirdropSelectModal';
import { AirdropStyled, FixedBottomWrapper, NotUsersMessage } from './styles';
import { TokensInput } from './ui/Input';
import { getAirdropTableColumns, getAirdropTableContentData } from './utils';

export const Airdrop = () => {
    const isMobile = useMediaQuery(`(max-width: ${xs})`);
    const [modalVisible, setModalVisible] = useState(false);
    const users = useStore(airdropStores.users);
    const [totalTokens, setTotalTokens] = useState('0');
    const [tokensAllUsers, setTokensAllUsers] = useState('0');
    const [arrayTokensUsers, setArrayTokensUsers] = useState<Array<{ user: WOM.GetUserResponse; tokens: string }>>([]);
    const [checkedAllUsersForDeleted, setCheckedAllUsersForDeleted] = useState(false);
    const [checkedUsersForDeleted, setCheckedUsersForDeleted] = useState<WOM.GetUserResponse[]>([]);

    const handleClickButtonDeleteCheckedUsers = () => {
        airdropEvent.removeUsers(checkedUsersForDeleted);
        setCheckedAllUsersForDeleted(false);
        setCheckedUsersForDeleted([]);
    };

    const onCheckUserForDelete = (user: WOM.GetUserResponse, checked: boolean) => {
        if (checked) {
            setCheckedUsersForDeleted(checkedUsersForDeleted => [...checkedUsersForDeleted, user]);
        } else {
            setCheckedUsersForDeleted(checkedUsersForDeleted =>
                checkedUsersForDeleted.filter(({ userId }) => userId !== user.userId)
            );
        }
        setCheckedAllUsersForDeleted(false);
    };

    const onCheckAllUsersForDelete = (checked: boolean) => {
        setCheckedAllUsersForDeleted(checked);
        setCheckedUsersForDeleted(checked ? users : []);
    };

    const changeAllUsersTokens = (value: string) => {
        setTokensAllUsers(value);
        setArrayTokensUsers(arrayTokensUsers =>
            arrayTokensUsers.map(tokensUser => ({
                ...tokensUser,
                tokens: value
            }))
        );
    };

    const onChangeAllUsersTokens = (value: string) => {
        if (arrayTokensUsers.some(({ tokens }) => tokens !== tokensAllUsers)) {
            modalEvents.openInformationModal({
                informationText: 'As a result of these actions, the current selection will be reset. Proceed?',
                onOkHandler: () => changeAllUsersTokens(value),
                withCancelButton: true
            });
        } else {
            changeAllUsersTokens(value);
        }
    };

    const onChangeUserTokens = (user: WOM.GetUserResponse, value: string) => {
        setTokensAllUsers('0');
        setArrayTokensUsers(arrayTokensUsers =>
            arrayTokensUsers.map(tokensUser => {
                if (tokensUser.user.userId === user.userId) {
                    return {
                        ...tokensUser,
                        tokens: value
                    };
                }

                return tokensUser;
            })
        );
    };

    const airdropTableContentData = getAirdropTableContentData(
        users,
        checkedUsersForDeleted,
        onCheckUserForDelete,
        arrayTokensUsers,
        onChangeUserTokens
    );

    const airdropTableColumns = getAirdropTableColumns(checkedAllUsersForDeleted, onCheckAllUsersForDelete);

    const onSend = async () => {
        const data: any = await airdropEffects.postTokens({
            userIds: users.map(user => user.userId),
            value: Number(tokensAllUsers)
        });
        if (data?.isSuccess) {
            notificationEvents.setNotification({ message: 'The operation was successful', place: 'tc' });
            airdropEvent.removeAll();
            setTokensAllUsers('0');
        } else notificationEvents.setNotification({ message: 'Error, please try later', place: 'tc' });
    };

    const handleClickSendTokensUsers = () => {
        modalEvents.openInformationModal({
            informationText: `Are you sure you want to send ${totalTokens} tokens to ${users.length} users?`,
            onOkHandler: onSend,
            withCancelButton: true
        });
    };

    useEffect(() => {
        users &&
            setArrayTokensUsers(
                users.map(user => ({
                    user,
                    tokens: '0'
                }))
            );
    }, [users]);

    useEffect(() => {
        const totalTokens = arrayTokensUsers.reduce((previousValue, { tokens }) => previousValue + Number(tokens), 0);

        setTotalTokens(totalTokens.toString());
    }, [arrayTokensUsers]);

    return (
        <>
            <MainLayout>
                <AirdropStyled isMobile={isMobile}>
                    <MarginWrapper marginBottom={isMobile ? '24px' : '0'}>
                        {!isMobile ? <Breadcrumb /> : <Text size="p">/Airdrop</Text>}
                    </MarginWrapper>

                    <Section justifyBetween marginBottom={isMobile ? '20px' : '40px'}>
                        <Row
                            columnGap="16px"
                            marginBottom={isMobile ? '12px' : '0'}
                            marginRight={isMobile ? '0' : '35px'}
                        >
                            <Button buttonType="blue" size="small" onClick={() => setModalVisible(true)}>
                                Add user
                            </Button>

                            {Boolean(checkedUsersForDeleted.length) && (
                                <Button buttonType="red" size="small" onClick={handleClickButtonDeleteCheckedUsers}>
                                    Delete
                                </Button>
                            )}
                        </Row>

                        <Row alignCenter justifyBetween={isMobile}>
                            <Row alignCenter columnGap="10px" marginRight="16px">
                                <Span color={!users.length ? textDisabled : white}>
                                    Quantity of tokens for all users
                                </Span>

                                {!users.length ? (
                                    <InfoButton disabled={!users.length} />
                                ) : (
                                    <Tooltip
                                        id="tokensForAllUsers"
                                        placement="top-end"
                                        title="The total number of tokens will apply to all users in the list"
                                    >
                                        <InfoButton />
                                    </Tooltip>
                                )}
                            </Row>

                            <TokensInput
                                disabled={!users.length}
                                tokens={tokensAllUsers}
                                onChangeTokens={onChangeAllUsersTokens}
                            />
                        </Row>
                    </Section>

                    <Section>
                        <SimpleTable withoutMarginBottom tbody={airdropTableContentData} thead={airdropTableColumns} />
                        {!users.length && <NotUsersMessage>Add a user to display data</NotUsersMessage>}
                    </Section>

                    <AbsoluteWrapper left="0">
                        <FixedBottomWrapper>
                            <Span>
                                Total: {totalTokens} tokens for {users.length} users
                            </Span>
                        </FixedBottomWrapper>
                    </AbsoluteWrapper>

                    <AbsoluteWrapper right="66px">
                        <FixedBottomWrapper>
                            <Button
                                buttonType="blue"
                                disabled={!tokensAllUsers || tokensAllUsers === '0'}
                                size="small"
                                onClick={handleClickSendTokensUsers}
                            >
                                Send
                            </Button>
                        </FixedBottomWrapper>
                    </AbsoluteWrapper>
                </AirdropStyled>
            </MainLayout>
            <AirdropSelectModal visible={modalVisible} onClose={() => setModalVisible(false)} />
        </>
    );
};
