import { defaultBalanceHistorical } from 'constants/defaults/wallet-admin';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'services';

const updateValues = createEvent<Omit<WOM.WalletBalanceHistoricalQueryRequest, 'limit'>>();
const values = createStore<WOM.WalletBalanceHistoricalQueryRequest>(defaultBalanceHistorical).on(
    updateValues,
    (state, newState) => ({ ...state, ...newState })
);

const getBalanceHistorical = createEffect({
    handler: async (value: WOM.WalletBalanceHistoricalQueryRequest) => {
        try {
            return await API.walletAdmin.getBalanceHistorical(value);
        } catch {
            return {};
        }
    }
});

const balanceHistorical = createStore<WOM.WalletBalanceHistoricalQueryResponse>({}).on(
    getBalanceHistorical.doneData,
    (_, newState) => newState
);

forward({ from: values, to: getBalanceHistorical });

export const walletAdminEvents = { updateValues };
export const walletAdminStores = { balanceHistorical, values };
export const walletAdminEffects = { getBalanceHistorical };
