import { propertyTitleColor } from 'constants/styles';
import { PoppinsFontFamily } from 'constants/styles/fonts';
import styled, { css } from 'styled-components';
import { TextAlignment } from 'types/interfaces/styles';

export type Size = 'h1' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

interface TextProps extends TextAlignment {
    color?: string;
    size?: Size;
    isBold?: boolean;
    isUppercase?: boolean;
}

export const getTitleStyles = (size: Size) => {
    switch (size) {
        case 'h1':
            return css`
                font-size: 38px;
                font-weight: 600;
                line-height: 46px;
            `;

        case 'h3':
            return css`
                font-size: 24px;
                line-height: 32px;
            `;

        case 'h4':
            return css`
                font-size: 17px;
                line-height: 24px;
            `;

        case 'h5':
            return css`
                font-size: 13px;
                line-height: 18px;
            `;

        case 'h6':
            return css`
                font-size: 12px;
                line-height: 14px;
            `;

        case 'p':
            return css`
                font-size: 14px;
                line-height: 20px;
            `;
    }
};

export const Text = styled.span<TextProps>`
    font-family: ${PoppinsFontFamily};
    font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
    color: ${({ color }) => color || propertyTitleColor};
    ${({ size = 'h1' }) => getTitleStyles(size)};
    ${({ isUppercase }) => isUppercase && 'text-transform: uppercase'};
    ${({ alignTextCenter }) => alignTextCenter && 'text-align: center'};
`;
