import { Span } from 'components/common/typography/Span';
import { Column, Row } from 'components/grid/wrappers/flexWrapper';
import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { propertyBackground, propertyTitleColor, xs } from 'constants/styles';

import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MinSizes, Title } from 'types';
import { propertyMinWidth } from '../DescriptionProperty/constants';

interface DescriptionContentProps extends Pick<Title, 'title'>, MinSizes {}

export const DescriptionContent: FC<DescriptionContentProps> = ({ children, title, minHeight }) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    return (
        <Column width={isMobile ? '100%' : 'auto'}>
            <Row marginBottom="15px">
                <Span color={propertyTitleColor}>{title}</Span>
            </Row>
            <ContentWrapper
                backgroundColor={propertyBackground}
                minHeight={minHeight || '100px'}
                minWidth={propertyMinWidth}
                padding="17px 15px"
                width={isMobile ? '100%' : 'auto'}
            >
                <Row width={isMobile ? '100%' : 'auto'}>{children}</Row>
            </ContentWrapper>
        </Column>
    );
};
