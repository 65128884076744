//@ts-nocheck
import React from 'react';
import { SimpleTable } from 'components/common/tables/SimpleTable';
import { noContentMessage } from 'constants/notifications';
import { camelCaseToCapitalizedWords, currencyToStandardForm } from 'utils/usefulFunctions';
import { defaultSortValue } from 'constants/messages';
import {
    getPerformancePaymentsData,
    unionPerformancePaymentsTableColumns
} from 'pages/PerformancePayments/PerformancePaymentsExpandedTable/UnionPerformancePaymentsTable/constants';

interface UnionPerformancePaymentsTableProps {
    items: WOM.KeyOfPerformancePaymentsValues[];
}

export const UnionPerformancePaymentsTable = ({ items }: UnionPerformancePaymentsTableProps) => {
    const tmpPerformancePaymentsData = getPerformancePaymentsData(items);

    const unionPerformancePaymentsData = tmpPerformancePaymentsData.map(({ nameParam, value, percent }) => {
        const isTotal = nameParam === 'Total';
        const name = camelCaseToCapitalizedWords(nameParam);

        return {
            data: {
                nameParam: (isTotal ? <b>{name}</b> : name) || <span>{noContentMessage}</span>,
                value: {
                    jsx: isTotal ? (
                        <b>{value ? currencyToStandardForm(value) : defaultSortValue}</b>
                    ) : (
                        <>{value ? currencyToStandardForm(value) : defaultSortValue}</>
                    ),
                    value: value || defaultSortValue
                },
                percent: {
                    jsx: isTotal ? (
                        <b>{percent ? currencyToStandardForm(percent) : defaultSortValue} %</b>
                    ) : (
                        <>{percent ? currencyToStandardForm(percent) : defaultSortValue} %</>
                    ),
                    value: percent || defaultSortValue
                }
            }
        };
    });

    return <SimpleTable responsive tbody={unionPerformancePaymentsData} thead={unionPerformancePaymentsTableColumns} />;
};
