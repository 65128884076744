import { SearchSelectInput } from 'components/common/inputs/SearchSelectInput';
import { InfoTitle } from 'components/common/typography/InfoTitle';
import { Text } from 'components/common/typography/Text';
import { Flex, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Pagination } from 'components/layouts/Pagination';
import { defaultLimit, defaultPage, sortTagsUsersValues } from 'constants/defaults';
import { useStore } from 'effector-react';
import { useQueryParams } from 'hooks/queryParams';
import { SearchWrapper } from 'pages/Users/UsersFilterLayout/styles';
import React, { FC, useEffect } from 'react';
import Select from 'react-select';
import { Col } from 'reactstrap';
import { UserQueryValues, usersEvents, usersStores } from 'stores/users';
import { SelectOptionType, TotalRecords } from 'types';

const { updateValues, setIsFirstToFalse, invokeGetItems } = usersEvents;

interface UsersQueryParams extends Partial<UserQueryValues> {}

interface UsersFilterLayoutProps extends TotalRecords {}

export const UsersFilterLayout: FC<UsersFilterLayoutProps> = ({ children, totalRecords }) => {
    const { pageIndex, limit, mobileNumber, rolesAny, userName, walletIds, email, userId } = useStore(
        usersStores.values
    );
    const isFirst = useStore(usersStores.isFirst);

    const updateQueryValues = (values: UsersQueryParams) => {
        updateValues(values);
    };

    const [queryParams, setQueryParams] = useQueryParams<UsersQueryParams>(updateQueryValues);

    // const onResetClick = () => {
    //     setQueryParams({});
    //     document.location.reload();
    // };

    // const onTextSearch = (text: string) => {
    //     updateValues({
    //         textSearch: text || undefined,
    //         pageIndex: defaultPage
    //     });
    // };

    const onUsernameSearch = (name: string) => {
        updateValues({
            userName: name || undefined,
            pageIndex: defaultPage
        });
    };

    const onEmailSearch = (email: string) => {
        updateValues({
            email: email || undefined,
            pageIndex: defaultPage
        });
    };

    const onPhoneNumberSearch = (phone: string) => {
        updateValues({
            mobileNumber: phone || undefined,
            pageIndex: defaultPage
        });
    };

    const onWalletIdSearch = (id: string) => {
        updateValues({
            walletIds: id ? [id] : undefined,
            pageIndex: defaultPage
        });
    };

    const onIdSearch = async (id: string) => {
        updateValues({
            userId: id || undefined,
            pageIndex: defaultPage
        });
    };

    const onSortChange = (option: SelectOptionType | null) => {
        const sort = option ? option.value : undefined;

        updateValues({
            rolesAny: sort && sort !== sortTagsUsersValues[0].value ? [sort] : undefined,
            pageIndex: defaultPage
        });
    };

    const onCurrentPageChange: (page: number, pageSize: number | undefined) => void = (page, pageSize) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    const searchItems = [
        {
            option: {
                value: 'userId',
                label: 'User ID'
            },
            onSearch: onIdSearch
        },
        {
            option: {
                value: 'userName / remoteId',
                label: 'Username / Remote ID'
            },
            onSearch: onUsernameSearch
        },
        {
            option: {
                value: 'mobilePhone',
                label: 'Mobile Phone'
            },
            onSearch: onPhoneNumberSearch
        },
        {
            option: {
                value: 'email',
                label: 'Email'
            },
            onSearch: onEmailSearch
        },

        {
            option: {
                value: 'walletId',
                label: 'Wallet ID'
            },
            onSearch: onWalletIdSearch
        }
    ];

    useEffect(() => {
        if (isFirst && !queryParams.userId) {
            invokeGetItems();
            setIsFirstToFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setQueryParams({
            userName,
            mobileNumber,
            walletIds,
            email,
            rolesAny,
            userId,
            pageIndex,
            limit
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, userName, email, mobileNumber, walletIds, pageIndex, limit, rolesAny]);

    return (
        <>
            <Flex alignEnd rowGap="15px">
                <Col lg={4} llg={4} md={4} sm={6} xl={4} xs={12}>
                    <MarginWrapper>
                        <Text size="h5">Filter by role</Text>
                    </MarginWrapper>
                    <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="sorts"
                        options={sortTagsUsersValues}
                        value={
                            rolesAny?.length
                                ? sortTagsUsersValues.find(({ value }) => value === rolesAny[0])
                                : sortTagsUsersValues[0]
                        }
                        onChange={value => onSortChange(value)}
                    />
                </Col>
                <Col lg={8} llg={8} md={8} sm={12} xl={8} xs={12}>
                    <SearchWrapper>
                        <SearchSelectInput items={searchItems} />
                    </SearchWrapper>
                </Col>
            </Flex>

            <InfoTitle title="Total: ">{totalRecords !== -1 ? totalRecords : 0}</InfoTitle>

            <Section minHeight="calc(100vh - 270px)">
                <Col>{children}</Col>
            </Section>
            <Section alignCenter justifyCenter>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Section>
        </>
    );
};
