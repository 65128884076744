import { TableHeadInterface } from 'types';

export const transactionTableColumns: TableHeadInterface[] = [
    {
        title: 'Field',
        dataIndex: 'field',
        key: 'field',
        style: { className: 'text-start' }
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        style: { className: 'text-start' }
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action'
    }
];
