import externalLinkIcon from 'assets/img/external_link_icon.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import React, { DetailedHTMLProps } from 'react';

interface ExternalLinkButtonProps
    extends Pick<DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'disabled'> {
    to: string;
}

export const ExternalLinkButton = ({ disabled, to }: ExternalLinkButtonProps) => {
    const onClick = () => {};

    return (
        <button className="external-link-button" disabled={disabled} onClick={onClick}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={to} rel="noreferrer" target="_blank">
                <CustomImg alt="external link" className="external-link-icon" src={externalLinkIcon} />
            </a>
        </button>
    );
};
