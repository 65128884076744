import { createEvent, createStore } from 'effector';
import { Visibility } from 'types/interfaces/styles';

interface Modal extends Required<Visibility> {}

const initializeStoreModal = () => {
    const openModal = createEvent();
    const closeModal = createEvent();

    const modal = createStore<Modal>({
        visible: false
    })
        .on(openModal, () => ({
            visible: true
        }))
        .on(closeModal, () => ({
            visible: false
        }));

    return { modal, openModal, closeModal };
};

export const userReportModal = initializeStoreModal();

// const initializeGenericStore = <T>(initialState: T) => {
//     const openModal = createEvent<T | void>();
//     const closeModal = createEvent();
//     const setState = createEvent<T>();

//     const visible = createStore(false)
//         .on(openModal, () => true)
//         .on(closeModal, () => false);
//     const state = createStore<T>(initialState).on([openModal, setState], (_, newState) => newState);
//     const modal = combine(visible, state);

//     return { modal, openModal, closeModal, setState };
// };
