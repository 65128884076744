import { flexStart, padding } from 'constants/styles';
import styled from 'styled-components';
import { FlexBooleanAlignment, RemoveMarginRightBottom } from 'types';

interface Props extends RemoveMarginRightBottom, FlexBooleanAlignment {}

export const Section = styled.section<Props>`
    width: 100%;
    ${flexStart};
    ${({ justifyCenter }) => (justifyCenter ? 'justify-content: center;' : '')}
    ${({ alignCenter }) => (alignCenter ? 'align-items: center;' : '')}
    flex-direction: row;
    flex-wrap: wrap;
    ${({ removeMarginBottom }) => (removeMarginBottom ? `` : `margin-bottom: ${padding}`)};
    ${({ removeMarginRight }) => (removeMarginRight ? `` : `padding-right: calc(2 * ${padding})`)};
`;
