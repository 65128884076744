import { InfoTitle } from 'components/common/typography/InfoTitle';
import { useStore } from 'effector-react';
import { useQueryParams } from 'hooks/queryParams';
import React, { FC, useEffect } from 'react';
import { usersEffects, usersStores } from 'stores/users';
import { TotalRecords } from 'types';

const { getWithdrawalByAddressTo } = usersEffects;

export const WithdrawalCheckFilterLayout: FC<TotalRecords> = ({ children, totalRecords }) => {
    const { addressTo } = useStore(usersStores.withdrawalCheck);

    const updateQueryValues = ({ addressTo }: WOM.CheckWithdrawalRequest) => {
        addressTo && getWithdrawalByAddressTo(addressTo);
    };

    const [, setQueryParams] = useQueryParams<WOM.CheckWithdrawalRequest>(updateQueryValues, { parseNumbers: false });

    useEffect(() => {
        setQueryParams({
            addressTo
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressTo]);

    return (
        <>
            <InfoTitle title="Total: ">{totalRecords !== -1 ? totalRecords : 0}</InfoTitle>
            {children}
        </>
    );
};
