import React, { InputHTMLAttributes } from 'react';
import { CustomInput } from 'reactstrap';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { Title } from 'types/interface';
import { Span } from 'components/common/typography/Span';
import { grey5 } from 'constants/styles';

interface CheckboxProps
    extends Pick<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'checked'>,
        Pick<Title, 'title'> {}

export const Checkbox = ({ onChange, checked, title }: CheckboxProps) => (
    <Row alignCenter noWrap>
        <Span noWrap color={grey5} fontSize="13px">
            {title}
        </Span>

        <Row height="42px" marginLeft="14px" width="80px">
            <CustomInput checked={checked} id="filterByContext" type="switch" onChange={onChange} />
        </Row>
    </Row>
);
