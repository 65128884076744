import { SelectedChartItemsTableDataProps, SelectedUsersDataProps } from '../types';

export const getTableDataByUserId = (
    items: WOM.WalletBalanceHistoricalDailyItem[] | null | undefined,
    userData: SelectedUsersDataProps[]
) => {
    if (!items) return;

    const tableData: SelectedChartItemsTableDataProps[] = [];

    items.forEach(({ items }) => {
        items?.forEach(item => {
            userData.forEach(({ userId, color }) => {
                if (userId === item.userId) {
                    tableData.push({ ...item, color: color });
                }
            });
        });
    });

    return tableData;
};
