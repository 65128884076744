import { HrProps } from 'components/common/dividers/HorizontalLine/types';
import styled from 'styled-components';

export const HorizontalLine = styled.hr<HrProps>`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '1px'};
    border: none;
    background-color: ${({ background }) => background || 'rgba(255, 255, 255, 0.03);'};
    margin: 0;
    ${({ opacity }) => opacity && `opacity: ${opacity};`};
`;
