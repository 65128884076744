import filedIcon from 'assets/img/failed_transaction.svg';
import { ReactComponent as WomIcon } from 'assets/wom_icon.svg';
import classNames from 'classnames';
import { TransactionInProgress } from 'components/common/dynamic/Loader';
import { InternalCopyLink } from 'components/common/links/InternalCopyLink';
import { SimpleTable } from 'components/common/tables/SimpleTable';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Icon, ValueWrapper } from 'components/common/tables/TransactionsTable/styles';
import { womNarrativeTypeObject } from 'constants/filters';
import { defaultSortValue } from 'constants/messages';
import { noContentMessage } from 'constants/notifications';
import { transactionsLink, withdrawalCheckLink } from 'constants/routes';
import React from 'react';
import { useHistory } from 'react-router';
import { usersEffects } from 'stores/users';
import { currencyToStandardForm, getEllipsisAddress, parseCalendarDate } from 'utils/usefulFunctions';
import { transactionTableColumns } from './constants';

interface TransactionsTableProps extends Pick<WOM.TransactionQueryResponse, 'items'> {}

export const TransactionsTable = ({ items }: TransactionsTableProps) => {
    const history = useHistory();

    const onWithdrawalCheckClick = (addressTo: string) => () => {
        history.push(withdrawalCheckLink);
        usersEffects.getWithdrawalByAddressTo(addressTo);
    };

    const transactionsTableContentData = (items as WOM.TransactionResponse[]).map(
        ({ type, status, from, to, utcCreated, transactionId, value }) => ({
            data: {
                transactionId: transactionId ? (
                    <InternalCopyLink
                        href={transactionsLink + '/' + transactionId}
                        subject={transactionId}
                        success="You successfully copied user id"
                    >
                        {transactionId}
                    </InternalCopyLink>
                ) : (
                    <span>{noContentMessage}</span>
                ),
                type: type !== undefined ? womNarrativeTypeObject[type] : <span>{noContentMessage}</span>,
                status:
                    status === 1 ? (
                        <TransactionInProgress />
                    ) : status === 2 ? (
                        <i className={classNames('tim-icons', 'icon-check-2')} />
                    ) : (
                        <img alt="failed icon" height="10px" src={filedIcon} width="10px" />
                    ),

                from: from ? getEllipsisAddress(from) : <span>{noContentMessage}</span>,
                to: to ? (
                    <>
                        <Row alignCenter justifyCenter noWrap>
                            <MarginWrapper marginRight="4px">{getEllipsisAddress(to)}</MarginWrapper>
                            {type && womNarrativeTypeObject[type] === 'Withdrawal' && (
                                <Icon
                                    className={classNames('tim-icons', 'icon-paper')}
                                    onClick={onWithdrawalCheckClick(to)}
                                />
                            )}
                        </Row>
                    </>
                ) : (
                    <span>{noContentMessage}</span>
                ),
                utcCreated:
                    utcCreated !== undefined ? (
                        parseCalendarDate(new Date(utcCreated))
                    ) : (
                        <span>{noContentMessage}</span>
                    ),
                value: {
                    jsx: (
                        <ValueWrapper>
                            <MarginWrapper marginRight="4px">
                                {value ? currencyToStandardForm(value) : defaultSortValue}
                            </MarginWrapper>
                            <WomIcon />
                        </ValueWrapper>
                    ),
                    value: value || defaultSortValue
                }
            },
            className: 'text-start'
        })
    );

    return <SimpleTable responsive tbody={transactionsTableContentData} thead={transactionTableColumns} />;
};
