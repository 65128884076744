import { noContentColor } from 'constants/styles';
import styled from 'styled-components';
import { MaxSizes, Sizes } from 'types/interface';

interface InputWrapperProps extends Sizes, MaxSizes {}

export const InputWrapper = styled.div<InputWrapperProps>`
    position: relative;
    max-width: 374px;
    width: ${({ width }) => (width ? width : '100%')};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
    height: ${({ height }) => (height ? height : '100%')};
    background-color: inherit;

    display: flex;
    align-items: center;

    @media (max-width: 460px) {
        min-width: auto;
        max-width: none;
    }
`;

export const Input = styled.input`
    width: 100%;
    border: 1px solid ${noContentColor};
    border-radius: 6px;
    background-color: inherit;
    padding: 8px 9px;
    padding-right: 28px;
    color: white;

    ::placeholder {
        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.15);
        opacity: 0.8;
    }
`;
