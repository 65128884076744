import React from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useStore } from 'effector-react';
import { modalEffects, modalEvents, modalStores } from 'stores/modal';
import { ModalLoader } from 'components/common/dynamic/Loader';
import { noop } from 'constants/global';

const { confirmationAction } = modalEffects;

const styleParameters = { display: 'block', marginTop: '-100px', width: '600px' };

export const ConfirmationModal = () => {
    const {
        visible,
        confirmationButtonText,
        confirmationQuestion,
        confirmationText,
        onConfirm,
        errorMessage,
        successMessage,
        successTitle,
        isSuccess,
        hasError
    } = useStore(modalStores.confirmationModal);
    const loading = useStore(confirmationAction.pending);

    const onClose = () => modalEvents.closeConfirmationModal();
    const onConfirmClick = () => confirmationAction(onConfirm);

    if (visible && loading)
        return (
            <ReactBSAlert showConfirm={false} style={styleParameters} title="Loading" onCancel={noop} onConfirm={noop}>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <ModalLoader />
                </div>
            </ReactBSAlert>
        );

    if (visible && isSuccess)
        return (
            <ReactBSAlert
                success
                btnSize=""
                confirmBtnBsStyle="success"
                style={styleParameters}
                title={successTitle}
                onCancel={onClose}
                onConfirm={onClose}
            >
                {successMessage}
            </ReactBSAlert>
        );

    if (visible && hasError)
        return (
            <ReactBSAlert
                danger
                btnSize=""
                confirmBtnBsStyle="success"
                style={styleParameters}
                title="Cancelled"
                onCancel={onClose}
                onConfirm={onClose}
            >
                {errorMessage}
            </ReactBSAlert>
        );

    return (
        <>
            {visible && (
                <ReactBSAlert
                    showCancel
                    warning
                    btnSize=""
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="success"
                    confirmBtnText={confirmationButtonText}
                    style={styleParameters}
                    title={confirmationQuestion}
                    onCancel={onClose}
                    onConfirm={onConfirmClick}
                >
                    {confirmationText}
                </ReactBSAlert>
            )}
        </>
    );
};
