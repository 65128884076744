import { Dictionary, SelectOptionType } from 'types';

export const sortModeTagsName = 'Filter mode: ';
export const sortTagsName = 'Filter by';

export const sortModeTagsValues = ['+asc', '+desc'];
export const sortModeTagsData = ['ascending', 'descending'];

export const sortTagsValues = ['likes', 'views', 'saves', 'shares'];

export const sortTagsTransactionsStatusValues: Array<WOM.TransactionStatus | undefined> = [undefined, 0, 1, 2, -1];
export const sortTagsTransactionsStatusData = ['All', 'None', 'In progress', 'Success', 'Failure'];

interface sortTagsOption {
    value: WOM.TransactionStatus | undefined;
    label: string;
}
export const sortTagsTransactionsStatusOption: sortTagsOption[] = [
    { value: undefined, label: 'All' },
    { value: 0, label: 'None' },
    { value: 1, label: 'In progress' },
    { value: 2, label: 'Success' },
    { value: -1, label: 'Failure' }
];

export const getLabelTransactionsStatusFilter = (value: WOM.TransactionStatus | undefined) => {
    const item = sortTagsTransactionsStatusOption.find(it => it.value === value);
    return item ? { value, label: item.label } : { value: undefined, label: 'All' };
};

export const sortTransactionsStatusLabels = sortTagsTransactionsStatusData.map(value => ({ value, label: value }));
export const getTransactionsStatusLabel = (status: WOM.TransactionStatus | undefined) => {
    const labelIndex = status !== undefined ? sortTagsTransactionsStatusValues.findIndex(value => value === status) : 0;
    return sortTransactionsStatusLabels[labelIndex];
};
export const getTransactionsStatusValue = (value: SelectOptionType | null) => {
    const valueIndex = value ? sortTransactionsStatusLabels.findIndex(({ label }) => label === value?.label) : 0;
    return sortTagsTransactionsStatusValues[valueIndex];
};

export const sortTagsTransactionsTypeValues: Array<WOM.WOMNarrativeType | undefined> = [
    undefined,
    1000,
    100,
    200,
    600,
    303
];
export const sortTagsTransactionsTypeData = [
    'All',
    'PP and Validation Reward',
    'Deposit',
    'Withdrawal',
    'User Transfer and Staking',
    'Creator Bonus'
];

interface sortTagsTransactionsTypeOption {
    value: WOM.WOMNarrativeType | undefined;
    label: string;
}

// eslint-disable-next-line no-redeclare
export const sortTagsTransactionsTypeOption: sortTagsTransactionsTypeOption[] = [
    {
        value: undefined,
        label: 'All'
    },

    {
        value: 100,
        label: 'Deposit'
    },
    {
        value: 200,
        label: 'Withdrawal'
    },
    {
        value: 300,
        label: 'Creator Stake'
    },
    {
        value: 301,
        label: 'Creator Reward'
    },
    {
        value: 302,
        label: 'Creator Stake Refund'
    },
    {
        value: 303,
        label: 'Creator Bonus'
    },
    {
        value: 400,
        label: 'Validation Stake'
    },
    {
        value: 401,
        label: 'Validation Reward'
    },
    {
        value: 402,
        label: 'Validation Stake Refund'
    },
    {
        value: 410,
        label: 'Validation Profit'
    },
    {
        value: 500,
        label: 'Exchange'
    },
    {
        value: 600,
        label: 'User Transfer'
    },
    {
        value: 700,
        label: 'Reward'
    },
    {
        value: 800,
        label: 'Migration Sync'
    },
    {
        value: 900,
        label: 'Campaign Payment'
    },
    {
        value: 1000,
        label: 'Performance Payment'
    },
    {
        value: 1001,
        label: 'Performance Payment Creator'
    },
    {
        value: 1002,
        label: 'Performance Payment Authenticator'
    },
    {
        value: 1003,
        label: 'Performance Payment Facilitator'
    },
    {
        value: 1004,
        label: 'Performance Payment Publisher'
    },
    {
        value: 1005,
        label: 'Performance Payment Display Network'
    },
    {
        value: 1006,
        label: 'Performance Payment Advertiser'
    },
    {
        value: 1007,
        label: 'Performance Payment Brand'
    },
    {
        value: 2000,
        label: 'Gift Card Payment'
    },
    {
        value: 2100,
        label: 'Gift Card Refund'
    },
    {
        value: 3000,
        label: 'Organization Purchase'
    },
    {
        value: 5000,
        label: 'Agent Payout'
    }
];

export const getLabelTransactionsTypeFilter = (value: WOM.WOMNarrativeType | undefined) => {
    const item = sortTagsTransactionsTypeOption.find(it => it.value === value);
    return item ? { value, label: item.label } : { value: undefined, label: 'All' };
};

export const sortTagsTransactionsByValue = ['All', 'Ascending', 'Descending'];
export const sortTagsTransactionsByValueLabels = sortTagsTransactionsByValue.map(value => ({ value, label: value }));
export const sortTagsTransactionsTypeLabels = sortTagsTransactionsTypeOption.map(({ label: value }) => ({
    value,
    label: value
}));
export const getTransactionsTypeLabel = (status: WOM.WOMNarrativeType | undefined) => {
    const labelIndex =
        status !== undefined ? sortTagsTransactionsTypeOption.findIndex(({ value }) => value === status) : 0;
    return {
        value: sortTagsTransactionsTypeOption[labelIndex].label,
        label: sortTagsTransactionsTypeOption[labelIndex].label
    };
};
export const getTransactionsTypeValue = (value: SelectOptionType | null) => {
    const valueIndex = value ? sortTagsTransactionsTypeLabels.findIndex(({ label }) => label === value?.label) : 0;
    return sortTagsTransactionsTypeOption[valueIndex].value;
};

export const sortTagsTransactionsDelayValues: Array<number | null> = [null, 5000, 10000, 30000, 60000, 300000, 600000];
export const sortTagsTransactionsDelayData = ['Never', '5с', '10с', '30с', '1м', '5м', '10м'];
export const sortTagsValuesTransactionsStatusDefault = sortTagsTransactionsStatusValues[0];
export const sortTagsValuesTransactionsTypeDefault = sortTagsTransactionsTypeValues[0];
export const sortTagsValuesTransactionsDelayDefault = sortTagsTransactionsDelayValues[0];

export const transactionStatusObject: Dictionary<string> = {
    0: 'None',
    1: 'In progress',
    2: 'Success',
    '-1': 'Failure'
};

/**
 * WOMNarrativeType
 * <br/><br/>Values:<br/>0 = None<br/>100 = Deposit<br/>200 = Withdrawal<br/>300 = CreatorStake<br/>301 = CreatorReward<br/>302 = CreatorStakeRefund<br/>303 = CreatorBonus<br/>400 = ValidationStake<br/>401 = ValidationReward<br/>402 = ValidationStakeRefund<br/>410 = ValidationProfit<br/>500 = Exchange<br/>600 = UserTransfer<br/>700 = Reward<br/>800 = MigrationSync<br/>900 = CampaignPayment<br/>1000 = PerformancePayment<br/>1001 = PerformancePaymentCreator<br/>1002 = PerformancePaymentAuthenticator<br/>1003 = PerformancePaymentFacilitator<br/>1004 = PerformancePaymentPublisher<br/>1005 = PerformancePaymentDisplayNetwork<br/>1006 = PerformancePaymentAdvertiser<br/>1007 = PerformancePaymentBrand<br/>2000 = GiftCardPayment<br/>2100 = GiftCardRefund
 */
export const womNarrativeTypeObject: Dictionary<string> = {
    0: 'None',
    100: 'Deposit',
    200: 'Withdrawal',
    300: 'Creator Stake',
    301: 'Creator Reward',
    302: 'Creator StakeRefund',
    303: 'Creator Bonus',
    400: 'Validation Stake',
    401: 'Validation Reward',
    402: 'Validation StakeRefund',
    410: 'Validation Profit',
    500: 'Exchange',
    600: 'User Transfer',
    700: 'Reward',
    800: 'Migration Sync',
    900: 'Campaign Payment',
    1000: 'Performance Payment',
    1001: 'Performance Payment Creator',
    1002: 'Performance Payment Authenticator',
    1003: 'Performance Payment Facilitator',
    1004: 'Performance Payment Publisher',
    1005: 'Performance Payment Display Network',
    1006: 'Performance Payment Advertiser',
    1007: 'Performance Payment Brand',
    2000: 'Gift cards',
    2100: 'Gift card refund',
    3000: 'Organization Purchase',
    4000: 'Escrow In',
    4001: 'Escrow Release',
    5000: 'Agent Payout',
    10000: 'Platform Fee'
};
export const womNarrativeType: WOM.WOMNarrativeType[] = [0, 100, 200, 300, 301, 302, 400, 401, 402, 500, 600, 700, 800];
