import { Loader } from 'components/common/dynamic/Loader';
import { InfoTitle } from 'components/common/typography/InfoTitle';
import { Text } from 'components/common/typography/Text';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Empty } from 'components/layouts/Empty';
import { MainLayout } from 'components/layouts/MainLayout';
import { useStore } from 'effector-react';
import {
    getUnionPayoutStatistics,
    sortingByPayoutNameValue
} from 'pages/PerformancePayments/PerformancePaymentsSingle/constants';
import { PerformancePaymentsContentSingleTable } from 'pages/PerformancePayments/PerformancePaymentsSingle/PerformancePaymentsContentSingleTable';
import { UnionPayoutsStatisticsTable } from 'pages/PerformancePayments/PerformancePaymentsSingle/UnionPayoutsStatisticsTable';
import { VideoDescription } from 'pages/PerformancePayments/PerformancePaymentsSingle/VideoDescription';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import { performancePaymentsEffects, performancePaymentsStores } from 'stores/performancePayments';
import { videosEffects, videosStores } from 'stores/videos';

interface PerformancePaymentsParams {
    contentId: string;
    auditId: string;
}

export const PerformancePaymentsSingle = () => {
    const { contentId, auditId } = useParams<PerformancePaymentsParams>();
    const [contentLoading, contentItems] = useStore(performancePaymentsStores.contentItemsStore);
    const sortedContentItems = contentItems?.items?.sort(sortingByPayoutNameValue) || [];

    const unionPayoutStatistics = getUnionPayoutStatistics(sortedContentItems);

    const videoItem = useStore(videosStores.item);
    const loadingVideo = useStore(videosStores.loading);

    useEffect(() => {
        performancePaymentsEffects.getContentItems({ contentId, auditId });
        videosEffects.getItemById(contentId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainLayout>
            <Breadcrumb isPerformancePage />
            {loadingVideo ? (
                <Section alignCenter justifyCenter height="330px">
                    <Loader />
                </Section>
            ) : (
                <>
                    <Section marginBottom="16px">
                        <Text size="h4">Video info</Text>
                    </Section>
                    <Row>
                        <Col>
                            {videoItem.womContentId ? (
                                <VideoDescription {...videoItem} />
                            ) : (
                                <MarginWrapper marginTop="48px">
                                    <Empty description="Video not found" />
                                </MarginWrapper>
                            )}
                        </Col>
                    </Row>
                </>
            )}
            {contentLoading ? (
                <Section alignCenter justifyCenter height="100%">
                    <Loader />
                </Section>
            ) : sortedContentItems?.length ? (
                <>
                    <Section marginBottom="16px">
                        <Text size="h4">Union Payouts Statistics</Text>
                    </Section>
                    <Row>
                        <Col md={6} sm={12}>
                            <UnionPayoutsStatisticsTable items={unionPayoutStatistics} />
                        </Col>
                    </Row>
                    <Section marginBottom="16px">
                        <Text size="h4">Payouts</Text>
                    </Section>

                    <InfoTitle title="Total: ">{sortedContentItems?.length || 0}</InfoTitle>

                    <Row>
                        <Col>
                            <PerformancePaymentsContentSingleTable items={sortedContentItems} />
                        </Col>
                    </Row>
                </>
            ) : (
                <Section alignCenter justifyCenter marginTop="48px">
                    <Empty description="Nothing found" />
                </Section>
            )}
        </MainLayout>
    );
};
