import { noop } from 'constants/global';
import { asyncError, editSuccessMessage } from 'constants/notifications';
import { createEffect, createEvent, createStore, restore } from 'effector';
import { API } from 'services';
import { loadingEffects } from 'stores/loading';
import { AsyncModal, EditVideoModalProps, InitialEditVideoModalProps, Visible } from 'types';
import { notificationEvents } from './notification';

const getInitialVideoEditData = createEffect({
    handler: async ({ setFields = noop, setAdditionalIds = noop, id }: InitialEditVideoModalProps) => {
        try {
            loadingEffects.updateInitialLoading();
            const data = await API.adminVideos.getCardById({ id: id });
            setAdditionalIds({
                ownerId: data.ownerId || '',
                primaryProductId: data.primaryProductId || ''
            });
            const editableData = {
                title: data.title,
                subtitle: data.subtitle,
                hashTags: data.hashTags
            };

            setFields(editableData);
            loadingEffects.updateInitialLoading();
        } catch {
            loadingEffects.updateInitialLoading();

            notificationEvents.setNotification({
                place: 'tc',
                message: asyncError,
                type: 'danger',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });
        }
    }
});

const editVideoInfo = createEffect({
    handler: async ({ onChange = noop, setVisible = noop, ...values }: EditVideoModalProps) => {
        try {
            loadingEffects.updateLoading();
            await API.adminVideos.editVideoInfo(values);
            loadingEffects.updateLoading();

            onChange({
                title: values.title || '',
                subtitle: values.subtitle || '',
                hashTags: values.hashTags || []
            });

            setVisible(false);

            notificationEvents.setNotification({
                place: 'tc',
                message: editSuccessMessage,
                type: 'primary',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });
        } catch {
            loadingEffects.updateLoading();

            notificationEvents.setNotification({
                place: 'tc',
                message: asyncError,
                type: 'danger',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });
        }
    }
});

// *********** AsyncModal ***********

const openAsyncModal = createEvent<AsyncModal>();
const closeAsyncModal = createEvent();

const asyncModal = createStore<AsyncModal>({
    visible: false,
    title: '',
    content: ''
})
    .on(openAsyncModal, (_, newState) => newState)
    .on(closeAsyncModal, () => ({
        visible: false,
        title: '',
        content: '',
        okHandler: noop
    }));

// *********** EditOrganizationModal ***********

interface EditOrganizationModalProps {
    visible?: boolean;
    organizationId?: string;
    publicId?: string;
    title?: string;
    mandatoryTags?: string[];
    adminIds?: string[];
    origin?: string;
    walletId?: string;
}

const initialEditOrganizationModal: EditOrganizationModalProps = {
    visible: false
};

const openEditOrganizationModal = createEvent<EditOrganizationModalProps>();
const closeEditOrganizationModal = createEvent();

const editOrganizationModal = createStore<EditOrganizationModalProps>(initialEditOrganizationModal)
    .on(openEditOrganizationModal, (state, newState) => ({ ...state, ...newState, visible: true }))
    .on(closeEditOrganizationModal, () => initialEditOrganizationModal);

// *********** ErrorModal ************
interface ErrorModalProps extends Required<Visible> {
    informationText: string;
    onOkHandler: () => void;
    entityId: string;
    path: string;
    error: string;
}

const initialErrorModal: ErrorModalProps = {
    visible: false,
    informationText: '',
    onOkHandler: () => undefined,
    entityId: '',
    path: '',
    error: ''
};

const openErrorModal = createEvent<Omit<ErrorModalProps, 'visible'>>();
const closeErrorModal = createEvent();

const errorModal = createStore<ErrorModalProps>(initialErrorModal)
    .on(closeErrorModal, () => initialErrorModal)
    .on(openErrorModal, (_, newState) => ({ ...newState, visible: true }));

// *********** InformationModal ************
interface InformationModalProps extends Required<Visible> {
    informationText: string;
    onOkHandler: () => void;
    withCancelButton?: boolean;
}

const initialInformationModal: InformationModalProps = {
    visible: false,
    informationText: '',
    onOkHandler: () => undefined,
    withCancelButton: false
};

const openInformationModal = createEvent<Omit<InformationModalProps, 'visible'>>();
const closeInformationModal = createEvent();

const informationModal = createStore<InformationModalProps>(initialInformationModal)
    .on(closeInformationModal, () => initialInformationModal)
    .on(openInformationModal, (_, newState) => ({ ...newState, visible: true }));

// *********** ConfirmationModal  ***********
interface ConfirmationModalProps extends Required<Visible> {
    confirmationText: string;
    confirmationButtonText: string;
    confirmationQuestion: string;
    errorMessage: string;
    successMessage: string;
    successTitle: string;
    onConfirm: () => Promise<any>;
    isSuccess?: boolean;
    hasError?: boolean;
}

interface OpenConfirmationModalProps extends Omit<ConfirmationModalProps, 'isSuccess' | 'hasError' | 'loading'> {}

const initialConfirmationModal: OpenConfirmationModalProps = {
    visible: false,
    confirmationText: '',
    confirmationButtonText: '',
    confirmationQuestion: '',
    errorMessage: '',
    successMessage: '',
    successTitle: '',
    onConfirm: () => new Promise(() => {})
};
const openConfirmationModal = createEvent<OpenConfirmationModalProps>();
const closeConfirmationModal = createEvent();

const confirmationAction = createEffect({
    handler: async (action: () => Promise<any>) => {
        try {
            await action();
            return { isSuccess: true, hasError: false };
        } catch {
            return { isSuccess: false, hasError: true };
        }
    }
});

const confirmationModal = restore<ConfirmationModalProps>(openConfirmationModal, initialConfirmationModal)
    .on(closeConfirmationModal, () => initialConfirmationModal)
    .on(confirmationAction.doneData, (state, newState) => ({ ...state, ...newState }));

// *********** stores  ***********

const modalEvents = {
    openAsyncModal,
    closeAsyncModal,
    openConfirmationModal,
    closeConfirmationModal,
    openEditOrganizationModal,
    closeEditOrganizationModal,
    openInformationModal,
    closeInformationModal,
    openErrorModal,
    closeErrorModal
};
const modalEffects = { editVideoInfo, getInitialVideoEditData, confirmationAction };
const modalStores = { asyncModal, confirmationModal, editOrganizationModal, informationModal, errorModal };

export { modalStores, modalEvents, modalEffects };
