import { Span } from 'components/common/typography/Span';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { grey5 } from 'constants/styles';
import React, { FC } from 'react';
import { Title as TitleProps } from 'types';

export const InfoTitle: FC<TitleProps> = ({ title = 'title', children }) => (
    <Section marginBottom="16px" marginTop="10px">
        <Span color={grey5} fontSize="13px" lineHeight="19px">
            {title}&nbsp; {children}
        </Span>
    </Section>
);
