import {
    disableDefaultButtonStyleMixin,
    white,
    redGradient,
    redHoverGradient,
    navyGradient,
    navyHoverGradient,
    blueGradient,
    blueHoverGradient,
    purpleGradient,
    purpleHoverGradient,
    greyGradient,
    grey4,
    grey1
} from 'constants/styles';
import { PoppinsFontFamily } from 'constants/styles/fonts';
import styled, { css } from 'styled-components';
import { Disabled, NoWrap } from 'types';

const buttonBase = () => css`
    font-weight: 400;
    font-family: ${PoppinsFontFamily};
    font-size: 14px;
    line-height: 20px;
    padding: 10px 40px;
    color: ${white};
    border-radius: 6px;
    display: flex;
    flex-wrap: nowrap;
`;

export const navyButton = (disabled: boolean | undefined) => css`
    background: ${navyGradient};
    &:hover {
        background: ${!disabled && navyHoverGradient};
    }
`;

export const blueButton = (disabled: boolean | undefined) => css`
    background: ${blueGradient};
    &:hover {
        background: ${!disabled && blueHoverGradient};
    }
`;

export const redButton = (disabled: boolean | undefined) => css`
    background: ${redGradient};
    &:hover {
        background: ${!disabled && redHoverGradient};
    }
`;

export const purpleButton = (disabled: boolean | undefined) => css`
    background: ${purpleGradient};
    &:hover {
        background: ${!disabled && purpleHoverGradient};
    }
`;

export const whiteButton = (disabled: boolean | undefined) => css`
    color: ${grey4};
    border: 1px solid ${grey4};
    background-color: ${white};

    &:hover {
        color: ${!disabled && grey1};
        border-color: ${!disabled && grey1};
    }
`;

const smallButton = css`
    padding: 10px 18px;
    font-size: 12px;
    line-height: 20px;
`;

export const getTypeStyle = (colorStyle: string, disabled: boolean | undefined) => {
    switch (colorStyle) {
        case 'navy':
            return navyButton(disabled);
        case 'blue':
            return blueButton(disabled);
        case 'red':
            return redButton(disabled);
        case 'purple':
            return purpleButton(disabled);
        case 'white':
            return whiteButton(disabled);
        default:
            return navyButton(disabled);
    }
};

export const getSizeStyle = (props: ButtonProps) => {
    switch (props.size) {
        case 'small':
            return smallButton;
        default:
            return null;
    }
};

export interface ButtonProps extends Disabled, NoWrap {
    buttonType?: 'navy' | 'blue' | 'red' | 'purple' | 'white';
    size?: 'default' | 'small';
}

export const StyledButton = styled.button<ButtonProps>`
    ${disableDefaultButtonStyleMixin};
    ${buttonBase()};
    ${({ buttonType, disabled }) => buttonType && getTypeStyle(buttonType, disabled)};
    ${size => getSizeStyle(size)};
    ${({ noWrap }) => noWrap && 'white-space: nowrap'};

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            background: ${greyGradient};
        `};
`;
