import avatarBullz from 'assets/bullz_logo.svg';
import avatarWOM from 'assets/wom_logo.png';
import avatarYEAY from 'assets/yeay_logo_bordered.svg';

export const sourceChooser = (source: string | null | undefined) => {
    switch (source) {
        case 'bullz': {
            return avatarBullz;
        }
        case 'yeay': {
            return avatarYEAY;
        }
        case 'wom': {
            return avatarWOM;
        }
        default: {
            return null;
        }
    }
};
