import { isDev } from 'constants/global';

const yeayDevVideos = 'https://yeay.admin.dev-stage.su/videos/';
const yeayProdVideos = 'https://afjmjt.yeay.com/videos/';

const bullzDevVideos = 'https://bullz.admin.dev-stage.su/videos/';

const bullzProdVideos = 'https://bnasatap.bullz.com/videos/';

export const getRedirectUrlToYeayOrBullzVideos = (remoteUserId: string, source: string) =>
    source === 'yeay'
        ? isDev
            ? yeayDevVideos + remoteUserId
            : yeayProdVideos + remoteUserId
        : isDev
        ? bullzDevVideos + remoteUserId
        : bullzProdVideos + remoteUserId;
