import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { BreadcrumbItem, Row } from 'reactstrap';
import { capitalizeFirstLetter } from 'utils/usefulFunctions';

interface Props {
    isPerformancePage?: boolean;
}

export const Breadcrumb = ({ isPerformancePage }: Props) => {
    const location = useLocation();
    const locationsArray = location.pathname.replace('/', '').split('/');
    return (
        <Row>
            <ol className="breadcrumb bg-transparent">
                {locationsArray.map((location, index) =>
                    index === locationsArray.length - 1 ? (
                        <BreadcrumbItem key={location} className="active">
                            {capitalizeFirstLetter(location)}
                        </BreadcrumbItem>
                    ) : (
                        <BreadcrumbItem key={location}>
                            <NavLink
                                to={
                                    isPerformancePage
                                        ? `/${locationsArray[0]}`
                                        : `/${locationsArray.slice(0, index + 1).join('/')}`
                                }
                            >
                                {capitalizeFirstLetter(location)}
                            </NavLink>
                        </BreadcrumbItem>
                    )
                )}
            </ol>
        </Row>
    );
};
