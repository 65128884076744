import { Span } from 'components/common/typography/Span';
import { Row, Section } from 'components/grid/wrappers/flexWrapper';
import { grey1 } from 'constants/styles';
import ReactEcharts from 'echarts-for-react';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomInput } from 'reactstrap';
import { walletAdminStores } from 'stores/wallet-admin';
import { HoldersTable } from '../HoldersTable';
import { SelectedChartItemsTableDataProps, SelectedUsersDataProps } from '../types';
import { options } from './constants';
import { ToggleButton, Wrapper } from './styles';
import { getChartData, getDataZoom, getGrid, getSeries, getTooltip, getXAxis } from './utils';

export const HoldersChart = () => {
    const [currentZoom, setCurrentZoom] = useState({ start: 0, end: 100 });
    const { items } = useStore(walletAdminStores.balanceHistorical);

    const [tooltipIsShown, setTooltipIsShown] = useState(true);
    const [tooltipTriggerSeriesItem, setTooltipTriggerSeriesItem] = useState(false);
    const [tooltipTriggerIsDisabled, setTooltipTriggerIsDisabled] = useState(false);
    const [selectedUsersData, setSelectedUsersData] = useState<SelectedUsersDataProps[]>([]);
    const [tableData, setTableData] = useState<SelectedChartItemsTableDataProps[]>();

    const chartItems = useMemo(() => getChartData(items), [items]);
    const series = useMemo(
        () => getSeries(chartItems),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [items]
    );
    const dataZoom = useMemo(() => getDataZoom(currentZoom), [currentZoom]);
    const tooltip = useMemo(() => getTooltip(tooltipTriggerSeriesItem), [tooltipTriggerSeriesItem]);
    const grid = useMemo(() => getGrid(tooltipIsShown), [tooltipIsShown]);
    const xAxis = useMemo(() => getXAxis(items), [items]);

    useEffect(() => {
        !tooltipIsShown ? setTooltipTriggerIsDisabled(true) : setTooltipTriggerIsDisabled(false);
    }, [tooltipIsShown]);

    const onChartItemClick = useCallback((params: any) => {
        const userId = params.data[3];
        const color = params.color;
        setSelectedUsersData(prev => {
            if (!prev.some(user => user.userId === userId)) {
                setTableData([]);
                return [...prev, { userId, color }];
            }
            return prev;
        });
    }, []);

    const onChartZoom = useCallback((params: any) => {
        const { end, start } = params;
        setCurrentZoom({ end, start });
    }, []);

    const onEvents = {
        click: onChartItemClick,
        dataZoom: onChartZoom
    };

    return (
        <Wrapper>
            {items?.length === 0 ? (
                <Section alignCenter justifyCenter height="100%">
                    <Span color="rgba(255, 255, 255, 0.15)" fontSize="30px" lineHeight="45px">
                        Nothing found
                    </Span>
                </Section>
            ) : (
                <>
                    <Section alignCenter justifyBetween>
                        <Span color={grey1} fontSize="12px" lineHeight="18px">
                            Wom Holders
                        </Span>
                        <Row marginRight="60px">
                            <ToggleButton>
                                <CustomInput
                                    id="tooltipToggle"
                                    label="Hide tooltip"
                                    type="switch"
                                    onChange={() => {
                                        setTooltipIsShown(!tooltipIsShown);
                                    }}
                                />
                            </ToggleButton>

                            <ToggleButton disabled={tooltipTriggerIsDisabled}>
                                <CustomInput
                                    disabled={tooltipTriggerIsDisabled}
                                    id="tooltipTrigger"
                                    label="Holder info"
                                    type="switch"
                                    onChange={() => {
                                        setTooltipTriggerSeriesItem(!tooltipTriggerSeriesItem);
                                    }}
                                />
                            </ToggleButton>
                        </Row>
                    </Section>
                    <ReactEcharts
                        option={{ ...options, tooltip, xAxis, grid, series, dataZoom }}
                        style={{ width: '100%', height: '377px' }}
                        onEvents={onEvents}
                    />
                    <HoldersTable
                        selectedUsersData={selectedUsersData}
                        setSelectedUsersData={setSelectedUsersData}
                        setTableData={setTableData}
                        tableData={tableData}
                    />
                </>
            )}
        </Wrapper>
    );
};
