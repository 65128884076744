import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

const addUsers = createEvent<WOM.GetUserResponse[]>();
const removeUsers = createEvent<WOM.GetUserResponse[]>();
const removeAll = createEvent();

const users = createStore<WOM.GetUserResponse[]>([])
    .on(addUsers, (users, addedUsers) => [...users, ...addedUsers])
    .on(removeUsers, (users, deletedUsers) =>
        users.filter(user => !deletedUsers.some(({ userId }) => user.userId === userId))
    )
    .on(removeAll, () => []);

const postTokens = createEffect({
    handler: async (value: WOM.WalletAirdropRequest) => {
        try {
            const data = await API.wallet.airdrop(value);

            return data;
        } catch (e) {
            return e;
        }
    }
});

export const airdropStores = { users };
export const airdropEvent = { addUsers, removeUsers, removeAll };
export const airdropEffects = { postTokens };
