import { Form, Input as AntInput, InputNumber } from 'antd';
import React, { FC } from 'react';
import { Type } from 'types';

interface Props extends Type {
    label?: string;
    name?: string;
    rules: any;
}

export const FormInput: FC<Props> = ({ label = 'Email', name = 'email', rules, type }) => (
    <Form.Item label={label} name={name} rules={rules}>
        {type === 'number' ? <InputNumber /> : name === 'password' ? <AntInput.Password /> : <AntInput />}
    </Form.Item>
);
