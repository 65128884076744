import { grey2, white } from 'constants/styles';
import styled from 'styled-components';
import { Disabled } from 'types';

export const Wrapper = styled.div`
    width: 100%;
    background-color: ${grey2};
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0px 1.16812px 23.3623px rgba(0, 0, 0, 0.1);
`;

export const ToggleButton = styled.div<Disabled>`
    white-space: noWrap;
    margin-left: 16px;
    opacity: 1;
    color: ${white};

    ${({ disabled }) => disabled && `opacity: 0.4;`}

    @media (max-width: 992px) {
        white-space: initial;
    }

    @media (max-width: 460px) {
        white-space: noWrap;
    }
`;
