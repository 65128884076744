import { CopyButton } from 'components/common/buttons/CopyButton';
import { ExternalLinkButton } from 'components/common/buttons/ExternalLinkButton';
import { Span } from 'components/common/typography/Span';
import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { propertyBackground, propertyTitleColor, white, xs } from 'constants/styles';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { Title } from 'types';
import { Copyable, Success } from 'types/interface';
import { getEllipsisAddress } from 'utils/usefulFunctions';
import { propertyMarginRight, propertyMinWidth, propertyPadding } from './constants';

interface Props extends Title, Copyable, Partial<Success> {
    externalLink?: string;
    internalLink?: string;
}

export const DescriptionProperty: FC<Props> = ({ title, subtitle, copyable, success, externalLink, internalLink }) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const isId = title?.toLowerCase().split(' ').includes('id');
    const id = isMobile && isId && getEllipsisAddress(subtitle as string, 18);

    return (
        <ContentWrapper
            backgroundColor={propertyBackground}
            minHeight="58px"
            minWidth={propertyMinWidth}
            padding={propertyPadding}
            width={isMobile ? '100%' : 'auto'}
        >
            <Section alignCenter justifyBetween noWrap height="100%">
                {title && (
                    <MarginWrapper marginRight={propertyMarginRight}>
                        <Span noWrap color={propertyTitleColor} fontSize="12px">
                            {title}
                        </Span>
                    </MarginWrapper>
                )}
                {internalLink ? (
                    <MarginWrapper marginRight={copyable || externalLink ? propertyMarginRight : '0'}>
                        <NavLink className="subtitle" to={internalLink}>
                            <Span color={white}>{id || subtitle}</Span>
                        </NavLink>
                    </MarginWrapper>
                ) : (
                    <MarginWrapper marginRight={copyable || externalLink ? propertyMarginRight : '0'}>
                        <Span color={white}>{id || subtitle}</Span>
                    </MarginWrapper>
                )}
                {copyable && <CopyButton subject={subtitle} success={success} />}
                {externalLink && <ExternalLinkButton to={externalLink} />}
            </Section>
        </ContentWrapper>
    );
};
