import copyButtonIcon from 'assets/img/copy_button.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import React, { MouseEvent } from 'react';
import { notificationEvents } from 'stores/notification';
import { Success } from 'types/interface';
import { triggerCopy } from 'utils/usefulFunctions';

export interface CopyButtonProps extends Partial<Success> {
    subject?: string;
}

export const CopyButton = ({ success, subject }: CopyButtonProps) => {
    const triggerButtonCopy = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (subject) {
            triggerCopy(subject);
            notificationEvents.setNotification({ message: success, place: 'tc' });
        }
    };

    return (
        <button className="copy-button" disabled={!subject} onClick={triggerButtonCopy}>
            <CustomImg alt="copy" className="copy-button-icon" src={copyButtonIcon} />
        </button>
    );
};
