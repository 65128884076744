import { UserQueryValues } from 'stores/users';
import { VideoQueryValues } from 'stores/videos';
import { defaultLimit, defaultPage } from './common';

export const BULLZ_SERVICES = 'bullz-services';

export const YEAY_SERVICES = 'yeay-services';

export const assignedUserRolesValues = [
    { value: 'administrator', label: 'Administrator' },
    { value: 'OrganizationAdministrator', label: 'Organization Administrator' }
];
export const defaultUserRolesValues = [
    { value: 'Validator', label: 'Validator' },
    { value: 'RemoteUser', label: 'Remote User' },
    { value: 'womadministrator', label: 'Wom Administrator' }
];
export const sortTagsUsersValues = [
    { value: 'All', label: 'All' },
    ...defaultUserRolesValues,
    ...assignedUserRolesValues
];

export const defaultUserRoles = defaultUserRolesValues.map(({ value }) => value);
export const assignedUserRoles = assignedUserRolesValues.map(({ value }) => value);

export const accessRoles = assignedUserRolesValues.map(({ value }) => value);
export const accessValues = [0, 1, 2, 3];

export const sortTagsValuesUsersDefault = sortTagsUsersValues[0];

export const defaultUserItem = {
    userId: ''
};

export const defaultUsersValues = {
    pageIndex: defaultPage,
    limit: defaultLimit,
    returnQueryCount: true
};

export const defaultUserSearchByParameterValues: Partial<UserQueryValues> = {
    mobileNumber: undefined,
    email: undefined,
    textSearch: undefined,
    walletIds: undefined,
    userName: undefined,
    userId: undefined
};

export const defaultVideoSearchByParameterValues: Partial<VideoQueryValues> = {
    videoId: undefined,
    remoteContentIds: undefined,
    source: undefined
};
