import { Button } from 'components/common/buttons/Button';
import { PropertyBlock } from 'components/common/dividers/PropertyBlock';
import { Loader } from 'components/common/dynamic/Loader';
import { TextInput } from 'components/common/inputs/TextInput';
import { Tag } from 'components/common/tags/Tag';
import { Row, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { ModalWrapper } from 'components/grid/wrappers/ModalWrapper';
import {
    copyOrganizationIdMessage,
    copyPublicIdMessage,
    copyWalletIdMessage,
    formatTagsArray,
    inputBorderBottom,
    nonEmptyStringRegExp
} from 'components/modals/EditOrganizationModal/constants';
import { InputWrapper, LoaderBackground, Subtitle, TagsWrapper } from 'components/modals/EditOrganizationModal/styles';
import { useStore } from 'effector-react';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { modalEvents, modalStores } from 'stores/modal';
import { organizationsEffects } from 'stores/organizations';

const { updateOrganizationData } = organizationsEffects;
const { closeEditOrganizationModal } = modalEvents;
const { editOrganizationModal } = modalStores;

export const EditOrganizationModal = () => {
    const { visible, organizationId, publicId, title, mandatoryTags, origin, walletId, adminIds } = useStore(
        editOrganizationModal
    );
    const isEditOrganizationPending = useStore(updateOrganizationData.pending);
    const [newTitle, setNewTitle] = useState(title);
    const [newMandatoryTag, setNewMandatoryTag] = useState<string | undefined>();
    const [tagsList, setTagsList] = useState<string[] | undefined>();
    const [newOrigin, setNewOrigin] = useState<string | undefined>();
    const [originTag, setOriginTag] = useState(origin);
    const disabledButton = !(newTitle && nonEmptyStringRegExp.test(newTitle) && tagsList && organizationId);
    const untouchableTag = tagsList && tagsList.length < 2;

    const addOriginTag = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && newOrigin && nonEmptyStringRegExp.test(newOrigin)) {
            setOriginTag(newOrigin.trim());
            setNewOrigin('');
        }
    };

    const addMandatoryTag = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && newMandatoryTag && nonEmptyStringRegExp.test(newMandatoryTag)) {
            if (!tagsList) {
                setTagsList([newMandatoryTag.trim()]);
            } else {
                !tagsList.some(tag => tag === newMandatoryTag.trim()) &&
                    setTagsList([...tagsList, newMandatoryTag.trim()]);
            }

            setNewMandatoryTag('');
        }
    };

    const removeTag = (tag: string) => setTagsList(state => state?.filter(i => i !== tag));

    const resetValues = () => {
        setTagsList([]);
        setNewTitle('');
        setNewOrigin('');
    };

    const onClose = () => {
        closeEditOrganizationModal();
        resetValues();
    };

    const applyClick = async () => {
        if (organizationId && tagsList && newTitle) {
            const response = await updateOrganizationData({
                organizationId,
                title: newTitle.trim(),
                mandatoryTags: tagsList,
                origin: newOrigin
            });

            response && onClose();
        }
    };

    useEffect(() => {
        title && nonEmptyStringRegExp.test(title) ? setNewTitle(title) : setNewTitle(undefined);
        origin && nonEmptyStringRegExp.test(origin) ? setOriginTag(origin) : setOriginTag(undefined);

        const updatedMandatoryTags = formatTagsArray(mandatoryTags, title);
        setTagsList(updatedMandatoryTags);
    }, [organizationId, publicId, title, mandatoryTags, origin, walletId]);

    return (
        <ModalWrapper visible={visible} onClose={onClose}>
            {isEditOrganizationPending && (
                <LoaderBackground>
                    <Loader />
                </LoaderBackground>
            )}
            <Row marginBottom="20px" width="242px">
                <TextInput
                    borderBottom={inputBorderBottom}
                    fontSize="17px"
                    lineHeight="25px"
                    placeholder="Organization name"
                    value={newTitle}
                    onChange={value => setNewTitle(value)}
                    onClear={() => setNewTitle('')}
                />
            </Row>
            <Section justifyBetween>
                <Row marginBottom="20px" width="48%">
                    <PropertyBlock
                        copyable
                        subtitle={organizationId}
                        success={copyOrganizationIdMessage}
                        title="Organization ID"
                    />
                </Row>
                <Row marginBottom="20px" width="48%">
                    <PropertyBlock copyable subtitle={walletId} success={copyWalletIdMessage} title="Wallet ID" />
                </Row>
            </Section>

            <Section>
                <PropertyBlock copyable subtitle={publicId} success={copyPublicIdMessage} title="PublicId ID" />
            </Section>

            <MarginWrapper marginBottom="20px" marginTop="20px">
                <InputWrapper>
                    <Subtitle>Mandatory tags</Subtitle>
                    <TextInput
                        borderBottom={inputBorderBottom}
                        value={newMandatoryTag}
                        onChange={value => setNewMandatoryTag(value.toUpperCase())}
                        onClear={() => setNewMandatoryTag('')}
                        onKeyDown={addMandatoryTag}
                    />
                </InputWrapper>

                <TagsWrapper>
                    {tagsList?.length ? (
                        tagsList?.map(
                            tag =>
                                !!tag && (
                                    <Tag key={tag} onRemove={untouchableTag ? undefined : () => removeTag(tag)}>
                                        {tag.toUpperCase()}
                                    </Tag>
                                )
                        )
                    ) : (
                        <span>No content</span>
                    )}
                </TagsWrapper>
            </MarginWrapper>

            <MarginWrapper marginBottom="20px">
                <Row>
                    <Subtitle>Admins</Subtitle>
                </Row>
                <TagsWrapper>
                    {adminIds?.length ? (
                        adminIds?.map(tag => <Tag key={tag}>{tag.toUpperCase()}</Tag>)
                    ) : (
                        <span>No content</span>
                    )}
                </TagsWrapper>
            </MarginWrapper>

            <MarginWrapper marginBottom="20px">
                <InputWrapper>
                    <Subtitle>Origin</Subtitle>
                    <TextInput
                        borderBottom={inputBorderBottom}
                        value={newOrigin}
                        onChange={value => setNewOrigin(value.toUpperCase())}
                        onClear={() => setNewOrigin('')}
                        onKeyDown={addOriginTag}
                    />
                </InputWrapper>
                <TagsWrapper>
                    {!!originTag ? (
                        <Tag
                            onRemove={() => {
                                setOriginTag('');
                            }}
                        >
                            {originTag.toUpperCase()}
                        </Tag>
                    ) : (
                        <span>No content</span>
                    )}
                </TagsWrapper>
            </MarginWrapper>
            <Section alignCenter justifyEnd>
                <Button buttonType="red" onClick={onClose}>
                    Cancel
                </Button>
                <MarginWrapper marginLeft="15px" marginRight="15px">
                    <Button buttonType="blue" disabled={disabledButton} onClick={applyClick}>
                        Save
                    </Button>
                </MarginWrapper>
            </Section>
        </ModalWrapper>
    );
};
