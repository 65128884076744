import { DataRangePicker } from 'components/common/inputs/DataPicker';
import { Span } from 'components/common/typography/Span';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { GraphCard } from 'components/layouts/cards/GraphCard';
import { sub } from 'date-fns';
import { useStore } from 'effector-react';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, CustomInput, Row } from 'reactstrap';
import { potsEffects, potsStores } from 'stores/pots';
import { charts, graphsColor } from '../constants';
import { PotList } from './PotList';
import { DateTitle, DatesRow } from './styles';

export const PotsDashboard = () => {
    const potsData = useStore(potsStores.pots);

    const [dateFrom, setDateFrom] = useState(moment(sub(new Date(), { days: 7 })).toISOString(true));
    const [dateTo, setDateTo] = useState(moment(new Date()).toISOString(true));
    const [groupByWeek, setGroupByWeek] = useState(false);

    useEffect(() => {
        potsEffects.queryPotsFx({
            dateFromInclusive: dateFrom,
            dateToInclusive: dateTo,
            limit: 30,
            groupByWeek
        });
    }, [dateFrom, dateTo, groupByWeek]);

    const getSystemPots = () => {
        if (potsData.items && potsData.items[0].items) {
            const pots: WOM.WalletBalanceHistoricalItem[] = [];
            potsData.items[0].items?.map(pot => {
                if (!pots.find(({ title }) => pot.title === title)) {
                    pots.push(pot);
                }
            });
            return pots;
        }
        return [];
    };

    const systemPots = getSystemPots();

    const graphsData = systemPots.map(item => {
        const data =
            potsData?.items
                ?.map(({ items }) => items?.find(({ title }) => title === item.title)?.balance || 0)
                .reverse() || [];

        return {
            title: item.title || '',
            key: item.walletId,
            data,
            total: item.balance || 0
        };
    });
    const labels = potsData.items?.map(({ date }) => moment(date).format('DD-MM-YYYY')).reverse();

    const onDateRangeChange = ([dateFrom, dateTo]: [Moment, Moment]) => {
        if (dateFrom.isSameOrAfter(dateTo, 'day')) {
            setDateFrom(dateFrom.toISOString(true));
            setDateTo(dateTo.add(7, 'day').toISOString(true));
        } else {
            setDateFrom(dateFrom.toISOString(true));
            setDateTo(dateTo.toISOString(true));
        }
    };

    return (
        <div>
            <Section marginBottom="30px">
                <PotList systemPots={systemPots} />
            </Section>
            <div>
                <DateTitle>Select Date Range</DateTitle>
                <DatesRow>
                    <Col md="3">
                        <DataRangePicker
                            disableJustifyBetween
                            value={[new Date(dateFrom), new Date(dateTo)]}
                            onChange={onDateRangeChange}
                        />
                    </Col>
                    <Col md="3">
                        <Row className="w-100">
                            <CustomInput
                                checked={groupByWeek}
                                id="groupByWeek"
                                label="Group By Week"
                                type="switch"
                                onChange={e => {
                                    setGroupByWeek(e.target.checked);
                                }}
                            />
                        </Row>
                    </Col>
                </DatesRow>
            </div>
            <Row>
                {graphsData?.length && labels ? (
                    graphsData?.map(({ data, total, title, key = '' }, index) => {
                        const colorIndex = index;
                        const color = graphsColor[colorIndex].pointColor;
                        const description = '';

                        const { data: dataGraph, options } = charts(
                            { data, labels, label: title },
                            graphsColor[colorIndex]
                        );

                        return (
                            <Col key={key} lg="6">
                                <GraphCard
                                    color={color}
                                    dataGraph={dataGraph}
                                    description={description}
                                    options={options}
                                    title={title}
                                    total={total}
                                />
                            </Col>
                        );
                    })
                ) : (
                    <Col>
                        <Card body style={{ height: '266px' }}>
                            <Section alignCenter justifyCenter height="100%">
                                <Span color="rgba(255, 255, 255, 0.15)" fontSize="30px" lineHeight="45px">
                                    Nothing found
                                </Span>
                            </Section>
                        </Card>
                    </Col>
                )}
            </Row>
        </div>
    );
};
