import PerfectScrollbar from 'perfect-scrollbar';

export let perfectScrollbar: PerfectScrollbar;

export const initializePerfectScrollbar = (element: HTMLDivElement, listener: EventListenerOrEventListenerObject) => {
    if (navigator.platform.indexOf('Win') > -1) {
        document.documentElement.classList.add('perfect-scrollbar-on');
        document.documentElement.classList.remove('perfect-scrollbar-off');

        perfectScrollbar = new PerfectScrollbar(element);

        element.addEventListener('ps-scroll-y', listener);
        let tables = document.querySelectorAll<HTMLElement>('.table-responsive');
        for (let i = 0; i < tables.length; i++) {
            perfectScrollbar = new PerfectScrollbar(tables[i]);
        }
    }
};

export const destroyPerfectScrollbar = (element: HTMLDivElement, listener: EventListenerOrEventListenerObject) => {
    if (navigator.platform.indexOf('Win') > -1) {
        perfectScrollbar.destroy();
        document.documentElement.classList.add('perfect-scrollbar-off');
        document.documentElement.classList.remove('perfect-scrollbar-on');
        element.removeEventListener('ps-scroll-y', listener);
    }
};

export const initializeSidebarPerfectScrollbar = (element: HTMLDivElement) => {
    if (navigator.platform.indexOf('Win') > -1) {
        perfectScrollbar = new PerfectScrollbar(element, {
            suppressScrollX: true,
            suppressScrollY: false
        });
    }
};

export const destroySidebarPerfectScrollbar = () => {
    if (navigator.platform.indexOf('Win') > -1) {
        perfectScrollbar.destroy();
    }
};
