import styled from 'styled-components';
import { grey2, grey4 } from 'constants/styles';

interface OpenErrorButtonProps {
    collapsed?: boolean;
}

interface ErrorInfoProps {
    collapsed?: boolean;
}

export const OpenErrorButton = styled.button<OpenErrorButtonProps>`
    position: relative;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    color: ${grey4};
    border: none;
    background: transparent;
    padding-right: 20px;
    padding-left: 0;

    &::after {
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        transform: translateY(-50%) ${({ collapsed }) => collapsed && 'rotate(180deg)'};
        width: 0;
        height: 0;
        border-width: 6px 3.5px 0 3.5px;
        border-color: ${grey4} transparent transparent transparent;
        border-style: solid;
        transition: transform 0.2s linear;
    }
`;

export const ErrorInfo = styled.div<ErrorInfoProps>`
    width: 100%;
    height: ${({ collapsed }) => (collapsed ? '166px' : '0')};
    background-color: rgba(196, 196, 196, 0.2);
    transition: all 0.2s linear;
    padding: ${({ collapsed }) => collapsed && '10px'};
    margin-bottom: 20px;
    border-radius: 8px;
    overflow-y: auto;
`;

export const ErrorInfoItem = styled.p`
    color: ${grey2};
`;
