import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const TimelineWraper = styled.div`
    width: 100%;
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(2, 49%);

    @media ${device.tablet} {
        grid-template-columns: repeat(1, 1fr);
    }
`;
