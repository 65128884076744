import axios, { CancelTokenSource } from 'axios';
import { defaultOrganizationsValues } from 'constants/defaults';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { AlertOptions } from 'react-notification-alert';
import { API } from 'services';
import { initializeIsFirstStore } from 'stores/initialize/initialize.isFirst.store';
import { initializeToggleStore } from 'stores/initialize/initialize.toggle.store';
import { notificationEvents } from 'stores/notification';
import { modalEvents } from './modal';

let cancelToken: CancelTokenSource | undefined;

const [loading, updateLoading] = initializeToggleStore();

const setRegisterError = createEvent<string>();

const registerError = createStore('').on(setRegisterError, (_, newState) => newState);
registerError.watch(setRegisterError, state => {
    state &&
        notificationEvents.setNotification({
            place: 'tc',
            message: state,
            type: 'danger',
            icon: 'tim-icons icon-bell-55',
            autoDismiss: 5
        });
});

const getItems = createEffect({
    handler: async (values: WOM.QueryOrganizationRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateLoading();
            const data = await API.organizations.getItems(values, cancelToken.token);
            updateLoading();

            return data;
        } catch {
            updateLoading();
            return {
                currentPageIndex: 0,
                totalPages: 0,
                totalRecords: 0
            };
        }
    }
});

const successNotificationOptions: () => AlertOptions = () => ({
    place: 'tc',
    message: 'The organization data was successfully updated!',
    type: 'primary',
    icon: 'tim-icons icon-bell-55',
    autoDismiss: 5
});

const errorNotificationOptions: () => AlertOptions = () => ({
    place: 'tc',
    message: "Sorry, an error has occurred. The organization data wasn't updated.",
    type: 'danger',
    icon: 'tim-icons icon-bell-55',
    autoDismiss: 5
});

const updateOrganizationData = createEffect({
    handler: async (values: WOM.UpdateOrganizationRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateLoading();
            const response = await API.organizations.updateOrganizationById(values, cancelToken.token);
            updateLoading();

            modalEvents.openInformationModal({
                informationText: 'Organization information is successfully updated!',
                onOkHandler: () => modalEvents.closeInformationModal()
            });
            notificationEvents.setNotification(successNotificationOptions());
            getItems({ ...defaultOrganizationsValues, organizationId: values.organizationId });

            return response;
        } catch (error) {
            modalEvents.openErrorModal({
                informationText:
                    'Sorry, an error has occurred. Please copy the error code and report this error to us.',
                entityId: values.organizationId.toString(),
                error: error.message,
                path: '/organization/update',
                onOkHandler: () => modalEvents.closeErrorModal()
            });
            updateLoading();
            notificationEvents.setNotification(errorNotificationOptions());

            return error.data;
        }
    }
});

const items = createStore<WOM.OrganizationsResponse>({}).on(getItems.doneData, (_, newState) => newState);

const updateValues = createEvent<WOM.QueryOrganizationRequestValues>();
const setDefaultValues = createEvent();

const { isFirst, setIsFirstToFalse, setIsFirstToTrue } = initializeIsFirstStore();

const values = createStore<WOM.QueryOrganizationRequest>(defaultOrganizationsValues)
    .on(updateValues, (state, values: WOM.QueryOrganizationRequestValues) => ({
        ...state,
        ...values
    }))
    .on(setDefaultValues, () => defaultOrganizationsValues);
values.watch(setDefaultValues, state => getItems(state));

forward({
    from: [values],
    to: [getItems]
});

const organizationsEvents = {
    updateValues,
    setDefaultValues,
    setIsFirstToFalse,
    setIsFirstToTrue
};
const organizationsEffects = { updateOrganizationData };
const organizationsStores = { registerError, loading, items, values, isFirst };

export { organizationsEffects, organizationsStores, organizationsEvents };
