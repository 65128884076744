import 'assets/css/nucleo-icons.css';
import 'assets/scss/black-dashboard-pro-react.scss?v=1.2.0';
import { ConfirmationModal } from 'components/modals/ConfirmationModal';
import { EditOrganizationModal } from 'components/modals/EditOrganizationModal';
import {
    airdropLink,
    authLink,
    createTransactionLink,
    createWithdrawalLimitLink,
    dashboardLink,
    error403Link,
    homeLink,
    organizationsLink,
    performancePaymentsLink,
    performancePaymentsSingleLink,
    potLink,
    transactionLink,
    transactionsLink,
    updateWithdrawalLimitLink,
    userLink,
    usersLink,
    videoLink,
    walletLink,
    walletSingleLink,
    withdrawalCheckLink,
    withdrawalLimitLink,
    womHoldersLink
} from 'constants/routes';
import { GlobalStyle } from 'constants/styles';
import { Error403 } from 'pages/403';
import { Airdrop } from 'pages/Airdrop';
import { Login } from 'pages/Auth';
import { CreateTransaction } from 'pages/CreateTransaction';
import { Dashboard } from 'pages/Dashboard';
import { Organizations } from 'pages/Organizations';
import { PerformancePayments } from 'pages/PerformancePayments';
import { PerformancePaymentsSingle } from 'pages/PerformancePayments/PerformancePaymentsSingle';
import { Pot } from 'pages/Pot';
import { Transactions } from 'pages/Transactions';
import { Transaction } from 'pages/Transactions/Transaction';
import { Users } from 'pages/Users';
import { User } from 'pages/Users/User';
import { Videos } from 'pages/Videos';
import { Video } from 'pages/Videos/Video';
import { Wallet } from 'pages/Wallet';
import { WalletSingle } from 'pages/Wallet/WalletSingle';
import { WithdrawalCheck } from 'pages/WithdrawalCheck';
import { WithdrawalLimit } from 'pages/WithdrawalLimit';
import { SingleWithdrawalLimit } from 'pages/WithdrawalLimit/SingleWithdrawalLimit';
import { WomHolders } from 'pages/WomHolders';
import React from 'react';
import 'react-notification-alert/dist/animate.css';
import { Redirect, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { AdminRoute } from 'routes/AdminRoute';
import { PrivateRoute } from 'routes/PrivateRoute';
import { PublicRoute } from 'routes/PublicRoute';
import history from './browserHistory';
import { ErrorModal } from './components/modals/ErrorModal';
import { InformationModal } from './components/modals/InformationModal';

const App = () => (
    <>
        <GlobalStyle />
        <ConfirmationModal />
        <ErrorModal />
        <EditOrganizationModal />
        <InformationModal />
        <Router history={history}>
            <Switch>
                <PublicRoute exact component={Login} path={authLink} />

                <PrivateRoute exact component={Dashboard} path={dashboardLink} />
                <PrivateRoute exact component={Videos} path={homeLink} />
                <PrivateRoute exact component={Video} path={videoLink} />
                <PrivateRoute exact component={Users} path={usersLink} />
                <PrivateRoute exact component={User} path={userLink} />
                <PrivateRoute exact component={Pot} path={potLink} />
                <PrivateRoute exact component={Error403} path={error403Link} />

                <AdminRoute exact component={PerformancePayments} path={performancePaymentsLink} />
                <AdminRoute exact component={PerformancePaymentsSingle} path={performancePaymentsSingleLink} />
                <AdminRoute exact component={Organizations} path={organizationsLink} />
                <AdminRoute exact component={Wallet} path={walletLink} />
                <AdminRoute exact component={WalletSingle} path={walletSingleLink} />
                <AdminRoute exact component={Transactions} path={transactionsLink} />
                <AdminRoute exact component={Transaction} path={transactionLink} />
                <AdminRoute exact component={CreateTransaction} path={createTransactionLink} />
                <AdminRoute exact component={WithdrawalCheck} path={withdrawalCheckLink} />
                <AdminRoute exact component={Airdrop} path={airdropLink} />
                <AdminRoute exact component={WomHolders} path={womHoldersLink} />
                <AdminRoute exact component={WithdrawalLimit} path={withdrawalLimitLink} />
                <AdminRoute exact component={SingleWithdrawalLimit} path={createWithdrawalLimitLink} />
                <AdminRoute exact component={SingleWithdrawalLimit} path={updateWithdrawalLimitLink} />

                <Redirect to={authLink} />
            </Switch>
        </Router>
    </>
);

export default App;
