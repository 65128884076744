import React, { ChangeEvent } from 'react';
import { CheckmarkIcon } from 'assets/img';
import { noop } from 'constants/functions';
import { CheckboxProps } from './types';
import { HiddenCheckbox, Label, VisibleCheckbox } from './styles';

interface Props extends CheckboxProps {
    onChange?: (checked: boolean) => void;
    name?: string;
    showName?: boolean;
}

export const BooleanCheckbox = ({
    checked,
    disabled = false,
    onChange = noop,
    showName = false,
    name = 'name',
    type = 'transparent'
}: Props) => {
    const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const targetChecked = e.target.checked;
        onChange(targetChecked);
    };

    return (
        <Label>
            <VisibleCheckbox checked={checked} disabled={disabled} type={type}>
                <CheckmarkIcon />
            </VisibleCheckbox>
            <HiddenCheckbox checked={checked} disabled={disabled} name={name} onChange={onCheckboxChange} />
            {showName && name}
        </Label>
    );
};
