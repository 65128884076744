import { VideoPlayer } from 'components/common/dynamic/VideoPlayer';
import { Tag } from 'components/common/tags/Tag';
import { Span } from 'components/common/typography/Span';
import { DashboardSingle } from 'components/grid/DashboardSingle';
import { DescriptionContent } from 'components/grid/DescriptionContent';
import { DescriptionProperty } from 'components/grid/DescriptionProperty';
import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { Column, Row, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { BASYUrl, formatDate, YASYUrl } from 'constants/global';
import { usersLink } from 'constants/routes';
import {
    descriptionMarginBottom,
    descriptionMarginRight,
    descriptionPadding,
    grey2,
    noContentColor,
    xs
} from 'constants/styles';
import { format } from 'date-fns';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

interface VideoDescriptionProps extends WOM.ContentItemResponse {
    products?: any[];
}

export const VideoDescription = ({
    userId,
    streamDetails,
    womContentId,
    remoteContentId,
    products,
    tags,
    createdUtc,
    uriPrimary,
    source
}: VideoDescriptionProps) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    // const endedReason = validationState?.endedReason;

    let externalLink = source === 'bullz' ? BASYUrl : YASYUrl;

    return (
        <>
            <ContentWrapper backgroundColor={grey2} marginBottom="30px" padding={descriptionPadding} width="100%">
                <Section noWrap={!isMobile}>
                    <ContentWrapper
                        borderRadius="0"
                        height={isMobile ? '280px' : '270px'}
                        marginBottom={isMobile ? '15px' : '0'}
                        marginRight={isMobile ? '0' : descriptionMarginRight}
                        width={isMobile ? '100%' : '313px'}
                    >
                        <VideoPlayer source={source} streamDetails={streamDetails} uriPrimary={uriPrimary} />
                        {/* <Activity endedReason={endedReason} womQualityScore={womQualityScore} /> */}
                    </ContentWrapper>
                    <Column>
                        <Section>
                            {womContentId && (
                                <Row
                                    marginBottom={descriptionMarginBottom}
                                    marginRight={isMobile ? '0' : descriptionMarginRight}
                                    width={isMobile ? '100%' : 'auto'}
                                >
                                    <DescriptionProperty
                                        copyable
                                        subtitle={womContentId}
                                        success="Video Id is successfully copied"
                                        title="ID"
                                    />
                                </Row>
                            )}
                            {remoteContentId && (
                                <Row marginBottom={descriptionMarginBottom} width={isMobile ? '100%' : 'auto'}>
                                    <DescriptionProperty
                                        externalLink={`${externalLink}/videos/${remoteContentId}`}
                                        subtitle={remoteContentId}
                                        title="Remote ID"
                                    />
                                </Row>
                            )}
                        </Section>

                        <Row>
                            {userId && (
                                <Row
                                    marginBottom={descriptionMarginBottom}
                                    marginRight={isMobile ? '0' : descriptionMarginRight}
                                    width={isMobile ? '100%' : 'auto'}
                                >
                                    <DescriptionProperty
                                        copyable
                                        internalLink={`${usersLink}/${userId}`}
                                        subtitle={userId}
                                        success="User ID is successfully copied"
                                        title="User ID"
                                    />
                                </Row>
                            )}
                            {createdUtc && (
                                <Row marginBottom={descriptionMarginBottom} width={isMobile ? '100%' : 'auto'}>
                                    <DescriptionProperty
                                        subtitle={format(new Date(createdUtc), formatDate)}
                                        title="Date of Creation"
                                    />
                                </Row>
                            )}
                        </Row>
                        <Row>
                            <Row
                                marginBottom={isMobile ? descriptionMarginBottom : '0'}
                                marginRight={isMobile ? '0' : descriptionMarginRight}
                                width={isMobile ? '100%' : 'auto'}
                            >
                                <DescriptionContent title="Tags">
                                    {tags?.length ? (
                                        tags?.map(tag => (
                                            <MarginWrapper key={tag} marginBottom="9px" marginRight="12px">
                                                <Tag color="danger">{tag}</Tag>
                                            </MarginWrapper>
                                        ))
                                    ) : (
                                        <Span color={noContentColor}>No content</Span>
                                    )}
                                </DescriptionContent>
                            </Row>
                            <DescriptionContent title="Product">
                                <Row className="no-gutters">
                                    {products?.length ? (
                                        products.join(', ')
                                    ) : (
                                        <Span color={noContentColor}>No content</Span>
                                    )}
                                </Row>
                            </DescriptionContent>
                        </Row>
                    </Column>
                </Section>
            </ContentWrapper>

            <DashboardSingle contentId={womContentId} />
        </>
    );
};
