import { closeIcon } from 'assets/img';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import React, { FC, MouseEvent } from 'react';
import { FixedModalWrapper, ModalClosableArea, ModalContent, StyledButton } from './styles';

interface ModalProps {
    bgColor?: string;
    onModalClose?: () => void;
}

interface ModalCloseButtonProps {
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ModalCloseButton: FC<ModalCloseButtonProps> = ({ onClick }) => (
    <StyledButton onClick={onClick}>
        <CustomImg height="12px" src={closeIcon} />
    </StyledButton>
);

export const Modal: FC<ModalProps> = ({ children, bgColor, onModalClose }) => (
    <FixedModalWrapper>
        <ModalClosableArea onClick={onModalClose} />

        <ModalContent backgroundColor={bgColor}>
            <ModalCloseButton onClick={onModalClose} />
            {children}
        </ModalContent>
    </FixedModalWrapper>
);
