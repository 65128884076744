import { ReactComponent as SearchIcon } from 'assets/searchIcon_white.svg';
import { ClickableWrapper } from 'components/grid/wrappers/ClickableWrapper';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { Input, InputWrapper } from 'components/common/inputs/SearchInput/styles';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import React, { ChangeEvent, useState } from 'react';
import { Sizes } from 'types';
import { Disabled, MaxSizes, Placeholder } from 'types/interface';

interface SearchInputProps extends Sizes, MaxSizes, Disabled, Placeholder {
    onSearch: (value: string) => void;
    allowClear?: boolean;
    defaultValue?: string;
    withoutIcon?: boolean;
}

//TODO: refactor with styled-components
export const SearchInput = ({
    placeholder = 'input search text',
    disabled = false,
    onSearch,
    withoutIcon,
    ...rest
}: SearchInputProps) => {
    const [value, setValue] = useState('');
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') onSearch(value);
    };

    const onSubmit = () => {
        onSearch(value);
    };

    return (
        <InputWrapper {...rest}>
            <Input
                disabled={disabled}
                placeholder={placeholder}
                type="search"
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
            {!withoutIcon && (
                <AbsoluteWrapper right="0" top="0" zIndex="0">
                    <ClickableWrapper onClick={onSubmit}>
                        <ContentWrapper backgroundColor="#1D8CF8" borderRadius="0 8px 8px 0" width="fit-content">
                            <Row alignCenter justifyCenter height="39px" width="38px">
                                <SearchIcon />
                            </Row>
                        </ContentWrapper>
                    </ClickableWrapper>
                </AbsoluteWrapper>
            )}
        </InputWrapper>
    );
};
