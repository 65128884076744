import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const TransactionWrapper = styled.div`
    width: 588px;

    @media ${device.mobile} {
        width: 100%;
    }
`;
