import { Loader } from 'components/common/dynamic/Loader';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { Empty } from 'components/layouts/Empty';
import { MainLayout } from 'components/layouts/MainLayout';
import { useStore } from 'effector-react';
import { WalletCard } from 'pages/Wallet/WalletCard';
import { WalletFilterLayout } from 'pages/Wallet/WalletFilterLayout';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { loadingStores } from 'stores/loading';
import { walletStores } from 'stores/wallet';

export const Wallet = () => {
    const loading = useStore(loadingStores.loading);
    const { items } = useStore(walletStores.item);

    return (
        <MainLayout>
            <WalletFilterLayout>
                {loading ? (
                    <Section alignCenter justifyCenter height="100%">
                        <Loader />
                    </Section>
                ) : (
                    <>
                        {items?.length ? (
                            <Row>
                                {items.map(item => (
                                    <Col key={item.address} md="6" xl="4">
                                        <WalletCard {...item} />
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Row className="justify-content-center align-items-center">
                                <div className="mt-5">
                                    <Empty description="Wallet not found" />
                                </div>
                            </Row>
                        )}
                    </>
                )}
            </WalletFilterLayout>
        </MainLayout>
    );
};
