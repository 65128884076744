import { PropertyBlock } from 'components/common/dividers/PropertyBlock';
import { Loader } from 'components/common/dynamic/Loader';
import { Text } from 'components/common/typography/Text';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { emptyWalletId } from 'constants/global';
import { walletLink } from 'constants/routes';
import { useStore } from 'effector-react';
import { TransactionsHistory } from 'pages/Users/User/TransactionsHistory';
import { WalletDescriptionTable } from 'pages/Wallet/WalletDescriptionTable';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import { userTransactionsEvents, userTransactionsStores } from 'stores/userTransactions';
import { walletEffects, walletStores } from 'stores/wallet';

const { updateValues, setIsFirstToTrue } = userTransactionsEvents;
const { getItem } = walletEffects;

interface ParamsProps {
    walletId: string;
}

export const WalletSingle = () => {
    const { walletId } = useParams<ParamsProps>();
    const history = useHistory();

    if (walletId === emptyWalletId) {
        history.push(walletLink);
    }

    const { items: walletItems, walletId: itemWalletId } = useStore(walletStores.item);
    const womAddress = useMemo(
        () => (walletItems?.length && walletItems[0]?.address !== null ? walletItems[0].address : ''),
        [walletItems]
    );
    const rpAddress = useMemo(
        () => (walletItems?.length && walletItems[1]?.address !== null ? walletItems[1]?.address : ''),
        [walletItems]
    );
    const loading = useStore(getItem.pending);
    const { items, totalRecords } = useStore(userTransactionsStores.items);
    const transactionsLoading = useStore(userTransactionsStores.initialLoading);

    useEffect(() => {
        walletId && getItem({ walletId });
    }, [walletId]);

    useEffect(() => {
        if (walletId === itemWalletId) {
            updateValues({
                walletId: itemWalletId
            });
            setIsFirstToTrue();
        }
    }, [itemWalletId, walletId]);

    return (
        <MainLayout>
            <Breadcrumb />

            <Section marginBottom="16px">
                <Text size="h4">Wallet info</Text>
            </Section>
            {loading ? (
                <Section alignCenter justifyCenter height="290px">
                    <Loader />
                </Section>
            ) : (
                <>
                    <Row className="mb-3">
                        <Col className="mb-2" md={4} sm={12}>
                            <PropertyBlock
                                copyable
                                backgroundColor="secondary"
                                subtitle={walletId}
                                success="Wallet ID is successfully copied"
                                title="Wallet ID"
                            />
                        </Col>
                        <Col className="mb-2" md={4} sm={12}>
                            <PropertyBlock
                                copyable
                                backgroundColor="secondary"
                                subtitle={womAddress}
                                success="WOM Address is successfully copied"
                                title="WOM Address"
                            />
                        </Col>
                        <Col className="mb-2" md={4} sm={12}>
                            <PropertyBlock
                                copyable
                                backgroundColor="secondary"
                                subtitle={rpAddress}
                                success="RP Address is successfully copied"
                                title="RP Address (currently not used)"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <WalletDescriptionTable items={walletItems} />
                        </Col>
                    </Row>
                </>
            )}
            <TransactionsHistory items={items} loading={transactionsLoading} totalRecords={totalRecords} />
        </MainLayout>
    );
};
