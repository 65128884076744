import { DataRangePicker } from 'components/common/inputs/DataPicker';
import { SearchInput } from 'components/common/inputs/SearchInput';
import { Switch } from 'components/common/inputs/Switch';
import { Span } from 'components/common/typography/Span';
import { Row, Section } from 'components/grid/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { defaultBalanceHistorical } from 'constants/defaults/wallet-admin';
import { lg, md, xs } from 'constants/styles';
import { useStore } from 'effector-react';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';
import { walletAdminEffects, walletAdminEvents, walletAdminStores } from 'stores/wallet-admin';
import { selectOptions } from './constants';
import { HoldersChart } from './HoldersChart';
import {
    FilterTitle,
    QueryId,
    SelectDate,
    SelectDateRange,
    ToggleButton,
    Wrapper,
    WrapperDataRangePicker,
    WrapperSearchInput,
    WrapperSelect,
    WrapperSelectDate
} from './styles';

const { updateValues } = walletAdminEvents;

export const WomHolders = () => {
    const [filterType, setFilterType] = useState(selectOptions[0]);
    const [groupByWeekChecked, setGroupByWeekChecked] = useState(false);
    const { dateFromInclusive, dateToInclusive } = useStore(walletAdminStores.values);
    const isTablet = useMediaQuery({ query: `(max-width: ${md})` });
    const isXsMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const isLgMobile = useMediaQuery({ query: `(max-width: ${lg})` });
    const checkboxLabelPosition = isXsMobile ? 'top' : isTablet ? 'right' : isLgMobile ? 'top' : 'right';

    const onGroupByWeekCheck = () => {
        setGroupByWeekChecked(prev => !prev);
    };

    const onDateRange = ([dateFrom, dateTo]: [Moment, Moment]) => {
        updateValues({
            dateFromInclusive: dateFrom.toISOString(),
            dateToInclusive: dateTo.toISOString()
        });
    };

    const onSearch = (userId: string) => {
        updateValues({ userId });
    };

    useEffect(() => {
        walletAdminEffects.getBalanceHistorical(defaultBalanceHistorical);
    }, []);

    return (
        <MainLayout>
            <Row marginBottom="15px">
                <Span fontSize="13px" lineHeight="19px" opacity={0.8}>
                    Wom Holders
                </Span>
            </Row>
            <Section marginBottom="30px" noWrap={!isTablet}>
                <SelectDate>
                    <WrapperSelectDate>
                        <FilterTitle>Select Date Range</FilterTitle>
                        <SelectDateRange>
                            <WrapperDataRangePicker>
                                <DataRangePicker
                                    value={[new Date(dateFromInclusive || ''), new Date(dateToInclusive || '')]}
                                    onChange={onDateRange}
                                />
                            </WrapperDataRangePicker>
                            <ToggleButton>
                                <Switch
                                    checked={groupByWeekChecked}
                                    label="Group By Week"
                                    labelPosition={checkboxLabelPosition}
                                    onChange={onGroupByWeekCheck}
                                />
                            </ToggleButton>
                        </SelectDateRange>
                    </WrapperSelectDate>
                </SelectDate>
                <Section marginLeft={!isTablet ? '32px' : '0'}>
                    <Wrapper>
                        <FilterTitle>Query by IDs</FilterTitle>
                        <QueryId>
                            <WrapperSearchInput>
                                <SearchInput placeholder="Search by user ID" onSearch={onSearch} />
                            </WrapperSearchInput>
                            <WrapperSelect>
                                <Select
                                    className="react-select info w-100"
                                    classNamePrefix="react-select"
                                    options={selectOptions}
                                    value={filterType}
                                    onChange={value => value && setFilterType(value)}
                                />
                            </WrapperSelect>
                        </QueryId>
                    </Wrapper>
                </Section>
            </Section>
            <HoldersChart />
        </MainLayout>
    );
};
