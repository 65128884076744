export const inputBorderBottom = '1px solid rgba(196, 196, 196, 0.4)';
export const nonEmptyStringRegExp = /\S/;

export const formatTagsArray = (arr: string[] | undefined, defaultTag: string | undefined) => {
    const nonEmptyDefaultTag = defaultTag && nonEmptyStringRegExp.test(defaultTag);
    const formattedArr = arr && arr.filter(item => nonEmptyStringRegExp.test(item));

    if (formattedArr && formattedArr.length > 0) {
        return formattedArr;
    }

    return nonEmptyDefaultTag && defaultTag ? [defaultTag] : undefined;
};

export const copyOrganizationIdMessage = 'You successfully copied Organization id!';
export const copyWalletIdMessage = 'You successfully copied Wallet id!';
export const copyPublicIdMessage = 'You successfully copied Public id!';
