import { isDev } from 'constants/global';
import { TableHeadInterface } from 'types';
import { removeSpaces } from 'utils/parsers';

export const performancePaymentsTitleColumns: TableHeadInterface[] = [
    {
        title: 'Date',
        dataIndex: 'date',
        style: {
            className: 'text-center'
        },
        key: 'date'
    },
    {
        title: 'Is completed',
        dataIndex: 'isCompleted',
        key: 'isCompleted'
    },
    {
        title: 'Calculated',
        dataIndex: 'womEmissionCalculated',
        key: 'womEmissionCalculated'
    },
    {
        title: 'Count',
        dataIndex: 'count',
        key: 'count'
        // sorter: {
        //     compare: (a: any, b: any) => removeSpaces(a.globalPoints) - removeSpaces(b.globalPoints)
        //     // multiple: 4
        // }
    },
    {
        title: 'EmissionExpected',
        dataIndex: 'womEmissionExpected',
        key: 'womEmissionExpected'
    },
    {
        title: 'Auth. P.',
        dataIndex: 'globalAuthenticationPoints',
        key: 'globalAuthenticationPoints'
    },
    {
        title: 'Content. P.',
        dataIndex: 'globalContentPoints',
        key: 'globalContentPoints'
    },
    {
        title: 'Points',
        dataIndex: 'globalPoints',
        key: 'globalPoints'
        // sorter: {
        //     compare: (a: any, b: any) => removeSpaces(a.globalPoints) - removeSpaces(b.globalPoints)
        //     // multiple: 4
        // }
    },
    {
        title: 'Excel',
        dataIndex: 'url',
        key: 'url'
    }
];

export const performancePaymentTitleColumns: TableHeadInterface[] = [
    {
        title: 'source',
        dataIndex: 'source',
        key: 'source',
        sorter: {
            compare: (a: any, b: any) => {
                if (a.source > b.source) {
                    return 1;
                }
                if (a.source < b.source) {
                    return -1;
                }
                return 0;
            }
        }
    },
    {
        title: 'Date of Creation',
        dataIndex: 'utcCreated',
        key: 'utcCreated',
        sorter: {
            compare: (a: any, b: any) => {
                if (a.utcCreated > b.utcCreated) {
                    return 1;
                }
                if (a.utcCreated < b.utcCreated) {
                    return -1;
                }
                return 0;
            }
        }
    },
    {
        title: 'User Name',
        dataIndex: 'userName',
        key: 'userName',
        sorter: {
            compare: (a: any, b: any) => {
                if (a.userName > b.userName) {
                    return 1;
                }
                if (a.userName < b.userName) {
                    return -1;
                }
                return 0;
            }
        }
    },
    {
        title: 'WOM User ID',
        dataIndex: 'userId',
        key: 'userId'
    },
    {
        title: 'Content ID',
        dataIndex: 'contentId',
        key: 'contentId'
    },

    {
        title: 'Authentication',
        dataIndex: 'authenticationPoints',
        key: 'authenticationPoints',
        sorter: {
            compare: (a: any, b: any) => removeSpaces(a.authenticationPoints) - removeSpaces(b.authenticationPoints)
        }
    },
    {
        title: 'Content',
        dataIndex: 'contentPoints',
        key: 'contentPoints',
        sorter: {
            compare: (a: any, b: any) => removeSpaces(a.contentPoints) - removeSpaces(b.contentPoints)
            // multiple: 3
        }
    },
    {
        title: 'Points',
        dataIndex: 'totalPoints',
        key: 'totalPoints',
        sorter: {
            compare: (a: any, b: any) => removeSpaces(a.totalPoints) - removeSpaces(b.totalPoints)
            // multiple: 2
        }
    },
    {
        title: 'Payout WOM',
        dataIndex: 'womPayout',
        key: 'womPayout',
        sorter: {
            compare: (a: any, b: any) => removeSpaces(a.womPayout) - removeSpaces(b.womPayout)
        },
        defaultSortOrder: 'descending'
    }
];

const yeayDevUsers = 'https://yeay.admin.dev-stage.su/users/';
const yeayProdUsers = 'https://afjmjt.yeay.com/users/';

const bullzDevUsers = 'https://bullz.admin.dev-stage.su/users/';

const bullzProdUsers = 'https://bnasatap.bullz.com/users/';

export const getRedirectUrlToYeayOrBullzUsers = (remoteUserId: string, source: string) =>
    source === 'yeay'
        ? isDev
            ? yeayDevUsers + remoteUserId
            : yeayProdUsers + remoteUserId
        : isDev
        ? bullzDevUsers + remoteUserId
        : bullzProdUsers + remoteUserId;
