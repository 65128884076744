import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { grey2, lg } from 'constants/styles';
import styled from 'styled-components';

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1031;
    background: rgba(0, 0, 0, 0.6);
`;

export const ModalWrapper = styled(ContentWrapper)`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 876px;
    min-height: 344px;
    max-height: 504px;
    background-color: ${grey2};
    border-radius: 12px;
    z-index: 1110;
    overflow: auto;

    @media screen and (max-width: ${lg}) {
        width: 80%;
        height: 80vh;
    }
`;

export const TableWrapper = styled(Row)`
    max-height: 222px;
    overflow-y: scroll;
`;

export const ButtonWrapper = styled(Row)`
    width: 100%;
    justify-content: flex-end;
    column-gap: 16px;
`;
