import { Loader } from 'components/common/dynamic/Loader';
import { Span } from 'components/common/typography/Span';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { Section } from 'components/grid/Section';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/layouts/Empty';
import { useStore } from 'effector-react';
import { NotFoundMessage, title } from 'pages/Transactions/Transaction/constants';
import { TransactionWrapper } from 'pages/Transactions/Transaction/styles';
import { TransactionDescriptionTable } from 'pages/Transactions/Transaction/TransactionDescriptionTable';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { transactionsEffects, transactionsStores } from 'stores/transactions';

interface ParamsProps {
    transactionId: string;
}

export const Transaction = () => {
    const { transactionId } = useParams<ParamsProps>();
    const item = useStore(transactionsStores.item);
    const loading = useStore(transactionsStores.loading);

    useEffect(() => {
        transactionId && transactionsEffects.getItemById(transactionId);
    }, [transactionId]);

    return (
        <>
            <MainLayout>
                <Breadcrumb />
                <Row marginBottom="30px">
                    <Span fontSize="17px" lineHeight="25px">
                        {title}
                    </Span>
                </Row>
                {loading ? (
                    <Section justifyCenter>
                        <Loader size="large" />
                    </Section>
                ) : item?.transactionId ? (
                    <TransactionWrapper>
                        <TransactionDescriptionTable {...item} />
                    </TransactionWrapper>
                ) : (
                    <Section alignCenter justifyCenter>
                        <Empty description={NotFoundMessage} />
                    </Section>
                )}
            </MainLayout>
        </>
    );
};
