import styled from 'styled-components';

export const SubtitleWrapper = styled.div`
    width: 70%;
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledLink = styled.a`
    width: 100%;
`;
