export enum TransactionTypes {
    None = 0,
    Deposit = 100,
    Withdrawal = 200,
    CreatorStake = 300,
    CreatorReward = 301,
    CreatorStakeRefund = 302,
    CreatorBonus = 303,
    ValidationStake = 400,
    ValidationReward = 401,
    ValidationStakeRefund = 402,
    ValidationProfit = 410,
    Exchange = 500,
    UserTransfer = 600,
    Reward = 700,
    MigrationSync = 800,
    CampaignPayment = 900,
    PerformancePayment = 1000,
    PerformancePaymentCreator = 1001,
    PerformancePaymentAuthenticator = 1002,
    PerformancePaymentFacilitator = 1003,
    PerformancePaymentPublisher = 1004,
    PerformancePaymentDisplayNetwork = 1005,
    PerformancePaymentAdvertiser = 1006,
    PerformancePaymentBrand = 1007,
    GiftCardPayment = 2000,
    GiftCardRefund = 2100,
    OrganizationPurchase = 3000,
    EscrowIn = 4000,
    EscrowRelease = 4001,
    PlatformFee = 10000
}

export const transactionTypesNames = {
    [TransactionTypes.None]: 'None',
    [TransactionTypes.Deposit]: 'Deposit',
    [TransactionTypes.Withdrawal]: 'Withdrawal',
    [TransactionTypes.CreatorStake]: 'Creator Stake',
    [TransactionTypes.CreatorReward]: 'Creator Reward',
    [TransactionTypes.CreatorStakeRefund]: 'Creator StakeRefund',
    [TransactionTypes.CreatorBonus]: 'Creator Bonus',
    [TransactionTypes.ValidationStake]: 'Validation Stake',
    [TransactionTypes.ValidationReward]: 'Validation Reward',
    [TransactionTypes.ValidationStakeRefund]: 'Validation Stake Refund',
    [TransactionTypes.ValidationProfit]: 'Validation Profit',
    [TransactionTypes.Exchange]: 'Exchange',
    [TransactionTypes.UserTransfer]: 'User Transfer',
    [TransactionTypes.Reward]: 'Reward',
    [TransactionTypes.MigrationSync]: 'Migration Sync',
    [TransactionTypes.CampaignPayment]: 'Campaign Payment',
    [TransactionTypes.PerformancePayment]: 'Performance Payment',
    [TransactionTypes.PerformancePaymentCreator]: 'Performance Payment Creator',
    [TransactionTypes.PerformancePaymentAuthenticator]: 'Performance Payment Authenticator',
    [TransactionTypes.PerformancePaymentFacilitator]: 'Performance Payment Facilitator',
    [TransactionTypes.PerformancePaymentPublisher]: 'Performance Payment Publisher',
    [TransactionTypes.PerformancePaymentDisplayNetwork]: 'Performance Payment DisplayNetwork',
    [TransactionTypes.PerformancePaymentAdvertiser]: 'Performance Payment Advertiser',
    [TransactionTypes.PerformancePaymentBrand]: 'Performance Payment Brand',
    [TransactionTypes.GiftCardPayment]: 'GiftCard Payment',
    [TransactionTypes.GiftCardRefund]: 'GiftCard Refund',
    [TransactionTypes.OrganizationPurchase]: 'Organization Purchase',
    [TransactionTypes.EscrowIn]: 'Escrow In',
    [TransactionTypes.EscrowRelease]: 'Escrow Release',
    [TransactionTypes.PlatformFee]: 'Platform Fee'
};

export const positiveTypes = {
    [TransactionTypes.None]: false,
    [TransactionTypes.Deposit]: true,
    [TransactionTypes.Withdrawal]: false,
    [TransactionTypes.CreatorStake]: false,
    [TransactionTypes.CreatorReward]: true,
    [TransactionTypes.CreatorStakeRefund]: true,
    [TransactionTypes.CreatorBonus]: true,
    [TransactionTypes.ValidationStake]: false,
    [TransactionTypes.ValidationReward]: true,
    [TransactionTypes.ValidationStakeRefund]: true,
    [TransactionTypes.ValidationProfit]: true,
    [TransactionTypes.Exchange]: true,
    [TransactionTypes.UserTransfer]: true,
    [TransactionTypes.Reward]: true,
    [TransactionTypes.MigrationSync]: false,
    [TransactionTypes.CampaignPayment]: true,
    [TransactionTypes.PerformancePayment]: true,
    [TransactionTypes.PerformancePaymentCreator]: true,
    [TransactionTypes.PerformancePaymentAuthenticator]: true,
    [TransactionTypes.PerformancePaymentFacilitator]: true,
    [TransactionTypes.PerformancePaymentPublisher]: true,
    [TransactionTypes.PerformancePaymentDisplayNetwork]: true,
    [TransactionTypes.PerformancePaymentAdvertiser]: true,
    [TransactionTypes.PerformancePaymentBrand]: true,
    [TransactionTypes.GiftCardPayment]: false,
    [TransactionTypes.GiftCardRefund]: true,
    [TransactionTypes.OrganizationPurchase]: true,
    [TransactionTypes.EscrowIn]: true,
    [TransactionTypes.EscrowRelease]: true,
    [TransactionTypes.PlatformFee]: false
};
