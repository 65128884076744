import styled from 'styled-components';
import { white } from 'constants/styles';
import { SwitchBgImage } from './consts';
import { PoppinsFontFamily } from 'constants/styles/fonts';

export type SwitchDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type SwitchJustify = 'flex-start' | 'flex-end' | 'center';

interface SwitchLabelProps {
    direction: SwitchDirection;
    justify: SwitchJustify;
    align: SwitchJustify;
}

interface SwitchWrapperProps {
    checked: boolean;
}

export const SwitchWrapper = styled.div<SwitchWrapperProps>`
    position: relative;
    height: 22px;
    width: 45px;

    &::before,
    &::after {
        content: '';
        position: absolute;
        transition: all 0.1s linear;
    }

    &::before {
        top: 0;
        left: 0;
        width: 45px;
        height: 22px;
        background: ${({ checked }) => (checked ? `url(${SwitchBgImage})` : 'rgba(0, 0, 0, 0.5)')};
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 11px;
    }
    &::after {
        top: 2px;
        left: ${({ checked }) => (checked ? '26px' : '1px')};
        width: 18px;
        height: 18px;
        background-color: ${({ checked }) => (checked ? white : '#8F8F90')};
        border-radius: 50%;
    }
`;

export const SwitchInput = styled.input`
    position: absolute;
    left: -9999px;
    appearance: none;
    visibility: hidden;
`;

export const SwitchLabel = styled.label<SwitchLabelProps>`
    display: flex;
    column-gap: 15px;
    row-gap: 5px;
    flex-direction: ${({ direction }) => direction};
    justify-content: ${({ justify }) => justify};
    align-items: ${({ align }) => align};
`;

export const SwitchLabelText = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    font-family: ${PoppinsFontFamily};
    justify-self: center;
    align-self: center;
`;
