import { TableHeadInterface } from 'types';

export const walletDescriptionTableColumns: TableHeadInterface[] = [
    {
        title: 'Account name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Created at',
        dataIndex: 'utcCreated',
        key: 'utcCreated'
    },
    {
        title: 'Account type',
        dataIndex: 'accountType',
        key: 'accountType'
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance'
    },
    {
        title: 'Balance pending',
        dataIndex: 'pendingBalance',
        key: 'pendingBalance'
    },
    {
        title: 'Balance queried at',
        dataIndex: 'balanceQueriedAtUtc',
        key: 'balanceQueriedAtUtc'
    }
];
