import { disableDefaultButtonStyleMixin, white, navyGradient, navyHoverGradient, grey } from 'constants/styles';
import { PoppinsFontFamily } from 'constants/styles/fonts';
import styled, { css } from 'styled-components';
import { Disabled, NoWrap } from 'types';
import { NavLink } from 'react-router-dom';

const buttonBase = () => css`
    font-weight: 400;
    font-family: ${PoppinsFontFamily};
    font-size: 14px;
    line-height: 21px;
    padding: 13px 40px;
    color: ${white};
    border-radius: 6px;
`;

export const navyButton = css`
    background: ${navyGradient};

    &:hover {
        color: ${white};
        background: ${navyHoverGradient};
    }
`;

export const getTypeStyle = (colorStyle: string) => {
    switch (colorStyle) {
        case 'navy':
            return navyButton;
        default:
            return navyButton;
    }
};

export interface ButtonLinkProps extends Disabled, NoWrap {
    buttonType?: 'navy';
}

export const StyledNavLink = styled(NavLink)<ButtonLinkProps>`
    ${disableDefaultButtonStyleMixin};
    ${buttonBase()};
    ${({ buttonType }) => buttonType && getTypeStyle(buttonType)};
    ${({ noWrap }) => noWrap && 'white-space: nowrap'};

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: pointer;
            background-color: ${grey};
        `};
`;
