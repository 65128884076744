import { ReactClick } from 'types/interfaces/react';
import { EditIcon } from 'assets/img';
import { StyledButton } from 'components/common/buttons/EditButton/styles';
import React from 'react';

export const EditButton = ({ onClick }: ReactClick<HTMLButtonElement>) => (
    <StyledButton onClick={onClick}>
        <EditIcon />
    </StyledButton>
);
