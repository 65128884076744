import { ThreeDotsIcon } from 'assets/img';
import logo from 'assets/wom_logo.png';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { CustomImg } from 'components/common/imgComponents/CustomImg';

import {
    Arrow,
    DropdownToggle,
    DropdownWrapper,
    Label,
    LeftSideNavBarWrapper,
    NavBarWrapper,
    StyledHorizontalLine,
    StyledSection,
    UserInfo,
    VersionInfo
} from 'components/grid/Navbar/styles';
import { Row, Section } from 'components/grid/wrappers/flexWrapper';
import { RelativeWrapper } from 'components/grid/wrappers/RelativeWrapper';
import { appVersion, environmentWOM } from 'constants/services';
import { lg } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
// reactstrap components
import { Button, DropdownItem, Nav, Navbar, NavbarBrand, NavLink, UncontrolledTooltip } from 'reactstrap';
import { userEvents, userStores } from 'stores/user';
import { HandleMiniToggle, IsSidebarOpened } from 'types/interfaces/global';
import { NoopType } from 'types/types';

interface AdminNavbarProps extends HandleMiniToggle, IsSidebarOpened {
    toggleSidebar: NoopType;
    brandText?: string;
    hiddenSearch?: boolean;
}

export const AdminNavbar = ({ handleMiniToggle, isSidebarOpened, toggleSidebar, brandText = '' }: AdminNavbarProps) => {
    const { user } = useStore(userStores.user);
    const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: ${lg})` });
    const navbarClassName = classNames('navbar-absolute', 'navbar-transparent');

    const toggleVisibility = () => {
        setDropdownIsVisible(!dropdownIsVisible);
    };

    const onVersionsButtonClick = () => {
        setDropdownIsVisible(!dropdownIsVisible);
        window.ivwt?.show && window.ivwt.show();
    };

    const logout = () => {
        setDropdownIsVisible(!dropdownIsVisible);
        userEvents.logout();
    };

    return (
        <NavBarWrapper>
            <Navbar className={navbarClassName} expand="lg">
                <StyledSection>
                    <div className="navbar-wrapper">
                        <LeftSideNavBarWrapper>
                            {/* //* screen > 992px hamburger -> bullet-list  */}
                            <div className="navbar-minimize d-inline">
                                <Button
                                    className="minimize-sidebar btn-just-icon"
                                    color="link"
                                    id="tooltip209599"
                                    onClick={handleMiniToggle}
                                >
                                    <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                                    <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                                </Button>
                                <UncontrolledTooltip delay={0} placement="right" target="tooltip209599">
                                    Sidebar toggle
                                </UncontrolledTooltip>
                            </div>

                            {/* //* screen < 992px Animated button from hamburger to cross */}
                            <div
                                className={classNames('navbar-toggle d-inline', {
                                    toggled: isSidebarOpened
                                })}
                            >
                                <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                                    <span className="navbar-toggler-bar bar1" />
                                    <span className="navbar-toggler-bar bar2" />
                                    <span className="navbar-toggler-bar bar3" />
                                </button>
                            </div>

                            <NavbarBrand
                                href="#pablo"
                                style={{ whiteSpace: 'normal' }}
                                onClick={e => e.preventDefault()}
                            >
                                <UserInfo>{brandText}&nbsp;&nbsp;</UserInfo>
                                <UserInfo>{user?.email ? ` (${user?.email})` : ''}</UserInfo>
                            </NavbarBrand>
                        </LeftSideNavBarWrapper>
                    </div>

                    <Row>
                        <Nav navbar className="ml-auto">
                            <RelativeWrapper>
                                <DropdownToggle onClick={() => toggleVisibility()}>
                                    {!isMobile ? (
                                        <Section alignCenter justifyAround noWrap>
                                            <Row alignCenter marginRight="10px" width="30px">
                                                <CustomImg alt="Logo" src={logo} />
                                            </Row>
                                            <Row alignCenter>
                                                <Arrow />
                                            </Row>
                                        </Section>
                                    ) : (
                                        <ThreeDotsIcon />
                                    )}
                                </DropdownToggle>

                                <DropdownWrapper visible={dropdownIsVisible}>
                                    <Label>User</Label>
                                    <NavLink tag="li">
                                        <DropdownItem className="nav-item" onClick={logout}>
                                            Log out
                                        </DropdownItem>
                                    </NavLink>
                                    <StyledHorizontalLine />

                                    <Label>About</Label>
                                    <NavLink tag="li">
                                        <DropdownItem className="nav-item" onClick={onVersionsButtonClick}>
                                            What’s new
                                            {/* <UpdatesCounter>2</UpdatesCounter> */}
                                        </DropdownItem>
                                    </NavLink>
                                    <VersionInfo>
                                        Version: {appVersion} WOM: {environmentWOM}
                                    </VersionInfo>
                                </DropdownWrapper>
                            </RelativeWrapper>

                            <li className="separator d-lg-none" />
                        </Nav>
                    </Row>
                </StyledSection>
            </Navbar>
        </NavBarWrapper>
    );
};
