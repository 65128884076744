import React, { FC, Fragment, useEffect, useState } from 'react';
import { TableProps } from 'reactstrap';
import classNames from 'classnames';
import { noop } from 'constants/global';
import { OrderType, TableBodyInterface, TableDataInterface, TableHeadInterface } from 'types';
import { ContentWrapper, TableStyled, TableHeadStyled, InfoMessage } from './styles';

interface SimpleTableProps extends Pick<TableProps, 'responsive'> {
    thead: TableHeadInterface[];
    tbody?: TableBodyInterface[];
    expandable?: (record: TableDataInterface) => JSX.Element;
    infoMessage?: string;
    withoutMarginBottom?: boolean;
}

export const SimpleTable: FC<SimpleTableProps> = ({ thead, tbody, expandable, infoMessage, withoutMarginBottom }) => {
    const bodyData =
        tbody?.map(({ data: rowData, className }) => ({
            data: thead.map(({ dataIndex, key }) => ({
                colData: rowData[dataIndex],
                key
            })),
            className
        })) || [];
    const [body, setBody] = useState(bodyData);
    const [expandedRows, setExpandedRows] = useState<number[]>([]);

    const [{ orderColumnKey, order }, setColumn] = useState<{ orderColumnKey?: string; order: OrderType }>({
        orderColumnKey: undefined,
        order: 'no'
    });

    const isSorter = thead.some(({ sorter }) => sorter);

    const onSort = (key: string, newOrder: OrderType) => {
        const dataSort = body.map(({ data }) => data);
        /* TODO: improve logic for any values and add sort callback for sorting method */
        const sortedData = dataSort.sort((a, b) => {
            const firstObj = a.find(({ key: keyValue }) => keyValue === key);
            const secondObj = b.find(({ key: keyValue }) => keyValue === key);

            // @ts-ignore
            if (!firstObj || !secondObj || !('jsx' in firstObj.colData) || !('jsx' in secondObj.colData)) return 0;

            const firstValue = firstObj.colData.value;
            const secondValue = secondObj.colData.value;

            if (newOrder === 'ascending') {
                if (typeof firstValue === 'number' && typeof secondValue === 'number') return firstValue - secondValue;
                if (firstValue > secondValue) {
                    return 1;
                }
                if (firstValue < secondValue) {
                    return -1;
                }
                return 0;
            }

            if (newOrder === 'descending') {
                if (typeof firstValue === 'number' && typeof secondValue === 'number') return secondValue - firstValue;
                if (secondValue > firstValue) {
                    return 1;
                }
                if (secondValue < firstValue) {
                    return -1;
                }
                return 0;
            }

            return 0;
        });

        if (newOrder === 'no') {
            setBody(bodyData);
            setColumn({ orderColumnKey: undefined, order: newOrder });
        } else {
            setBody(currentBody => currentBody.map(({ className }, i) => ({ data: sortedData[i], className })));
            setColumn({ orderColumnKey: key, order: newOrder });
        }
    };

    const onSortClick = (key: string) => () =>
        order === 'no'
            ? onSort(key, 'ascending')
            : order === 'ascending'
            ? onSort(key, 'descending')
            : onSort(key, 'no');

    /* TODO: Improve sorting. Need table sorting with first components rendering (in bodyData if defaultSortOrder is exist)  */
    /* now when triggered useEffect user see table sorting with table component rerender  */
    useEffect(() => {
        setBody(bodyData);
        thead.forEach(({ defaultSortOrder, key }) => {
            defaultSortOrder && onSort(key, defaultSortOrder);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tbody]);

    return (
        <ContentWrapper withoutMarginBottom={withoutMarginBottom}>
            <TableStyled className={classNames({ tablesorter: isSorter })}>
                <TableHeadStyled className="text-primary">
                    <tr>
                        {expandable && <th />}
                        {thead.map(({ style, key, title, sorter }) => {
                            const className = style?.className || 'text-center';
                            return (
                                <th
                                    key={key.toString()}
                                    className={classNames({
                                        [className]: !!className,
                                        header: !!sorter,
                                        headerSortDown: orderColumnKey === key && order === 'ascending',
                                        headerSortUp: orderColumnKey === key && order === 'descending'
                                    })}
                                    style={{ whiteSpace: 'nowrap' }}
                                    onClick={!!sorter ? onSortClick(key) : noop}
                                >
                                    {title}
                                </th>
                            );
                        })}
                    </tr>
                </TableHeadStyled>
                <tbody>
                    {tbody && body && expandable
                        ? body.map(({ data }, key) => (
                              <Fragment key={key.toString()}>
                                  <tr>
                                      <td>
                                          <div className="d-flex flex-column justify-content-center align-items-center">
                                              <button
                                                  className="expand-button"
                                                  onClick={() =>
                                                      setExpandedRows(currentState =>
                                                          currentState.some(index => index === key)
                                                              ? currentState.filter(index => index !== key)
                                                              : [...currentState, key]
                                                      )
                                                  }
                                              >
                                                  <i
                                                      className={classNames(
                                                          'tim-icons',
                                                          expandedRows.some(index => index === key)
                                                              ? 'icon-simple-delete'
                                                              : 'icon-simple-add'
                                                      )}
                                                  />
                                              </button>
                                          </div>
                                      </td>
                                      {data.map(({ colData, key: keyCol }, index) => {
                                          const dataClassName = thead[index]?.style?.className || 'text-center';

                                          return (
                                              <td
                                                  key={keyCol}
                                                  className={classNames({
                                                      [dataClassName]: !!dataClassName
                                                  })}
                                              >
                                                  {typeof colData === 'object' && 'jsx' in colData
                                                      ? colData.jsx
                                                      : colData}
                                              </td>
                                          );
                                      })}
                                  </tr>
                                  {expandedRows.some(index => index === key) && (
                                      <tr>
                                          <td colSpan={data.length + 1}>{expandable(tbody[key].data)}</td>
                                      </tr>
                                  )}
                              </Fragment>
                          ))
                        : body.map(({ data }, key) => (
                              <tr key={key.toString()}>
                                  {data.map(({ colData, key: keyCol }, index) => {
                                      const dataClassName = thead[index]?.style?.className || 'text-center';

                                      return (
                                          <td
                                              key={keyCol}
                                              className={classNames({
                                                  [dataClassName]: !!dataClassName
                                              })}
                                          >
                                              {typeof colData === 'object' && 'jsx' in colData ? colData.jsx : colData}
                                          </td>
                                      );
                                  })}
                              </tr>
                          ))}
                </tbody>
            </TableStyled>
            {(!tbody || (tbody && !tbody.length)) && infoMessage && <InfoMessage>{infoMessage}</InfoMessage>}
        </ContentWrapper>
    );
};
