import {
    blue3,
    disableDefaultButtonStyleMixin,
    flexCenter,
    textDisabled,
    transitionTime,
    white
} from 'constants/styles';
import styled from 'styled-components';

interface StyledButtonProps {
    width?: string;
}

export const StyledButton = styled.button<StyledButtonProps>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};

    width: 16px;
    height: 16px;
    color: ${white};
    border: 1px solid ${white};
    border-radius: 50%;
    transition: color ease-in ${transitionTime}, border-color ease-in ${transitionTime};

    &:hover {
        color: ${blue3};
        border-color: ${blue3};
    }

    &:disabled {
        cursor: auto;
        color: ${textDisabled};
        border-color: ${textDisabled};
    }
`;
