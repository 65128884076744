import { TableHeadInterface } from 'types';
import { removeSpaces } from 'utils/parsers';

export const performancePaymentsContentSingleColumns: TableHeadInterface[] = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        style: {
            className: 'text-start'
        }
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: {
            compare: (a: any, b: any) => removeSpaces(a.value) - removeSpaces(b.value)
        },
        style: {
            className: 'text-start'
        }
    },
    {
        title: 'User id',
        dataIndex: 'userId',
        key: 'userId',
        style: {
            className: 'text-start'
        }
    },
    {
        title: 'Transaction id',
        dataIndex: 'transactionId',
        key: 'transactionId',
        style: {
            className: 'text-start'
        }
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        style: {
            className: 'text-start'
        }
    }
];
