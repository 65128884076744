import React, { FC } from 'react';
import { QuestionMarkIcon } from 'assets/img';
import { InfoButtonProps } from './types';
import { StyledButton } from './styles';

export const InfoButton: FC<InfoButtonProps> = ({ ...props }) => (
    <StyledButton {...props}>
        <QuestionMarkIcon />
    </StyledButton>
);
