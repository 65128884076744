import avatarBULLZ from 'assets/bullz_logo.svg';
import errorIcon from 'assets/img/error_icon.svg';
import avatarWOM from 'assets/wom_logo.png';
import avatarYEAY from 'assets/yeay_logo.svg';
import { Button } from 'components/common/buttons/Button';
import { Avatar } from 'components/common/dividers/Avatar';
import { PropertyBlock } from 'components/common/dividers/PropertyBlock';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { Tag } from 'components/common/tags/Tag';
import { Text } from 'components/common/typography/Text';
import { CardWrapper } from 'components/grid/wrappers/CardWrapper/styles';
import { Column, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Tooltip } from 'components/modals/Tooltip';
import { defaultUserRoles } from 'constants/defaults';
import { BASYUrl, formatDate, YASYUrl } from 'constants/global';
import { usersLink } from 'constants/routes';
import { blue } from 'constants/styles';
import { format } from 'date-fns';
import {
    copyUserIdMessage,
    copyUsernameMessage,
    copyWalletIdMessage,
    getRole,
    parseRemoveRoleDescription,
    parseRemoveRoleSuccessMessage,
    ywbChooser
} from 'pages/Users/UserCard/constants';
import { TagsWrapper } from 'pages/Users/UserCard/styles';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import { modalEvents } from 'stores/modal';
import { usersEffects } from 'stores/users';

const { manageUserRole } = usersEffects;

export const UserCard: FC<WOM.GetUserResponse> = ({
    userId,
    username,
    email,
    utcCreated,
    utcLastAuthentication,
    roles,
    isAccountVerified,
    walletId,
    freeStakesRemaining,
    remoteIssuer,
    remoteUsername,
    remoteUserId,
    mobileNumber
}) => {
    const history = useHistory();
    const moreInfoHandleClick = () => history.push(usersLink + '/' + userId);
    const avatarSrc = ywbChooser(remoteIssuer, avatarYEAY, avatarBULLZ, avatarWOM);
    const usernameToShow = ywbChooser(remoteIssuer, remoteUsername, remoteUsername, username);
    const linkToShow = ywbChooser(
        remoteIssuer,
        `${YASYUrl}/users/${remoteUserId}`,
        `${BASYUrl}/users/${remoteUserId}`,
        ''
    );

    const onRemoveRoleClick = (roleToRemove: string) => () => {
        modalEvents.openConfirmationModal({
            visible: true,
            confirmationQuestion: 'Remove role from a user',
            confirmationText: parseRemoveRoleDescription(username || '', roleToRemove),
            errorMessage: `Something wrong`,
            successMessage: parseRemoveRoleSuccessMessage(roleToRemove, username || ''),
            successTitle: `Changed`,
            confirmationButtonText: 'Remove',
            onConfirm: () =>
                manageUserRole({
                    userId,
                    remove: true,
                    role: roleToRemove
                })
        });
    };

    return (
        <CardWrapper>
            {!isAccountVerified && (
                <div className="not-verified-badge">
                    <Tooltip id={userId + 'error-icon'} title="Account is not verified">
                        <CustomImg alt="error" src={errorIcon} />
                    </Tooltip>
                </div>
            )}
            <Section alignCenter noWrap marginBottom="16px">
                <MarginWrapper marginRight="16px">
                    <Avatar src={avatarSrc} />
                </MarginWrapper>
                <Column>
                    <Tooltip id={userId + 'username' || ''} placement="top" title="Username">
                        <Text size="h4">{usernameToShow}</Text>
                    </Tooltip>

                    {email ? (
                        <Tooltip id={userId + 'email' || ''} placement="top" title="Email">
                            <Text color={blue} size="h5">
                                {email}
                            </Text>
                        </Tooltip>
                    ) : mobileNumber ? (
                        <Tooltip id={userId + 'mobileNumber' || ''} placement="top" title="Mobile number">
                            <Text color={blue} size="h5">
                                {mobileNumber}
                            </Text>
                        </Tooltip>
                    ) : (
                        <span>No email</span>
                    )}
                </Column>
            </Section>
            <Row className="mb-2">
                <Col className="mb-2" lg="6">
                    <PropertyBlock copyable subtitle={userId} success={copyUserIdMessage} title="User ID" />
                </Col>
                <Col className="mb-2" lg="6">
                    <PropertyBlock
                        copyable
                        link={remoteUserId ? linkToShow : undefined}
                        subtitle={usernameToShow || ''}
                        success={copyUsernameMessage}
                        title="Username"
                    />
                </Col>
                <Col className="mb-2" lg="12">
                    <PropertyBlock copyable subtitle={walletId} success={copyWalletIdMessage} title="Wallet ID" />
                </Col>
                <Col className="mb-2" lg="6">
                    <PropertyBlock subtitle={format(new Date(utcCreated || ''), formatDate)} title="Created Account" />
                </Col>
                <Col className="mb-2" lg="6">
                    <PropertyBlock
                        subtitle={format(new Date(utcLastAuthentication || ''), formatDate)}
                        title="Last login"
                    />
                </Col>
            </Row>
            <Section noWrap marginBottom="16px">
                <Text size="h4">Free Stakes Remaining: </Text>
                <Text color={blue} size="h4">
                    &nbsp;{freeStakesRemaining || 0}
                </Text>
            </Section>
            <TagsWrapper>
                {roles?.length ? (
                    roles?.map(role => (
                        <div key={role}>
                            <Tag
                                color="info"
                                onRemove={defaultUserRoles.includes(role) ? undefined : onRemoveRoleClick(role)}
                            >
                                {getRole(role)}
                            </Tag>
                        </div>
                    ))
                ) : (
                    <span>No roles</span>
                )}
            </TagsWrapper>
            <Section justifyCenter>
                <Button onClick={moreInfoHandleClick}>More Info</Button>
            </Section>
        </CardWrapper>
    );
};
