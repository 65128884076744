import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { ReactComponent as WomIcon } from 'assets/wom_icon.svg';
import { CopyButton } from 'components/common/buttons/CopyButton';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { InfoWrapper, PotBalance, PotData, PotGrid, PotItem, PotTitle, PotValue } from './styles';

interface Props {
    systemPots: WOM.WalletBalanceHistoricalItem[];
}

export const PotList: FC<Props> = ({ systemPots }) => {
    const history = useHistory();

    return (
        <PotGrid>
            {systemPots.map(({ title, walletId, balance }) => (
                <PotItem key={title} onClick={() => history.push(`/pots/${walletId}`)}>
                    <InfoWrapper>
                        <InfoIcon />
                    </InfoWrapper>
                    <PotTitle>{title}</PotTitle>
                    <PotBalance>
                        {balance?.toFixed(2)} <WomIcon />
                    </PotBalance>
                    <PotData>
                        Wallet ID: <PotValue>{walletId}</PotValue>
                        <CopyButton subject={walletId} success="You successfully copied Wallet id!" />
                    </PotData>
                    {/* TODO segmentId is not the wallet address, change this to real wallet address when backend made it */}
                    {/* <PotData>
                        Wallet Address: <PotValue>{segmentId}</PotValue>
                        <CopyButton subject={segmentId} success="You successfully copied Wallet Address!" />
                    </PotData> */}
                </PotItem>
            ))}
        </PotGrid>
    );
};
