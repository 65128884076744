import { ReactComponent as GoBackIcon } from 'assets/go_back.svg';
import { Button } from 'components/common/buttons/Button';
import { ClearInputButton } from 'components/common/buttons/ClearInputButton';
import { Loader } from 'components/common/dynamic/Loader';
import { BooleanCheckbox } from 'components/common/inputs/BooleanCheckbox';
import { SearchSelect } from 'components/common/inputs/SearchSelect';
import { Span } from 'components/common/typography/Span';
import { ClickableWrapper } from 'components/grid/wrappers/ClickableWrapper';
import { Column, Row, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { useStore } from 'effector-react';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { locationEffects, locationStores } from 'stores/location';
import { walletEffects, walletStores } from 'stores/wallet';
import { withdrawalLimitsEffects, withdrawalLimitsStores } from 'stores/withdrawal-limits';
import { totalCurrency } from 'utils/usefulFunctions';
import { CountryWrapper, EurWrapper, Input, InputWrapper, Wrapper } from './styles';

export const SingleWithdrawalLimit = () => {
    const history = useHistory();
    const { countries } = useStore(locationStores.countries);
    const countriesList = countries ? [...countries].map(item => item.countryName).sort() : [''];
    const [selectedCountry, setSelectedCountry] = useState<WOM.CountryResponse | undefined>();
    const [limit, setLimit] = useState<undefined | number>();
    const [checked, setChecked] = useState(false);
    const eurRate = useStore(walletStores.eurRate);
    const EUR = limit && totalCurrency(limit, eurRate);
    const pathArray = history.location.pathname.split('/');
    const isUpdate = pathArray[pathArray.length - 1] !== 'create';
    const id = pathArray[pathArray.length - 1];
    const { maxLimitInWom, regionId } = useStore(withdrawalLimitsStores.withdrawalLimitItem);
    const countryName = countries?.find(({ countryCode }) => countryCode === regionId)?.countryName;
    const defaultDisabled = !checked || !limit;
    const disabledButton = isUpdate ? defaultDisabled : defaultDisabled || !selectedCountry;
    const loading = useStore(withdrawalLimitsStores.loading);

    const onLimitChange: ChangeEventHandler<HTMLInputElement> = e => {
        setLimit(Number(e.target.value));
    };

    const onCountryChange = (country: string) => {
        if (countries) {
            const selectedCountry = countries.find(({ countryName }) => countryName === country);
            setSelectedCountry(selectedCountry);
        }
    };

    const onGoBack = () => history.goBack();

    const resetValues = () => {
        setChecked(false);
        setLimit(undefined);
        setSelectedCountry(undefined);
    };

    const onCreateLimit = () => {
        withdrawalLimitsEffects.createWithdrawalLimit({
            regionCode: selectedCountry?.countryCode,
            maxLimitInWom: limit
        });
        resetValues();
    };

    const onUpdateLimit = () => {
        withdrawalLimitsEffects.updateWithdrawalLimit({
            maxLimitInWom: limit,
            id
        });
        onGoBack();
    };

    const onCountryClose = () => setSelectedCountry(undefined);

    useEffect(() => {
        locationEffects.getQueryCountries();
        walletEffects.getTokenInfo();
    }, []);

    useEffect(() => {
        if (isUpdate) {
            withdrawalLimitsEffects.getWithdrawalLimitQueryByID({ id });
            setLimit(maxLimitInWom);
        }
    }, [maxLimitInWom, isUpdate, id]);

    return (
        <MainLayout>
            <Wrapper>
                <Section alignCenter marginBottom="22px">
                    <ClickableWrapper onClick={onGoBack}>
                        <GoBackIcon />
                    </ClickableWrapper>
                    <MarginWrapper marginLeft="15px">
                        <Span fontSize="17px" fontWeight="600" lineHeight="25px">
                            {isUpdate ? 'Update' : 'Create'} Withdrawal Limit
                        </Span>
                    </MarginWrapper>
                </Section>
                {loading ? (
                    <Section alignCenter>
                        <Loader />
                    </Section>
                ) : (
                    <>
                        <Column marginBottom="15px">
                            <MarginWrapper marginBottom="11px">
                                <Span color="dark-gray" fontSize="14px" lineHeight="21px">
                                    {isUpdate ? 'Update' : 'Set'} Limit
                                </Span>
                            </MarginWrapper>
                            <InputWrapper withIcon>
                                <Input type="number" value={limit || ''} onChange={onLimitChange} />
                                <EurWrapper>{EUR} Euros</EurWrapper>
                            </InputWrapper>
                        </Column>
                        <Column marginBottom="15px">
                            <MarginWrapper marginBottom="11px">
                                <Span color="dark-gray" fontSize="14px" lineHeight="21px">
                                    Target Country
                                </Span>
                            </MarginWrapper>
                            {isUpdate ? (
                                <CountryWrapper untouchable>{countryName || ''}</CountryWrapper>
                            ) : selectedCountry ? (
                                <CountryWrapper>
                                    {selectedCountry.countryName}
                                    <ClearInputButton onClick={onCountryClose} />
                                </CountryWrapper>
                            ) : (
                                <InputWrapper>
                                    <SearchSelect
                                        itemsList={countriesList as string[]}
                                        placeholder="Add countries"
                                        onItemSelect={onCountryChange}
                                    />
                                </InputWrapper>
                            )}
                        </Column>
                    </>
                )}
                <MarginWrapper marginBottom="25px">
                    <BooleanCheckbox
                        showName
                        checked={checked}
                        name={`Are you sure you want to ${isUpdate ? 'update' : 'create new'} limit?`}
                        onChange={setChecked}
                    />
                </MarginWrapper>
                <Row>
                    <Button
                        buttonType="blue"
                        disabled={disabledButton}
                        size="small"
                        onClick={isUpdate ? onUpdateLimit : onCreateLimit}
                    >
                        {isUpdate ? 'Update' : 'Create'} Limit
                    </Button>

                    <MarginWrapper marginLeft="16px">
                        <Button buttonType="red" size="small" onClick={resetValues}>
                            Cancel
                        </Button>
                    </MarginWrapper>
                </Row>
            </Wrapper>
        </MainLayout>
    );
};
