import { createEffect, createEvent, createStore } from 'effector';
import { AlertOptions } from 'react-notification-alert';
import { forward } from 'effector/effector.cjs';
import { defaultNotificationOption } from 'constants/defaults/notification';

let timeout: NodeJS.Timeout;

const setNotification = createEvent<Partial<AlertOptions>>();
const clearNotificationOptions = createEvent();

const clearNotification = createEffect({
    handler: ({ autoDismiss }: Partial<AlertOptions>) => {
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => clearNotificationOptions(), (autoDismiss || 7) * 1000);
    }
});

const notificationOptions = createStore<Partial<AlertOptions>>(defaultNotificationOption)
    .on(setNotification, (state, newState) => ({
        ...state,
        ...newState
    }))
    .on(clearNotificationOptions, _ => defaultNotificationOption);

forward({ from: setNotification, to: clearNotification });

export const notificationStores = { notificationOptions };
export const notificationEvents = { setNotification };
