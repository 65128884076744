import { CustomImg, CustomImgProps } from 'components/common/imgComponents/CustomImg';
import React from 'react';

interface AvatarProps extends Required<Pick<CustomImgProps, 'src'>> {}

export const Avatar = ({ src }: AvatarProps) => (
    <div className="avatar mb-0">
        <CustomImg alt="avatar" src={src} />
    </div>
);
