import { Column, Section } from 'components/grid/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const StyledSection = styled(Section)`
    align-items: center;
    margin-bottom: 16px;

    @media (${device.mobile}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const StyledColumn = styled(Column)`
    align-items: flex-start;
    width: 216px;

    &:not(div:last-child) {
        margin-right: 30px;
    }

    @media (${device.mobile}) {
        width: 100%;
        max-width: 310px;
        margin-bottom: 22px;
        margin-right: 0;
    }
`;
