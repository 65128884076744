import { Row } from 'components/grid/wrappers/flexWrapper';
import { Span } from 'components/common/typography/Span';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { grey2 } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { modalEvents, modalStores } from 'stores/modal';
import { Modal } from '../Modal';
import { ContentWrapper } from './styles';
import { Button } from 'components/common/buttons/Button';

const { closeInformationModal } = modalEvents;
const { informationModal } = modalStores;

export const InformationModal: FC = () => {
    const { visible, informationText, onOkHandler, withCancelButton } = useStore(informationModal);

    if (!visible) {
        return null;
    }
    const onOk = () => {
        onOkHandler();
        closeInformationModal();
    };

    const onClose = () => closeInformationModal();

    return (
        <Modal onModalClose={onClose}>
            <ContentWrapper>
                <MarginWrapper marginBottom="16px">
                    <Span color={grey2} fontSize="14px" fontWeight="500">
                        {informationText}
                    </Span>
                </MarginWrapper>
                <Row alignCenter justifyCenter>
                    <Button buttonType="blue" size="small" onClick={onOk}>
                        OK
                    </Button>

                    {withCancelButton && (
                        <MarginWrapper marginLeft="16px">
                            <Button buttonType="red" size="small" onClick={onClose}>
                                Cancel
                            </Button>
                        </MarginWrapper>
                    )}
                </Row>
            </ContentWrapper>
        </Modal>
    );
};
