import { TableHeadInterface } from 'types';

export const withdrawalCheckTableColumns: TableHeadInterface[] = [
    {
        title: 'Wom User ID',
        dataIndex: 'womUserId',
        key: 'womUserId'
    },
    {
        title: 'Remote ID',
        dataIndex: 'remoteId',
        key: 'remoteId'
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username'
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'
    },

    {
        title: 'Wallet ID',
        dataIndex: 'walletId',
        key: 'walletId'
    },
    {
        title: 'Email Address / Phone number',
        dataIndex: 'emailAddress',
        key: 'emailAddress'
    }
];
