import axios from './axios';

export const authenticateUser = (data: WOM.UserAuthChallengeEmailOrUsernameOrPhoneRequest) =>
    axios<WOM.UserJwtTokenResponse>(
        {
            url: '/validation-user/authenticate',
            data
        },
        false
    );
