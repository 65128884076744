import { accessRoles, accessValues } from 'constants/defaults';
import { administratorTypeName, boundaryNumber, numbersAfterDotWom, quantityNumbersAfterComma } from 'constants/global';
import { RoutesType, SidebarStatesType } from 'constants/routes';
import { cardPaddingMultiplier, padding, responsiveNumber } from 'constants/styles';
import _ from 'lodash';
import { matchPath } from 'react-router';

//TODO: add tests
export const findElementInChildrenList = (targetElement: Element, searchElement: EventTarget | null) => {
    let isInParentBlock = false;
    const checkChildrenRef = (el: Element) => {
        if (el.childElementCount === 0) return;
        else
            el.childNodes.forEach((el: any) => {
                if (searchElement === el) isInParentBlock = true;
                checkChildrenRef(el);
            });
    };

    checkChildrenRef(targetElement);
    return isInParentBlock;
};

// INPUT NUMBER WHICH POINTING THE QUANTITY OF CARDS IN A ROW FOR SELECTED WIDTH INTERVAL (FROM TO)
export const adaptiveCard: (n: number, from: string, to: string) => string = (n, from, to) => {
    if (Number.isInteger(n))
        if (n > 0)
            return `@media (min-width: ${from}) and (max-width: ${to}) {
        width: calc(100% / ${n} - ${cardPaddingMultiplier} * ${padding} * ${n} / ${n});
    }`;

    return '';
};

// TRIGGER COPY TO CLIPBOARD
export const triggerCopy: (text: string) => void = text => navigator.clipboard.writeText(text);

// CHECK THAT ADMINISTRATOR ROLE EXISTS TO THIS USER
export const checkOnAdmin: (user: YEAY.UserAuthorizeResponse) => boolean = user => {
    const roles = user?.user?.roles;
    if (roles && roles.length)
        for (let i = 0; i < roles.length; i++) if (roles[i] === administratorTypeName) return true;

    return false;
};

// give access a user as natural (-1 - no any access) number (the less number, the more rights user has)
export const giveAccess = (user: WOM.UserJwtTokenResponse) => {
    const roles = user?.user?.roles?.map(i => i.toLowerCase());
    let access = -1;
    if (roles && roles.length) {
        accessRoles.forEach(
            (role, i) =>
                roles.includes(role.toLowerCase()) &&
                (access > accessValues[i] || access === -1) &&
                (access = accessValues[i])
        );
    }

    return access;
};

// REPLACE EXACT NUMBER TO APPROXIMATE NUMBER TO REDUCE ITS LENGTH
export const numberConverter: (n: number) => string = n => {
    if (n <= 0 || n === Infinity) return '0';
    if (!Number.isInteger(n)) n = Math.round(n);
    let str = n.toString();
    let i = 0;
    if (n < boundaryNumber)
        while (str.length > 4) {
            i++;
            str = str.substring(0, str.length - 3);
        }
    else
        while (str.length > 3) {
            i++;
            str = str.substring(0, str.length - 3);
        }

    if (i !== 0) {
        if (i === 1) str += 'K';
        else if (i === 2) str += 'M';
        else if (i === 3) str += 'B';
        else str += 'B+';
    }

    return str;
};

export const addZero = (date: number, n = 2) => date.toString().padStart(n, '0');

// PARSE CALENDAR DATE
export const parseCalendarDate: (date: Date) => string = date =>
    date.toString() === 'Invalid Date'
        ? 'invalid date'
        : addZero(date.getDate()) +
          '.' +
          addZero(date.getMonth() + 1) +
          '.' +
          date.getFullYear() +
          ' (' +
          addZero(date.getHours()) +
          ':' +
          addZero(date.getMinutes()) +
          ':' +
          addZero(date.getSeconds()) +
          ')';

// SUM AVERAGE OF ARRAY OF NUMBERS
export const averageValue: (array: number[]) => number = array => {
    const length = array.length;
    if (length < 1) return 0;

    let sum = 0;
    for (let i = 0; i < array.length; i++) {
        if (isNaN(array[i])) return NaN;
        sum += array[i];
    }

    return Number((sum / length).toFixed(quantityNumbersAfterComma));
};

// FOR GRID ADAPTIVE CELL
export const getWidthString = (span: number) => {
    if (!span || !Number.isInteger(span) || span < 0 || span > responsiveNumber) return '';

    return `width: ${(span / responsiveNumber) * 100}%;`;
};

// IMITATING ASYNC REQUEST
export const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

// PARSE MODAL ASSIGN ROLE DESCRIPTION
export const parseAssignRoleDescription: (username: string, role: string) => string = (username, role) =>
    `Do you want to assign a user ${username ? username : 'anonymous'} a role ${role ? role : 'unknown'}?`;

// PARSE KEY FOR SIDE BAR ACTIVE BUTTONS AND SUBMENU
export const parseKeyWithoutId: (path: string) => string = path => {
    let i = 0;
    path.replace(new RegExp(`/`, 'gi'), () => {
        i++;
        return '/';
    });

    if (i < 2) return path;
    return path.slice(0, path.lastIndexOf('/') + 1);
};

export const objectIsEmpty = (obj: object) => !Object.values(obj).length;

export const insertSymbolTo = (str: string, symbol: string, place: number) =>
    str.substring(0, place) + symbol + str.substring(place, str.length);

export const insertDotTo = (str: string, place = 2) => insertSymbolTo(str, '.', place);

export const getEllipsisAddress = (str = '', quantity = 12) =>
    str.length < quantity ? '' : '...' + str.substring(str.length - quantity, str.length);

// export const spaceInserter = (str: string) => String(str.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 '));
export const spaceInserter = (str: string) => {
    const data = parseFloat(str).toLocaleString('ru-RU');
    if (data === 'не число') return '';
    else return data.toString().replace(',', '.');
};

export const removeLastNulls = (x: number) => {
    if (Number.isNaN(x)) return '0';
    let y = x.toString();
    // @ts-ignore
    while (y[y.length - 1] === 0 && !Number.isInteger(y)) y = y.slice(0, -1);

    return y;
};

export const currencyToStandardForm = (x: number) =>
    spaceInserter(removeLastNulls(Number(x.toFixed(numbersAfterDotWom))));

// export const getDateBeforeAndReturnISO = (beforeDays = 1) =>
//     new Date(Date.now() - beforeDays * 24 * 60 * 60 * 1000).toISOString();

//TODO: Adding test
export const isScrollLessThanValue = (element: HTMLDivElement, value: number): boolean => {
    if (!document.scrollingElement) return false;
    return (
        document.documentElement.scrollTop > value ||
        document.scrollingElement.scrollTop > value ||
        element.scrollTop > value
    );
};

//TODO: Adding test
export const clearScrollPosition = (element: HTMLDivElement) => {
    document.documentElement.scrollTop = 0;

    if (!document.scrollingElement) return;
    document.scrollingElement.scrollTop = 0;

    element.scrollTop = 0;
};

//TODO: Adding test
export const getActiveRouteName = (routes: RoutesType): string | undefined => {
    let activeRoute = 'WASY';
    for (let i = 0; i < routes.length; i++) {
        const { views, collapse, path, title, nest, view } = routes[i];

        const isCurrentActive = matchPath(window.location.pathname, {
            path: path,
            exact: true,
            strict: false
        })?.isExact;

        const isNestActive = view
            ? matchPath(window.location.pathname, {
                  path: view.path,
                  exact: true,
                  strict: false
              })?.isExact
            : false;

        if (collapse && views) {
            let collapseActiveRoute = getActiveRouteName(views);
            if (collapseActiveRoute !== activeRoute) {
                return collapseActiveRoute;
            }
        } else if (nest && view && isNestActive) {
            let nestActiveRoute = getActiveRouteName([view]);
            if (nestActiveRoute !== activeRoute) {
                return nestActiveRoute;
            }
        } else {
            if (path && isCurrentActive) {
                return title;
            }
        }
    }
    return activeRoute;
};

// this verifies if any of the collapses should be default opened on a rerender of this component
// for example, on the refresh of the page,
// while on the src/views/forms/RegularForms.js - route /admin/regular-forms
//TODO: Adding test
export const getCollapseInitialState = (routes: RoutesType): boolean => {
    for (let i = 0; i < routes.length; i++) {
        const { collapse, views, path } = routes[i];
        const match = matchPath(window.location.pathname, {
            path: path,
            exact: true,
            strict: false
        });
        if (collapse && views && getCollapseInitialState(views)) {
            return true;
        } else if (path && match?.isExact) {
            return true;
        }
    }
    return false;
};

// this creates the initial state of this component based on the collapse routes
// that it gets through routes from props
//TODO: Adding test
export const getCollapseStates = (routes: RoutesType): SidebarStatesType => {
    let initialState = {};
    routes.map(({ collapse, state, views }) => {
        if (collapse && views && state) {
            initialState = {
                [state]: getCollapseInitialState(views),
                ...getCollapseStates(views),
                ...initialState
            };
        }
        return null;
    });
    return initialState;
};

//TODO: Adding test
export function formatEngagementStatisticsValues(statisticsValue: number) {
    if (statisticsValue <= 0 || !Number.isFinite(statisticsValue)) return '0';
    let result;
    const arr = [
        { power: 9, letter: 'b' },
        { power: 6, letter: 'm' },
        { power: 3, letter: 'k' }
    ];

    for (let i = 0; i < arr.length; i++) {
        if (statisticsValue >= Math.pow(10, arr[i].power)) {
            result =
                statisticsValue % Math.pow(10, arr[i].power) === 0
                    ? `${Math.round(statisticsValue / Math.pow(10, arr[i].power))}${arr[i].letter}`
                    : `${(statisticsValue / Math.pow(10, arr[i].power)).toFixed(1)}${arr[i].letter}`;

            return result.toString();
        }
    }
    result = statisticsValue;
    return result.toString();
}

export const camelCaseToCapitalizedWords = (input: string) =>
    input
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .split(' ')
        .filter(value => value !== '')
        .map(value => _.capitalize(value))
        .join(' ');

export const convert = (text: string) => text.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ');

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getDateFromString = (dateISOString?: string) => {
    if (!dateISOString) return '';

    //2021-01-09T13:57:15.832Z -> 2021-01-09
    return dateISOString.split('T')[0];
};

export const totalCurrency = (walletBalance: number, rate: number) =>
    Number.isNaN(walletBalance) ? 0 : (rate * walletBalance).toFixed(0);
