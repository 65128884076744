import { Column } from 'components/grid/wrappers/flexWrapper';
import styled from 'styled-components';

export const StyledColumn = styled(Column)`
    margin-bottom: 16px;
    margin-right: 24px;
    width: 280px;

    @media (max-width: 460px) {
        width: 100%;
        margin-right: 0;
    }
`;
