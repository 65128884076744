import { grey2 } from 'constants/styles';
import styled from 'styled-components';

export const StyledCard = styled.div`
    padding: 24px 16px;
    background-color: ${grey2};
    border-radius: 8px;
    box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%);
`;

export const Grid = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-auto-columns: 100%;
`;
