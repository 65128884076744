import styled from 'styled-components';
import { disableDefaultButtonStyleMixin, textDisabled, transitionTime, white } from 'constants/styles';

interface StyledControlInputButtonProps {
    disabled?: boolean;
}

export const StyledControlInputButton = styled.button<StyledControlInputButtonProps>`
    ${disableDefaultButtonStyleMixin};

    line-height: 0;
    color: ${white};
    ${({ disabled }) => disabled && `color: ${textDisabled};`};
    transition: color ease-in ${transitionTime};

    &:hover {
        color: ${white};
    }
`;
