import { grey1 } from 'constants/styles';

export const options = {
    yAxis: {
        type: 'value',
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        minorTick: {
            show: false
        },
        splitLine: {
            show: false
        },
        axisLabel: { show: true, fontSize: 14, lineHeight: 21, color: grey1, padding: [0, 34, 0, 0] }
    }
};
