import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { baseURL, apiVersion } from 'constants/global';
import { error403Link, performancePaymentsLink, transactionsLink, usersLink } from 'constants/routes';
import { performancePaymentsEvents } from 'stores/performancePayments';
import { transactionsEvents } from 'stores/transactions';
import { userEvents, userStores } from 'stores/user';
import { usersEvents } from 'stores/users';
import { videosEvents } from 'stores/videos';
import history from '../browserHistory';

const womAxiosInstance = axios.create();

womAxiosInstance.defaults.baseURL = baseURL;
womAxiosInstance.defaults.method = 'POST';
womAxiosInstance.defaults.headers['api-version'] = apiVersion;
womAxiosInstance.interceptors.response.use(
    config => config.data,
    config => {
        const status = config.response.status;
        if (status === 403) history.push(error403Link);
        else if (status === 401) {
            switch (window.location.pathname) {
                case usersLink:
                    usersEvents.setIsFirstToTrue();
                    break;
                case transactionsLink:
                    transactionsEvents.setIsFirstToTrue();
                    break;
                case performancePaymentsLink:
                    performancePaymentsEvents.setIsFirstToTrue();
                    break;
                default:
                    videosEvents.setIsFirstToTrue();
            }
            userEvents.logout();
        }

        return Promise.reject(config.response.data);
    }
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const request: AxiosPromise<T> = womAxiosInstance({
        ...config,
        headers: withToken
            ? {
                  Authorization: `Bearer ${userStores.user.getState().token}`
              }
            : {}
    });

    return (request as any) as Promise<T>;
};
