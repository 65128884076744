import { set } from 'date-fns';
import { createEffect, createStore } from 'effector';
import { API } from 'services';
import { notificationEvents } from 'stores/notification';

const queryPotsFx = createEffect({
    handler: async (data: WOM.WalletBalanceHistoricalQueryRequest) => {
        try {
            const { dateToInclusive, dateFromInclusive } = data;
            const normalizedRequestData = {
                ...data,
                dateFromInclusive: set(new Date(dateFromInclusive || ''), {}).toLocaleDateString('sv'),
                dateToInclusive: set(new Date(dateToInclusive || ''), {}).toLocaleDateString('sv'),
                isSystem: true
            };
            return await API.walletAdmin.getBalanceHistorical(normalizedRequestData);
        } catch (error) {
            // @ts-ignore
            const message = error.message;
            message && notificationEvents.setNotification({ message });
            return {};
        }
    }
});

const getWalletTransactionsFx = createEffect({
    handler: async (data: WOM.TransactionQueryRequestValues) => {
        try {
            const { walletId, dateFromInclusive, dateToInclusive } = data;
            const response = await API.transactions.getItems({
                walletId,
                dateFromInclusive: set(new Date(dateFromInclusive || ''), {}).toLocaleDateString('sv'),
                dateToInclusive: set(new Date(dateToInclusive || ''), {}).toLocaleDateString('sv'),
                limit: 100,
                returnAll: true
            });

            return response.items || [];
        } catch (error) {}
    }
});

const pots = createStore<WOM.WalletBalanceHistoricalQueryResponse>({}).on(
    queryPotsFx.doneData,
    (_, potsData) => potsData
);

const walletTransactions = createStore<WOM.TransactionResponse[]>([]).on(
    getWalletTransactionsFx.doneData,
    (_, data) => data
);

export const potsStores = { pots, walletTransactions };
export const potsEffects = { queryPotsFx, getWalletTransactionsFx };
