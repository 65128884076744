import styled from 'styled-components';
import { disableDefaultButtonStyleMixin, flexCenter, white } from 'constants/styles';

type ModalContentProps = {
    backgroundColor?: string;
};

export const FixedModalWrapper = styled.div`
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(30, 31, 46, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
`;

export const ModalContent = styled.div<ModalContentProps>`
    position: relative;
    z-index: 10002;
    background-color: ${({ backgroundColor }) => backgroundColor || white};
    border-radius: 8px;
    max-width: 440px;
    width: 100%;
`;

export const ModalClosableArea = styled.div`
    position: absolute;
    z-index: 10001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    padding: 14px;
    position: absolute;
    right: 10px;
    top: 10px;
`;
