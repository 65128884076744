import { Button } from 'components/common/buttons/Button';
import { SearchInput } from 'components/common/inputs/SearchInput';
import { Span } from 'components/common/typography/Span';
import { SearchCell } from 'components/grid/SectionCell/styles';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Pagination } from 'components/layouts/Pagination';
import { defaultLimit, defaultPage } from 'constants/defaults';
import {
    getLabelTransactionsStatusFilter,
    getLabelTransactionsTypeFilter,
    sortTagsTransactionsDelayData,
    sortTagsTransactionsDelayValues,
    sortTagsTransactionsStatusOption,
    sortTagsTransactionsTypeOption,
    sortTagsValuesTransactionsDelayDefault
} from 'constants/filters';
import { white, xs } from 'constants/styles';
import { useStore } from 'effector-react';
import { useInterval } from 'hooks/use.interval';
import {
    addressSearchPlaceholder,
    infoTitle,
    transactionIdSearchPlaceholder,
    walletIdSearchPlaceholder,
    filterMarginBottom,
    filterMarginRight,
    MAX,
    MIN,
    primaryMarginBottom,
    rangeWrapperMobileStyles,
    rangeWrapperStyles,
    sectionMarginBottom,
    STEP,
    thumbLabelStyles,
    thumbStyles,
    thumbWrapperStyles,
    trackStyles,
    trackWrapperStyles
} from 'pages/Transactions/TransactionsFilterLayout/constant';
import { FilterSection, FilterWrapper, SelectTitle } from 'pages/Transactions/TransactionsFilterLayout/styles';
import React, { FC, useEffect, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';
import { transactionsEffects, transactionsEvents, transactionsStores } from 'stores/transactions';
import { TotalRecords } from 'types';

interface Props extends TotalRecords {}

const { getItemById } = transactionsEffects;

export const TransactionsFilterLayout: FC<Props> = ({ totalRecords, children }) => {
    const { pageIndex, limit, walletId, address, status, narrativeType } = useStore(transactionsStores.values);
    const defaultId = useStore(transactionsStores.getRequestId);
    const isFirst = useStore(transactionsStores.isFirst);
    const { updateValues, overrideValues, updateIsFirst, setId } = transactionsEvents;

    const [delay, setDelay] = useState<number | null>(sortTagsValuesTransactionsDelayDefault);
    const [value, setValue] = useState([0]);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const onWalletIdSearch = (id: string | undefined) =>
        id
            ? updateValues({
                  walletId: id,
                  pageIndex: defaultPage,
                  returnAll: false
              })
            : updateValues({
                  walletId: undefined,
                  pageIndex: defaultPage,
                  returnAll: true
              });

    const onAddressSearch = (name: string) =>
        name
            ? updateValues({
                  address: name,
                  pageIndex: defaultPage,
                  returnAll: false
              })
            : updateValues({
                  address: undefined,
                  pageIndex: defaultPage,
                  returnAll: true
              });

    const onIdSearch = (id: string) => {
        setId(id);
        getItemById(id);
    };

    const onSortStatusChange = (value: WOM.TransactionStatus | undefined) =>
        updateValues({
            status: value,
            pageIndex: defaultPage
        });

    const onSortTypeChange = (value: WOM.WOMNarrativeType | undefined) =>
        updateValues({
            narrativeType: value,
            pageIndex: defaultPage
        });

    const onRangeChange = (value: number[]) => {
        setValue(value);
        setDelay(sortTagsTransactionsDelayValues[value[0]]);
    };

    const resetFilters = () => document.location.reload();

    const onCurrentPageChange: (page: number, pageSize: number | undefined) => void = (page, pageSize) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    useInterval(() => {
        updateValues({
            pageIndex: defaultPage
        });
    }, delay);

    useEffect(() => {
        if (isFirst) {
            overrideValues({
                returnAll: true
            });
            updateIsFirst();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Section justifyEnd marginBottom={sectionMarginBottom}>
                <Button buttonType="blue" size="small" onClick={resetFilters}>
                    Reset filter
                </Button>
            </Section>
            <Section justifyBetween marginBottom={isMobile ? '8px' : sectionMarginBottom}>
                <SearchCell lg={4}>
                    <SearchInput
                        defaultValue={walletId || ''}
                        placeholder={walletIdSearchPlaceholder}
                        onSearch={onWalletIdSearch}
                    />
                </SearchCell>
                <SearchCell lg={4}>
                    <SearchInput
                        defaultValue={address || ''}
                        placeholder={addressSearchPlaceholder}
                        onSearch={onAddressSearch}
                    />
                </SearchCell>
                <SearchCell removePaddingRight lg={4}>
                    <SearchInput
                        defaultValue={defaultId}
                        placeholder={transactionIdSearchPlaceholder}
                        onSearch={onIdSearch}
                    />
                </SearchCell>
            </Section>

            <FilterSection>
                <FilterWrapper
                    marginBottom={isMobile ? filterMarginBottom : '0'}
                    marginRight={isMobile ? '0' : filterMarginRight}
                >
                    <MarginWrapper marginBottom={primaryMarginBottom}>
                        <SelectTitle>Filter by status</SelectTitle>
                    </MarginWrapper>
                    <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        options={sortTagsTransactionsStatusOption}
                        value={getLabelTransactionsStatusFilter(status)}
                        onChange={value => onSortStatusChange(value?.value)}
                    />
                </FilterWrapper>

                <FilterWrapper
                    marginBottom={isMobile ? filterMarginBottom : '0'}
                    marginRight={isMobile ? '0' : filterMarginRight}
                >
                    <MarginWrapper marginBottom={primaryMarginBottom}>
                        <SelectTitle>Filter by type</SelectTitle>
                    </MarginWrapper>
                    <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        options={sortTagsTransactionsTypeOption}
                        value={getLabelTransactionsTypeFilter(narrativeType)}
                        onChange={value => onSortTypeChange(value?.value)}
                    />
                </FilterWrapper>

                <FilterWrapper marginBottom={isMobile ? '8px' : '0'}>
                    <SelectTitle>Auto update in</SelectTitle>

                    <div style={isMobile ? rangeWrapperMobileStyles : rangeWrapperStyles}>
                        <Range
                            max={MAX}
                            min={MIN}
                            renderThumb={({ props, isDragged }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        ...thumbWrapperStyles
                                    }}
                                >
                                    <div style={thumbLabelStyles}>{sortTagsTransactionsDelayData[value[0]]}</div>
                                    <div
                                        style={{
                                            ...thumbStyles,
                                            backgroundColor: isDragged ? '#40556F' : '#FFF'
                                        }}
                                    />
                                </div>
                            )}
                            renderTrack={({ props, children }) => (
                                <div
                                    style={{
                                        ...props.style,
                                        ...trackWrapperStyles
                                    }}
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            ...trackStyles,
                                            background: getTrackBackground({
                                                values: value,
                                                colors: ['#40556F', 'rgba(227, 227, 227, 0.1)'],
                                                min: MIN,
                                                max: MAX
                                            })
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            step={STEP}
                            values={value}
                            onChange={onRangeChange}
                        />
                    </div>
                </FilterWrapper>
            </FilterSection>

            <Section>
                <Span color={white} fontSize="13px" lineHeight="19px">
                    {infoTitle} {totalRecords !== -1 ? totalRecords : 0}
                </Span>
            </Section>
            {children}
            <Section alignCenter justifyCenter>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Section>
        </>
    );
};
