import { CancelToken } from 'axios';
import axios from './axios';

export const create = (data: WOM.TransactionCreateRequest) =>
    axios<WOM.TransactionResponse>({
        url: '/wallet/transaction-create',
        data
    });

export const getItemById = (data: WOM.TransactionGetRequest) =>
    axios<WOM.TransactionResponse>({
        url: '/wallet/transaction-get',
        data
    });

export const getItems = (data: WOM.TransactionQueryRequestValues, cancelToken?: CancelToken) =>
    axios<WOM.TransactionQueryResponse>({
        url: '/wallet/transaction-query',
        cancelToken,
        data
    });

export const getItemsTransferHistory = (data: WOM.TransactionQueryRequest, cancelToken?: CancelToken) =>
    axios<WOM.TransactionQueryResponse>({
        url: '/wallet/transfer-history',
        cancelToken,
        data
    });
