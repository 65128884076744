import axios, { CancelTokenSource } from 'axios';
import history from 'browserHistory';
import { defaultVideoSearchByParameterValues, defaultVideosValues } from 'constants/defaults';
import { homeLink } from 'constants/routes';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'services';
import { initializeIsFirstStore } from 'stores/initialize/initialize.isFirst.store';
import { initializeToggleStore } from 'stores/initialize/initialize.toggle.store';
import { notificationEvents } from './notification';

let cancelToken: CancelTokenSource | undefined;

const [initialLoading, updateInitialLoading] = initializeToggleStore();
const [loading, updateLoading] = initializeToggleStore();

const getItemById = createEffect({
    handler: async (id: string) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateLoading();
            const data = await API.adminVideos.getItemById({ contentId: id }, cancelToken?.token);
            updateLoading();
            const items = data ? [data] : [];

            return {
                currentPageIndex: 0,
                items,
                totalPages: items.length,
                totalRecords: items.length
            };
        } catch {
            updateLoading();
            return {
                currentPageIndex: 0,
                totalPages: 0,
                totalRecords: 0
            };
        }
    }
});

const getIdAndRedirectToSinglePage = createEffect({
    handler: async (id: string) => {
        try {
            updateLoading();
            const data = await API.adminVideos.getItemById({ contentId: id });
            updateLoading();

            data?.womContentId
                ? history.push(homeLink + '/' + data.womContentId)
                : notificationEvents.setNotification({
                      place: 'tc',
                      message: "Such video doesn't exist",
                      type: 'danger',
                      icon: 'tim-icons icon-bell-55',
                      autoDismiss: 5
                  });

            return data;
        } catch {
            notificationEvents.setNotification({
                place: 'tc',
                message: "Such video doesn't exist",
                type: 'danger',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });

            updateLoading();
            return {};
        }
    }
});

const getItems = createEffect({
    handler: async (values: WOM.ContentQueryRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateInitialLoading();
            const data = await API.adminVideos.getItems(values, cancelToken?.token);
            updateInitialLoading();

            return data ? data : {};
        } catch {
            updateInitialLoading();
            return {};
        }
    }
});

// const singleItem = createStore<WOM.ContentItemResponse>({}).on(getSingleItemById.doneData, (_, newState) => newState);
const item = createStore<WOM.ContentItemResponse>({}).on(getItemById.doneData, (_, { items }) =>
    items && items.length > 0 ? items[0] : {}
);
const items = createStore<WOM.ContentQueryResponse>({})
    .on(getItems.doneData, (_, newState) => newState)
    .on(getItemById.doneData, (_, newState) => newState);

const { isFirst, setIsFirstToFalse, setIsFirstToTrue } = initializeIsFirstStore();

export interface VideoQueryValues extends WOM.ContentQueryRequest {
    videoId?: string;
}

const getVideoItems = createEffect({
    handler: async ({ videoId, ...itemsQueryParameters }: VideoQueryValues) => {
        videoId ? getItemById(videoId) : getItems(itemsQueryParameters);
    }
});

const updateValues = createEvent<Partial<VideoQueryValues>>();
const setDefaultValues = createEvent();
const invokeGetItems = createEvent();

// values store keeps request values,
// after updating or removing some fields of the values,
// watcher initiate getItems request due the new values
// (old fields of values are not removed if they are not pointed as remove values in removeAndUpdateValues event)
const values = createStore<VideoQueryValues>(defaultVideosValues)
    .on(updateValues, (state, values) => {
        /* if the request was made for parameters that should be mutually exclusive, then they will be overwritten. If not, then the request will continue with the old parameters plus new ones  */
        const keysOfValues = Object.keys(values) as Array<keyof VideoQueryValues>;
        const keysOfDefaultSearchByParameterValues = Object.keys(defaultVideoSearchByParameterValues) as Array<
            keyof VideoQueryValues
        >;

        const isQueryByDefaultSearchParameter = keysOfValues.some(valuesKey =>
            keysOfDefaultSearchByParameterValues.some(defaultValuesKey => defaultValuesKey === valuesKey)
        );

        if (isQueryByDefaultSearchParameter) {
            return { ...state, ...defaultVideoSearchByParameterValues, ...values };
        } else {
            return { ...state, ...values };
        }
    })
    .on(setDefaultValues, () => defaultVideosValues)
    .on(invokeGetItems, state => state);

forward({
    from: [values],
    to: [getVideoItems]
});
values.watch(invokeGetItems, state => getVideoItems(state));

const videosEvents = { updateValues, setDefaultValues, setIsFirstToFalse, setIsFirstToTrue, invokeGetItems };
const videosEffects = { getItemById, getIdAndRedirectToSinglePage };
const videosStores = { items, item, isFirst, initialLoading, loading, values };

export { videosEffects, videosEvents, videosStores };
