import React from 'react';
import avatarYEAY from 'assets/yeay_logo.svg';
import avatarBULLZ from 'assets/bullz_logo.svg';
import avatarWOM from 'assets/wom_logo.png';
import { Span, CustomImg, InternalCopyLink, BooleanCheckbox } from 'components/common';
import { usersLink } from 'constants/routes';
import { purple } from 'constants/styles';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { TokensInput } from './ui/Input';

export const getAirdropTableContentData = (
    users: WOM.GetUserResponse[],
    checkedUsersForDeleted: WOM.GetUserResponse[],
    onCheckUser: (user: WOM.GetUserResponse, isChecked: boolean) => void,
    arrayTokensUsers: Array<{ user: WOM.GetUserResponse; tokens: string }>,
    onChangeUserTokens: (user: WOM.GetUserResponse, isChecked: string) => void
) =>
    users.map(user => {
        const { remoteIssuer, remoteUsername, username, location, userId, womUserId } = user;
        const avatarSrc =
            remoteIssuer === 'yeay-services' ? avatarYEAY : remoteIssuer === 'bullz-services' ? avatarBULLZ : avatarWOM;
        const tokens = arrayTokensUsers.find(tokensUser => tokensUser.user.userId === userId)?.tokens || '0';
        const checkedForDelete = checkedUsersForDeleted.some(userForDeleted => userForDeleted.userId === userId);

        return {
            data: {
                checkboxToDelete: (
                    <BooleanCheckbox
                        checked={checkedForDelete}
                        type="filled"
                        onChange={value => onCheckUser(user, value)}
                    />
                ),
                platforms: <CustomImg alt="avatar" src={avatarSrc} width="43px" />,
                username: <Span>{remoteUsername || username || '-'}</Span>,
                locale: <Span>{location?.countryCode || '-'}</Span>,
                userId: userId ? (
                    <Row width="230px">
                        <InternalCopyLink
                            color={purple}
                            href={usersLink + '/' + userId}
                            subject={userId}
                            success="You successfully copied user id"
                        >
                            {userId}
                        </InternalCopyLink>
                    </Row>
                ) : (
                    <Span>-</Span>
                ),
                womUserId: womUserId ? (
                    <Row width="230px">
                        <InternalCopyLink
                            color={purple}
                            href={usersLink + '/' + womUserId}
                            subject={womUserId}
                            success="You successfully copied user id"
                        >
                            {womUserId}
                        </InternalCopyLink>
                    </Row>
                ) : (
                    <Span>-</Span>
                ),
                tokens: (
                    <TokensInput disabled tokens={tokens} onChangeTokens={value => onChangeUserTokens(user, value)} />
                ) //remove disable props after refining api
            }
        };
    });

export const getAirdropTableColumns = (checked: boolean, setIsCheck: (checked: boolean) => void) => [
    {
        title: <BooleanCheckbox checked={checked} type="filled" onChange={setIsCheck} />,
        dataIndex: 'checkboxToDelete',
        key: 'checkboxToDelete'
    },
    {
        title: 'Platforms',
        dataIndex: 'platforms',
        key: 'platforms'
    },
    {
        title: 'USERNAME',
        dataIndex: 'username',
        key: 'username'
    },
    {
        title: 'LOCALE',
        dataIndex: 'locale',
        key: 'locale'
    },
    {
        title: 'user ID',
        dataIndex: 'userId',
        key: 'userId'
    },
    {
        title: 'WOM USER ID',
        dataIndex: 'womUserId',
        key: 'womUserId'
    },
    {
        title: 'tokens',
        dataIndex: 'tokens',
        key: 'tokens'
    }
];
