import { DataRangePicker } from 'components/common/inputs/DataPicker';
import { Span } from 'components/common/typography/Span';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { GraphCard } from 'components/layouts/cards/GraphCard';
import { useStore } from 'effector-react';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, useState } from 'react';
import Select from 'react-select';
import { Card, CardBody, CardHeader, CardTitle, Col, CustomInput, Row } from 'reactstrap';
import { catalogueEvents, catalogueStores } from 'stores/catalogue';
import { walletStores } from 'stores/wallet';
import {
    charts,
    externalTransactionsTypes,
    getEarningsStatisticByKey,
    graphsColor,
    graphsKeys,
    transactionTypeOptions
} from '../constants';
import { QueryById } from './QueryById';

const { updateValues } = catalogueEvents;

export const TransactionDashboard = () => {
    const [transactionType, setTransactionType] = useState(transactionTypeOptions[0]);
    const { items: internalItems } = useStore(catalogueStores.earningsStatistics);
    const { items: transactionsSummaryItems } = useStore(walletStores.transactionSummaryItems);

    const { dateTo, dateFrom, groupByWeek } = useStore(catalogueStores.values);

    const onDateRangeChange = ([dateFrom, dateTo]: [Moment, Moment]) => {
        if (dateFrom.isSameOrAfter(dateTo, 'day')) {
            updateValues({
                dateFrom: dateFrom.toISOString(),
                dateTo: dateTo.add(7, 'day').toISOString()
            });
        }
        updateValues({ dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString() });
    };

    const onGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateValues({ groupByWeek: e.target.checked });
    };

    const labels = internalItems?.map(({ date }) => moment(date).format('DD-MM-YYYY'));

    const internalGraphsData = internalItems
        ? Object.keys(graphsKeys)
              .map(key => {
                  const data = getEarningsStatisticByKey(internalItems, key as keyof WOM.EarningStatisticsValues);

                  return {
                      data,
                      key,
                      title: _.startCase(key),
                      total: data.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                  };
              })
              .filter(({ total }) => total !== 0)
        : [];

    const allExternalGraphsData = transactionsSummaryItems
        ? Object.keys(graphsKeys)
              .map(key => {
                  const data = getEarningsStatisticByKey(
                      transactionsSummaryItems,
                      key as keyof WOM.EarningStatisticsValues
                  );

                  return {
                      data,
                      key,
                      title: _.startCase(key),
                      total: data.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                  };
              })
              .filter(({ total }) => total !== 0)
        : [];

    const externalGraphsData = allExternalGraphsData.filter(item =>
        externalTransactionsTypes.some(type => type === item.key)
    );

    const graphsData =
        transactionType.value === transactionTypeOptions[0].value ? internalGraphsData : externalGraphsData;
    return (
        <>
            <MarginWrapper marginBottom="24px">
                <div style={{ width: '280px' }}>
                    <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        options={transactionTypeOptions}
                        value={transactionType}
                        onChange={value => value && setTransactionType(value)}
                    />
                </div>
            </MarginWrapper>
            <Row>
                <Col className="d-flex w-100" lg="12" xl="6">
                    <Card body>
                        <CardHeader>
                            <CardTitle tag="h4">Select Date Range</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-center">
                                <Col md="6">
                                    <DataRangePicker
                                        value={[new Date(dateFrom || ''), new Date(dateTo || '')]}
                                        onChange={onDateRangeChange}
                                    />
                                </Col>
                                <Col md="6">
                                    <Row className="w-100">
                                        <CustomInput
                                            checked={groupByWeek}
                                            className="mr-5"
                                            id="groupByWeek"
                                            label="Group By Week"
                                            type="switch"
                                            onChange={onGroupChange}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="12" xl="6">
                    <QueryById />
                </Col>
            </Row>
            <Row>
                {graphsData.length && labels ? (
                    graphsData.map(({ data, total, title, key }, index) => {
                        const colorIndex = index % graphsColor.length;
                        const color = graphsColor[colorIndex].pointColor;
                        const description = graphsKeys[key as keyof WOM.EarningStatisticsValues]?.description;

                        const { data: dataGraph, options } = charts(
                            { data, labels, label: title },
                            graphsColor[colorIndex]
                        );

                        return (
                            <Col key={key} lg="6">
                                <GraphCard
                                    color={color}
                                    dataGraph={dataGraph}
                                    description={description}
                                    options={options}
                                    title={title}
                                    total={total}
                                />
                            </Col>
                        );
                    })
                ) : (
                    <Col>
                        <Card body style={{ height: '266px' }}>
                            <Section alignCenter justifyCenter height="100%">
                                <Span color="rgba(255, 255, 255, 0.15)" fontSize="30px" lineHeight="45px">
                                    Nothing found
                                </Span>
                            </Section>
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
};
