import { Loader } from 'components/common/dynamic/Loader';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/layouts/Empty';
import { useStore } from 'effector-react';
import { videosNotFoundMessage } from 'pages/Videos/constants';
import { VideoCard } from 'pages/Videos/VideoCard';
import { VideosFilterLayout } from 'pages/Videos/VideosFilterLayout';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { videosStores } from 'stores/videos';

export const Videos = () => {
    const { items, totalRecords } = useStore(videosStores.items);
    const loading = useStore(videosStores.initialLoading);

    return (
        <MainLayout>
            <VideosFilterLayout totalRecords={totalRecords}>
                {loading ? (
                    <Section alignCenter justifyCenter height="100%">
                        <Loader />
                    </Section>
                ) : (
                    <>
                        {items?.length ? (
                            <Row>
                                {items.map(item => (
                                    <Col key={item.womContentId} md="4" sm="6" xl="3">
                                        <VideoCard {...item} />
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Row className="justify-content-center align-items-center">
                                <div className="mt-5">
                                    <Empty description={videosNotFoundMessage} />
                                </div>
                            </Row>
                        )}
                    </>
                )}
            </VideosFilterLayout>
        </MainLayout>
    );
};
