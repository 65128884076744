import React, { useRef } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useStore } from 'effector-react';
import { userReportModal } from 'stores/initialize/initialize.modal.store';
import { parseCalendarDate, triggerCopy } from 'utils/usefulFunctions';
import { notificationEvents } from 'stores/notification';
import { usersEffects, usersStores } from 'stores/users';
import { ModalLoader } from 'components/common/dynamic/Loader';
import { Text } from 'components/common/typography/Text';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { grey2 } from 'constants/styles';
import { ButtonsWrapper, RelativeWrapper, StyledText } from './styles';
import { Column, Section } from 'components/grid/wrappers/flexWrapper';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';

const { closeModal } = userReportModal;

export const UserReportModal = () => {
    const { visible } = useStore(userReportModal.modal);
    const reportRef = useRef<HTMLDivElement>(null);
    const {
        email,
        createdAt,
        privateBalance,
        rPBalance,
        internalAddress,
        totalAmountDeposit,
        totalAmountWithdrawals,
        totalCountDeposit,
        totalCountWithdrawals,
        withdrawalAddress,
        userId
    } = useStore(usersStores.userReport);
    const reportLoading = useStore(usersEffects.generateUserReport.pending);

    const toggleModalClassic = () => closeModal();

    const copyReportToClipboard = () => {
        if (!reportRef.current) return;

        triggerCopy(reportRef.current?.innerText.replaceAll('\n\n', '\n'));
        notificationEvents.setNotification({ message: 'Data copied', place: 'tc' });
    };

    return (
        <Modal isOpen={visible} toggle={toggleModalClassic}>
            <RelativeWrapper>
                <AbsoluteWrapper right="20px" top="20px">
                    <button
                        aria-hidden
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalClassic}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                </AbsoluteWrapper>

                <StyledText isBold isUppercase marginBottom="0px" marginTop="5px" size="h6">
                    User Report
                </StyledText>
            </RelativeWrapper>
            <ModalBody className="user-report">
                {reportLoading ? (
                    <Section alignCenter justifyCenter height="510px">
                        <ModalLoader />
                    </Section>
                ) : (
                    <Column ref={reportRef}>
                        <MarginWrapper marginBottom="4px">
                            <Text color={grey2} size="h4">
                                User
                            </Text>
                        </MarginWrapper>

                        <StyledText size="h5">email: {email || '...'}</StyledText>
                        <StyledText size="h5">wom userId: {userId}</StyledText>
                        <StyledText size="h5">
                            Created at: {createdAt ? parseCalendarDate(new Date(createdAt)) : '...'}
                        </StyledText>

                        <MarginWrapper marginBottom="4px" marginTop="20px">
                            <Text color={grey2} size="h4">
                                Wallet
                            </Text>
                        </MarginWrapper>

                        <StyledText size="h5">WOM Private Balance: {privateBalance || 0} WOM</StyledText>
                        <StyledText size="h5">RP Balance: {rPBalance || 0} WOM</StyledText>

                        <MarginWrapper marginBottom="4px" marginTop="20px">
                            <Text color={grey2} size="h4">
                                Transactions history
                            </Text>
                        </MarginWrapper>

                        <StyledText size="h5">Deposits total count: {totalCountDeposit || 0}</StyledText>
                        <StyledText marginBottom="20px" size="h5">
                            Deposit total amount: {totalAmountDeposit || 0}
                        </StyledText>
                        <StyledText size="h5">Withdrawals total count: {totalCountWithdrawals || 0}</StyledText>
                        <StyledText size="h5">Withdrawals total amount: {totalAmountWithdrawals || 0}</StyledText>

                        <MarginWrapper marginBottom="4px" marginTop="20px">
                            <Text color={grey2} size="h4">
                                Addresses
                            </Text>
                        </MarginWrapper>
                        {internalAddress && internalAddress?.length > 0 ? (
                            <>
                                <MarginWrapper marginBottom="4px">
                                    <Text color={grey2} size="h4">
                                        Internal address:
                                    </Text>
                                </MarginWrapper>

                                <div className="ml-3">
                                    {internalAddress.map(addressTo => (
                                        <Text key={addressTo} color={grey2} size="h5">
                                            {addressTo}
                                        </Text>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <StyledText size="h5">Internal address: no deposit transactions</StyledText>
                        )}
                        {withdrawalAddress && withdrawalAddress?.length > 0 ? (
                            <>
                                <MarginWrapper marginBottom="4px">
                                    <Text color={grey2} size="h4">
                                        Withdrawal destination address:
                                    </Text>
                                </MarginWrapper>

                                <div className="ml-3">
                                    {withdrawalAddress.map(addressTo => (
                                        <Text key={addressTo} color={grey2} size="h5">
                                            {addressTo}
                                        </Text>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <StyledText size="h5">
                                Withdrawal destination address: no withdrawal transactions
                            </StyledText>
                        )}
                        <br />
                        {withdrawalAddress &&
                            withdrawalAddress?.length > 0 &&
                            withdrawalAddress.map(addressTo => (
                                <StyledText key={addressTo} size="h5">
                                    <a href={`https://etherscan.io/tokentxns?a=${addressTo}`}>
                                        https://etherscan.io/tokentxns?a={addressTo}
                                    </a>
                                </StyledText>
                            ))}
                    </Column>
                )}
            </ModalBody>
            <ButtonsWrapper>
                <Button color="default" type="button" onClick={copyReportToClipboard}>
                    Copy
                </Button>
                <Button color="danger" data-dismiss="modal" type="button" onClick={toggleModalClassic}>
                    Close
                </Button>
            </ButtonsWrapper>
        </Modal>
    );
};
