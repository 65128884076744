import { Tooltip } from 'components/modals/Tooltip';
import React from 'react';
import classNames from 'classnames';

interface Props {
    state: boolean;
    id: string;
}

export const BooleanTableAnswer = ({ state, id }: Props) => (
    <Tooltip id={id} title={String(state)}>
        <i className={classNames('tim-icons', state ? 'icon-check-2' : 'icon-simple-remove')} />
    </Tooltip>
);
