import history from 'browserHistory';
import { asyncError } from 'constants/notifications';
import { walletLink } from 'constants/routes';
import { combine, createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { loadingEffects } from 'stores/loading';
import { notificationEvents } from './notification';

const getItem = createEffect({
    handler: async (values?: WOM.WalletGetRequest) => {
        try {
            loadingEffects.updateLoading();
            const data = await API.wallet.getItem(values ? values : {});
            loadingEffects.updateLoading();

            return data;
        } catch {
            loadingEffects.updateLoading();
            return {};
        }
    }
});

const getIdAndRedirectToSinglePage = createEffect({
    handler: async (values: WOM.WalletGetRequest) => {
        try {
            loadingEffects.updateInitialLoading();
            const data = await API.wallet.getItem(values);
            loadingEffects.updateInitialLoading();

            data?.walletId
                ? history.push(walletLink + '/' + data.walletId)
                : notificationEvents.setNotification({
                      place: 'tc',
                      message: 'Wallet not found',
                      type: 'danger',
                      icon: 'tim-icons icon-bell-55',
                      autoDismiss: 5
                  });

            return data;
        } catch {
            notificationEvents.setNotification({
                place: 'tc',
                message: 'Wallet not found',
                type: 'danger',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });

            loadingEffects.updateInitialLoading();
            return {};
        }
    }
});

const setTransferError = createEvent<string>();
const transferError = createStore<string>('').on(setTransferError, (_, newState) => newState);
transferError.watch(
    setTransferError,
    state =>
        state &&
        notificationEvents.setNotification({
            place: 'tc',
            message: state,
            type: 'danger',
            icon: 'tim-icons icon-bell-55',
            autoDismiss: 5
        })
);

const transferMoney = createEffect({
    handler: async (values: WOM.TransactionCreateRequest) => {
        try {
            loadingEffects.updateLoading();
            await API.wallet.transferMoney(values);
            loadingEffects.updateLoading();

            notificationEvents.setNotification({
                place: 'tc',
                message: 'Transaction was successfully created',
                type: 'primary',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });
        } catch {
            loadingEffects.updateLoading();

            notificationEvents.setNotification({
                place: 'tc',
                message: asyncError,
                type: 'danger',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });
            setTransferError(asyncError);
        }
    }
});

const item = createStore<WOM.WalletResponse>({}).on(getItem.doneData, (_, newState) => newState);

const getTransactionSummary = createEffect({
    handler: async (values: WOM.TxSummaryQueryRequest) => {
        try {
            loadingEffects.updateInitialLoading();
            const data = await API.wallet.getTransactionSummary(values);
            loadingEffects.updateInitialLoading();
            return data;
        } catch {
            return {};
        }
    }
});

const getTokenInfo = createEffect({
    handler: async () => {
        try {
            return await API.wallet.getTokenInfo({});
        } catch {
            return {};
        }
    }
});

const usdRate = createStore(0)
    // @ts-ignore
    .on(getTokenInfo.doneData, (_, { womExchangeRates }) => womExchangeRates[0].price.toFixed(4));

const eurRate = createStore(0)
    // @ts-ignore
    .on(getTokenInfo.doneData, (_, { womExchangeRates }) => womExchangeRates[1].price.toFixed(4));

const rates = combine(usdRate, eurRate);

const transactionSummaryItems = createStore<WOM.TxSummaryQueryResponse>({}).on(
    getTransactionSummary.doneData,
    (_, newState) => newState
);

const walletEvents = {};
const walletEffects = { getItem, getIdAndRedirectToSinglePage, transferMoney, getTransactionSummary, getTokenInfo };
const walletStores = { item, transferError, transactionSummaryItems, eurRate, rates };

export { walletEvents, walletEffects, walletStores };
