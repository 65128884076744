import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { ReactComponent as WomIcon } from 'assets/wom_icon.svg';
import { Button } from 'components/common/buttons/Button';
import { Loader } from 'components/common/dynamic/Loader';
import { SearchInput } from 'components/common/inputs/SearchInput';
import { SimpleTable } from 'components/common/tables/SimpleTable';
import { Span } from 'components/common/typography/Span';
import { ClickableWrapper } from 'components/grid/wrappers/ClickableWrapper';
import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { Row, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Empty } from 'components/layouts/Empty';
import { MainLayout } from 'components/layouts/MainLayout';
import { Pagination } from 'components/layouts/Pagination';
import { defaultWithdrawalLimits } from 'constants/defaults/withdrawalLimits';
import { createWithdrawalLimitLink, withdrawalLimitLink } from 'constants/routes';
import { grey2, grey3, md } from 'constants/styles';
import { useStore } from 'effector-react';
import { withdrawalLimitTableHead } from 'pages/WithdrawalLimit/constants';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { Col, Row as ReactstrapRow } from 'reactstrap';
import { locationEffects, locationStores } from 'stores/location';
import { modalEvents } from 'stores/modal';
import { walletEffects, walletStores } from 'stores/wallet';
import { withdrawalLimitsEffects, withdrawalLimitsEvents, withdrawalLimitsStores } from 'stores/withdrawal-limits';
import { TableBodyInterface } from 'types/interfaces/global';
import { totalCurrency } from 'utils/usefulFunctions';
import { DefaultLimit, QueryByLimitId, TableCell, TableWrapper, Wrapper, WrapperLimits } from './styles';

export const WithdrawalLimit = () => {
    const history = useHistory();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const { limit, pageIndex } = useStore(withdrawalLimitsStores.values);
    const { items, totalRecords } = useStore(withdrawalLimitsStores.withdrawalLimitsQuery);
    const eurRate = useStore(walletStores.eurRate);
    const { countries } = useStore(locationStores.countries);
    const loading = useStore(withdrawalLimitsStores.loading);
    const defaultLimit = items
        ? items.reduce((total, currentItem) => total + Number(currentItem.maxLimitInWom || 0), 0)
        : 0;

    const defaultLimitEUR = totalCurrency(defaultLimit, eurRate);

    const onCreateLimit = () => history.push(createWithdrawalLimitLink);
    const onSearch = (id: string) => {
        if (id) {
            withdrawalLimitsEffects.getWithdrawalLimitQueryByID({ id });
        } else {
            withdrawalLimitsEffects.getWithdrawalLimitQuery({
                ...defaultWithdrawalLimits,
                pageIndex: pageIndex || defaultWithdrawalLimits.pageIndex,
                limit: limit || defaultWithdrawalLimits.limit
            });
        }
    };
    const onCurrentPageChange: (page: number, pageSize: number | undefined) => void = (page, pageSize) => {
        withdrawalLimitsEvents.updateValues({
            limit: pageSize || defaultWithdrawalLimits.limit,
            pageIndex: page
        });
    };

    const isTablet = useMediaQuery({ query: `(max-width: ${md})` });

    const tableData: TableBodyInterface[] | undefined = items?.map(({ maxLimitInWom, regionId, id }) => {
        const EUR = maxLimitInWom && totalCurrency(maxLimitInWom, eurRate);
        const location = countries ? countries.find(({ countryCode }) => countryCode === regionId)?.countryName : '';

        const onConfirm = async () => {
            await withdrawalLimitsEffects.deleteWithdrawalLimit({ id });
            withdrawalLimitsEffects.getWithdrawalLimitQuery(defaultWithdrawalLimits);
        };

        const onDeleteClick = () => {
            modalEvents.openInformationModal({
                informationText: 'Are you sure you want to delete limit?',
                onOkHandler: onConfirm,
                withCancelButton: true
            });
        };

        const onCell = () => id && history.push(`${withdrawalLimitLink}/${id}`);

        return {
            data: {
                id: <TableCell onClick={onCell}>{id}</TableCell>,
                withdrawalLimit: (
                    <TableCell onClick={onCell}>
                        {maxLimitInWom} <WomIcon />
                    </TableCell>
                ),
                euros: <TableCell onClick={onCell}>{EUR}</TableCell>,
                locations: <TableCell onClick={onCell}>{location}</TableCell>,
                deleteLimit: (
                    <ClickableWrapper onClick={onDeleteClick}>
                        <TrashIcon />
                    </ClickableWrapper>
                )
            }
        };
    });

    useEffect(() => {
        if (isFirstLoad) {
            withdrawalLimitsEffects.getWithdrawalLimitQuery({
                ...defaultWithdrawalLimits,
                pageIndex: pageIndex || defaultWithdrawalLimits.pageIndex,
                limit: limit || defaultWithdrawalLimits.limit
            });
            walletEffects.getTokenInfo();
            locationEffects.getQueryCountries();

            setIsFirstLoad(false);
        }
    }, [limit, pageIndex, isFirstLoad]);

    return (
        <MainLayout>
            <Wrapper>
                <Section alignCenter justifyBetween marginBottom="15px">
                    <Span fontSize="17px" lineHeight="25px">
                        Withdrawal Limit
                    </Span>

                    <Button buttonType="blue" size="small" onClick={onCreateLimit}>
                        Create Limit
                    </Button>
                </Section>
                <Section>
                    {!isTablet ? (
                        <ReactstrapRow md="2" sm="1" style={{ width: 'calc(100% + 30px)' }} xs="1">
                            <Col>
                                <ContentWrapper
                                    backgroundColor={grey2}
                                    height="98px"
                                    marginBottom="15px"
                                    padding="15px"
                                    width="100%"
                                >
                                    <Row marginBottom="8px">
                                        <Span color={grey3} fontSize="12px">
                                            Default Limit
                                        </Span>
                                    </Row>
                                    <Row alignCenter marginRight="10px">
                                        <MarginWrapper marginRight="4px">
                                            <Span fontSize="14px" lineHeight="21px">
                                                {defaultLimit}
                                            </Span>
                                        </MarginWrapper>{' '}
                                        <WomIcon />
                                        <MarginWrapper marginLeft="10px">
                                            <Span color={grey3} fontSize="12px" lineHeight="18px">
                                                {defaultLimitEUR} Euros
                                            </Span>
                                        </MarginWrapper>
                                    </Row>
                                </ContentWrapper>
                            </Col>
                            <Col>
                                <ContentWrapper
                                    backgroundColor={grey2}
                                    height="98px"
                                    marginBottom="15px"
                                    padding="15px 30px"
                                    width="100%"
                                >
                                    <Row marginBottom="8px">
                                        <Span color={grey3} fontSize="12px">
                                            Query by limit ID
                                        </Span>
                                    </Row>
                                    <Section alignCenter>
                                        <SearchInput placeholder="Search by limit ID" onSearch={onSearch} />
                                    </Section>
                                </ContentWrapper>
                            </Col>
                        </ReactstrapRow>
                    ) : (
                        <WrapperLimits>
                            <DefaultLimit>
                                <ContentWrapper backgroundColor={grey2} marginBottom="15px" padding="15px" width="100%">
                                    <Row marginBottom="8px">
                                        <Span color={grey3} fontSize="12px">
                                            Default Limit
                                        </Span>
                                    </Row>
                                    <Row alignCenter marginRight="10px">
                                        <MarginWrapper marginRight="4px">
                                            <Span fontSize="14px" lineHeight="21px">
                                                {defaultLimit}
                                            </Span>
                                        </MarginWrapper>{' '}
                                        <WomIcon />
                                        <MarginWrapper marginLeft="10px">
                                            <Span color={grey3} fontSize="12px" lineHeight="18px">
                                                {defaultLimitEUR} Euros
                                            </Span>
                                        </MarginWrapper>
                                    </Row>
                                </ContentWrapper>
                            </DefaultLimit>
                            <QueryByLimitId>
                                <ContentWrapper
                                    backgroundColor={grey2}
                                    height="98px"
                                    marginBottom="15px"
                                    padding="15px"
                                    width="100%"
                                >
                                    <Row marginBottom="8px">
                                        <Span color={grey3} fontSize="12px">
                                            Query by limit ID
                                        </Span>
                                    </Row>
                                    <Section alignCenter>
                                        <SearchInput placeholder="Search by limit ID" onSearch={onSearch} />
                                    </Section>
                                </ContentWrapper>
                            </QueryByLimitId>
                        </WrapperLimits>
                    )}
                </Section>
                <TableWrapper paddingTop={!tableData ? '50px' : undefined}>
                    {loading ? (
                        <Section justifyCenter marginTop="30px">
                            <Loader />
                        </Section>
                    ) : tableData ? (
                        <SimpleTable tbody={tableData} thead={withdrawalLimitTableHead} />
                    ) : (
                        <Empty description="Nothing found" />
                    )}
                </TableWrapper>
                <Section justifyCenter noWrap>
                    <Pagination
                        currentIndex={pageIndex + 1}
                        defaultSize={limit}
                        totalItems={totalRecords}
                        onSizeChange={onCurrentPageChange}
                    />
                </Section>
            </Wrapper>
        </MainLayout>
    );
};
