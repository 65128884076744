import { createEffect, createStore } from 'effector';
import { API } from 'services';

const getQueryCountries = createEffect({
    handler: async () => {
        try {
            return await API.locaiton.getQueryCountries({});
        } catch {
            return {};
        }
    }
});

const countries = createStore<WOM.AdminAllCountriesResponse>({}).on(
    getQueryCountries.doneData,
    (_, newState) => newState
);

export const locationEffects = { getQueryCountries };
export const locationStores = { countries };
