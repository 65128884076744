import { Tag } from 'components/common/tags/Tag';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { Section, Column } from 'components/grid/wrappers/flexWrapper';
import { Placeholder } from 'types/interface';
import { Checkbox } from 'components/common/inputs/Checkbox';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Span } from 'components/common/typography/Span';
import { grey5 } from 'constants/styles';

interface TagsFilterProps extends Placeholder {
    tags?: string[];
    onChange?: (tags: string[]) => void;
    onCheckboxChange: React.ChangeEventHandler<HTMLInputElement>;
    defaultChecked?: boolean;
}

export const TagsFilter = ({
    placeholder,
    tags = [],
    defaultChecked = false,
    onChange,
    onCheckboxChange
}: TagsFilterProps) => {
    const [hashtags, setHashtags] = useState<string[]>(tags);

    const onTagsChange = (tags: string[]) => onChange && onChange(tags);

    const addNewHashtag = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!hashtags.some(tag => e.currentTarget.value === tag)) {
                const newHashtagsValue = [...hashtags, e.currentTarget.value];
                setHashtags(newHashtagsValue);
                onTagsChange(newHashtagsValue);
            }
            e.currentTarget.value = '';
        }
    };

    const removeHashtag = (name: string) => {
        setHashtags(state => state.filter(i => i !== name.replace('#', '')));
        onTagsChange(hashtags.filter(i => i !== name.replace('#', '')));
    };

    useEffect(() => setHashtags(tags), [tags]);

    return (
        <Column justifyCenter width="390px">
            <Span noWrap color={grey5} fontSize="13px">
                Filter by tags
            </Span>
            <Section alignCenter noWrap marginTop="16px">
                <Input height="36px" placeholder={placeholder} type="text" width="204px" onKeyDown={addNewHashtag} />

                <MarginWrapper marginLeft="16px">
                    <Checkbox checked={defaultChecked} title="Filter tag by context" onChange={onCheckboxChange} />
                </MarginWrapper>
            </Section>
            <Section alignCenter>
                {hashtags.map(tag => (
                    <Tag key={tag} color="danger" onRemove={removeHashtag}>
                        {`#${tag}`}
                    </Tag>
                ))}
            </Section>
        </Column>
    );
};
