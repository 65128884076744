import { Column } from 'components/grid/wrappers/flexWrapper';
import { ScrollableWrapper } from 'components/grid/wrappers/ScrollableWrapper/styles';
import { flexCenter, white } from 'constants/styles';
import styled from 'styled-components';

export const Title = styled.span`
    font-size: 17px;
    line-height: 26px;
    color: ${white};
`;

export const Subtitle = styled.span`
    font-size: 13px;
    line-height: 20px;
    color: ${white};
`;

export const TagsWrapper = styled(ScrollableWrapper)`
    width: 100%;
    height: 35px;
    margin-top: 10px;
    flex-wrap: noWrap;
    overflow-y: hidden;
`;

export const LoaderBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(75, 77, 98, 0.4);
    padding: 15px;
    z-index: 1;
    ${flexCenter};
`;

export const InputWrapper = styled(Column)`
    width: 242px;
`;
