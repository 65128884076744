export const otherPayoutNameValue = 'Other';
export const sortedPayoutsNameValues = ['Creator', 'Facilitator', otherPayoutNameValue, 'Authenticator'];
export const otherValueIndex = sortedPayoutsNameValues.indexOf(otherPayoutNameValue);

export const sortingByPayoutNameValue: (
    a: WOM.PerformancePaymentsContentPaymentsItem,
    b: WOM.PerformancePaymentsContentPaymentsItem
) => number = ({ name: aValueName }, { name: bValueName }) => {
    const aIndex =
        sortedPayoutsNameValues.indexOf(aValueName || '') === -1
            ? otherValueIndex
            : sortedPayoutsNameValues.indexOf(aValueName || '');
    const bIndex =
        sortedPayoutsNameValues.indexOf(bValueName || '') === -1
            ? otherValueIndex
            : sortedPayoutsNameValues.indexOf(bValueName || '');

    return aIndex - bIndex;
};

export const getUnionPayoutStatistics = (sortedContentItems: WOM.PerformancePaymentsContentPaymentsItem[]) => {
    const uniqContentItemNames = Array.from(new Set(sortedContentItems?.map(({ name }) => name)));

    const tempTableData = uniqContentItemNames.map(nameParam => {
        const sum =
            sortedContentItems
                ?.filter(({ name }) => name === nameParam)
                .reduce((currentValue, { value }) => currentValue + (value || 0), 0) || 0;

        return {
            nameParam,
            sum,
            count: sortedContentItems?.filter(({ name }) => name === nameParam).length,
            percent:
                (sum * 100) /
                (sortedContentItems?.reduce((currentValue, { value }) => currentValue + (value || 0), 0) || 0)
        };
    });

    return [
        ...tempTableData,
        {
            nameParam: 'Total',
            sum: sortedContentItems?.reduce((currentValue, { value }) => currentValue + (value || 0), 0) || 0,
            count: sortedContentItems?.length,
            percent: 100
        }
    ];
};
