import { backgroundColor, blue, grey2, noContentColor, white } from 'constants/styles/colors';
import { borderWidth } from 'constants/styles/sizes';
import styled, { css } from 'styled-components';
import { checkboxBorderRadius, checkboxDiameter } from './constants';
import { CheckboxProps } from './types';

export const Label = styled.label`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${white};
    margin: 0;

    &:hover {
        cursor: pointer;
    }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const VisibleCheckbox = styled.div<CheckboxProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${checkboxDiameter};
    height: ${checkboxDiameter};
    background: transparent;
    color: ${white};
    border-radius: ${checkboxBorderRadius};
    border: ${borderWidth} solid ${white};
    ${({ type }) => type === 'filled' && `border: 2px solid ${noContentColor};`};
    transition: all 0.3s;
    cursor: pointer;

    ${({ type, checked }) =>
        checked &&
        type === 'filled' &&
        css`
            background-color: ${blue};
            border-color: ${blue};
        `};

    ${({ disabled }) =>
        disabled ? `background: '${backgroundColor}'; border-color: ${grey2}; cursor: not-allowed` : ``};

    & svg {
        width: 8px;
        height: 8px;
        display: ${({ checked }) => (checked ? 'block' : 'none')};
    }
`;
