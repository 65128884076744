import { white } from 'constants/styles';
import styled from 'styled-components';
import { Disabled } from 'types/interfaces/form';

export const FilterWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`;

export const ToggleButton = styled.div<Disabled>`
    white-space: noWrap;
    margin-left: 16px;
    margin-top: 10px;
    opacity: 1;
    color: ${white};

    ${({ disabled }) => disabled && `opacity: 0.4;`}

    @media (max-width: 480px) {
        margin-left: 0;
        margin-top: 0;
        white-space: noWrap;
    }
`;
