import { Flex } from 'components/grid/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const SearchWrapper = styled(Flex)`
    width: 100%;
    max-width: 730px;

    @media ${device.tablet} {
        max-width: 640px;
    }
`;
