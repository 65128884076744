import classNames from 'classnames';
import { AdminNavbar } from 'components/grid/Navbar/AdminNavbar';
import { Sidebar } from 'components/grid/Sidebar/Sidebar';
import { logoApplication, sidebarNotificationOptions } from 'components/layouts/MainLayout/constants';
import { Notification } from 'components/modals/Notification';
import { newRoutesArray } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { notificationEvents } from 'stores/notification';
import { settingsEvents, settingsStores } from 'stores/settings';
import { destroyPerfectScrollbar, initializePerfectScrollbar } from 'utils/prefectScrollbar';
import { clearScrollPosition, getActiveRouteName, isScrollLessThanValue } from 'utils/usefulFunctions';

interface Props {
    hiddenSearch?: boolean;
}

export const MainLayout: FC<Props> = ({ children, hiddenSearch }) => {
    const { isDarkMode, isSidebarMini, activeColor } = useStore(settingsStores.sidebarSettings);
    const [showNavButton, setShowNavButton] = useState(false);
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const mainPanelRef = useRef<null | HTMLDivElement>(null);
    const location = useLocation();

    const showNavbarButton = () => {
        if (!mainPanelRef.current) return;
        setShowNavButton(isScrollLessThanValue(mainPanelRef.current, 50));
    };

    useEffect(() => {
        if (!mainPanelRef.current) return;
        clearScrollPosition(mainPanelRef.current);
    }, [location]);

    useEffect(() => {
        if (!mainPanelRef.current) return;
        let innerMainPanelRef = mainPanelRef;

        initializePerfectScrollbar(mainPanelRef.current, showNavbarButton);
        window.addEventListener('scroll', showNavbarButton);
        return function cleanup() {
            innerMainPanelRef.current && destroyPerfectScrollbar(innerMainPanelRef.current, showNavbarButton);
            window.removeEventListener('scroll', showNavbarButton);
        };
    }, []);

    const handleMiniClick = () => {
        notificationEvents.setNotification(sidebarNotificationOptions());
        settingsEvents.setSidebarSettings({ isSidebarMini: !isSidebarMini });
        document.body.classList.toggle('sidebar-mini');
    };
    const toggleSidebar = () => {
        setSidebarOpened(!sidebarOpened);
        document.documentElement.classList.toggle('nav-open');
    };
    const closeSidebar = () => {
        setSidebarOpened(false);
        document.documentElement.classList.remove('nav-open');
    };

    useEffect(() => {
        isDarkMode ? document.body.classList.add('white-content') : document.body.classList.remove('white-content');
        isSidebarMini ? document.body.classList.add('sidebar-mini') : document.body.classList.remove('sidebar-mini');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="wrapper" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
            <Notification />
            <div className={classNames('navbar-minimize-fixed', { 'opacity-10': showNavButton })}>
                <button className="minimize-sidebar btn btn-link btn-just-icon" onClick={handleMiniClick}>
                    <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
                    <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
                </button>
            </div>
            <Sidebar
                activeColor={activeColor}
                closeSidebar={closeSidebar}
                logo={logoApplication}
                routes={newRoutesArray}
            />
            <div ref={mainPanelRef} className="main-panel">
                <AdminNavbar
                    brandText={getActiveRouteName(newRoutesArray)}
                    handleMiniToggle={handleMiniClick}
                    hiddenSearch={hiddenSearch}
                    isSidebarOpened={sidebarOpened}
                    toggleSidebar={toggleSidebar}
                />

                <div className="content">{children}</div>
            </div>
        </div>
    );
};
