type GraphKeysInterface = {
    [key in keyof WOM.PerformancePaymentsItem]: number;
};

export const graphKeys: GraphKeysInterface = {
    contentId: 0,
    authenticationPoints: 0,
    womPayout: 0,
    contentPoints: 0,
    totalPoints: 0
};

type ConfigYAxisKeysInterface = {
    [key in keyof WOM.PerformancePaymentsItem]: any;
};

const chartsColors = {
    gray: '#404040',
    blue: 'rgba(29, 140, 248, 1)',
    purple: 'rgba(225, 78, 202, 1)',
    linearBlue: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 1,
        y2: 1,
        colorStops: [
            {
                offset: 0,
                color: 'rgba(29, 140, 248, 1)' // color at 0% position
            },
            {
                offset: 1,
                color: 'rgba(51, 88, 244, 1)' // color at 100% position
            }
        ],
        global: false // false by default
    },
    linearPurple: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 1,
        y2: 1,
        colorStops: [
            {
                offset: 0,
                color: 'rgba(186, 84, 245, 1)' // color at 0% position
            },
            {
                offset: 1,
                color: 'rgba(225, 78, 202, 1)' // color at 100% position
            }
        ],
        global: false // false by default
    }
};

const configYAxis: ConfigYAxisKeysInterface = {
    contentPoints: {
        yAxis: {
            type: 'value',
            position: 'right',
            axisLine: {
                show: true,
                lineStyle: {
                    color: chartsColors.purple
                }
            },
            splitLine: {
                lineStyle: {
                    color: chartsColors.gray
                }
            },
            axisLabel: {
                formatter: '{value} POINTS',
                fontSize: 14,
                lineHeight: 13
            },
            name: 'Content Points',
            nameTextStyle: {
                fontSize: 14,
                lineHeight: 13
            }
        },
        color: chartsColors.linearPurple,
        unit: 'POINTS'
    },
    womPayout: {
        yAxis: {
            type: 'value',
            position: 'left',
            axisLine: {
                show: true,
                lineStyle: {
                    color: chartsColors.blue
                }
            },
            splitLine: {
                lineStyle: {
                    color: chartsColors.gray
                }
            },
            axisLabel: {
                formatter: '{value} WOM',
                fontSize: 14,
                lineHeight: 13
            },
            name: 'Payout WOM',
            nameTextStyle: {
                fontSize: 14,
                lineHeight: 13
            }
        },
        color: chartsColors.linearBlue,
        unit: 'WOM'
    }
};

export interface PerformancePaymentsChartData {
    xAxisData: string[];
    series: Array<{ name: string; data: number[] }>;
}

export const getChartOption = ({ xAxisData, series }: PerformancePaymentsChartData) => ({
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: (params: any) =>
            params && params.length > 0
                ? [
                      params[0].axisValue,
                      '<br/>',
                      '<br/>',
                      ...params.map(
                          ({ marker, value, seriesName }: any) =>
                              `${marker} ${seriesName}: ${value.toFixed(2)} ${
                                  configYAxis[seriesName as keyof WOM.PerformancePaymentsItem].unit
                              }<br/>`
                      )
                  ].join('')
                : ''
    },
    legend: {
        data: Object.keys(graphKeys).map(key => key),
        // right: 300,
        itemHeight: 30,
        itemWidth: 70,
        itemGap: 24,
        textStyle: {
            color: 'white',
            fontSize: 14,
            lineHeight: 13
        }
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    yAxis: series.map(({ name }) => configYAxis[name as keyof WOM.PerformancePaymentsItem].yAxis),
    xAxis: {
        type: 'category',
        data: xAxisData,
        axisTick: { show: false },
        axisLabel: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            formatter: (value: string, i: number) => i,
            color: 'white',
            fontSize: 12,
            lineHeight: 13
        }
    },
    series: series.map(({ data, name }, i) => ({
        name,
        type: 'bar',
        // stack: 'total',
        label: {
            show: true,
            formatter: ({ value }: any) => (value === 0 ? '' : value.toFixed(2)),
            position: 'insideBottom',
            distance: 15,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            fontSize: 12,
            lineHeight: 13
        },
        emphasis: {
            focus: 'series'
        },
        yAxisIndex: i,
        itemStyle: {
            color: configYAxis[name as keyof WOM.PerformancePaymentsItem].color,
            barBorderRadius: [8, 8, 0, 0]
        },
        data
    }))
});

export const payoutRanges = [
    { low: 0, high: 1, name: '0 - 1' },
    { low: 2, high: 25, name: '2 - 25' },
    { low: 26, high: 50, name: '26 - 50' },
    { low: 51, high: 100, name: '51 - 100' },
    { low: 101, high: 200, name: '101 - 200' },
    { low: 201, name: '201 - ...' }
];

export interface PerformancePaymentsTreeMapChartData {
    series: Array<{ data: Array<{ name: string; value: number }>; name: string; value: number }>;
}

export const getTreeMapChartOption = ({ series }: PerformancePaymentsTreeMapChartData) => ({
    title: {
        top: 5,
        left: 'center',
        text: 'How payments WOM are distributed',
        textStyle: {
            color: 'white'
        }
    },
    color: ['#419EF9', '#00BF9A', '#FF9F89', '#EC250D', '#E66AD2', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    tooltip: {},
    series: [
        {
            type: 'treemap',
            tooltip: {
                formatter: ({ data, treePathInfo }: any) => {
                    const title = treePathInfo && treePathInfo.length > 0 ? treePathInfo[1]?.name || '' : '';
                    const returnedValue = [
                        `<span style='font-weight: 600' >${title}</span>`,
                        '<br/>',
                        '<br/>',
                        `<span style='font-weight: 500' >Payout WOM</span>`,
                        '<br/>',
                        `${data.name} : ${data.value.toFixed(2)} WOM`
                    ];
                    return returnedValue.join('');
                }
            },
            itemStyle: {
                normal: {
                    borderColor: 'black'
                }
            },
            levels: [
                {
                    itemStyle: {
                        normal: {
                            borderWidth: 3,
                            gapWidth: 3
                        }
                    }
                }
            ],
            data: series.map(({ data, name, value }) => ({
                name,
                value,
                children: data.map(({ name, value }) => ({ name: name, value: value }))
            }))
        }
    ]
});

export const chartsStyle = { height: '800px', width: '100%' };
