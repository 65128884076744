import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { useQueryParams } from 'hooks/queryParams';
import React, { ReactNode, useEffect, useState } from 'react';
import { DashboardQueryParameters } from 'types/interfaces/urlQueryParameters';
import { PotsDashboard } from './PotsDashboard';
import { StyledSpan } from './styles';
import { TransactionDashboard } from './TransactionDashboard';

export enum DashboardTab {
    Transaction = 'transaction',
    Pots = 'pots'
}

export const Dashboard = () => {
    const [activeTab, setActiveTab] = useState<DashboardTab>(DashboardTab.Transaction);

    const [queryParams, setQueryParams] = useQueryParams<DashboardQueryParameters>();

    useEffect(() => {
        if (queryParams.tab && activeTab !== queryParams.tab) {
            setActiveTab(queryParams.tab);
        }
    }, [queryParams, activeTab]);

    const tabs: Record<DashboardTab, ReactNode> = {
        transaction: <TransactionDashboard />,
        pots: <PotsDashboard />
    };

    const handleTabClick = (tab: DashboardTab) => () => {
        setActiveTab(tab);
        setQueryParams({ ...queryParams, tab });
    };

    return (
        <MainLayout>
            <MarginWrapper marginBottom="30px">
                <StyledSpan
                    active={activeTab === DashboardTab.Transaction}
                    fontSize="14px"
                    lineHeight="20px"
                    onClick={handleTabClick(DashboardTab.Transaction)}
                >
                    Transaction
                </StyledSpan>
                <StyledSpan
                    active={activeTab === DashboardTab.Pots}
                    fontSize="14px"
                    lineHeight="20px"
                    onClick={handleTabClick(DashboardTab.Pots)}
                >
                    Company Pots
                </StyledSpan>
            </MarginWrapper>
            {tabs[activeTab]}
        </MainLayout>
    );
};
