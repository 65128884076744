import { FlexGrow } from 'components/grid/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const SearchSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    @media (max-width: 460px) {
        flex-wrap: wrap;
    }
`;

export const SelectWrapper = styled(FlexGrow)`
    max-width: 249px;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};

    @media ${device.mobile} {
        margin-left: 0;
    }

    @media (max-width: 460px) {
        flex-wrap: wrap;
        max-width: none;
    }
`;
