import React, { FC } from 'react';
import { ReactClick } from 'types/interfaces/react';
import { HTMLButtonType } from 'types';
import { StyledControlInputButton } from './styles';

interface ControlInputButtonProps extends ReactClick<HTMLButtonElement>, HTMLButtonType {
    disabled?: boolean;
}

export const ControlInputButton: FC<ControlInputButtonProps> = ({ onClick, children, ...props }) => (
    <StyledControlInputButton onClick={onClick} {...props}>
        {children}
    </StyledControlInputButton>
);
