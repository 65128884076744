import { Loader } from 'components/common/dynamic/Loader';
import { Section } from 'components/grid/Section';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/layouts/Empty';
import { TransactionsTable } from 'components/common/tables/TransactionsTable';
import { useStore } from 'effector-react';

import { TransactionsFilterLayout } from 'pages/Transactions/TransactionsFilterLayout';
import React from 'react';
import { transactionsStores } from 'stores/transactions';

export const Transactions = () => {
    const { items, totalRecords } = useStore(transactionsStores.items);
    const loading = useStore(transactionsStores.initialLoading);

    return (
        <MainLayout hiddenSearch>
            <TransactionsFilterLayout totalRecords={totalRecords}>
                {loading ? (
                    <Section justifyCenter>
                        <Loader size="large" />
                    </Section>
                ) : items?.length ? (
                    <Section>
                        <TransactionsTable items={items} />
                    </Section>
                ) : (
                    <Section alignCenter justifyCenter>
                        <Empty description="Transactions not found" />
                    </Section>
                )}
            </TransactionsFilterLayout>
        </MainLayout>
    );
};
