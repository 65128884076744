import { PoppinsFontFamily } from 'constants/styles/fonts';
import { Span } from 'components/common/typography/Span';
import { Row } from 'reactstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
    font-family: ${PoppinsFontFamily};
`;

export const StyledSpan = styled(Span)<{
    active?: boolean;
}>`
    margin-right: 10px;
    cursor: pointer;
    padding-bottom: 4px;
    color: ${({ active }) => (active ? '#fff' : 'rgba(255, 255, 255, 0.6)')};
    border-bottom: 1px solid ${({ active }) => (active ? '#9B49CD' : 'transparent')};
`;

export const Balance = styled.span`
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    text-transform: uppercase;
    color: #00ca69;
    margin-left: 12px;
    margin-right: 6px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

export const WalletField = styled.div`
    margin-bottom: 18px;
`;

export const WalletLabel = styled.span`
    margin-right: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    color: #ffffff;
`;

export const WalletValue = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    color: #9b49cd;
    margin-right: 6px;
`;

export const Description = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 30px;
`;

export const BackImage = styled.img`
    margin-right: 8px;
`;

export const BackWrapper = styled.div`
    cursor: pointer;
`;

export const DateTitle = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 12px;
`;

export const DatesRow = styled(Row)`
    align-items: center;
    margin-bottom: 24px;
`;

export const DataHeader = styled.div`
    font-weight: 600;
    border-bottom: 1px solid #353543;
    padding: 13px 16px;
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
`;

export const DataItem = styled.div`
    border-bottom: 1px solid #353543;
    padding: 13px 16px;
    color: #ffffff;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    justify-content: space-between;
`;

export const GraphsWrapper = styled.div`
    margin-top: 27px;
`;
