import noHLSIcon from 'assets/img/empty_source_video.svg';
import errorIcon from 'assets/img/error_icon.svg';
import playIcon from 'assets/img/play_icon.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { RelativeWrapper } from 'components/grid/wrappers/RelativeWrapper';
import { Tooltip } from 'components/modals/Tooltip';
import Hls from 'hls.js';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { sourceChooser } from './constants';
import { IconsWrapper, NoVideoWrapper, PlayIconWrapper, SourceWrapper, Video, VideoPoster } from './styles';

export const VideoPlayer: FC<Pick<WOM.ContentItemResponse, 'streamDetails' | 'uriPrimary' | 'source'>> = ({
    streamDetails,
    uriPrimary,
    source
}) => {
    const video = useRef<HTMLVideoElement>(null);
    const uriPrimaryString = typeof uriPrimary === 'string' ? uriPrimary : '';
    const [startLoading, setStartLoading] = useState(false);

    const hlsUrl = useMemo(() => streamDetails?.hlsUrl, [streamDetails]);
    const videoSrc = hlsUrl;
    const sourceImage = sourceChooser(source);

    const goLoading = () => setStartLoading(true);

    useEffect(() => {
        if (startLoading && Hls.isSupported() && videoSrc && video.current) {
            var hls = new Hls();
            hls.loadSource(videoSrc);
            hls.attachMedia(video.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                video?.current?.play();
            });
        }
    }, [videoSrc, startLoading]);

    return (
        <RelativeWrapper>
            {!startLoading && hlsUrl && (
                <PlayIconWrapper>
                    <CustomImg alt="play icon" height="40px" src={playIcon} width="40px" onClick={goLoading} />
                </PlayIconWrapper>
            )}
            <IconsWrapper left="15px" top="15px">
                {source && sourceImage && (
                    <SourceWrapper>
                        <CustomImg alt="video source" height="20px" src={sourceImage} width="36px" />
                    </SourceWrapper>
                )}
                {!hlsUrl && (
                    <Tooltip id="error-icon" title="Video has no hls stream">
                        <CustomImg alt="error" src={errorIcon} />
                    </Tooltip>
                )}
            </IconsWrapper>
            {hlsUrl ? (
                <Video ref={video} controls id="video" poster={uriPrimaryString} preload="metadata" />
            ) : uriPrimary ? (
                <VideoPoster>
                    <CustomImg alt="video poster" src={uriPrimary} />
                </VideoPoster>
            ) : (
                <NoVideoWrapper>
                    <CustomImg alt="empty video poster" src={noHLSIcon} />
                </NoVideoWrapper>
            )}
        </RelativeWrapper>
    );
};
