import { Tag } from 'components/common/tags/Tag';
import { TagColorType } from 'components/common/tags/Tag/types';
import { Span, TagsWrapper, Title } from 'pages/Organizations/ScrollableTags/styles';
import React, { FC } from 'react';

interface Props {
    data?: string[] | null;
    title: string;
    linkBase?: string | undefined;
    color?: TagColorType;
}

export const ScrollableTags: FC<Props> = ({ data, title, linkBase, color = 'info' }) => (
    <div>
        <Title>{title}</Title>
        <TagsWrapper>
            {data?.length ? (
                data?.map(tag => (
                    <Tag key={tag} color={color} link={linkBase && `${linkBase}${tag}`}>
                        {tag}
                    </Tag>
                ))
            ) : (
                <Span>No content</Span>
            )}
        </TagsWrapper>
    </div>
);
