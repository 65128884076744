import { TableHeadInterface } from 'types';

export const infoMessage = 'Enter a query in the search engine to display data here';

export const searchOptions = [
    {
        value: 'userName',
        label: 'User Name'
    },
    {
        value: 'userIds',
        label: 'User Id'
    },
    {
        value: 'mobileNumber',
        label: 'Mobile phone'
    },
    {
        value: 'email',
        label: 'Email'
    },
    {
        value: 'walletIds',
        label: 'Wallet ID'
    }
];

export const airdropAddUserTableColumns: TableHeadInterface[] = [
    {
        title: 'Platforms',
        dataIndex: 'platforms',
        key: 'platforms'
    },
    {
        title: 'USERNAME',
        dataIndex: 'username',
        key: 'username'
    },
    {
        title: 'user ID',
        dataIndex: 'userId',
        key: 'userId'
    },

    {
        title: 'WOM USER ID',
        dataIndex: 'womUserId',
        key: 'womUserId'
    },
    {
        title: '',
        dataIndex: 'addButton',
        key: 'addButton'
    }
];
