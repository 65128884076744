import { PropertyBlock } from 'components/common/dividers/PropertyBlock';
import { Text } from 'components/common/typography/Text';
import { Column, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { formatDate } from 'constants/global';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';

export const WalletCard: FC<WOM.AddressResponse> = ({ name, utcCreated, address, balance }) => (
    <Card className="wallet-card">
        <CardBody>
            <MarginWrapper marginBottom="16px">
                <Text size="h4">{name}</Text>
            </MarginWrapper>
            <Column marginBottom="8px">
                <Section marginBottom="8px">
                    <PropertyBlock
                        copyable
                        subtitle={address || ''}
                        success="You successfully copied address"
                        title="Address"
                    />
                </Section>
                <Section marginBottom="8px">
                    <PropertyBlock subtitle={balance?.toFixed(2)} title="Balance" />
                </Section>
                <Section marginBottom="8px">
                    <PropertyBlock subtitle={format(new Date(utcCreated || ''), formatDate)} title="Created At" />
                </Section>
            </Column>
        </CardBody>
    </Card>
);
