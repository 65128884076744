import axios, { CancelTokenSource } from 'axios';
import { defaultPerformancePaymentsValues, defaultValuesEntries } from 'constants/defaults';
import { combine, createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'services';
import { initializeIsFirstStore } from 'stores/initialize/initialize.isFirst.store';
import { initializeToggleStore } from 'stores/initialize/initialize.toggle.store';

let cancelToken: CancelTokenSource | undefined;

const [loading, updateLoading] = initializeToggleStore();
const [singleLoading, updateSingleLoading] = initializeToggleStore();
const [contentItemLoading, updateContentItemLoading] = initializeToggleStore();

const getItems = createEffect({
    handler: async (values: WOM.PerformancePaymentsQueryRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateLoading();
            const data = await API.performancePayments.getItems(values, cancelToken.token);
            updateLoading();

            return data;
        } catch {
            updateLoading();
            return {
                totalRecords: 0
            };
        }
    }
});

const getContentItems = createEffect({
    handler: async (values: WOM.PerformancePaymentsContentPaymentsRequest) => {
        try {
            updateContentItemLoading();
            const data = await API.performancePayments.getContentItems(values);
            updateContentItemLoading();

            return data;
        } catch {
            updateContentItemLoading();
            return {};
        }
    }
});

const getItemByContentId = createEffect({
    handler: async (id: string) => {
        try {
            updateSingleLoading();
            const data = await API.performancePayments.getItem({ ...defaultValuesEntries, contentId: id });
            updateSingleLoading();

            return data;
        } catch {
            updateSingleLoading();
            return {
                totalRecords: 0
            };
        }
    }
});

const items = createStore<WOM.PerformancePaymentsQueryResponse>({}).on(getItems.doneData, (_, newState) => newState);
const contentItems = createStore<WOM.PerformancePaymentsContentPaymentsResponse>({}).on(
    getContentItems.doneData,
    (_, newState) => newState
);
const item = createStore<WOM.ValidationEntriesResponse>({}).on(getItemByContentId.doneData, (_, newState) => newState);

const itemStore = combine([singleLoading, item]);
const contentItemsStore = combine([contentItemLoading, contentItems]);

const updateValues = createEvent<WOM.PerformancePaymentsQueryRequestValues>();
const setDefaultValues = createEvent();

const { isFirst, setIsFirstToFalse, setIsFirstToTrue } = initializeIsFirstStore();

const values = createStore<WOM.PerformancePaymentsQueryRequest>(defaultPerformancePaymentsValues)
    .on(updateValues, (state, values: WOM.PerformancePaymentsQueryRequestValues) => ({
        ...state,
        ...values
    }))
    .on(setDefaultValues, () => defaultPerformancePaymentsValues);

forward({
    from: values,
    to: getItems
});

values.watch(setDefaultValues, state => getItems(state));

const performancePaymentsEvents = {
    updateValues,
    setDefaultValues,
    setIsFirstToFalse,
    setIsFirstToTrue
};
const performancePaymentsEffects = { getItems, getItemByContentId, getContentItems };
const performancePaymentsStores = {
    loading,
    items,
    itemStore,
    values,
    isFirst,
    contentItemsStore
};

export { performancePaymentsEvents, performancePaymentsEffects, performancePaymentsStores };
