export const searchByConsensusItems = [
    {
        option: {
            label: 'All',
            value: ''
        }
    },
    {
        option: {
            label: 'NotProcessed',
            value: '0'
        }
    },
    {
        option: {
            label: 'AcceptedByConsensus',
            value: '1'
        }
    },
    {
        option: {
            label: 'RejectedByConsensus',
            value: '-1'
        }
    }
];

export const searchByConsensusOptions = searchByConsensusItems.map(item => item.option);
