import { Section } from 'components/grid/wrappers/flexWrapper/styles';
import { disableDefaultButtonStyleMixin, ellipsisMixin } from 'constants/styles';
import styled from 'styled-components';
import { Color, Sizes } from 'types/interfaces/styles';

export const StyledTable = styled.table`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
`;

export const TableRow = styled.tr`
    width: 100%;
`;

export const HeaderCell = styled.th`
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #525f7f;
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.05);
`;

interface TableCellProps extends Pick<Sizes, 'width'>, Color {}

export const Cell = styled.td<TableCellProps>`
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    color: ${({ color }) => color || '#666666'};
    width: ${({ width }) => width || 'fit-content'};
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.05);
`;

export const CellContentWrapper = styled(Section)`
    align-items: center;
    justify-content: space-between;
    flex-wrap: noWrap;
`;

export const EllipsisSpan = styled.span`
    @media (max-width: 992px) {
        width: 100px;
    }

    @media (max-width: 768px) {
        width: 50px;
    }

    @media (max-width: 460px) {
        width: 40px;
    }

    ${ellipsisMixin};
`;

export const CopyButtonWrapper = styled.div`
    margin-left: 5px;

    .copy-button {
        padding: 2px 5px;
    }
    .copy-button-icon {
        width: 13px;
    }
`;

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    padding: 6px 10px;
    margin-right: 40px;
    border-radius: 6px;

    :hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
`;
