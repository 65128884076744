import React, { ChangeEvent, MouseEvent } from 'react';
import { ControlArrowDownIcon, ControlArrowUpIcon } from 'assets/img';
import { noop } from 'constants/functions';
import { ControlInputButton } from 'components/common/buttons/ControlInputButton';
import { ClearInputButton } from 'components/common/buttons/ClearInputButton';
import { ReactKeyboard } from 'types/interfaces/react';
import { OnStringChange, Placeholder } from 'types/interfaces/form';
import { BorderProperties, BorderRadius, Color } from 'types/interfaces/styles';
import { Input, InputProps, InputWrapper, ButtonsChangeCount } from './styles';

export interface Props
    extends Placeholder,
        InputProps,
        BorderProperties,
        OnStringChange,
        ReactKeyboard<HTMLInputElement>,
        BorderRadius,
        Color {
    value?: string;
    disabled?: boolean;
    disableClearButton?: boolean;
    disableEnterKeyDown?: boolean;
    type?: string;
    onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const TextInput = ({
    value,
    placeholder,
    disabled,
    disableEnterKeyDown,
    onChange = noop,
    onKeyDown = noop,
    onClear = noop,
    width,
    borderBottom,
    border,
    borderRadius,
    height,
    type,
    ...props
}: Props) => {
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const currentTarget = e.currentTarget;
        if (currentTarget.type === 'number') {
            onChange(Math.abs(Number(currentTarget.value)).toString());
        } else {
            onChange(currentTarget.value);
        }
    };

    const onClickButtonUp = () => {
        onChange((Number(value) + 1).toString());
    };

    const onClickButtonDown = () => {
        if (value === '0') return;
        onChange((Number(value) - 1).toString());
    };

    return (
        <InputWrapper
            border={border}
            borderBottom={borderBottom}
            borderRadius={borderRadius}
            height={height}
            width={width}
        >
            <Input
                disabled={disabled}
                placeholder={placeholder}
                type={type || 'text'}
                value={value}
                onChange={onInputChange}
                onKeyDown={disableEnterKeyDown ? undefined : onKeyDown}
                {...props}
            />

            {type === 'number' && (
                <ButtonsChangeCount>
                    <ControlInputButton disabled={disabled} onClick={onClickButtonUp}>
                        <ControlArrowUpIcon />
                    </ControlInputButton>

                    <ControlInputButton disabled={value === '0' || disabled} onClick={onClickButtonDown}>
                        <ControlArrowDownIcon />
                    </ControlInputButton>
                </ButtonsChangeCount>
            )}

            {value && type !== 'number' && <ClearInputButton onClick={onClear} />}
        </InputWrapper>
    );
};
