import classNames from 'classnames';
import { defaultEarningsStatisticsQuery, defaultTransactionSummary } from 'constants/defaults/earningsStatistics';
import { useStore } from 'effector-react';
import { useQueryParams } from 'hooks/queryParams';
import _ from 'lodash';
import {
    defaultInputState,
    QueryEarningStatisticsByIds,
    searchByParameters,
    transactionTypeOptions
} from 'pages/Dashboard/constants';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import { catalogueEffects, catalogueEvents, catalogueStores } from 'stores/catalogue';
import { walletEffects } from 'stores/wallet';
import { DashboardQueryParameters } from 'types/interfaces/urlQueryParameters';

const { updateValues } = catalogueEvents;

const updateQueryParams = (params: DashboardQueryParameters) => {
    if (params.dateTo && params.dateFrom) updateValues(params);
};

export const QueryById = () => {
    const [transactionType] = useState(transactionTypeOptions[0]);
    const { dateTo, dateFrom, groupByWeek, userId, remoteContentId, contentId } = useStore(catalogueStores.values);
    const remoteContentIdIsString = typeof remoteContentId === 'string' ? remoteContentId : '';
    const [searchBy, setSearchBy] = useState<keyof QueryEarningStatisticsByIds>('userId');
    const [input, setInput] = useState<{ [k in keyof QueryEarningStatisticsByIds]: string | undefined }>(
        defaultInputState
    );
    const [queryParams, setQueryParams] = useQueryParams<DashboardQueryParameters>(updateQueryParams);
    const isExternal = transactionType.value === transactionTypeOptions[1].value;

    useEffect(() => {
        walletEffects.getTransactionSummary({
            ...defaultTransactionSummary,
            userId,
            userFromRequest: !!userId,
            dateFrom,
            dateTo
        });
        setQueryParams({
            ...queryParams,
            userId,
            remoteContentId,
            contentId,
            groupByWeek: groupByWeek || undefined,
            dateFrom,
            dateTo
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, remoteContentId, contentId, groupByWeek, dateFrom, dateTo]);

    useEffect(() => {
        if (!queryParams.dateTo && !queryParams.dateFrom)
            catalogueEffects.queryEarningsStatistics(defaultEarningsStatisticsQuery);
        const { userId, contentId, remoteContentId } = queryParams;
        setSearchBy(
            (_.findKey({ userId, contentId, remoteContentId }, o => o) as keyof QueryEarningStatisticsByIds) || 'userId'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => setInput({ userId, remoteContentId: remoteContentIdIsString, contentId }), [
        userId,
        remoteContentIdIsString,
        contentId
    ]);

    const onSearchClick = () => {
        updateValues({ ...input });
    };

    const setSearch = (key: keyof QueryEarningStatisticsByIds) => () => {
        setSearchBy(key);
        setInput({ ...defaultInputState, userId, remoteContentId: remoteContentIdIsString, contentId });
    };

    const onChange = (key: keyof QueryEarningStatisticsByIds) => (e: ChangeEvent<HTMLInputElement>) => {
        setInput({ ...defaultInputState, [key]: e.target.value || undefined });
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h4">Query by IDs</CardTitle>
            </CardHeader>
            <CardBody>
                <Row className="align-items-center mb-3 no-gutters">
                    <Col md="9">
                        <Nav pills className="nav-pills-info">
                            {isExternal ? (
                                <NavItem>
                                    <NavLink
                                        className={classNames({ active: true })}
                                        data-toggle="tab"
                                        onClick={setSearch('userId')}
                                    >
                                        {searchByParameters[0].name}
                                    </NavLink>
                                </NavItem>
                            ) : (
                                searchByParameters.map(({ key, name }) => (
                                    <NavItem key={key}>
                                        <NavLink
                                            className={classNames({ active: key === searchBy })}
                                            data-toggle="tab"
                                            onClick={setSearch(key)}
                                        >
                                            {name}
                                        </NavLink>
                                    </NavItem>
                                ))
                            )}
                        </Nav>
                    </Col>
                    <Col md="3">
                        <Row className="justify-content-end no-gutters">
                            <Button color="info" onClick={onSearchClick}>
                                Search
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <TabContent activeTab={searchBy} className="tab-space p-0">
                    {searchByParameters.map(({ key, name }) => (
                        <TabPane key={key} tabId={key}>
                            <FormGroup>
                                <Input
                                    id={key}
                                    placeholder={`Search by ${name}`}
                                    type="text"
                                    value={input[key] || ''}
                                    onChange={onChange(key)}
                                    onKeyDown={e => e.key === 'Enter' && onSearchClick()}
                                />
                            </FormGroup>
                        </TabPane>
                    ))}
                </TabContent>
            </CardBody>
        </Card>
    );
};
