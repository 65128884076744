import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { blue, disableDefaultButtonStyleMixin, grey1, grey2, white } from 'constants/styles';
import styled, { css } from 'styled-components';
import { inputBorderRadius } from './constants';

interface Props {
    isExpanded?: boolean;
}

export const IconAbsoluteWrapper = styled(AbsoluteWrapper)`
    padding: 8px;
`;

export const SearchSelectWrapper = styled.div<Props>`
    position: relative;

    ${({ isExpanded }) =>
        isExpanded
            ? css`
                  border-radius: ${inputBorderRadius} ${inputBorderRadius} 0 0;
                  border-bottom-color: transparent;
              `
            : css`
                  border-radius: ${inputBorderRadius};
              `};
`;

export const ItemsAbsoluteWrapper = styled(AbsoluteWrapper)`
    width: 370px;
    max-height: 160px;
    left: -1px;
    top: 30px;
    overflow: auto;
    padding: 0;
    z-index: 4;
`;

export const ListItemWrapper = styled.button`
    ${disableDefaultButtonStyleMixin}
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 8px 16px;
    background-color: ${grey2};
    font-size: 14px;
    line-height: 21px;
    color: ${grey1};

    :hover {
        background-color: ${blue};
    }

    :last-child {
        border-radius: 0;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    width: 370px;
`;

export const Input = styled.input`
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 21px;
    width: 100%;

    color: ${white};

    ::placeholder {
        font-size: 14px;
        line-height: 21px;
        color: ${grey1};
    }
`;
