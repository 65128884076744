import { device } from 'constants/styles/media';
import styled from 'styled-components';
import { Margin } from 'types';

const defaultGap = 16;

interface Props extends Margin {
    columns?: number;
    gap?: number;
}

export const GridWrapper = styled.div<Props>`
    display: grid;
    width: 100%;
    grid-template-columns: ${({ columns, gap }) => {
        if (columns && columns !== 1) {
            const allGaps = ((gap ?? defaultGap) * (columns - 1)) / columns;
            return `repeat(${columns}, calc(${100 / columns}% - ${allGaps}px))`;
        }
        return 'auto';
    }};
    gap: ${({ gap }) => `${gap || defaultGap}px`};
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};

    @media ${device.mobile} {
        grid-template-columns: 100%;
    }
`;
