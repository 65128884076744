import styled from 'styled-components';
import { infoTextColor, PoppinsFontFamily, tableBorderLine } from 'constants/styles';
import { Table } from 'reactstrap';

interface ContentWrapperProps {
    withoutMarginBottom?: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
    width: 100%;
    ${({ withoutMarginBottom }) => !withoutMarginBottom && 'margin-bottom: 30px'};
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #40556f;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const TableStyled = styled(Table)`
    margin-bottom: 0;
`;

export const TableHeadStyled = styled.thead`
    border-bottom: 0.0625rem solid ${tableBorderLine};
`;

export const InfoMessage = styled.p`
    text-align: center;
    font-family: ${PoppinsFontFamily};
    font-size: 16px;
    line-height: normal;
    margin-bottom: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    color: ${infoTextColor};
`;
