export const authLink = '/login';

export const dashboardLink = '/dashboard';

export const homeLink = '/videos';
export const videoLink = homeLink + '/:videoId';

export const usersLink = '/users';
export const userLink = usersLink + '/:userId';

export const walletLink = '/wallet';
export const walletSingleLink = walletLink + '/:walletId';

export const transactionsLink = '/transactions';
export const transactionLink = transactionsLink + '/:transactionId';

export const performancePaymentsLink = '/performance_payments';
export const performancePaymentsSingleLink = performancePaymentsLink + '/:contentId/:auditId';

export const organizationsLink = '/organizations';

export const potLink = '/pots/:id';

export const createTransactionLink = '/create_transaction';
export const airdropLink = '/airdrop';
export const womHoldersLink = '/wom_holders';
export const withdrawalLimitLink = '/withdrawal_limit';
export const createWithdrawalLimitLink = withdrawalLimitLink + '/create';
export const updateWithdrawalLimitLink = withdrawalLimitLink + '/:id';

export const authLinkName = 'Log out';
const dashboardLinkName = 'Dashboard';
const homeLinkName = 'Videos';
const videoLinkName = 'Single';
const videoLinkTitle = 'Video Page (Single)';
const usersLinkName = 'Users';
const userLinkName = 'Single';
const userLinkTitle = 'User Page (Single)';
const walletLinkName = 'Wallet';
const walletLinkTitle = 'Wallet Page (Single)';
const transactionsLinkName = 'Transactions';
const performancePaymentsLinkName = 'Performance payments';
const performancePaymentsSingleLinkName = 'Single';
const organizationsLinkName = 'Organizations';

export const withdrawalCheckLink = '/withdrawal_check';

const womHoldersLinkName = 'Wom Holders';
const withdrawalLimitLinkName = 'Withdrawal limit';

export const error403Link = '/403';

type RouteStateType = 'videosNested' | 'usersNested' | 'performanceNested' | 'walletNested' | 'withdrawalCheckNested';

export type SidebarStatesType = {
    [key in RouteStateType]?: boolean;
};

interface RouteInterface {
    collapse?: boolean;
    nest?: boolean;
    path: string;
    name: string;
    title: string;
    mini?: string;
    icon?: string;
    proxy?: number;
    isSearchable?: boolean;
    isResetable?: boolean;
    state?: RouteStateType;
    view?: RouteInterface;
    views?: RouteInterface[];
}

export type RoutesType = RouteInterface[];

export const newRoutesArray: RoutesType = [
    {
        path: dashboardLink,
        name: dashboardLinkName,
        title: dashboardLinkName,
        icon: 'tim-icons icon-chart-pie-36'
    },
    {
        path: homeLink,
        name: homeLinkName,
        title: homeLinkName,
        icon: 'tim-icons icon-triangle-right-17',
        isSearchable: true,
        isResetable: true,
        nest: true,
        state: 'videosNested',
        view: {
            path: videoLink,
            name: videoLinkName,
            title: videoLinkTitle,
            mini: 'S'
        }
    },
    {
        path: usersLink,
        name: usersLinkName,
        title: usersLinkName,
        icon: 'tim-icons icon-single-02',
        isSearchable: true,
        isResetable: true,
        nest: true,
        state: 'usersNested',
        view: {
            path: userLink,
            name: userLinkName,
            title: userLinkTitle,
            mini: 'S'
        }
    },
    {
        path: performancePaymentsLink,
        name: performancePaymentsLinkName,
        title: performancePaymentsLinkName,
        icon: 'tim-icons icon-chart-bar-32',
        isResetable: true,
        nest: true,
        state: 'performanceNested',
        view: {
            path: performancePaymentsSingleLink,
            name: performancePaymentsSingleLinkName,
            title: performancePaymentsSingleLinkName,
            mini: 'S'
        }
    },
    {
        path: organizationsLink,
        name: organizationsLinkName,
        title: organizationsLinkName,
        icon: 'tim-icons icon-bank'
    },
    {
        path: walletLink,
        name: walletLinkName,
        title: walletLinkName,
        icon: 'tim-icons icon-wallet-43',
        isSearchable: true,
        isResetable: true,
        nest: true,
        state: 'walletNested',
        view: {
            path: walletSingleLink,
            name: walletLinkName,
            title: walletLinkTitle,
            mini: 'S'
        }
    },
    {
        path: transactionsLink,
        name: transactionsLinkName,
        title: transactionsLinkName,
        icon: 'tim-icons icon-money-coins',
        isSearchable: true,
        isResetable: true
    },
    {
        path: womHoldersLink,
        name: womHoldersLinkName,
        title: womHoldersLinkName,
        icon: 'tim-icons icon-coins'
    },
    {
        path: withdrawalLimitLink,
        name: withdrawalLimitLinkName,
        title: withdrawalLimitLinkName,
        icon: 'tim-icons icon-credit-card'
    }
];

export const routesArray = [
    {
        path: dashboardLink,
        name: dashboardLinkName
    },
    {
        path: homeLink,
        name: homeLinkName
    },
    {
        path: usersLink,
        name: usersLinkName
    },
    {
        path: performancePaymentsLink,
        name: performancePaymentsLinkName,
        proxy: 0
    },
    {
        path: organizationsLink,
        name: organizationsLinkName,
        proxy: 0
    },
    {
        path: walletLink,
        name: walletLinkName,
        proxy: 0
    },
    {
        path: transactionsLink,
        name: transactionsLinkName,
        proxy: 0
    }
];
