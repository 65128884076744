import { disableDefaultButtonStyleMixin, grey2, white } from 'constants/styles';
import styled from 'styled-components';
import { Disabled } from 'types';

export const Wrapper = styled.div`
    width: 100%;
    background-color: ${grey2};
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0px 1.16812px 23.3623px rgba(0, 0, 0, 0.1);
`;

export const FilterTitle = styled.span`
    font-size: 17px;
    line-height: 25px;
    text-transform: capitalize;
    color: ${white};
`;

export const QueryId = styled.div`
    align-items: center;
    flex-wrap: noWrap;
    margin-bottom: 16px;
    display: flex;
    margin-top: 26px;
    width: 100%;

    @media (max-width: 992px) {
        flex-wrap: wrap;
    }

    @media (max-width: 768px) {
        flex-wrap: noWrap;
    }

    @media (max-width: 460px) {
        flex-wrap: wrap;
    }
`;

export const WrapperSelect = styled.div`
    margin-left: 15px;
    width: 280px;

    @media (max-width: 992px) {
        margin-left: 0;
        width: 100%;
    }

    @media (max-width: 768px) {
        margin-left: 15px;
        width: 280px;
        flex-shrink: 0;
    }

    @media (max-width: 460px) {
        margin-left: 0;
        width: 100%;
    }
`;

export const WrapperSearchInput = styled.div`
    width: 100%;

    @media (max-width: 992px) {
        margin-bottom: 17px;
    }

    @media (max-width: 768px) {
        margin-bottom: 0;
    }

    @media (max-width: 460px) {
        margin-bottom: 14px;
    }
`;

export const SelectDate = styled.div`
    width: 100%;

    @media (max-width: 768px) {
        margin-bottom: 24px;
    }
`;

export const WrapperSelectDate = styled.div`
    width: 100%;
    background-color: ${grey2};
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0px 1.16812px 23.3623px rgba(0, 0, 0, 0.1);

    @media (max-width: 992px) {
        height: 200px;
    }

    @media (max-width: 768px) {
        height: 100%;
    }
`;

export const SelectDateRange = styled.div`
    width: 100%;
    flex-wrap: noWrap;
    margin-bottom: 17px;
    display: flex;
    margin-top: 27px;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 768px) {
        justify-content: flex-start;
    }
`;

export const WrapperDataRangePicker = styled.div`
    width: 100%;

    @media (max-width: 768px) {
        width: auto;
    }

    @media (max-width: 768px) {
        width: auto;
        flex-grow: 1;
    }
`;

export const ToggleButton = styled.div<Disabled>`
    white-space: noWrap;
    margin-left: 16px;
    opacity: 1;
    color: ${white};

    ${({ disabled }) => disabled && `opacity: 0.4;`}

    @media (max-width: 992px) {
        white-space: initial;
    }

    @media (max-width: 460px) {
        white-space: noWrap;
    }
`;

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    padding: 6px 10px;
    margin-right: 40px;
    border-radius: 6px;

    :hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
`;
