import { SearchSelectInput } from 'components/common/inputs/SearchSelectInput';
import { TagsFilter } from 'components/common/inputs/TagsFilter';
import { InfoTitle } from 'components/common/typography/InfoTitle';
import { Span } from 'components/common/typography/Span';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Row, Section } from 'components/grid/wrappers/flexWrapper';
import { Pagination } from 'components/layouts/Pagination';
import { defaultLimit, defaultPage } from 'constants/defaults';
import { grey5 } from 'constants/styles';
import { useStore } from 'effector-react';
import { useQueryParams } from 'hooks/queryParams';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { Col } from 'reactstrap';
import { VideoQueryValues, videosEvents, videosStores } from 'stores/videos';
import { SelectOptions, TotalRecords } from 'types';
import { searchByConsensusOptions } from './constants';
import { StyledColumn } from './styles';

const { updateValues, invokeGetItems, setIsFirstToFalse } = videosEvents;

interface VideosQueryParams extends Partial<VideoQueryValues> {
    byContext?: boolean;
}

export const VideosFilterLayout: FC<TotalRecords> = ({ children, totalRecords }) => {
    const { pageIndex, limit, tagsAny, tagsAll, videoId, validationResult, remoteContentIds } = useStore(
        videosStores.values
    );
    const isFirst = useStore(videosStores.isFirst);
    const [isTagsAny, setIsTagsAny] = useState(false);
    const tags = isTagsAny ? tagsAny || [] : tagsAll || [];

    const onVideoSourceChange = (newSource: SelectOptions | null) => {
        const source = newSource?.value;
        updateValues({
            source: source || undefined,
            pageIndex: defaultPage,
            limit: defaultLimit
        });
    };

    const [consensusSource, setConsensusSource] = useState(searchByConsensusOptions[0]);

    const onConsensusChange = (newConsensusSource: SelectOptions | null) => {
        if (newConsensusSource) setConsensusSource(newConsensusSource);
        const sort = newConsensusSource?.value;
        updateValues({
            validationResult:
                newConsensusSource !== searchByConsensusOptions[0] ? (Number(sort) as WOM.ValidationResult) : undefined
        });
    };

    const updateQueryValues = ({ byContext, ...params }: VideosQueryParams) => {
        updateValues({
            ...params
        });
        byContext && setIsTagsAny(byContext);
    };

    const [queryParams, setQueryParams] = useQueryParams<VideosQueryParams>(updateQueryValues);

    // const onResetClick = () => {
    //     setQueryParams({});
    //     document.location.reload();
    // };

    const onIdSearch = async (id: string) =>
        updateValues({
            videoId: id || undefined
        });

    const onUserRemoteIdSearch = (id: string) =>
        updateValues({
            pageIndex: defaultPage,
            limit: defaultLimit,
            remoteContentIds: id ? [id] : undefined
        });

    const onTagsChange = (tags: string[], isTagsAny: boolean) =>
        updateValues({
            tagsAll: !isTagsAny && tags.length ? tags : undefined,
            tagsAny: isTagsAny && tags.length ? tags : undefined,
            pageIndex: defaultPage,
            limit: defaultLimit
        });

    const onTagsFilterChange = (tags: string[]) => onTagsChange(tags, isTagsAny);

    const onTagsByContextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setIsTagsAny(checked);
        onTagsChange(tags, checked);
    };

    const onCurrentPageChange: (page: number, pageSize: number | undefined) => void = (page, pageSize) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    useEffect(() => {
        if (isFirst && !queryParams.videoId) {
            invokeGetItems();
            setIsFirstToFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchByIDItems = [
        {
            option: {
                label: 'Video ID',
                value: 'videoId'
            },
            onSearch: onIdSearch
        },
        {
            option: {
                label: 'Remote Content ID',
                value: 'remoteContentIds'
            },
            onSearch: onUserRemoteIdSearch
        }
    ];

    const searchBySourceItems = [
        {
            label: 'All',
            value: ''
        },
        {
            label: 'Yeay',
            value: 'yeay'
        },
        {
            label: 'Wom',
            value: 'wom'
        },
        {
            label: 'Bullz',
            value: 'bullz'
        }
    ];

    useEffect(() => {
        setQueryParams({
            remoteContentIds,
            tagsAny,
            validationResult,
            tagsAll,
            videoId,
            byContext: isTagsAny || undefined,
            pageIndex,
            limit
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteContentIds, tagsAny, tagsAll, videoId, isTagsAny, pageIndex, limit, validationResult]);

    return (
        <>
            <Section>
                <StyledColumn>
                    <MarginWrapper marginBottom="16px">
                        <Span noWrap color={grey5} fontSize="13px">
                            Filter by consensus
                        </Span>
                    </MarginWrapper>
                    <Select
                        className="react-select info w-100"
                        classNamePrefix="react-select"
                        name="sorts"
                        options={searchByConsensusOptions}
                        value={consensusSource}
                        onChange={onConsensusChange}
                    />
                </StyledColumn>
                <StyledColumn>
                    <MarginWrapper marginBottom="16px">
                        <Span noWrap color={grey5} fontSize="13px">
                            Filter by videos
                        </Span>
                    </MarginWrapper>
                    <Select
                        className="react-select info w-100"
                        classNamePrefix="react-select"
                        options={searchBySourceItems}
                        onChange={onVideoSourceChange}
                    />
                </StyledColumn>
                <Row alignCenter marginBottom="16px">
                    <TagsFilter
                        defaultChecked={isTagsAny}
                        placeholder="Type here..."
                        tags={tags}
                        onChange={onTagsFilterChange}
                        onCheckboxChange={onTagsByContextChange}
                    ></TagsFilter>
                </Row>
            </Section>

            <Section marginBottom="15px">
                <SearchSelectInput items={searchByIDItems} />
            </Section>

            <InfoTitle title="Total: ">{totalRecords !== -1 ? totalRecords : 0}</InfoTitle>

            <Section minHeight="calc(100vh - 270px)">
                <Col>{children}</Col>
            </Section>
            <Section alignCenter justifyCenter>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Section>
        </>
    );
};
