import { Column, Row } from 'components/grid/wrappers/flexWrapper';
import { white } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const ButtonsWrapper = styled(Row)`
    width: 100%;
    max-width: 680px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    @media ${device.mobile} {
        height: 160px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
`;

export const FormWrapper = styled(Column)`
    background-color: ${white};
    width: 100%;
    max-width: 680px;
    border-radius: 6px;
`;
