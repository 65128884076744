import noFoundImg from 'assets/img/no_found_img.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { Span } from 'components/common/typography/Span';
import { Column } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { grey6 } from 'constants/styles';
import React, { FC } from 'react';

interface Props {
    description: string;
}

export const Empty: FC<Props> = ({ description }) => (
    <Column alignCenter justifyCenter height="100%">
        <CustomImg alt="no found icon" src={noFoundImg} />
        <MarginWrapper marginBottom="30px" marginTop="30px">
            <Span color={grey6} fontSize="23px">
                {description}
            </Span>
        </MarginWrapper>
    </Column>
);
