import { CopyButton } from 'components/common/buttons/CopyButton';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { useStore } from 'effector-react';
import { SelectedChartItemsTableDataProps, SelectedUsersDataProps } from 'pages/WomHolders/types';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { walletAdminStores } from 'stores/wallet-admin';
import { getDateFromString } from 'utils/usefulFunctions';
import { tableHeaderData } from './constants';
import {
    Cell,
    CellContentWrapper,
    CopyButtonWrapper,
    EllipsisSpan,
    HeaderCell,
    StyledButton,
    StyledTable,
    TableRow
} from './styles';
import { getTableDataByUserId } from './utils';

interface Props {
    tableData: SelectedChartItemsTableDataProps[] | undefined;
    setTableData: Dispatch<SetStateAction<SelectedChartItemsTableDataProps[] | undefined>>;
    selectedUsersData: SelectedUsersDataProps[];
    setSelectedUsersData: Dispatch<SetStateAction<SelectedUsersDataProps[]>>;
}

export const HoldersTable: FC<Props> = ({ selectedUsersData, setSelectedUsersData, tableData, setTableData }) => {
    const { items } = useStore(walletAdminStores.balanceHistorical);

    useEffect(() => {
        const data = getTableDataByUserId(items, selectedUsersData);

        setTableData(data as SelectedChartItemsTableDataProps[]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUsersData]);

    useEffect(() => {
        console.log(tableData);
    }, [tableData]);

    const clearTable = () => {
        setTableData([]);
        setSelectedUsersData([]);
    };

    return tableData && tableData?.length > 0 ? (
        <>
            <Section justifyEnd height="50px" marginTop="30px">
                <StyledButton onClick={clearTable}>Clear table</StyledButton>
            </Section>
            <StyledTable>
                <thead>
                    <TableRow>
                        {tableHeaderData.map(({ title, key }) => (
                            <HeaderCell key={key}>
                                <Section alignCenter justifyCenter noWrap>
                                    {title}
                                </Section>
                            </HeaderCell>
                        ))}
                    </TableRow>
                </thead>
                <tbody>
                    {tableData.length > 0 &&
                        tableData.map(
                            ({
                                date,
                                balance,
                                color = 'white',
                                username,
                                remoteUsername,
                                userId,
                                walletId,
                                source
                            }) => (
                                <TableRow key={userId}>
                                    <Cell color={color} width="100px">
                                        {getDateFromString(date)}
                                    </Cell>
                                    <Cell color={color}>{balance?.toFixed(2)}</Cell>
                                    <Cell color={color}>{source}</Cell>
                                    <Cell color={color}>
                                        <CellContentWrapper>
                                            <EllipsisSpan>{username}</EllipsisSpan>
                                            {!!username && (
                                                <CopyButtonWrapper>
                                                    <CopyButton
                                                        subject={username}
                                                        success="You successfully copied username!"
                                                    />
                                                </CopyButtonWrapper>
                                            )}
                                        </CellContentWrapper>
                                    </Cell>
                                    <Cell color={color}>
                                        <CellContentWrapper>
                                            <EllipsisSpan>{remoteUsername}</EllipsisSpan>

                                            {!!remoteUsername && (
                                                <CopyButtonWrapper>
                                                    <CopyButton
                                                        subject={remoteUsername}
                                                        success="You successfully copied remote username!"
                                                    />
                                                </CopyButtonWrapper>
                                            )}
                                        </CellContentWrapper>
                                    </Cell>
                                    <Cell color={color}>
                                        <CellContentWrapper>
                                            <EllipsisSpan>{userId}</EllipsisSpan>

                                            {!!userId && (
                                                <CopyButtonWrapper>
                                                    <CopyButton
                                                        subject={userId}
                                                        success="You successfully copied wom user ID!"
                                                    />
                                                </CopyButtonWrapper>
                                            )}
                                        </CellContentWrapper>
                                    </Cell>
                                    <Cell color={color}>
                                        <CellContentWrapper>
                                            <EllipsisSpan>{walletId}</EllipsisSpan>

                                            {!!walletId && (
                                                <CopyButtonWrapper>
                                                    <CopyButton
                                                        subject={walletId}
                                                        success="You successfully copied wallet ID!"
                                                    />
                                                </CopyButtonWrapper>
                                            )}
                                        </CellContentWrapper>
                                    </Cell>
                                </TableRow>
                            )
                        )}
                </tbody>
            </StyledTable>
        </>
    ) : null;
};
