import loaderAnimation from 'assets/img/loader.gif';
import loaderModalAnimation from 'assets/img/loader_modal.gif';
import LoaderWhite from 'assets/img/loader_white.gif';
import { SizeType } from 'components/common/dynamic/Loader/types';
import React, { FC } from 'react';

interface Props {
    size?: SizeType;
}

export const Loader: FC<Props> = ({ size = 'default' }) => (
    <div className="loader">
        <img alt="loader" className={size} src={loaderAnimation} />
    </div>
);

export const ModalLoader: FC<Props> = () => (
    <div className="modal-loader">
        <img alt="loader" src={loaderModalAnimation} />
    </div>
);

export const TransactionInProgress: FC<Props> = () => <img alt="loader" height="10px" src={LoaderWhite} width="10px" />;
