import React, { FC } from 'react';
import {
    RowWrapper,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    TimeWrapper,
    ValueWrapper,
    TransactionLabel,
    DataWrapper,
    ContainerWrapper,
    StyledStatus,
    Label
} from './styles';
import { ReactComponent as WomIcon } from 'assets/wom_icon.svg';
import { womNarrativeTypeObject } from 'constants/filters';
import classNames from 'classnames';
import { InternalCopyLink } from 'components/common/links/InternalCopyLink';
import { currencyToStandardForm, getEllipsisAddress } from 'utils/usefulFunctions';
import { defaultSortValue } from 'constants/messages';
import { noContentMessage } from 'constants/notifications';
import { Icon } from '@material-ui/core';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { transactionsLink, withdrawalCheckLink } from 'constants/routes';
import { purple } from 'constants/styles/colors';
import { useHistory } from 'react-router';
import { usersEffects } from 'stores/users';
import { Text } from 'components/common/typography/Text';
import { parseISO, format } from 'date-fns';
import { statusTitle } from './constants';
import { Row } from 'components/grid/wrappers/flexWrapper';

export const UserTimeline: FC<WOM.TransactionResponse> = ({
    status,
    transactionId,
    value,
    utcCreated,
    to,
    from,
    type
}) => {
    const history = useHistory();

    const onWithdrawalCheckClick = (addressTo: string) => () => {
        history.push(withdrawalCheckLink);
        usersEffects.getWithdrawalByAddressTo(addressTo);
    };

    const getFormattedDate = (dateString: string) => {
        // Преобразуем строку в объект Date с помощью функции parseISO
        const dateObj = parseISO(dateString);

        // Форматируем дату (день, месяц, год) отдельно
        const formattedDate = format(dateObj, 'dd.MM.yyyy'); // Вывод: "07.07.2023"

        // Форматируем время (часы, минуты, секунды) отдельно
        const formattedTime = format(dateObj, 'HH:mm:ss'); // Вывод: "07:20:12"
        return (
            <>
                <DataWrapper>{formattedDate}</DataWrapper>
                <DataWrapper>{formattedTime}</DataWrapper>
            </>
        );
    };

    const data = {
        transactionId: transactionId ? (
            <InternalCopyLink
                href={transactionsLink + '/' + transactionId}
                subject={transactionId}
                success="You successfully copied user id"
            >
                <Text color={purple} size="h5">
                    {getEllipsisAddress(transactionId)}
                </Text>
            </InternalCopyLink>
        ) : (
            <span>{noContentMessage}</span>
        ),
        type: type !== undefined ? womNarrativeTypeObject[type] : <span>{noContentMessage}</span>,
        status:
            status !== undefined ? (
                <StyledStatus status={status}>{statusTitle[status]}</StyledStatus>
            ) : (
                <span>{noContentMessage}</span>
            ),

        from: from ? <Label>{getEllipsisAddress(from)}</Label> : <span>{noContentMessage}</span>,
        to: to ? (
            <>
                <Row alignCenter justifyCenter noWrap>
                    <MarginWrapper marginRight="4px">
                        <Label>{getEllipsisAddress(to)}</Label>
                    </MarginWrapper>
                    {type && womNarrativeTypeObject[type] === 'Withdrawal' && (
                        <Icon className={classNames('tim-icons', 'icon-paper')} onClick={onWithdrawalCheckClick(to)} />
                    )}
                </Row>
            </>
        ) : (
            <span>{noContentMessage}</span>
        ),
        utcCreated: utcCreated ? (
            getFormattedDate(utcCreated)
        ) : (
            <>
                <span>{noContentMessage}</span>
            </>
        ),
        value: {
            jsx: (
                <ValueWrapper>
                    <MarginWrapper marginRight="4px">
                        {value ? currencyToStandardForm(value) : defaultSortValue}
                    </MarginWrapper>
                    <WomIcon />
                </ValueWrapper>
            ),
            value: value || defaultSortValue
        }
    };

    return (
        <ContainerWrapper>
            <RowWrapper>
                <TimeWrapper>
                    <TransactionLabel>{data.type}</TransactionLabel>
                    {data.utcCreated}
                </TimeWrapper>
                <ValueWrapper>
                    <span>{data.value.jsx}</span>
                </ValueWrapper>
            </RowWrapper>
            <RowWrapper>
                <Table>
                    <tbody>
                        <TableRow>
                            <TableCell>
                                <TableHeader>STATUS</TableHeader>
                                {data.status}
                            </TableCell>
                            <TableCell>
                                <TableHeader>ID</TableHeader>
                                {data.transactionId}
                            </TableCell>
                            <TableCell>
                                <TableHeader>ADDRESS FROM</TableHeader>
                                {data.from}
                            </TableCell>
                            <TableCell>
                                <TableHeader>ADDRESS TO</TableHeader>
                                {data.to}
                            </TableCell>
                        </TableRow>
                    </tbody>
                </Table>
            </RowWrapper>
        </ContainerWrapper>
    );
};
