import { Loader } from 'components/common/dynamic/Loader';
import { Text } from 'components/common/typography/Text';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Empty } from 'components/layouts/Empty';
import { MainLayout } from 'components/layouts/MainLayout';
import { emptyWalletId } from 'constants/global';
import { useStore } from 'effector-react';
import { userNotFoundMessage } from 'pages/Users/constants';
import { TransactionsHistory } from 'pages/Users/User/TransactionsHistory';
import { UserDescription } from 'pages/Users/User/UserDescription';
import { UserReportModal } from 'pages/Users/User/UserReportModal';
import { disableButtonText } from 'pages/Users/UserCard/constants';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Button } from 'components/common/buttons/Button';
import { userReportModal } from 'stores/initialize/initialize.modal.store';
import { modalEvents } from 'stores/modal';
import { usersEffects, usersStores } from 'stores/users';
import { userTransactionsEvents, userTransactionsStores } from 'stores/userTransactions';
import { BreadcrumbWrapper, ButtonsWrapper } from './styles';

const { generateUserReport, getItemById, getAuditItems, disableUser } = usersEffects;
const { updateValues, setIsFirstToTrue } = userTransactionsEvents;

interface ParamsProps {
    userId: string;
}

export const User = () => {
    const { userId } = useParams<ParamsProps>();
    const item = useStore(usersStores.item);
    const { username, isDisabled } = item;
    const { items: userTransactionItems, totalRecords } = useStore(userTransactionsStores.items);
    const transactionsLoading = useStore(userTransactionsStores.initialLoading);

    // TODO temporary hidden cause need optimization
    // const { items: userAuditItems } = useStore(usersStores.auditUser);
    // const userAuditLoading = useStore(getAuditItems.pending);

    const loading = useStore(usersEffects.getItemById.pending);

    const disableUserById = async () => {
        await disableUser({
            userId,
            isDisabled: !isDisabled
        });
        getItemById(userId);
    };

    const onDisableClick = () => {
        modalEvents.openConfirmationModal({
            visible: true,
            confirmationQuestion: 'Disabling or activating a user',
            confirmationText: `Are you sure you want to ${isDisabled ? 'activate' : 'disable'} a user ${username}?`,
            errorMessage: `Something wrong`,
            successMessage: `User have been ${isDisabled ? 'activated' : 'disabled'}`,
            successTitle: `Changed`,
            confirmationButtonText: disableButtonText(isDisabled),
            onConfirm: disableUserById
        });
    };

    const onGenerateReportClick = () => {
        userReportModal.openModal();
        generateUserReport(userId);
    };

    useEffect(() => {
        getItemById(userId);
        getAuditItems(userId);
    }, [userId]);

    useEffect(() => {
        if (userId === item.userId) {
            updateValues({
                walletId: item.walletId
            });
            setIsFirstToTrue();
        }
    }, [item, userId]);

    return (
        <MainLayout>
            <BreadcrumbWrapper>
                <Breadcrumb />
            </BreadcrumbWrapper>

            <MarginWrapper marginBottom="16px">
                <Text size="h4">User info</Text>
            </MarginWrapper>

            {loading ? (
                <Section alignCenter justifyCenter height="418px">
                    <Loader />
                </Section>
            ) : (
                <>
                    <Section justifyEnd>
                        <ButtonsWrapper>
                            <Button buttonType="red" onClick={onGenerateReportClick}>
                                Generate report
                            </Button>

                            <MarginWrapper marginLeft="10px">
                                <Button buttonType="blue" onClick={onDisableClick}>
                                    {disableButtonText(isDisabled)}
                                </Button>
                            </MarginWrapper>
                        </ButtonsWrapper>
                    </Section>
                    <Section>
                        {item.userId ? (
                            <UserDescription {...item} />
                        ) : (
                            <Section alignCenter justifyCenter height="100%">
                                <Empty description={userNotFoundMessage} />
                            </Section>
                        )}
                    </Section>
                </>
            )}

            {item.walletId !== emptyWalletId && (
                <>
                    <TransactionsHistory
                        items={userTransactionItems}
                        loading={transactionsLoading}
                        totalRecords={totalRecords}
                    />

                    {/* TODO temporary hidden cause need optimization  */}
                    {/* {userAuditLoading ? (
                        <Section alignCenter justifyCenter height="418px">
                            <Loader />
                        </Section>
                    ) : userAuditItems?.length ? (
                        <Section>
                            <TimelineWrapper>
                                <MarginWrapper marginBottom="16px">
                                    <Text size="h4">Timeline</Text>
                                </MarginWrapper>
                                <AuditUserTimeline items={userAuditItems} />
                            </TimelineWrapper>
                        </Section>
                    ) : null} */}
                </>
            )}

            <UserReportModal />
        </MainLayout>
    );
};
