import { ErrorFormTitleMinHeight } from 'components/formComponents/FormTitle/constants';
import { errorColor, padding } from 'constants/styles';
import styled from 'styled-components';

export const ErrorFormTitle = styled.span`
    color: ${errorColor};
    margin-right: 8px;
    margin-bottom: ${padding};
    min-height: ${ErrorFormTitleMinHeight};
`;
