import { Row } from 'components/grid/wrappers/flexWrapper';
import { flexCenter } from 'constants/styles';
import { PoppinsFontFamily } from 'constants/styles/fonts';
import styled from 'styled-components';

export const Icon = styled.i`
    position: relative;
    cursor: pointer;
    &::after {
        position: absolute;
        top: -31px;
        left: 50%;
        transform: translateX(-50%);
        content: 'Withdrawal Check';
        color: rgb(255, 255, 255);
        font-family: ${PoppinsFontFamily};
        font-weight: normal;
        font-size: 13px;
        border: 1px solid rgb(64, 85, 111);
        border-radius: 2px;
        background-color: #32325d;
        padding-left: 8px;
        padding-right: 8px;
        white-space: nowrap;
        opacity: 0;
        height: 22px;
        ${flexCenter};
    }

    &:hover::after {
        opacity: 1;
    }
`;

export const ValueWrapper = styled(Row)`
    align-items: center;
    justify-content: center;
`;
