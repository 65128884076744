import { ellipsisMixin } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const PotGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 24px;
    width: 100%;

    @media ${device.tablet} {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
`;

export const PotItem = styled.div`
    background-color: #27293d;
    padding: 15px 12px;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
`;

export const PotTitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    margin-bottom: 15px;
`;

export const PotBalance = styled.div`
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    text-transform: uppercase;
    color: #00ca69;
    margin-bottom: 11px;
    display: flex;
    align-items: center;

    & svg {
        margin-left: 5px;
    }
`;

export const PotData = styled.div`
    margin-bottom: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const PotValue = styled.span`
    display: inline-block;
    margin-right: 7px;
    margin-left: 5px;
    color: #9b49cd;
    ${ellipsisMixin}
`;

export const InfoWrapper = styled.span`
    position: absolute;
    top: 10px;
    right: 17px;
`;
