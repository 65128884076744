import React, { FC } from 'react';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { Section, Row } from 'components/grid/wrappers/flexWrapper';
import { engagementsIcons } from 'pages/Videos/VideoCard/constants';
import { Text } from 'components/common/typography/Text';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';

export const Engagements: FC<Pick<WOM.ContentItemResponse, 'engagement'>> = ({ engagement }) => (
    <Section alignCenter justifyBetween>
        {engagementsIcons.map(({ alt, icon, key }) => (
            <Row key={key} alignCenter>
                <CustomImg alt={alt} src={icon} />

                <MarginWrapper marginLeft="4px">
                    <Text size="h5">{engagement ? engagement[key] : 0}</Text>
                </MarginWrapper>
            </Row>
        ))}
    </Section>
);
