import { grey2, white } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';
import { Padding } from 'types/interfaces';

export const Wrapper = styled.div`
    width: 929px;
    @media ${device.desktop} {
        width: 100%;
    }
`;

export const TableWrapper = styled.div<Pick<Padding, 'paddingTop'>>`
    background-color: ${grey2};
    border-radius: 8px;
    width: 100%;
    margin-bottom: 46px;
    padding: ${({ paddingTop }) => paddingTop || '16px'} 10px 16px;
`;

export const TableCell = styled.span`
    font-size: 14px;
    line-height: 21px;
    color: ${white};
    transition: opacity 0.3s;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export const DefaultLimit = styled.div`
    order: 2;
`;

export const QueryByLimitId = styled.div`
    order: 1;
`;

export const WrapperLimits = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
