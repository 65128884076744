import React from 'react';

export const walletIdSearchPlaceholder = 'Search by wallet id';
export const addressSearchPlaceholder = 'Search by address';
export const transactionIdSearchPlaceholder = 'Find transaction by id and go to more info';
export const infoTitle = 'Total: ';

export const STEP = 1;
export const MIN = 0;
export const MAX = 6;

export const sectionMarginBottom = '31px';
export const primaryMarginBottom = '15px';
export const filterMarginRight = '30px';
export const filterMarginBottom = '22px';

export const rangeWrapperMobileStyles: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '27px',
    marginBottom: '15px',
    padding: '0 16px'
};

export const rangeWrapperStyles: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '27px'
};

export const thumbWrapperStyles: React.CSSProperties = {
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export const thumbLabelStyles: React.CSSProperties = {
    position: 'absolute',
    top: '-34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 'normal',
    fontSize: '13px',
    fontFamily: 'inherit',
    width: '54px',
    height: '24px',
    border: '1px solid #40556F',
    borderRadius: '2px',
    backgroundColor: 'transparent',
    opacity: 0.8
};

export const thumbStyles: React.CSSProperties = { height: '8px', width: '8px', borderRadius: '50%' };

export const trackWrapperStyles: React.CSSProperties = { height: '36px', display: 'flex', width: '100%' };

export const trackStyles: React.CSSProperties = {
    height: '5px',
    width: '100%',
    borderRadius: '2px',
    alignSelf: 'center'
};
