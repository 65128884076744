import { Row } from 'components/grid/wrappers/flexWrapper';
import { PoppinsFontFamily } from 'constants/styles/fonts';
import styled from 'styled-components';

import {
    purple,
    white,
    grey2,
    blue,
    darkGrey4,
    darkGrey3,
    textCurrentFontSize,
    textInfoFontSize,
    borderRadius
} from 'constants/styles';
import { statusColors } from './constants';
import { device } from 'constants/styles/media';

export const ContainerWrapper = styled(Row)`
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${grey2};
    border-radius: 8px;
    margin-bottom: 16px;
    padding-top: 12px;
    padding-bottom: 32px;
    row-gap: 12px;

    &:not(:last-of-type) {
        margin-right: 16px;
    }
`;

export const RowWrapper = styled(Row)`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    margin-left: 12px;
    margin-right: 12px;

    @media ${device.tablet} {
        justify-content: none;
    }
`;

export const TimeWrapper = styled.div`
    display: flex;
    column-gap: 12px;
    align-items: center;
`;

export const DataWrapper = styled.span`
    color: ${darkGrey3};
`;

export const ValueWrapper = styled.div`
    display: flex;
    color: ${white};
    column-gap: 4px;
    align-items: center;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid ${darkGrey4};
`;

export const TableHeader = styled.th`
    display: flex;
    justify-content: center;
    padding-top: 8px;
    margin-bottom: 8px;
    color: ${white};
    font-family: ${PoppinsFontFamily};
    font-weight: bold;
    font-size: ${textCurrentFontSize};

    @media ${device.tablet} {
        justify-content: start;
    }
`;

export const TableCell = styled.td`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 12px;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;

    @media ${device.tablet} {
        margin-bottom: 0;
        justify-content: none;
    }
`;

interface Props {
    status: WOM.TransactionStatus;
}

export const StyledStatus = styled.span<Props>`
    color: ${({ status }) => status && statusColors[status]};
`;

export const TableRow = styled.tr`
    display: flex;
    justify-content: space-between;

    @media ${device.tablet} {
        flex-wrap: wrap;
        column-gap: 8px;
    }
`;

export const TransactionLabel = styled.div`
    height: 28px;
    background-color: ${blue};
    color: ${white};
    font-family: ${PoppinsFontFamily};
    font-weight: normal;
    font-size: ${textInfoFontSize};
    border-radius: ${borderRadius};
    border: none;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const Label = styled.div`
    display: block;
    color: ${purple};
    font-family: ${PoppinsFontFamily};
    font-size: ${textCurrentFontSize};
    text-align: center;
`;
