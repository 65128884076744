import { EditButton } from 'components/common/buttons/EditButton';
import { PropertyBlock } from 'components/common/dividers/PropertyBlock';
import { Text } from 'components/common/typography/Text';
import { Section } from 'components/grid/wrappers/flexWrapper/styles';
import { GridWrapper } from 'components/grid/wrappers/GridWrapper';
import { usersLink } from 'constants/routes';
import React, { FC } from 'react';
import { modalEvents } from 'stores/modal';
import { ScrollableTags } from '../ScrollableTags';
import { Grid, StyledCard } from './styles';

const { openEditOrganizationModal } = modalEvents;

export const OrganizationCard: FC<WOM.OrganizationResponse> = ({
    organizationId,
    publicId,
    title,
    walletId,
    mandatoryTags,
    adminIds,
    memberIds,
    origin
}) => {
    const onEditClick = () => {
        openEditOrganizationModal({
            organizationId,
            publicId: publicId || undefined,
            title: title || undefined,
            mandatoryTags: mandatoryTags || undefined,
            origin: origin || undefined,
            walletId: walletId,
            adminIds: adminIds || undefined
        });
    };

    return (
        <StyledCard>
            <Section alignCenter justifyBetween marginBottom="20px">
                <Text size="h4">{title}</Text>
                <EditButton onClick={onEditClick} />
            </Section>
            <GridWrapper columns={2} marginBottom="24px">
                <PropertyBlock
                    copyable
                    subtitle={organizationId}
                    success="You successfully copied Organization id!"
                    title="Organization ID"
                />
                <PropertyBlock
                    copyable
                    subtitle={walletId}
                    success="You successfully copied Wallet id!"
                    title="Wallet ID"
                />
            </GridWrapper>
            <Grid>
                <ScrollableTags data={mandatoryTags} title="Mandatory tags" />
                <ScrollableTags data={adminIds} linkBase={usersLink + '/'} title="Admins" />
                <ScrollableTags data={origin ? [origin] : []} title="Origin" />
                <ScrollableTags data={memberIds} linkBase={usersLink + '/'} title="Members" />
            </Grid>
        </StyledCard>
    );
};
