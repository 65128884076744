import { SimpleTable } from 'components/common/tables/SimpleTable';
import { noContentMessage } from 'constants/notifications';
import { usersLink } from 'constants/routes';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { withdrawalCheckTableColumns } from './constants';
import { Wrapper } from './styles';

export const WithdrawalCheckTable: FC<WOM.CheckWithdrawalResponse> = ({ items }) => {
    const withdrawalCheckData = (items as WOM.CheckWithdrawalResponseItem[])?.map(
        ({ womUserId, remoteId, username, address, walletId, emailAddress, phoneNumber }) => ({
            data: {
                womUserId: womUserId ? (
                    <Link to={usersLink + '/' + womUserId}>{womUserId}</Link>
                ) : (
                    <span>{noContentMessage}</span>
                ),
                remoteId: remoteId || <span>{noContentMessage}</span>,
                username: username || <span>{noContentMessage}</span>,
                address: address || <span>{address}</span>,
                walletId: walletId || <span>{walletId}</span>,
                emailAddress: emailAddress || <span>{emailAddress || phoneNumber}</span>
            }
        })
    );

    return (
        <Wrapper>
            <SimpleTable responsive tbody={withdrawalCheckData} thead={withdrawalCheckTableColumns} />
        </Wrapper>
    );
};
