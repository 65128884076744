import { CopyButton } from 'components/common/buttons/CopyButton';
import { ExternalLinkButton } from 'components/common/buttons/ExternalLinkButton';
import { VideoPlayer } from 'components/common/dynamic/VideoPlayer';
import { Tag } from 'components/common/tags/Tag';
import { Text } from 'components/common/typography/Text';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { formatDate } from 'constants/global';
import { usersLink } from 'constants/routes';
import { format } from 'date-fns';
import { getRedirectUrlToYeayOrBullzVideos } from 'pages/PerformancePayments/PerformancePaymentsSingle/VideoDescription/constants';
import { Activity } from 'pages/Videos/VideoCard/Activity';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Title } from 'types';
import { Copyable, Success } from 'types/interface';

interface DescriptionPropertyProps extends Title, Copyable, Partial<Success> {
    externalLink?: string;
    internalLink?: string;
}

export const DescriptionProperty: FC<DescriptionPropertyProps> = ({
    title,
    subtitle,
    copyable,
    success,
    externalLink,
    internalLink
}) => (
    <div className="description-property">
        <Row className="no-gutters align-items-center">
            <div className="mr-3">
                <span className="title">{title}</span>
            </div>
            <div className="mr-3">
                {internalLink ? (
                    <NavLink className="subtitle" to={internalLink}>
                        {subtitle}
                    </NavLink>
                ) : (
                    <span className="subtitle">{subtitle}</span>
                )}
            </div>
            {copyable && (
                <div>
                    <CopyButton subject={subtitle} success={success} />
                </div>
            )}
            {externalLink && (
                <div>
                    <ExternalLinkButton to={externalLink} />
                </div>
            )}
        </Row>
    </div>
);

export const DescriptionContent: FC<Pick<Title, 'title'>> = ({ children, title }) => (
    <div className="description-content">
        <MarginWrapper marginBottom="16px">
            <Text size="h4">{title}</Text>
        </MarginWrapper>

        <Row>
            <Col>
                <div className="content-wrapper">{children}</div>
            </Col>
        </Row>
    </div>
);

export const VideoDescription: FC<WOM.ContentItemResponse & { products?: any[] }> = ({
    userId,
    streamDetails,
    womContentId,
    remoteContentId,
    title,
    products,
    tags,
    createdUtc,
    uriPrimary,
    womQualityScore = {},
    validationState,
    source
}) => {
    const endedReason = validationState?.endedReason;

    return (
        <Card>
            <CardBody className="video-description">
                <Row>
                    <Col className="video-player-col">
                        <div className="video-player-wrapper">
                            <VideoPlayer streamDetails={streamDetails} uriPrimary={uriPrimary} />
                            <Activity endedReason={endedReason} womQualityScore={womQualityScore} />
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex flex-column">
                            <Row className="video-properties">
                                {title && (
                                    <Col className="col-property">
                                        <DescriptionProperty subtitle={title} title="Title" />
                                    </Col>
                                )}
                                {womContentId && (
                                    <Col className="col-property">
                                        <DescriptionProperty
                                            copyable
                                            subtitle={womContentId}
                                            success="Video Id is successfully copied"
                                            title="ID"
                                        />
                                    </Col>
                                )}
                                {remoteContentId && source && (
                                    <Col className="col-property">
                                        <DescriptionProperty
                                            externalLink={getRedirectUrlToYeayOrBullzVideos(remoteContentId, source)}
                                            subtitle={remoteContentId}
                                            title="Remote Content ID"
                                        />
                                    </Col>
                                )}
                                {userId && (
                                    <Col className="col-property">
                                        <DescriptionProperty
                                            internalLink={`${usersLink}/${userId}`}
                                            subtitle={userId}
                                            title="User ID"
                                        />
                                    </Col>
                                )}
                                {createdUtc && (
                                    <Col className="col-property">
                                        <DescriptionProperty
                                            subtitle={format(new Date(createdUtc), formatDate)}
                                            title="Date of Creation"
                                        />
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col className="description-content-property col-auto">
                                    <DescriptionContent title="Tags">
                                        <Row className="no-gutters">
                                            {tags?.length ? (
                                                tags?.map(tag => (
                                                    <MarginWrapper key={tag} marginBottom="9px" marginRight="12px">
                                                        <Tag color="danger">{tag}</Tag>
                                                    </MarginWrapper>
                                                ))
                                            ) : (
                                                <span>No content</span>
                                            )}
                                        </Row>
                                    </DescriptionContent>
                                </Col>
                                <Col className="description-content-property col-auto">
                                    <DescriptionContent title="Product">
                                        <Row className="no-gutters">
                                            {products?.length ? products.join(', ') : <span>No content</span>}
                                        </Row>
                                    </DescriptionContent>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
