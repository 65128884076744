export const registerOrganizationSuccessMessage =
    'You successfully created an organization and sent an invitation to a user';
export const editSuccessMessage = 'Editing was successful!';

export const requiredFieldMessage = 'This field is required';
export const errorDataMessage = 'Your data is incorrect!';
export const errorEmptyMessage = 'Fill the fields!';
export const errorNotEntryAllowed = 'You have no rights to enter!';
export const asyncError = 'Something was wrong!';

export const noContentMessage = 'no content';
