import filedIcon from 'assets/img/failed_transaction.svg';
import classNames from 'classnames';
import { CopyButton } from 'components/common/buttons/CopyButton';
import { TransactionInProgress } from 'components/common/dynamic/Loader';
import { SimpleTable } from 'components/common/tables/SimpleTable';
import { Text } from 'components/common/typography/Text';
import { womNarrativeTypeObject } from 'constants/filters';
import { noContentMessage } from 'constants/notifications';
import { purple, textInfoColor } from 'constants/styles';
import React from 'react';
import { getEllipsisAddress, parseCalendarDate } from 'utils/usefulFunctions';
import { transactionTableColumns } from './constants';

export const TransactionDescriptionTable = ({
    transactionId,
    utcCreated,
    type,
    status,
    from,
    to,
    valueWei,
    value
}: WOM.TransactionResponse) => {
    const tableData = [
        {
            data: {
                field: (
                    <Text color={purple} size="p">
                        Transactions ID:
                    </Text>
                ),
                value: transactionId || '',
                action: <CopyButton subject={transactionId} success="You successfully copied transaction id!" />
            }
        },
        {
            data: {
                field: (
                    <Text color={purple} size="p">
                        Created at:
                    </Text>
                ),
                value: utcCreated ? (
                    parseCalendarDate(new Date(utcCreated))
                ) : (
                    <Text color={textInfoColor} size="p">
                        {noContentMessage}
                    </Text>
                ),
                action: ''
            }
        },
        {
            data: {
                field: (
                    <Text color={purple} size="p">
                        Type:
                    </Text>
                ),
                value:
                    type !== undefined ? (
                        womNarrativeTypeObject[type]
                    ) : (
                        <Text color={textInfoColor} size="p">
                            {noContentMessage}
                        </Text>
                    ),
                action: ''
            }
        },
        {
            data: {
                field: (
                    <Text color={purple} size="p">
                        Status:
                    </Text>
                ),
                value:
                    status === 1 ? (
                        <TransactionInProgress />
                    ) : status === 2 ? (
                        <i className={classNames('tim-icons', 'icon-check-2')} />
                    ) : (
                        <img alt="failed icon" height="10px" src={filedIcon} width="10px" />
                    ),
                action: ''
            }
        },
        {
            data: {
                field: (
                    <Text color={purple} size="p">
                        Address from:
                    </Text>
                ),
                value: from ? (
                    getEllipsisAddress(from)
                ) : (
                    <Text color={textInfoColor} size="p">
                        {noContentMessage}
                    </Text>
                ),
                action: from ? <CopyButton subject={from} success="You successfully copied address from!" /> : ''
            }
        },
        {
            data: {
                field: (
                    <Text color={purple} size="p">
                        Address to:
                    </Text>
                ),
                value: to ? (
                    getEllipsisAddress(to)
                ) : (
                    <Text color={textInfoColor} size="p">
                        {noContentMessage}
                    </Text>
                ),
                action: to ? <CopyButton subject={to} success="You successfully copied address to!" /> : ''
            }
        },
        {
            data: {
                field: (
                    <Text color={purple} size="p">
                        WEI value:
                    </Text>
                ),
                value: valueWei ? (
                    valueWei.toString()
                ) : (
                    <Text color={textInfoColor} size="p">
                        {noContentMessage}
                    </Text>
                ),
                action: ''
            }
        },
        {
            data: {
                field: (
                    <Text color={purple} size="p">
                        Value:
                    </Text>
                ),
                value: value ? (
                    value.toString()
                ) : (
                    <Text color={textInfoColor} size="p">
                        {noContentMessage}
                    </Text>
                ),
                action: ''
            }
        }
    ];

    return (
        <>
            <SimpleTable responsive tbody={tableData} thead={transactionTableColumns} />
        </>
    );
};
