import { Loader } from 'components/common/dynamic/Loader';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/layouts/Empty';
import { useStore } from 'effector-react';
import { PerformancePaymentsExpandedTable } from 'pages/PerformancePayments/PerformancePaymentsExpandedTable';
import { PerformancePaymentsFilterLayout } from 'pages/PerformancePayments/PerformancePaymentsFilterLayout';
import React from 'react';
import { Row } from 'reactstrap';
import { performancePaymentsStores } from 'stores/performancePayments';

export const PerformancePayments = () => {
    const { totalRecords, items } = useStore(performancePaymentsStores.items);
    const loading = useStore(performancePaymentsStores.loading);

    return (
        <MainLayout>
            <PerformancePaymentsFilterLayout totalRecords={totalRecords}>
                {loading ? (
                    <Section alignCenter justifyCenter height="100%">
                        <Loader />
                    </Section>
                ) : (
                    <>
                        {items?.length ? (
                            <Row>
                                <PerformancePaymentsExpandedTable items={items} />
                            </Row>
                        ) : (
                            <Section alignCenter justifyCenter height="100%">
                                <Empty description="Performance Payments not found" />
                            </Section>
                        )}
                    </>
                )}
            </PerformancePaymentsFilterLayout>
        </MainLayout>
    );
};
