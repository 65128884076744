import Chart from 'chart.js';
import { formatEngagementStatisticsValues } from 'utils/usefulFunctions';

export const externalTransactionsTypes = [
    'deposit',
    'withdrawal',
    'giftCardPayment',
    'giftCardRefund',
    'organizationPurchase'
];

export const transactionTypeOptions = [
    {
        value: 'Internal',
        label: 'Internal'
    },
    {
        value: 'External',
        label: 'External'
    }
];

export const YEAYServiceUserId = 'ad94b0844c8c81e70ad7ad93';

export const defaultInputState = {
    userId: undefined,
    remoteContentId: undefined,
    contentId: undefined
};

export interface QueryEarningStatisticsByIds
    extends Pick<WOM.EarningsStatisticsQueryRequest, 'userId' | 'contentId' | 'remoteContentId'> {}

export const searchByParameters: Array<{ key: keyof QueryEarningStatisticsByIds; name: string }> = [
    {
        key: 'userId',
        name: 'User ID'
    },
    {
        key: 'contentId',
        name: 'Content ID'
    },
    {
        key: 'remoteContentId',
        name: 'Remote Content ID'
    }
];

interface GraphsKey {
    description?: string;
}

export const graphsKeys: { [key in keyof WOM.EarningStatisticsValues]: GraphsKey } = {
    performancePaymentCreator: {
        description: 'Amount made minus the stake'
    },
    performancePaymentAuthenticator: undefined,
    performancePaymentFacilitator: undefined,
    creatorBonus: undefined,
    validationReward: undefined,
    validationProfit: undefined,
    validationStakeRefund: undefined,
    creatorStakeRefund: undefined,
    none: undefined,
    deposit: undefined,
    withdrawal: undefined,
    creatorStake: undefined,
    creatorReward: undefined,
    validationStake: undefined,
    exchange: undefined,
    userTransfer: undefined,
    reward: undefined,
    migrationSync: undefined,
    campaignPayment: undefined,
    performancePayment: undefined,
    performancePaymentPublisher: undefined,
    performancePaymentDisplayNetwork: undefined,
    performancePaymentAdvertiser: undefined,
    performancePaymentBrand: undefined,
    giftCardPayment: undefined,
    giftCardRefund: undefined
};

const codeDictionary: WOM.EarningStatisticsValues = {
    deposit: 100, // 0x00000064
    withdrawal: 200, // 0x000000C8
    creatorStake: 300, // 0x0000012C
    creatorReward: 301, // 0x0000012D
    creatorStakeRefund: 302, // 0x0000012E
    creatorBonus: 303, // 0x0000012F
    validationStake: 400, // 0x00000190
    validationReward: 401, // 0x00000191
    validationStakeRefund: 402, // 0x00000192
    validationProfit: 410, // 0x0000019A
    exchange: 500, // 0x000001F4
    userTransfer: 600, // 0x00000258
    reward: 700, // 0x000002BC
    migrationSync: 800, // 0x00000320
    campaignPayment: 900, // 0x00000384
    performancePayment: 1000, // 0x000003E8
    performancePaymentCreator: 1001, // 0x000003E9
    performancePaymentAuthenticator: 1002, // 0x000003EA
    performancePaymentFacilitator: 1003, // 0x000003EB
    performancePaymentPublisher: 1004, // 0x000003EC
    performancePaymentDisplayNetwork: 1005, // 0x000003ED
    performancePaymentAdvertiser: 1006, // 0x000003EE
    performancePaymentBrand: 1007, // 0x000003EF
    giftCardPayment: 2000, // 0x000007D0
    giftCardRefund: 2100, // 0x00000834};
    organizationPurchase: 3000
};
export const getEarningsStatisticByKey = (
    data: WOM.EarningStatisticsDailyItem[],
    key: keyof WOM.EarningStatisticsValues
) => {
    const code = codeDictionary[key];
    const codeIsNumber = typeof code === 'number' ? code : 0;

    return data?.map(({ values }) => {
        const item = values?.find(it => it.code === codeIsNumber);

        if (item) {
            return typeof item.value === 'number' ? item.value : 0;
        } else {
            return 0;
        }
    });
};

export const graphsColor = [
    {
        yGridLinesColor: 'rgba(29,140,248,0.0)',
        xGridLinesColor: 'rgba(29,140,248,0.1)',
        borderColor: '#1f8ef1',
        pointColor: '#1f8ef1',
        gradientColor: {
            start: 'rgba(29,140,248,0.2)',
            middle: 'rgba(29,140,248,0.0)',
            stop: 'rgba(29,140,248,0)'
        }
    },
    {
        yGridLinesColor: 'rgba(225,78,202,0.0)',
        xGridLinesColor: 'rgba(225,78,202,0.1)',
        borderColor: '#d048b6',
        pointColor: '#be55ed',
        gradientColor: {
            start: 'rgba(72,72,176,0.1)',
            middle: 'rgba(72,72,176,0.0)',
            stop: 'rgba(119,52,169,0)'
        }
    },
    {
        yGridLinesColor: 'rgba(0,242,195,0.0)',
        xGridLinesColor: 'rgba(29,140,248,0.1)',
        borderColor: '#00d6b4',
        pointColor: '#00d6b4',
        gradientColor: {
            start: 'rgba(66,134,121,0.15)',
            middle: 'rgba(66,134,121,0.0)',
            stop: 'rgba(66,134,121,0)'
        }
    },
    {
        yGridLinesColor: 'rgba(0,242,195,0.0)',
        xGridLinesColor: 'rgba(237,38,14,0.1)',
        borderColor: '#FD5D93',
        pointColor: '#FD5D93',
        gradientColor: {
            start: 'rgba(237,38,14,0.2)',
            middle: 'rgba(237,38,14,0.1)',
            stop: 'rgba(237,38,14,0.0)'
        }
    },
    {
        yGridLinesColor: 'rgba(255,141,114,0.0)',
        xGridLinesColor: 'rgba(255,141,114,0.1)',
        borderColor: '#FF8D72',
        pointColor: '#FF8D72',
        gradientColor: {
            start: 'rgba(255,141,114,0.15)',
            middle: 'rgba(255,141,114,0.0)',
            stop: 'rgba(255,141,114,0)'
        }
    }
];

export const chartsOptions: (colors: typeof graphsColor[0]) => Chart.ChartOptions = ({
    xGridLinesColor,
    yGridLinesColor
}) => ({
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    tooltips: {
        backgroundColor: '#fff',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: false,
        position: 'nearest',
        callbacks: {
            label: (tooltipItem, data) =>
                (data.datasets ? data.datasets[0].label : 'Default Label') +
                ': ' +
                formatEngagementStatisticsValues(tooltipItem.yLabel as number)
        }
    },
    responsive: true,
    scales: {
        yAxes: [
            {
                // barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: yGridLinesColor,
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    suggestedMin: 5,
                    suggestedMax: 10,
                    padding: 20,
                    fontColor: '#9e9e9e',
                    callback: value => formatEngagementStatisticsValues(value as number)
                }
            }
        ],
        xAxes: [
            {
                // barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: xGridLinesColor,
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    padding: 20,
                    fontColor: '#9e9e9e'
                }
            }
        ]
    }
});

export const chartsData = (
    { label, data, labels }: { labels: string[]; data: number[]; label: string },
    { gradientColor, borderColor, pointColor }: typeof graphsColor[0]
) => (canvas: HTMLElement) => {
    let context = (canvas as HTMLCanvasElement).getContext('2d');

    let gradientStroke = context?.createLinearGradient(0, 230, 0, 50);

    gradientStroke?.addColorStop(1, gradientColor.start);
    gradientStroke?.addColorStop(0.4, gradientColor.middle);
    gradientStroke?.addColorStop(0, gradientColor.stop); //blue colors

    return {
        labels,
        datasets: [
            {
                label: label || 'Chart',
                fill: true,
                backgroundColor: gradientStroke,
                borderColor,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: pointColor,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: pointColor,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data
            }
        ]
    };
};

export const charts = (data: { labels: string[]; data: number[]; label: string }, colors: typeof graphsColor[0]) => ({
    options: chartsOptions(colors),
    data: chartsData(data, colors)
});
