import { DataRangePicker } from 'components/common/inputs/DataPicker';
import { Switch } from 'components/common/inputs/Switch';
import { Span } from 'components/common/typography/Span';
import {
    charts,
    getEarningsStatisticByKey,
    graphHeight,
    graphsColor,
    graphsKeys,
    graphWidth
} from 'components/grid/DashboardSingle/constants';
import { Column, Row, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { GraphCard } from 'components/layouts/cards/GraphCard';
import { defaultUserStatisticsQuery } from 'constants/defaults/earningsStatistics';
import { md, white, xs } from 'constants/styles';
// core components
import { useStore } from 'effector-react';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
// reactstrap components
import { catalogueEvents, catalogueStores } from 'stores/catalogue';
import { FilterWrapper, ToggleButton } from './styles';

const { updateUserValues } = catalogueEvents;

interface DashboardSingleProps {
    contentId?: string;
    userId?: string;
}

export const DashboardSingle = ({ contentId, userId }: DashboardSingleProps) => {
    const { items } = useStore(catalogueStores.userStatistics);
    const { dateTo, dateFrom } = useStore(catalogueStores.userValues);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const isTablet = useMediaQuery({ query: `(max-width: ${md})` });
    const [groupByWeekChecked, setGroupByWeekChecked] = useState(false);

    const onGroupByWeekCheck = (e: ChangeEvent<HTMLInputElement>) => {
        setGroupByWeekChecked(prev => !prev);
        updateUserValues({ groupByWeek: e.target.checked });
    };

    const onDateRangeChange = ([dateFrom, dateTo]: [Moment, Moment]) => {
        if (dateFrom.isSameOrAfter(dateTo, 'day')) {
            updateUserValues({
                dateFrom: dateFrom.toISOString(),
                dateTo: dateTo.add(7, 'day').toISOString()
            });
        }
        updateUserValues({ dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString() });
    };

    const labels = items?.map(({ date }) => moment(date).format('DD-MM-YYYY'));

    const graphsData = items
        ? Object.keys(graphsKeys)
              .map(key => {
                  const data = getEarningsStatisticByKey(items, key as keyof WOM.EarningStatisticsValues);
                  return {
                      data,
                      key,
                      title: _.startCase(key),
                      total: data.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                  };
              })
              .filter(({ total }) => total !== 0)
        : [];

    useEffect(() => {
        updateUserValues({ ...defaultUserStatisticsQuery, contentId, userId });
    }, [contentId, userId]);

    return (
        <>
            <Column marginBottom="30px" width="100%">
                <MarginWrapper marginBottom="15px">
                    <Span color={white} fontSize="17px" lineHeight="25px">
                        Select Date Range
                    </Span>
                </MarginWrapper>
                <FilterWrapper>
                    <MarginWrapper marginBottom={isMobile ? '20px' : '0'} marginRight={isMobile ? '30px' : '15px'}>
                        <DataRangePicker
                            value={[new Date(dateFrom || ''), new Date(dateTo || '')]}
                            onChange={onDateRangeChange}
                        />
                    </MarginWrapper>

                    <ToggleButton>
                        <Switch
                            checked={groupByWeekChecked}
                            label="Group By Week"
                            labelPosition="left"
                            onChange={onGroupByWeekCheck}
                        />
                    </ToggleButton>
                </FilterWrapper>
            </Column>
            <Section justifyBetween>
                {items &&
                    labels &&
                    graphsData.map(({ data, total, title, key }, index) => {
                        const colorIndex = index % graphsColor.length;
                        const color = graphsColor[colorIndex].pointColor;
                        const description = graphsKeys[key as keyof WOM.EarningStatisticsValues]?.description;

                        const { data: dataGraph, options } = charts(
                            { data, labels, label: title },
                            graphsColor[colorIndex]
                        );
                        return (
                            <Row key={key} height={graphHeight} width={isTablet ? '100%' : graphWidth}>
                                <GraphCard
                                    color={color}
                                    dataGraph={dataGraph}
                                    description={description}
                                    options={options}
                                    title={title}
                                    total={total}
                                />
                            </Row>
                        );
                    })}
            </Section>
        </>
    );
};
