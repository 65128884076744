import { defaultEarningsStatisticsQuery, defaultUserStatisticsQuery } from 'constants/defaults/earningsStatistics';
import { set } from 'date-fns';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'services';
import { notificationEvents } from 'stores/notification';

const updateValues = createEvent<WOM.EarningsStatisticsQueryRequest>();
const updateUserValues = createEvent<WOM.EarningsStatisticsQueryRequest>();

const values = createStore<WOM.EarningsStatisticsQueryRequest>(defaultEarningsStatisticsQuery).on(
    updateValues,
    (state, newValues) => ({ ...state, ...newValues })
);

const userValues = createStore<WOM.EarningsStatisticsQueryRequest>(defaultUserStatisticsQuery).on(
    updateUserValues,
    (state, newValues) => ({
        ...state,
        ...newValues
    })
);

const queryEarningsStatistics = createEffect({
    handler: async (data: WOM.EarningsStatisticsQueryRequest) => {
        try {
            const { dateTo, dateFrom } = data;
            /* Normalize the request data for query (statistics should be obtained from 00:00:00 to 23:59:59) */
            const normalizedRequestData = {
                ...data,
                dateFrom: set(new Date(dateFrom || ''), {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    milliseconds: 0
                }).toISOString(),
                dateTo: set(new Date(dateTo || ''), {
                    hours: 23,
                    minutes: 59,
                    seconds: 59,
                    milliseconds: 0
                }).toISOString()
            };
            return await API.catalogue.queryEarningsStatistics(normalizedRequestData);
        } catch (error) {
            const message = error.message;
            message && notificationEvents.setNotification({ message });
            return {};
        }
    }
});

const earningsStatistics = createStore<WOM.EarningsStatisticsQueryResponse>({}).on(
    queryEarningsStatistics.doneData,
    (_, statistics) => statistics
);

const queryUserStatistics = createEffect({
    handler: async (data: WOM.EarningsStatisticsQueryRequest) => {
        try {
            const { dateTo, dateFrom } = data;
            /* Normalize the request data for query (statistics should be obtained from 00:00:00 to 23:59:59) */
            const normalizedRequestData = {
                ...data,
                dateFrom: set(new Date(dateFrom || ''), {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    milliseconds: 0
                }).toISOString(),
                dateTo: set(new Date(dateTo || ''), {
                    hours: 23,
                    minutes: 59,
                    seconds: 59,
                    milliseconds: 0
                }).toISOString()
            };
            return await API.catalogue.queryEarningsStatistics(normalizedRequestData);
        } catch (error) {
            const message = error.message;
            message && notificationEvents.setNotification({ message });
            return {};
        }
    }
});

const userStatistics = createStore<WOM.EarningsStatisticsQueryResponse>({}).on(
    queryUserStatistics.doneData,
    (_, statistics) => statistics
);

forward({ from: [values], to: [queryEarningsStatistics] });
forward({ from: [userValues], to: [queryUserStatistics] });

export const catalogueStores = { earningsStatistics, values, userStatistics, userValues };
export const catalogueEvents = { updateValues, updateUserValues };
export const catalogueEffects = { queryEarningsStatistics, queryUserStatistics };
