import { disableDefaultButtonStyleMixin, flexCenter, propertyTitleColor } from 'constants/styles';
import styled from 'styled-components';

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};

    width: 40px;
    height: 30px;
    color: rgba(255, 255, 255, 0, 35);
    transition: color 0.3s;

    :hover {
        color: ${propertyTitleColor};
    }
`;
