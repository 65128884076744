import axios, { CancelTokenSource } from 'axios';
import history from 'browserHistory';
import { defaultUserItem, defaultUserSearchByParameterValues, defaultUsersValues } from 'constants/defaults';
import { usersLink } from 'constants/routes';
import { createEffect, createEvent, createStore, forward } from 'effector';
import _ from 'lodash';
import { API } from 'services';
import { initializeIsFirstStore } from 'stores/initialize/initialize.isFirst.store';
import { initializeToggleStore } from 'stores/initialize/initialize.toggle.store';
import { notificationEvents } from 'stores/notification';

let cancelToken: CancelTokenSource | undefined;

const [initialLoading, updateInitialLoading] = initializeToggleStore();
const [loading, updateLoading] = initializeToggleStore();

const updateValues = createEvent<Partial<UserQueryValues>>();
const overrideValues = createEvent<Partial<UserQueryValues>>();
const setDefaultValues = createEvent();
const invokeGetItems = createEvent();

const getSingleItemById = createEffect({
    handler: async (id: string) => {
        try {
            updateLoading();
            const data = await API.adminUsers.getItemById({ userId: id });
            updateLoading();

            return data;
        } catch {
            updateLoading();
            return defaultUserItem;
        }
    }
});

const getItemById = createEffect({
    handler: async (id: string) => {
        try {
            updateLoading();
            const data = await API.adminUsers.getItemById({ userId: id });
            updateLoading();

            const items = data ? [data] : [];

            return {
                currentPageIndex: 0,
                items,
                totalPages: items.length,
                totalRecords: items.length
            };
        } catch {
            updateLoading();
            return {
                currentPageIndex: 0,
                totalPages: 0,
                totalRecords: 0
            };
        }
    }
});

const getIdAndRedirectToSinglePage = createEffect({
    handler: async (id: string) => {
        try {
            updateLoading();
            const data = await API.adminUsers.getItemById({ userId: id });
            updateLoading();

            data?.userId
                ? history.push(usersLink + '/' + data.userId)
                : notificationEvents.setNotification({
                      place: 'tc',
                      message: "Such user doesn't exist",
                      type: 'danger',
                      icon: 'tim-icons icon-bell-55',
                      autoDismiss: 5
                  });

            return data;
        } catch {
            notificationEvents.setNotification({
                place: 'tc',
                message: "Such user doesn't exist",
                type: 'danger',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });

            updateLoading();
            return {};
        }
    }
});

const getItems = createEffect({
    handler: async (values: WOM.UserQueryRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateInitialLoading();
            const data = await API.adminUsers.getItems(values, cancelToken.token);
            updateInitialLoading();

            return data ? data : {};
        } catch {
            updateInitialLoading();
            return {};
        }
    }
});

const disableUser = createEffect({
    handler: async (values: WOM.UserDisableRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateInitialLoading();
            const data = await API.adminUsers.disableUserById(values);
            updateInitialLoading();

            // TODO fix types

            return { ...data, ...values } as WOM.UserDisableRequest;
        } catch {
            updateInitialLoading();
            return {} as WOM.UserDisableRequest;
        }
    }
});

const manageUserRole = createEffect({
    handler: async (values: WOM.UserRoleChangeRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateInitialLoading();
            const response = await API.adminUsers.manageUserRoleById(values);
            updateInitialLoading();

            invokeGetItems();

            return response;
        } catch {
            updateInitialLoading();
            return {};
        }
    }
});

const item = createStore<WOM.GetUserResponse>(defaultUserItem).on(getItemById.doneData, (_, { items }) =>
    items && items.length > 0 ? items[0] : defaultUserItem
);
const items = createStore<WOM.UserQueryResponse>({})
    .on(getItems.doneData, (_, newState) => newState)
    .on(getItemById.doneData, (_, newState) => newState)
    .on(disableUser.doneData, (prevState, newState) => ({
        ...prevState,
        items: prevState?.items?.map(item => {
            if (newState.userId === item.userId) {
                return { ...item, isDisabled: newState.isDisabled };
            }
            return item;
        })
    }));

export interface UserQueryValues extends WOM.UserQueryRequest, Pick<WOM.GetUserRequest, 'userId'> {}

const getUserItems = createEffect({
    handler: async ({ userId, ...itemsQueryParameters }: UserQueryValues) => {
        userId ? getItemById(userId) : getItems(itemsQueryParameters);
    }
});

const { isFirst, setIsFirstToFalse, setIsFirstToTrue } = initializeIsFirstStore();

// values store keeps request values,
// after updating or removing some fields of the values,
// watcher initiate getItems request due the new values
// (old fields of values are not removed if they are not pointed as remove values in removeAndUpdateValues event)
const values = createStore<UserQueryValues>(defaultUsersValues)
    .on(updateValues, (state, values) => {
        /* if the request was made for parameters that should be mutually exclusive, then they will be overwritten. If not, then the request will continue with the old parameters plus new ones  */
        const keysOfValues = Object.keys(values) as Array<keyof UserQueryValues>;
        const keysOfDefaultSearchByParameterValues = Object.keys(defaultUserSearchByParameterValues) as Array<
            keyof UserQueryValues
        >;

        const isQueryByDefaultSearchParameter = keysOfValues.some(valuesKey =>
            keysOfDefaultSearchByParameterValues.some(defaultValuesKey => defaultValuesKey === valuesKey)
        );

        if (isQueryByDefaultSearchParameter) {
            return { ...state, ...defaultUserSearchByParameterValues, ...values };
        } else {
            return { ...state, ...values };
        }
    })
    .on(overrideValues, (_, values) => ({ ...defaultUsersValues, ...values }))
    .on(setDefaultValues, () => defaultUsersValues)
    .on(invokeGetItems, state => state);

forward({
    from: [values],
    to: [getUserItems]
});
values.watch(invokeGetItems, state => getUserItems(state));

const setId = createEvent<string>();
const getRequestId = createStore<string>('').on(setId, (_, id) => id);

const generateUserReport = createEffect({
    handler: async (userId: string): Promise<WOM.UserReport> => {
        //TODO: Improve this all logic
        const { email, walletId, utcCreated } = await API.adminUsers.getItemById({ userId });

        const { items } = await API.wallet.getItem({ walletId });
        const [{ balance: privateBalance }, { balance: rPBalance }] = items ? items : [{ balance: 0 }, { balance: 0 }];

        const defaultQueryParams = { limit: 100, returnQueryCount: true };

        const {
            totalRecords: totalCountDeposit,
            totalPages: totalDepositPages,
            items: itemsDeposit
        } = await API.transactions.getItems({
            walletId,
            type: 1,
            pageIndex: 0,
            ...defaultQueryParams
        });

        let unionItemsDeposit = itemsDeposit ? [...itemsDeposit] : [];

        if (totalDepositPages) {
            for (let i = 1; i < totalDepositPages; i++) {
                const { items } = await API.transactions.getItems({
                    walletId,
                    type: 1,
                    pageIndex: i,
                    ...defaultQueryParams
                });
                unionItemsDeposit = items ? [...unionItemsDeposit, ...items] : [...unionItemsDeposit];
            }
        }

        const {
            totalRecords: totalCountWithdrawals,
            totalPages: totalWithdrawalsPages,
            items: itemsWithdrawals
        } = await API.transactions.getItems({
            walletId,
            type: 2,
            pageIndex: 0,
            ...defaultQueryParams
        });

        let unionWithdrawalsDeposit = itemsWithdrawals ? [...itemsWithdrawals] : [];

        if (totalWithdrawalsPages) {
            for (let i = 1; i < totalWithdrawalsPages; i++) {
                const { items } = await API.transactions.getItems({
                    walletId,
                    type: 2,
                    pageIndex: i,
                    ...defaultQueryParams
                });
                unionWithdrawalsDeposit = items ? [...unionWithdrawalsDeposit, ...items] : [...unionWithdrawalsDeposit];
            }
        }

        const totalAmountDeposit = unionItemsDeposit.reduce((prev, { value }) => prev + (value || 0), 0);
        const totalAmountWithdrawals = unionWithdrawalsDeposit.reduce((prev, { value }) => prev + (value || 0), 0);

        const depositAddressTo = _.uniq(unionItemsDeposit.map(({ to }) => to || ''));
        const withdrawalAddressTo = _.uniq(unionWithdrawalsDeposit.map(({ to }) => to || ''));
        const emailIsString = typeof email === 'string' ? email : '';

        return {
            email: emailIsString,
            createdAt: utcCreated,
            userId,
            walletId,
            rPBalance: rPBalance || 0,
            privateBalance: privateBalance || 0,
            totalCountDeposit: totalCountDeposit || 0,
            totalAmountDeposit: totalAmountDeposit || 0,
            totalCountWithdrawals: totalCountWithdrawals || 0,
            totalAmountWithdrawals: totalAmountWithdrawals || 0,
            internalAddress: depositAddressTo || [],
            withdrawalAddress: withdrawalAddressTo || []
        };
    }
});

const getWithdrawalByAddressTo = createEffect({
    handler: async (addressTo: string) => {
        const { items } = await API.adminUsers.getWithdrawalCheck({ addressTo });

        return { addressTo, items };
    }
});

interface WithdrawalCheckResponse extends WOM.CheckWithdrawalResponse, WOM.CheckWithdrawalRequest {}

const withdrawalCheck = createStore<WithdrawalCheckResponse>({}).on(
    getWithdrawalByAddressTo.doneData,
    (_, newState) => newState
);

const userReport = createStore<Partial<WOM.UserReport>>({}).on(generateUserReport.doneData, (_, newState) => newState);

const getAuditItems = createEffect({
    handler: async (userId: string) => {
        try {
            return await API.adminUsers.getAudit({ userId });
        } catch (e) {
            // @ts-ignore
            // TODO fix types
            const errorMessage = e.message;
            errorMessage && notificationEvents.setNotification({ message: errorMessage, autoDismiss: 2 });
        }
    }
});

const auditUser = createStore<WOM.UserAuditResponse>({}).on(getAuditItems.doneData, (_, newState) => newState);

const usersEvents = {
    updateValues,
    overrideValues,
    setDefaultValues,
    setIsFirstToTrue,
    setIsFirstToFalse,
    setId,
    invokeGetItems
};
const usersEffects = {
    getItemById,
    getSingleItemById,
    getIdAndRedirectToSinglePage,
    generateUserReport,
    getWithdrawalByAddressTo,
    getAuditItems,
    getItems,
    disableUser,
    manageUserRole
};
const usersStores = {
    getRequestId,
    items,
    item,
    isFirst,
    initialLoading,
    loading,
    values,
    userReport,
    withdrawalCheck,
    auditUser
};

export { usersEvents, usersEffects, usersStores };
