export const size = {
    mobile: '460px',
    tablet: '767px',
    laptop: '991px',
    desktop: '1199px',
    bigScreen: '1379px'
};

export const device = {
    smallMobile: `(max-width: ${size.mobile})`,
    mobile: `(max-width: ${size.tablet})`,
    tablet: `(max-width: ${size.laptop})`,
    laptop: `(max-width: ${size.desktop})`,
    desktop: `(max-width: ${size.bigScreen})`
};
