import React, { useEffect, useRef } from 'react';
import NotificationAlert, { AlertOptions } from 'react-notification-alert';
import { useStore } from 'effector-react';
import { notificationStores } from 'stores/notification';

export const Notification = () => {
    const notificationAlertRef = useRef<NotificationAlert | null>(null);
    const notificationOptions = useStore(notificationStores.notificationOptions);

    useEffect(() => {
        notificationAlertRef.current &&
            notificationOptions.message &&
            notificationAlertRef.current.notificationAlert(notificationOptions as AlertOptions);
    }, [notificationOptions]);

    return (
        <div className="rna-container">
            <NotificationAlert ref={notificationAlertRef} />
        </div>
    );
};
