import { Row, Section } from 'components/grid/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const ButtonsWrapper = styled(Row)`
    width: 100%;
    margin-bottom: 16px;
    justify-content: flex-end;
    align-items: center;

    @media ${device.smallMobile} {
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
`;

export const TimelineWrapper = styled.div`
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;

    @media ${device.laptop} {
        width: 100%;
        max-width: 640px;
    }
`;

export const BreadcrumbWrapper = styled(Section)`
    align-items: center;
    margin-top: 0;

    @media ${device.smallMobile} {
        margin-top: 15px;
    }
`;
