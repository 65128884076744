import React from 'react';
import { MainLayout } from 'components/layouts/MainLayout';
import { Text } from 'components/common/typography/Text';
import { homeLink } from 'constants/routes';
import { ForbiddenIcon } from 'assets/img';
import { Column, Section } from 'components/grid/wrappers/flexWrapper';
import { ButtonLink } from 'components/common/buttons/ButtonLink';

export const Error403 = () => (
    <MainLayout>
        <Column alignCenter justifyCenter marginTop="50px" width="100%">
            <ForbiddenIcon />

            <Section justifyCenter marginBottom="50px" marginTop="40px">
                <Text alignTextCenter size="h3">
                    Sorry, you are not allowed to access this action.
                </Text>
            </Section>

            <ButtonLink noWrap href={homeLink}>
                Back Home
            </ButtonLink>
        </Column>
    </MainLayout>
);
