import axios, { CancelTokenSource } from 'axios';
import { defaultTransactionsValues } from 'constants/defaults/transactions';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

let cancelToken: CancelTokenSource | undefined;

const updateInitialLoading = createEvent();
const setInitialLoading = createEvent<boolean>();

const initialLoading = createStore<boolean>(false)
    .on(updateInitialLoading, state => !state)
    .on(setInitialLoading, (_, state) => state);

const updateLoading = createEvent();
const loading = createStore<boolean>(false).on(updateLoading, loading => !loading);

const getItems = createEffect({
    handler: async (values: WOM.TransactionQueryRequestValues) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateInitialLoading();
            const data = await API.transactions.getItems(values, cancelToken.token);
            updateInitialLoading();

            return data ? data : {};
        } catch {
            updateInitialLoading();
            return {};
        }
    }
});

const sortAscending = createEvent();
const sortDescending = createEvent();
const sortByDate = createEvent();

const items = createStore<WOM.TransactionQueryResponse>({})
    .on([getItems.doneData], (_, newState) => newState)
    .on(sortAscending, state => {
        const items = [...(state.items as WOM.TransactionResponse[])];
        return {
            ...state,
            items: items.sort((a, b) => (a.value as number) - (b.value as number))
        };
    })
    .on(sortDescending, state => {
        const items = [...(state.items as WOM.TransactionResponse[])];
        return {
            ...state,
            items: items.sort((a, b) => (b.value as number) - (a.value as number))
        };
    })
    .on(sortByDate, state => {
        const items = [...(state.items as WOM.TransactionResponse[])];
        return {
            ...state,
            items: items.sort((a, b) => Date.parse(b.utcCreated || '') - Date.parse(a.utcCreated || ''))
        };
    });

const updateValues = createEvent<WOM.TransactionQueryRequestValues>();
const setDefaultValues = createEvent();

const updateIsFirst = createEvent();
const setIsFirstToTrue = createEvent();

const isFirst = createStore<boolean>(true)
    .on(updateIsFirst, state => !state)
    .on(setIsFirstToTrue, () => true);

// values store keeps request values,
// after updating or removing some fields of the values,
// watcher initiate getItems request due the new values
// (old fields of values are not removed if they are not pointed as remove values in removeAndUpdateValues event)
const values = createStore<WOM.TransactionQueryRequest>(defaultTransactionsValues)
    .on(updateValues, (state, values: WOM.TransactionQueryRequestValues) => ({ ...state, ...values }))
    .on(setDefaultValues, () => defaultTransactionsValues);
values.watch(updateValues, state => getItems(state));
values.watch(setDefaultValues, state => getItems(state));

const setId = createEvent<string>();
const getRequestId = createStore<string>('').on(setId, (_, id) => id);

const userTransactionsEvents = {
    updateValues,
    setDefaultValues,
    updateIsFirst,
    setIsFirstToTrue,
    setId,
    sortAscending,
    sortDescending,
    sortByDate
};
const userTransactionsEffects = { getItems };
const userTransactionsStores = { items, initialLoading, loading, isFirst, getRequestId, values };

export { userTransactionsEvents, userTransactionsEffects, userTransactionsStores };
