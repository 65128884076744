import { CancelToken } from 'axios';
import axios from './axios';

export const getItems = (data: WOM.PerformancePaymentsQueryRequest, cancelToken?: CancelToken) =>
    axios<WOM.PerformancePaymentsQueryResponse>({
        url: '/performancePayments/query',
        cancelToken,
        data
    });

export const getContentItems = (data: WOM.PerformancePaymentsContentPaymentsRequest, cancelToken?: CancelToken) =>
    axios<WOM.PerformancePaymentsContentPaymentsResponse>({
        url: '/performancePayments/query-content',
        cancelToken,
        data
    });

export const getItem = (data: WOM.ValidationEntriesRequest, cancelToken?: CancelToken) =>
    axios<WOM.ValidationEntriesResponse>({
        url: '/validation/validation-entries',
        cancelToken,
        data
    });
