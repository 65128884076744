import React, { FC, useState } from 'react';
import { Tooltip as ReactTooltip, UncontrolledTooltipProps } from 'reactstrap';

interface TooltipProps
    extends Pick<UncontrolledTooltipProps, 'placement'>,
        Required<Pick<UncontrolledTooltipProps, 'id'>> {
    title: React.ReactNode;
}

export const Tooltip: FC<TooltipProps> = ({ children, placement, title, id }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const tooltipId = 'Tooltip-' + id;

    return (
        <>
            <div className="custom-tooltip" id={tooltipId}>
                {children}
            </div>
            <ReactTooltip isOpen={tooltipOpen} placement={placement} target={tooltipId} toggle={toggle}>
                {title}
            </ReactTooltip>
        </>
    );
};
