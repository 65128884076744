import axios from './axios';

export const getItem = (data: WOM.WalletGetRequest) =>
    axios<WOM.WalletResponse>({
        url: '/wallet/get',
        data
    });

export const transferMoney = (data: WOM.TransactionCreateRequest) =>
    axios<WOM.TransactionResponse>({
        url: '/wallet/transaction-create',
        data
    });

export const airdrop = (data: WOM.WalletAirdropRequest) =>
    axios<WOM.MessageResponseBase>({
        url: '/wallet/airdrop',
        data
    });

export const getTransactionSummary = (data: WOM.TxSummaryQueryRequest) =>
    axios<WOM.TxSummaryQueryResponse>({
        url: 'wallet/transaction-summary',
        data
    });

export const getTokenInfo = (data: WOM.ExchangeRateRequest) =>
    axios<WOM.ExchangeRateResponse>({
        url: '/wallet/getTokenInfo',
        data
    });
