import { defaultWithdrawalLimits } from 'constants/defaults/withdrawalLimits';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'services';
import { notificationEvents } from 'stores/notification';

export const errorMessage = 'An error occurred, please try again later';

const updateLoading = createEvent();

const loading = createStore(false).on(updateLoading, state => !state);

const updateValues = createEvent<WOM.WithdrawalLimitQueryRequest>();
const values = createStore<WOM.WithdrawalLimitQueryRequest>(defaultWithdrawalLimits).on(
    updateValues,
    (state, newState) => ({ ...state, ...newState })
);

const getWithdrawalLimitQuery = createEffect({
    handler: async (value: WOM.WithdrawalLimitQueryRequest) => {
        try {
            updateLoading();
            const data = await API.withdrawalLimits.getWithdrawalLimitQuery(value);
            updateLoading();
            return data;
        } catch {
            updateLoading();
            return {};
        }
    }
});

const getWithdrawalLimitQueryByID = createEffect({
    handler: async (value: WOM.WithdrawalLimitGetRequest) => {
        try {
            updateLoading();
            const data = await API.withdrawalLimits.getWithdrawalLimitByID(value);
            updateLoading();

            return data;
        } catch {
            updateLoading();
            return;
        }
    }
});

const withdrawalLimitsQuery = createStore<WOM.WithdrawalLimitQueryResponse>({})
    .on(getWithdrawalLimitQuery.doneData, (_, newState) => newState)
    .on(getWithdrawalLimitQueryByID.doneData, (_, newState) => ({
        items: newState ? [newState] : undefined
    }));

const createWithdrawalLimit = createEffect({
    handler: async (value: WOM.WithdrawalLimitCreateRequest) => {
        try {
            await API.withdrawalLimits.createWithdrawalLimit(value);
            notificationEvents.setNotification({ message: 'Withdrawal Limit Created', place: 'tc' });
        } catch {
            notificationEvents.setNotification({ message: errorMessage, place: 'tc' });
        }
    }
});

const deleteWithdrawalLimit = createEffect({
    handler: async (value: WOM.WithdrawalLimitDeleteRequest) => {
        try {
            await API.withdrawalLimits.deleteWithdrawalLimit(value);
            notificationEvents.setNotification({ message: 'Withdrawal Limit Deleted', place: 'tc' });
        } catch {
            notificationEvents.setNotification({ message: errorMessage, place: 'tc' });
        }
    }
});

const updateWithdrawalLimit = createEffect({
    handler: async (value: WOM.WithdrawalLimitUpdateRequest) => {
        try {
            await API.withdrawalLimits.updateWithdrawalLimit(value);
            notificationEvents.setNotification({ message: 'Withdrawal Limit Updated', place: 'tc' });
        } catch {
            notificationEvents.setNotification({ message: errorMessage, place: 'tc' });
        }
    }
});

const withdrawalLimitItem = createStore<WOM.WithdrawalLimitResponse>({})
    .on(getWithdrawalLimitQueryByID.doneData, (_, newState) => newState)
    .on(updateWithdrawalLimit.doneData, (_, newState) => newState);

forward({
    from: values,
    to: getWithdrawalLimitQuery
});

export const withdrawalLimitsStores = { withdrawalLimitsQuery, loading, withdrawalLimitItem, values };

export const withdrawalLimitsEffects = {
    getWithdrawalLimitQuery,
    createWithdrawalLimit,
    deleteWithdrawalLimit,
    getWithdrawalLimitQueryByID,
    updateWithdrawalLimit
};

export const withdrawalLimitsEvents = { updateValues };
