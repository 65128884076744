import classNames from 'classnames';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { SideBarWrapper } from 'components/grid/Sidebar/styles';
import { RoutesType, SidebarStatesType } from 'constants/routes';
import React, { useEffect, useRef, useState } from 'react';
import { matchPath } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
// reactstrap components
import { Collapse, Nav } from 'reactstrap';
import { NoopType } from 'types';
import { ActiveColor, LogoSidebar } from 'types/interfaces/global';
import { destroySidebarPerfectScrollbar, initializeSidebarPerfectScrollbar } from 'utils/prefectScrollbar';
import { getCollapseInitialState, getCollapseStates } from 'utils/usefulFunctions';

interface CloseSidebar {
    // this is used on responsive to close the sidebar on route navigation
    closeSidebar: NoopType;
}

interface LogoSidebarItemProps extends LogoSidebar, CloseSidebar {}

const LogoSidebarItem = ({ logo, closeSidebar }: LogoSidebarItemProps) => {
    const { outerLink, innerLink, imgSrc, text } = logo;

    const logoImg = outerLink ? (
        <a
            className="simple-text logo-mini"
            href={outerLink}
            rel="noreferrer"
            //eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            onClick={closeSidebar}
        >
            <div className="logo-img">
                <CustomImg alt="react-logo" src={imgSrc} />
            </div>
        </a>
    ) : innerLink ? (
        <NavLink className="simple-text logo-mini" to={innerLink} onClick={closeSidebar}>
            <div className="logo-img">
                <CustomImg alt="react-logo" src={imgSrc} />
            </div>
        </NavLink>
    ) : null;

    const logoText = outerLink ? (
        <a
            className="simple-text logo-normal"
            href={outerLink}
            rel="noreferrer"
            //eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            onClick={closeSidebar}
        >
            {text}
        </a>
    ) : innerLink ? (
        <NavLink className="simple-text logo-normal" to={innerLink} onClick={closeSidebar}>
            {text}
        </NavLink>
    ) : null;

    return (
        <div className="logo">
            {logoImg}
            {logoText}
        </div>
    );
};

interface SidebarProps extends ActiveColor, LogoSidebar, CloseSidebar {
    routes: RoutesType;
}

export const Sidebar = ({ routes, logo, activeColor, closeSidebar }: SidebarProps) => {
    const [sidebarStates, setSidebarStates] = useState<SidebarStatesType>({});
    const sidebarRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    useEffect(() => {
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        sidebarRef.current && initializeSidebarPerfectScrollbar(sidebarRef.current);

        return function cleanup() {
            // we need to destroy the false scrollbar when we navigate
            // to a page that doesn't have this component rendered
            destroySidebarPerfectScrollbar();
        };
    });

    useEffect(() => {
        setSidebarStates(getCollapseStates(routes));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes: RoutesType, isNested?: boolean) =>
        routes.map(({ collapse, views, view, state, nest, icon, name, mini, path }, key) => {
            const isCurrentActivePath = matchPath(location.pathname, {
                path,
                exact: true,
                strict: false
            })?.isExact;

            if (collapse && views && state) {
                return (
                    <li key={key.toString()} className={classNames({ active: getCollapseInitialState(views) })}>
                        <a
                            aria-expanded={sidebarStates[state]}
                            data-toggle="collapse"
                            href="#pablo"
                            onClick={e => {
                                e.preventDefault();
                                setSidebarStates({ ...sidebarStates, [state]: !sidebarStates[state] });
                            }}
                        >
                            {icon ? (
                                <>
                                    <i className={icon} />
                                    <p>
                                        {name}
                                        <b className="caret" />
                                    </p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{mini}</span>
                                    <span className="sidebar-normal">
                                        {name}
                                        <b className="caret" />
                                    </span>
                                </>
                            )}
                        </a>
                        <Collapse isOpen={sidebarStates[state]}>
                            <ul className="nav">{createLinks(views)}</ul>
                        </Collapse>
                    </li>
                );
            }

            if (nest && view && state) {
                const isCurrentView = matchPath(location.pathname, {
                    path: view.path,
                    exact: true,
                    strict: false
                })?.isExact;
                if (isCurrentView) {
                    return (
                        <li key={key.toString()} className={classNames({ active: getCollapseInitialState([view]) })}>
                            <NavLink
                                activeClassName=""
                                aria-expanded={sidebarStates[state]}
                                data-toggle="collapse"
                                to={path}
                                onClick={closeSidebar}
                            >
                                {icon ? (
                                    <>
                                        <i className={icon} />
                                        <p>{name}</p>
                                    </>
                                ) : (
                                    <>
                                        <span className="sidebar-mini-icon">{mini}</span>
                                        <span className="sidebar-normal">{name}</span>
                                    </>
                                )}
                            </NavLink>
                            <Collapse isOpen>
                                <ul className="nav">{createLinks([view], true)}</ul>
                            </Collapse>
                        </li>
                    );
                }
            }

            return (
                <li key={key.toString()} className={classNames({ active: isCurrentActivePath })}>
                    {isNested ? (
                        <a href="#empty" onClick={e => e.preventDefault()}>
                            {icon ? (
                                <>
                                    <i className={icon} />
                                    <p>{name}</p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{mini}</span>
                                    <span className="sidebar-normal">{name}</span>
                                </>
                            )}
                        </a>
                    ) : (
                        <NavLink activeClassName="" to={path} onClick={closeSidebar}>
                            {icon ? (
                                <>
                                    <i className={icon} />
                                    <p>{name}</p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{mini}</span>
                                    <span className="sidebar-normal">{name}</span>
                                </>
                            )}
                        </NavLink>
                    )}
                </li>
            );
        });

    return (
        <SideBarWrapper>
            <div className="sidebar" data={activeColor}>
                <div ref={sidebarRef} className="sidebar-wrapper" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    {logo && <LogoSidebarItem closeSidebar={closeSidebar} logo={logo} />}
                    <Nav>{createLinks(routes)}</Nav>
                </div>
            </div>
        </SideBarWrapper>
    );
};
