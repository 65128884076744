export const baseURL = process.env.REACT_APP_API_URL as string;
export const appVersion = process.env.REACT_APP_VERSION as string;

export const isDev = process.env.REACT_APP_API_URL === 'https://dev.api.womprotocol.io/';

const YASYUrlProd = 'https://afjmjt.yeay.com';
const YASYUrlDev = 'https://yeay.admin.dev-stage.su';

export const apiVersion = process.env.REACT_APP_API_VERSION as string;

export const YASYUrl = isDev ? YASYUrlDev : YASYUrlProd;

export const BASYUrl = isDev ? 'https://bullz.admin.dev-stage.su' : 'https://bnasatap.bullz.com';

export const environment = isDev ? 'DEV' : 'PROD';

export const administratorTypeName = 'administrator';

export const storageName = 'womUser';
export const bufferStorageName = 'womBuffer';
export const formName = 'basic';
export const emptyWalletId = '000000000000000000000000';

export const boundaryNumber = 10000;
export const quantityNumbersAfterComma = 1;
export const numbersAfterDotWom = 3;

export const noop = () => {};

export const formatDate = 'dd-MM-y (HH:mm:s)';
