import React, { FC, InputHTMLAttributes } from 'react';
import { SwitchDirection, SwitchInput, SwitchJustify, SwitchLabel, SwitchLabelText, SwitchWrapper } from './style';

type PositionKeys = 'top' | 'left' | 'right' | 'bottom';

const directionsMap: Record<PositionKeys, SwitchDirection> = {
    left: 'row',
    right: 'row-reverse',
    top: 'column',
    bottom: 'column-reverse'
};

const justifyMap: Record<PositionKeys, SwitchJustify> = {
    left: 'flex-start',
    right: 'flex-end',
    top: 'flex-start',
    bottom: 'flex-start'
};

interface SwitchProps extends Pick<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    label: string;
    labelPosition: PositionKeys;
    checked: boolean;
}

export const Switch: FC<SwitchProps> = ({ label, labelPosition, checked, onChange }) => (
    <SwitchLabel
        align={justifyMap[labelPosition]}
        direction={directionsMap[labelPosition]}
        justify={justifyMap[labelPosition]}
    >
        <SwitchLabelText>{label}</SwitchLabelText>
        <SwitchWrapper checked={checked}>
            <SwitchInput checked={checked} type="checkbox" onChange={onChange} />
        </SwitchWrapper>
    </SwitchLabel>
);
