import React, { FC } from 'react';
import { CloseIcon } from 'assets/img';
import { StyledButton } from 'components/common/buttons/CloseButton/styles';
import { CloseButtonProps } from './types';

export const CloseButton: FC<CloseButtonProps> = ({ ...props }) => (
    <StyledButton {...props}>
        <CloseIcon />
    </StyledButton>
);
