import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const SourceWrapper = styled.div`
    background-color: #1e1f2e;
    padding: 7px 11px;
    border-radius: 4px;
    margin-right: 15px;
`;

export const IconsWrapper = styled(AbsoluteWrapper)`
    display: flex;
    align-items: center;
`;

export const Video = styled.video`
    width: 100%;
    height: 100%;

    &:focus {
        outline: none;
    }
`;

export const VideoPoster = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
        height: 100%;
    }
`;

export const PlayIconWrapper = styled.div`
    position: absolute;
    z-index: 2;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    cursor: pointer;
`;

export const NoVideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
`;
