import { SearchInput } from 'components/common/inputs/SearchInput';
import { SearchSelectWrapper, SelectWrapper } from 'components/common/inputs/SearchSelectInput/styles';
import React, { useState } from 'react';
import Select from 'react-select';
import { SelectOptions } from 'types';

interface Items {
    option: SelectOptions;
    onSearch: (value: string) => void;
}

interface Props {
    items: Items[];
    wide?: boolean;
}

export const SearchSelectInput = ({ items, wide }: Props) => {
    const [value, setValue] = useState(items[0].option);
    const placeholder = `Search by ${value.label}`;
    const options = items.map(it => it.option);

    const onChange = (value: SelectOptions | null) => {
        if (value) setValue(value);
    };

    const onSearchSubmit = (searchText: string) => {
        const item = items.find(it => it.option.value === value.value);
        if (item) item.onSearch(searchText);
    };

    return (
        <SearchSelectWrapper>
            <SearchInput maxWidth={wide ? '63%' : '374px'} placeholder={placeholder} onSearch={onSearchSubmit} />
            <SelectWrapper flexShrink="0" maxWidth={wide ? '36%' : '250px'} width="100%">
                <Select
                    className="react-select info w-100"
                    classNamePrefix="react-select"
                    options={options}
                    value={value}
                    onChange={onChange}
                />
            </SelectWrapper>
        </SearchSelectWrapper>
    );
};
