import { CancelToken } from 'axios';
import axios from './axios';

export const getUsers = (data: YEAY.QueryAllUsersRequest) =>
    axios<YEAY.QueryUsersResponse>({
        url: '/admin/user/query',
        data
    });

export const getUserById = (data: YEAY.AdminGetUserRequest) =>
    axios<YEAY.AdminGetUserResponse>({
        url: '/admin/user/get',
        data
    });

export const manageUserRoleById = (data: WOM.UserRoleChangeRequest) =>
    axios<WOM.MessageResponseBase>({
        url: '/user-admin/manage-role',
        data
    });

export const disableUserById = (data: WOM.UserDisableRequest) =>
    axios<WOM.UserDisableRequest>({
        url: '/user/disable',
        data
    });

export const createUser = (data: YEAY.UserCreateAccountRequest) =>
    axios<YEAY.UserJwtTokenResponse>({
        url: '/user/create-account',
        data
    });

export const getItemById = (data: WOM.GetUserRequest) =>
    axios<WOM.GetUserResponse>({
        url: '/user/get',
        data
    });

export const getItems = (data: WOM.UserQueryRequest, cancelToken?: CancelToken) =>
    axios<WOM.UserQueryResponse>({
        url: '/user-admin/query',
        cancelToken,
        data
    });

export const getAudit = (data: WOM.UserAuditRequest, cancelToken?: CancelToken) =>
    axios<WOM.UserAuditResponse>({
        url: '/user-admin/audit',
        cancelToken,
        data
    });

export const getWithdrawalCheck = (data: WOM.CheckWithdrawalRequest) =>
    axios<WOM.CheckWithdrawalResponse>({
        url: '/user-admin/withdrawal-check',
        data
    });
