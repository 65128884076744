import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};

    width: 30px;
    height: 20px;
    color: rgba(255, 255, 255, 0.6);
    transition: color 0.3s;

    :hover {
        color: rgba(255, 255, 255, 1);
    }
`;
