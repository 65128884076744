import { cardPaddingMultiplier } from './common';

export const padding = '10px';
export const cardMargin = `calc(${padding} * ${cardPaddingMultiplier})`;
export const borderWidth = '1px';
export const borderRadius = '4px';
export const opacity = 0.8;
export const disabledOpacity = 0.5;
export const textCurrentFontSize = '12px';
export const textInfoFontSize = '14px';
export const textInfoLineHeight = '16px';

export const headerHeight = '40px';
export const sideBarWidth = '200px';
export const miniPlayerHeight = '200px';
export const pinnedSphereDiameter = '32px';
export const featureHeight = '40px';
export const avatarDiameter = '40px';
export const iconsFontSIze = '20px';
export const footerHeight = '105px';
export const CloseButtonDiameter = '40px';
export const hashtagsSliderHeight = '30px';
export const featureIconSize = '25px';

export const primaryCardWidth = 400;
export const antdCardWidth = '250px';
export const ellipsisRowWidth = `calc(${antdCardWidth} - 2 * ${padding})`;
export const antdCardAvatarWidth = '50px';

export const xs = '480px';
export const sm = '576px';
export const md = '768px';
export const lg = '992px';
export const xl = '1200px';
export const xxl = '1600px';

export const xs_1 = '479px';
export const sm_1 = '575px';
export const md_1 = '767px';
export const lg_1 = '991px';
export const xl_1 = '1199px';
export const xxl_1 = '1599px';

export const scrollBarWidth = '7px';

export const descriptionPadding = '15px 15px 17px 15px';
export const descriptionMarginBottom = '10px';
export const descriptionMarginRight = '30px';
