import { Loader } from 'components/common/dynamic/Loader';
import { Form } from 'components/formComponents/Form';
import { FormButton } from 'components/formComponents/FormButton';
import { FormInput } from 'components/formComponents/FormInput';
import { AuthLayout } from 'components/layouts/AuthLayout';
import { errorEmptyMessage } from 'constants/notifications';
import { useStore } from 'effector-react';
import { Text } from 'components/common/typography/Text';
import {
    buttonText,
    formName,
    nameMessage,
    passwordLabel,
    passwordMessage,
    passwordName,
    title
} from 'pages/Auth/constants';
import React, { FC } from 'react';
import { loadingStores } from 'stores/loading';
import { userEffects } from 'stores/user';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { grey2 } from 'constants/styles';
import { notificationEvents } from 'stores/notification';

export const Login: FC = () => {
    const loading = useStore(loadingStores.loading);

    const onFinish = (values: WOM.UserAuthChallengeEmailOrUsernameOrPhoneRequest) => userEffects.loadToken(values);

    const onFinishFailed = () => {
        notificationEvents.setNotification({
            place: 'tc',
            message: errorEmptyMessage,
            type: 'danger',
            icon: 'tim-icons icon-bell-55',
            autoDismiss: 5
        });
    };

    return (
        <AuthLayout>
            <MarginWrapper marginBottom="20px">
                <Text isBold color={grey2}>
                    {title}
                </Text>
            </MarginWrapper>
            <Form
                initialValues={{ remember: true }}
                name={formName}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <FormInput rules={[{ required: true, message: nameMessage }]} />
                <FormInput
                    label={passwordLabel}
                    name={passwordName}
                    rules={[{ required: true, message: passwordMessage }]}
                />
                <FormButton disabled={loading && true}>{loading ? <Loader size="small" /> : buttonText}</FormButton>
            </Form>
        </AuthLayout>
    );
};
