import { TableHeadInterface } from 'types';

export const unionPerformancePaymentsTableColumns: TableHeadInterface[] = [
    {
        title: 'Name',
        dataIndex: 'nameParam',
        key: 'nameParam',
        style: {
            className: 'text-start'
        }
    },
    {
        title: 'Value (WOM)',
        dataIndex: 'value',
        key: 'value'
    },
    {
        title: 'Percent',
        dataIndex: 'percent',
        key: 'percent'
    }
];

export const getPerformancePaymentsData = (items: WOM.KeyOfPerformancePaymentsValues[]) => {
    const totalPerformancePaymentsValue = items.reduce((currentValue, performancePaymentsValue) => {
        const [value] = Object.values(performancePaymentsValue);

        return (value || 0) + currentValue;
    }, 0);

    const totalTableData = { nameParam: 'Total', value: totalPerformancePaymentsValue, percent: 100 };

    return [
        ...items.map(
            performancePaymentsValue =>
                Object.entries(performancePaymentsValue).map(([key, value]) => ({
                    nameParam: key,
                    value: value || 0,
                    percent: ((value || 0) * 100) / totalPerformancePaymentsValue
                }))[0]
        ),
        totalTableData
    ];
};
