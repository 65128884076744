import React, { FC } from 'react';
import { TextInput } from 'components/common/inputs/TextInput';
import { textDisabled, white } from 'constants/styles';

interface TokensInputProps {
    disabled?: boolean;
    tokens: string;
    onChangeTokens: (value: string) => void;
}

export const TokensInput: FC<TokensInputProps> = ({ disabled, tokens, onChangeTokens }) => (
    <TextInput
        border="1px solid #40556F"
        borderRadius="6px"
        color={tokens === '0' ? textDisabled : white}
        disabled={disabled}
        height="40px"
        padding="10px"
        type="number"
        value={tokens}
        width={'90px'}
        onChange={onChangeTokens}
    />
);
