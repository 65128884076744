import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import {
    chartsStyle,
    getChartOption,
    getTreeMapChartOption,
    graphKeys,
    payoutRanges,
    PerformancePaymentsChartData,
    PerformancePaymentsTreeMapChartData
} from 'pages/PerformancePayments/PerformancePaymentsExpandedTable/PerformancePaymentsChart/constants';
import React from 'react';
import { Col, Row } from 'reactstrap';

interface PerformancePaymentsChartProps extends WOM.PerformancePaymentsGetResponse {}

export const PerformancePaymentsChart = ({ items }: PerformancePaymentsChartProps) => {
    const filteredItems =
        items && items.length
            ? _(
                  items.map(item => ({
                      ...item,
                      sum: Object.keys(graphKeys)
                          .filter(key => key !== 'contentId')
                          .reduce(
                              (currentValue, key) =>
                                  currentValue + (item[key as keyof WOM.PerformancePaymentsItem] as number),
                              0
                          )
                  }))
              )
                  .sortBy('womPayout')
                  .value()
                  .reverse()
                  .filter((_, i) => i < 15)
            : [];

    const optionData: PerformancePaymentsChartData = {
        xAxisData: filteredItems.map(({ contentId }) => contentId || ''),
        series: Object.keys(graphKeys)
            .filter(key => key === 'womPayout' || key === 'contentPoints')
            .map(key => ({
                name: key,
                data: filteredItems.map(item => item[key as keyof WOM.PerformancePaymentsItem]) as number[]
            }))
    };

    const treeMapOptionData: PerformancePaymentsTreeMapChartData = {
        series: payoutRanges.map(({ low, high, name }) => {
            const chartData =
                items && items.length
                    ? items.filter(({ womPayout }) => {
                          if (!womPayout) return false;
                          if (!high) return womPayout >= low;
                          return womPayout >= low && womPayout <= high;
                      })
                    : [];
            return {
                name,
                value: chartData.reduce((currentValue, { womPayout }) => currentValue + (womPayout || 0), 0),
                data: chartData.map(({ contentId, womPayout }) => ({ name: contentId || '', value: womPayout || 0 }))
            };
        })
    };

    return (
        <Row>
            <Col md={12} xl={7}>
                <ReactEcharts option={getChartOption(optionData)} style={chartsStyle} />
            </Col>
            <Col md={12} xl={5}>
                <ReactEcharts option={getTreeMapChartOption(treeMapOptionData)} style={chartsStyle} />
            </Col>
        </Row>
    );
};
