import styled from 'styled-components';
import { grey7 } from 'constants/styles';

interface AirdropStyled {
    isMobile: boolean;
}

export const AirdropStyled = styled.div<AirdropStyled>`
    position: relative;
    height: calc(
        100vh - ${({ isMobile }) => (!isMobile ? '160px' : '226px')}
    ); // height screen minus height header (80px | 146px(mobile) and minus FixedBottomWrapper (80px)
    overflow-y: scroll;
`;

export const FixedBottomWrapper = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 80px;
`;

export const NotUsersMessage = styled.span`
    margin: 100px auto 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    color: ${grey7};
`;
