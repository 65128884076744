import axios from 'services/axios';

export const getWithdrawalLimitQuery = (data: WOM.WithdrawalLimitQueryRequest) =>
    axios<WOM.WithdrawalLimitQueryResponse>({
        url: '/withdrawal-limit/query',
        data
    });

export const getWithdrawalLimitByID = (data: WOM.WithdrawalLimitGetRequest) =>
    axios<WOM.WithdrawalLimitResponse>({ url: '/withdrawal-limit/get', data });

export const createWithdrawalLimit = (data: WOM.WithdrawalLimitCreateRequest) =>
    axios<WOM.WithdrawalLimitResponse>({ url: '/withdrawal-limit/create', data });

export const deleteWithdrawalLimit = (data: WOM.WithdrawalLimitDeleteRequest) =>
    axios<WOM.MessageResponseBase>({ url: '/withdrawal-limit/delete', data });

export const updateWithdrawalLimit = (data: WOM.WithdrawalLimitUpdateRequest) =>
    axios<WOM.WithdrawalLimitResponse>({ url: '/withdrawal-limit/update', data });
