import { Loader } from 'components/common/dynamic/Loader';
import { CardCatalogGrid } from 'components/grid/CatalogGrid/styles';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { Empty } from 'components/layouts/Empty';
import { MainLayout } from 'components/layouts/MainLayout';
import { useStore } from 'effector-react';
import { OrganizationCard } from 'pages/Organizations/OrganizationCard';
import { OrganizationsFilterLayout } from 'pages/Organizations/OrganizationsFilterLayout';
import React from 'react';
import { organizationsStores } from 'stores/organizations';

export const Organizations = () => {
    const { items, totalRecords } = useStore(organizationsStores.items);
    const loading = useStore(organizationsStores.loading);

    return (
        <MainLayout>
            <OrganizationsFilterLayout totalRecords={totalRecords}>
                {loading ? (
                    <Section alignCenter justifyCenter height="100%">
                        <Loader />
                    </Section>
                ) : items?.length ? (
                    <CardCatalogGrid>
                        {items.map(item => (
                            <OrganizationCard key={item.organizationId} {...item} />
                        ))}
                    </CardCatalogGrid>
                ) : (
                    <Section alignCenter justifyCenter height="100%">
                        <Empty description="Organization not found" />
                    </Section>
                )}
            </OrganizationsFilterLayout>
        </MainLayout>
    );
};
