import { Row } from 'reactstrap';
import styled from 'styled-components';

export const DateTitle = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 12px;
`;

export const DatesRow = styled(Row)`
    align-items: center;
    margin-bottom: 24px;
`;
