export enum TitleKey {
    Date = 'date',
    Balance = 'balance',
    Source = 'source',
    Username = 'username',
    RemoteUsername = 'remoteUsername',
    WomUserId = 'womUserId',
    WalletId = 'walletId'
}

export const tableHeaderData = [
    {
        title: 'Date',
        key: TitleKey.Date
    },
    {
        title: 'Balance',
        key: TitleKey.Balance
    },
    {
        title: 'Source',
        key: TitleKey.Source
    },
    {
        title: 'Username',
        key: TitleKey.Username
    },
    {
        title: 'Remote username',
        key: TitleKey.RemoteUsername
    },
    {
        title: 'Wom User ID',
        key: TitleKey.WomUserId
    },
    {
        title: 'Wallet ID',
        key: TitleKey.WalletId
    }
];
