import { DownloadLink } from 'components/common/links/DownloadLink';
import { InternalCopyLink } from 'components/common/links/InternalCopyLink';
import { BooleanTableAnswer } from 'components/common/tables/BooleanTableAnswer';
import { SimpleTable } from 'components/common/tables/SimpleTable';
import { UnionPerformancePaymentsTable } from 'pages/PerformancePayments/PerformancePaymentsExpandedTable/UnionPerformancePaymentsTable';
import { InfoTitle } from 'components/common/typography/InfoTitle';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Tooltip } from 'components/modals/Tooltip';
import { defaultSortValue } from 'constants/messages';
import { noContentMessage } from 'constants/notifications';
import { performancePaymentsLink, usersLink } from 'constants/routes';
import {
    getRedirectUrlToYeayOrBullzUsers,
    performancePaymentsTitleColumns,
    performancePaymentTitleColumns
} from 'pages/PerformancePayments/PerformancePaymentsExpandedTable/constants';
import { PerformancePaymentsChart } from 'pages/PerformancePayments/PerformancePaymentsExpandedTable/PerformancePaymentsChart';
import { graphKeys } from 'pages/PerformancePayments/PerformancePaymentsExpandedTable/PerformancePaymentsChart/constants';

import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { TableDataInterface } from 'types';
import { currencyToStandardForm, parseCalendarDate } from 'utils/usefulFunctions';

interface PerformancePaymentsExpandedTableProps extends WOM.PerformancePaymentsQueryResponse {}

export const PerformancePaymentsExpandedTable = ({ items }: PerformancePaymentsExpandedTableProps) => {
    const performancePaymentsData = items?.map(item => {
        const id = item?.auditId || '';

        /* Object { [key]: value } -> Array<{key: value}>*/
        const values = Array.from(Object.entries(item?.values || [])).map(([key, value]) => ({
            [key]: value
        })) as WOM.KeyOfPerformancePaymentsValues[];

        return {
            data: {
                // key: (j + 1).toString(),
                date: item.date ? parseCalendarDate(new Date(item.date)) : <span>{noContentMessage}</span>,
                isCompleted:
                    item.isCompleted !== undefined ? (
                        <BooleanTableAnswer id={`bool_answer_${id}`} state={item.isCompleted} />
                    ) : (
                        <span>{noContentMessage}</span>
                    ),
                womEmissionCalculated: (
                    <Tooltip id={`wom_calc_${id}`} title="WOM emission calculated">
                        <p>
                            {item.womEmissionCalculated
                                ? currencyToStandardForm(item.womEmissionCalculated)
                                : defaultSortValue}
                        </p>
                    </Tooltip>
                ),
                womEmissionExpected: (
                    <Tooltip id={`wom_exp_${id}`} title="WOM emission expected">
                        <p>
                            {item.womEmissionExpected
                                ? currencyToStandardForm(item.womEmissionExpected)
                                : defaultSortValue}
                        </p>
                    </Tooltip>
                ),
                globalAuthenticationPoints: (
                    <Tooltip id={`auth_points_${id}`} title="Global authentication points">
                        <p>
                            {item.globalAuthenticationPoints
                                ? currencyToStandardForm(item.globalAuthenticationPoints)
                                : defaultSortValue}
                        </p>
                    </Tooltip>
                ),
                globalContentPoints: (
                    <Tooltip id={`con_points_${id}`} title="Global content points">
                        <p>
                            {item.globalContentPoints
                                ? currencyToStandardForm(item.globalContentPoints)
                                : defaultSortValue}
                        </p>
                    </Tooltip>
                ),
                // globalPoints: {
                //     jsx: (
                //         <Tooltip id={`points_${id}`} title="Global points">
                //             <p>{item.globalPoints ? currencyToStandardForm(item.globalPoints) : defaultSortValue}</p>
                //         </Tooltip>
                //     ),
                //     value: item.globalPoints ? parseFloat(currencyToStandardForm(item.globalPoints)) : defaultSortValue
                // },
                globalPoints: (
                    <Tooltip id={`points_${id}`} title="Global points">
                        <p>{item.globalPoints ? currencyToStandardForm(item.globalPoints) : defaultSortValue}</p>
                    </Tooltip>
                ),
                url: item.url ? (
                    <DownloadLink href={item.url}>Download as excel</DownloadLink>
                ) : (
                    <span>{noContentMessage}</span>
                ),
                items: item.items?.length ? item.items : [],
                auditId: item.auditId ? item.auditId : '',
                values,
                count: <>{item.items?.length || 0}</>
            },
            className: 'text-center'
        };
    });

    const expandedData = (record: TableDataInterface) => {
        const { auditId, items, values } = record;

        const performancePaymentData = (items as any[]).map(
            ({
                contentId,
                authenticationPoints,
                contentPoints,
                totalPoints,
                womPayout,
                userId,
                userName,
                utcCreated,
                source,
                remoteUserId
            }) => ({
                data: {
                    source: {
                        jsx: <>{source ? source.toUpperCase() : ''}</>,
                        value: source || defaultSortValue
                    },
                    utcCreated: {
                        jsx: <>{utcCreated ? parseCalendarDate(new Date(utcCreated)) : ''}</>,
                        value: utcCreated || defaultSortValue
                    },
                    userName: {
                        jsx: (
                            <>
                                {userName ? (
                                    <a
                                        href={getRedirectUrlToYeayOrBullzUsers(remoteUserId, source as string)}
                                        target="blank"
                                    >
                                        {userName}
                                    </a>
                                ) : (
                                    <span>{noContentMessage}</span>
                                )}
                            </>
                        ),
                        value: userName || defaultSortValue
                    },
                    userId: userId ? (
                        <InternalCopyLink
                            href={usersLink + '/' + userId}
                            subject={userId}
                            success="You successfully copied user id"
                        >
                            {userId}
                        </InternalCopyLink>
                    ) : (
                        <span>{noContentMessage}</span>
                    ),
                    contentId: contentId ? (
                        <InternalCopyLink
                            href={performancePaymentsLink + '/' + contentId + '/' + auditId}
                            subject={contentId}
                            success="You successfully copied content id"
                        >
                            {contentId}
                        </InternalCopyLink>
                    ) : (
                        <span>{noContentMessage}</span>
                    ),
                    authenticationPoints: {
                        jsx: (
                            <>
                                {authenticationPoints ? currencyToStandardForm(authenticationPoints) : defaultSortValue}
                            </>
                        ),
                        value: authenticationPoints || defaultSortValue
                    },
                    contentPoints: {
                        jsx: <>{contentPoints ? currencyToStandardForm(contentPoints) : defaultSortValue}</>,
                        value: contentPoints || defaultSortValue
                    },
                    totalPoints: {
                        jsx: <>{totalPoints ? currencyToStandardForm(totalPoints) : defaultSortValue}</>,
                        value: totalPoints || defaultSortValue
                    },
                    womPayout: {
                        jsx: <>{womPayout ? currencyToStandardForm(womPayout) : defaultSortValue}</>,
                        value: womPayout || defaultSortValue
                    }
                }
            })
        );

        const chartData = (items as WOM.PerformancePaymentsItem[])?.map((item: WOM.PerformancePaymentsItem) =>
            Object.keys(graphKeys)
                .map(key => ({
                    [key]: item[key as keyof WOM.PerformancePaymentsItem]
                }))
                .reduce((result, currentObject) => ({ ...currentObject, ...result }), {})
        );

        return (
            <>
                <Section marginBottom="16px">
                    <InfoTitle title="Total: ">{performancePaymentData ? performancePaymentData.length : 0}</InfoTitle>
                </Section>
                <MarginWrapper marginBottom="16px">
                    <PerformancePaymentsChart items={chartData} />
                </MarginWrapper>
                <Section marginBottom="16px">
                    <UnionPerformancePaymentsTable items={values as WOM.KeyOfPerformancePaymentsValues[]} />
                </Section>
                <SimpleTable responsive tbody={performancePaymentData} thead={performancePaymentTitleColumns} />
            </>
        );
    };

    return (
        <Card>
            <CardBody>
                <SimpleTable
                    responsive
                    expandable={expandedData}
                    tbody={performancePaymentsData}
                    thead={performancePaymentsTitleColumns}
                />
            </CardBody>
        </Card>
    );
};
