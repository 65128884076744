import { SimpleTable } from 'components/common/tables/SimpleTable';
import { noContentMessage } from 'constants/notifications';
import { walletDescriptionTableColumns } from 'pages/Wallet/WalletDescriptionTable/constants';
import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { currencyToStandardForm, parseCalendarDate } from 'utils/usefulFunctions';

export const WalletDescriptionTable: FC<Pick<WOM.WalletResponse, 'items'>> = ({ items }) => {
    const walletDescriptionData = (items as WOM.AddressResponse[])?.map(
        ({ name, utcCreated, accountType, balance, pendingBalance, balanceQueriedAtUtc }) => ({
            data: {
                name: name || <span>{noContentMessage}</span>,
                utcCreated:
                    utcCreated !== undefined ? (
                        parseCalendarDate(new Date(utcCreated))
                    ) : (
                        <span>{noContentMessage}</span>
                    ),
                accountType: accountType || <span>{noContentMessage}</span>,
                balance: currencyToStandardForm(balance || 0),
                pendingBalance: currencyToStandardForm(pendingBalance || 0),
                balanceQueriedAtUtc: balanceQueriedAtUtc ? (
                    parseCalendarDate(new Date(balanceQueriedAtUtc))
                ) : (
                    <span>{noContentMessage}</span>
                )
            }
        })
    );

    return (
        <Card>
            <CardBody>
                <SimpleTable responsive tbody={walletDescriptionData} thead={walletDescriptionTableColumns} />
            </CardBody>
        </Card>
    );
};
