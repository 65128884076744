import womIcon from 'assets/wom_icon.svg';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { blue2, grey1, grey2, white } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width: 929px;
    background-color: ${grey2};
    border-radius: 8px;
    padding: 23px 30px;
    @media ${device.desktop} {
        width: 100%;
    }
`;

interface InputWrapperProps {
    withIcon?: boolean;
}

export const InputWrapper = styled.div<InputWrapperProps>`
    display: flex;
    position: relative;
    width: 370px;
    border-bottom: 1px solid rgba(227, 227, 227, 0.1);

    @media (max-width: 460px) {
        width: 100%;
    }

    ${({ withIcon }) =>
        withIcon &&
        css`
            &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 6px;
                z-index: 1;

                width: 10px;
                height: 11px;
                background: url(${womIcon});
            }

            & input {
                padding-left: 14px;
            }
        `};
`;

export const Input = styled.input`
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 21px;
    width: 100%;

    color: ${white};

    ::placeholder {
        font-size: 14px;
        line-height: 21px;
        color: ${grey1};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const EurWrapper = styled.span`
    display: inline-block;
    margin-left: 15px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 21px;
    color: ${grey1};
`;

interface CountryWrapperProps {
    untouchable?: boolean;
}

export const CountryWrapper = styled(Row)<CountryWrapperProps>`
    align-items: center;
    padding: 4px 10px 4px 10px;
    ${({ untouchable }) => !untouchable && `padding-right: 0px`};
    border: 1px solid ${blue2};
    box-sizing: border-box;
    border-radius: 32px;

    font-size: 14px;
    line-height: 21px;
    color: ${white};
`;
