export const primaryColor = '#4259FF'; //'#3333ff'; //'#0c0d21'; //'rgb(67, 160, 71)'; //'#1890ff';
export const secondaryColor = '#5488f0';

export const black = 'black';
export const lightBlack = '#060c07';
export const white = '#fff';
export const grey = '#d9d9d9';
export const grey1 = '#9a9a9a';
export const grey2 = '#27293D';
export const grey3 = '#D2D2D5';
export const grey4 = '#C4C4C4';
export const grey5 = '#C0C1C2';
export const grey6 = '#bdbdbd';
export const grey7 = '#737481';
export const hoverGrey = '#f5f5f5';
export const darkGrey = 'greylatedark';
export const darkGrey2 = '#1e1e24';
export const darkGrey3 = '#737481';
export const darkGrey4 = '#424245';

export const backgroundColor = '#f0f2f5';
export const infoColor = 'rgb(25, 118, 210)';
export const successColor = 'rgb(67, 160, 71)';
export const errorColor = 'rgb(211, 47, 47)';
export const cardBackgroundColor = 'white'; //"#f5fcfe";
export const inProgressColor = '#e37816';
export const textInfoColor = '#585858';
export const blue = '#1D8CF8';
export const blue2 = '#344675';
export const blue3 = '#4572AD';
export const blueGradient = 'linear-gradient(229.34deg, #1D8CF8 8.35%, #3358F4 89.61%)';
export const blueHoverGradient = 'linear-gradient(229.34deg, #6ab3fc 8.35%, #3358F4 89.61%)';
export const redGradient = 'linear-gradient(229.34deg, #FD5D93 8.35%, #EC250D 87.43%)';
export const redHoverGradient = 'linear-gradient(229.34deg, #fc9fbe 8.35%, #EC250D 87.43%)';
export const navyGradient = 'linear-gradient(229.34deg, #344675 8.35%, #263148 87.43%)';
export const navyHoverGradient = 'linear-gradient(229.34deg, #597bd5 8.35%, #263148 87.43%)';
export const purpleGradient = 'linear-gradient(229.34deg, #ed53d6 8.35%, #aa49e3 87.43%)';
export const purpleHoverGradient = 'linear-gradient(229.34deg, #fa7fe8 8.35%, #aa49e3 87.43%)';
export const greyGradient = 'linear-gradient(229.34deg, #b7b7b7 8.35%, #5e5a5a 87.43%)';

export const pink = '#fd5d93';
export const purple = '#ba54f5';

export const womColor = '#0c0d21';
export const noContentColor = '#40556F';
export const propertyBackground = '#2B3553';
export const propertyTitleColor = 'rgba(255, 255, 255, 0.8)';
export const statisticsTextColor = 'rgba(255, 255, 255, 0.5)';
export const infoTextColor = 'rgba(255, 255, 255, 0.15)';
export const tableBorderLine = 'rgba(255, 255, 255, 0.1)';
export const textDisabled = '#40414D';
