import { Section } from 'components/grid/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const DataPickerWrapper = styled.div`
    width: 460px;

    @media ${device.smallMobile} {
        width: 100%;
        max-width: 430px;
    }
`;

export const PageTitleWrapper = styled(Section)`
    margin-bottom: 16px;
    margin-top: 20px;

    @media ${device.tablet} {
        margin-top: 46px;
    }
`;
