import avatarBULLZ from 'assets/bullz_logo.svg';
import avatarWOM from 'assets/wom_logo.png';
import avatarYEAY from 'assets/yeay_logo.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { Tag } from 'components/common/tags/Tag';
import { Span } from 'components/common/typography/Span';
import { DashboardSingle } from 'components/grid/DashboardSingle';
import { DescriptionContent } from 'components/grid/DescriptionContent';
import { DescriptionProperty } from 'components/grid/DescriptionProperty';
import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { Column, Row, Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { emptyWalletId, formatDate } from 'constants/global';
import { walletLink } from 'constants/routes';
import { descriptionMarginBottom, descriptionPadding, grey2, noContentColor, xs } from 'constants/styles';
import { format } from 'date-fns';
import { getRolesLabel } from 'pages/Users/UserCard/constants';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AvatarWrapper } from './styles';

export const UserDescription: FC<WOM.GetUserResponse> = ({
    userId,
    roles,
    walletId,
    utcCreated,
    utcUpdated,
    utcLastAuthentication,
    profile,
    remoteIssuer,
    remoteUsername,
    remoteUserId
}) => {
    const avatarSrc = profile?.imageUrl
        ? profile?.imageUrl
        : remoteIssuer === 'yeay-services'
        ? avatarYEAY
        : remoteIssuer === 'bullz-services'
        ? avatarBULLZ
        : avatarWOM;
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const walletIdLink = emptyWalletId !== walletId ? `${walletLink}/${walletId}` : undefined;
    const rowProps = {
        marginBottom: descriptionMarginBottom,
        marginRight: isMobile ? '0' : '8px',
        width: isMobile ? '100%' : 'auto'
    };

    return (
        <>
            <ContentWrapper backgroundColor={grey2} marginBottom="30px" padding={descriptionPadding} width="100%">
                <Section noWrap={!isMobile}>
                    <AvatarWrapper>
                        <CustomImg alt="avatar" src={avatarSrc} width="71px" />
                    </AvatarWrapper>
                    <MarginWrapper marginBottom="30px" marginRight="30px" />
                    <Column>
                        <Section>
                            {userId && (
                                <Row {...rowProps}>
                                    <DescriptionProperty
                                        copyable
                                        subtitle={userId}
                                        success="User Id is successfully copied"
                                        title="ID"
                                    />
                                </Row>
                            )}
                            {remoteUsername && (
                                <Row {...rowProps}>
                                    <DescriptionProperty
                                        copyable
                                        subtitle={remoteUsername || ''}
                                        success="Username is successfully copied"
                                        title="Username"
                                    />
                                </Row>
                            )}

                            {walletId && (
                                <Row {...rowProps}>
                                    <DescriptionProperty
                                        copyable
                                        internalLink={walletIdLink}
                                        subtitle={walletId}
                                        success="Wallet ID is successfully copied"
                                        title="Wallet ID"
                                    />
                                </Row>
                            )}
                            {remoteUserId && (
                                <Row {...rowProps}>
                                    <DescriptionProperty
                                        copyable
                                        subtitle={remoteUserId}
                                        success="Remote User ID is successfully copied"
                                        title="Remote User ID"
                                    />
                                </Row>
                            )}
                            {utcCreated && (
                                <Row {...rowProps}>
                                    <DescriptionProperty
                                        subtitle={format(new Date(utcCreated), formatDate)}
                                        title="Date of Creation"
                                    />
                                </Row>
                            )}

                            {utcLastAuthentication && (
                                <Row {...rowProps}>
                                    <DescriptionProperty
                                        subtitle={format(new Date(utcLastAuthentication), formatDate)}
                                        title="Date of Last Auth"
                                    />
                                </Row>
                            )}

                            {utcUpdated && (
                                <Row {...rowProps}>
                                    <DescriptionProperty
                                        subtitle={format(new Date(utcUpdated), formatDate)}
                                        title="Date of Update"
                                    />
                                </Row>
                            )}
                        </Section>

                        <MarginWrapper marginTop="8px">
                            <DescriptionContent minHeight="54px" title="ROLES">
                                {roles?.length ? (
                                    roles?.map(role => (
                                        <MarginWrapper key={role} marginRight="12px">
                                            <Tag color="danger">{getRolesLabel(role)}</Tag>
                                        </MarginWrapper>
                                    ))
                                ) : (
                                    <Span color={noContentColor}>No content</Span>
                                )}
                            </DescriptionContent>
                        </MarginWrapper>
                    </Column>
                </Section>
            </ContentWrapper>
            {walletId !== emptyWalletId && <DashboardSingle userId={userId} />}
        </>
    );
};
