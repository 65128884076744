import styled from 'styled-components';
import { Text } from 'components/common/typography/Text';
import { Margin } from 'types/interfaces';
import { flexCenter, grey2 } from 'constants/styles';
import { Section } from 'components/grid/wrappers/flexWrapper';

export const StyledText = styled(Text)<Margin>`
    margin-bottom: ${({ marginBottom }) => marginBottom || '4px'};
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`};
    color: ${grey2};
`;

export const ButtonsWrapper = styled(Section)`
    padding: 24px 24px 16px;
    justify-content: space-between;
    align-items: center;
`;

export const RelativeWrapper = styled(Section)`
    position: relative;
    ${flexCenter};
    padding: 40px 24px 16px;
`;
