import { SearchSelectInput } from 'components/common/inputs/SearchSelectInput';
import { InfoTitle } from 'components/common/typography/InfoTitle';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { Pagination } from 'components/layouts/Pagination';
import { defaultLimit } from 'constants/defaults';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { organizationsEvents, organizationsStores } from 'stores/organizations';
import { TotalRecords } from 'types';
import { SearchWrapper } from './styles';

const { updateValues, setDefaultValues, setIsFirstToFalse } = organizationsEvents;

export const OrganizationsFilterLayout: FC<TotalRecords> = ({ totalRecords, children }) => {
    const { pageIndex, limit } = useStore(organizationsStores.values);
    const isFirst = useStore(organizationsStores.isFirst);
    const [pageBeforeSearch, setPageBeforeSearch] = useState(pageIndex);

    const searchByOrganizationName = (value: string) => {
        if (value) {
            setPageBeforeSearch(pageIndex);
        }
        updateValues({
            organizationName: value,
            pageIndex: value ? 0 : pageBeforeSearch,
            organizationId: undefined,
            publicId: undefined
        });
    };

    const searchByOrganizationId = (value: string) => {
        if (value) {
            setPageBeforeSearch(pageIndex);
        }
        updateValues({
            organizationName: undefined,
            pageIndex: value ? 0 : pageBeforeSearch,
            organizationId: value,
            publicId: undefined
        });
    };

    const searchByOrganizationPublicId = (value: string) => {
        if (value) {
            setPageBeforeSearch(pageIndex);
        }
        updateValues({
            organizationName: undefined,
            pageIndex: value ? 0 : pageBeforeSearch,
            organizationId: undefined,
            publicId: value
        });
    };

    const searchItems = [
        { option: { value: 'organizationName', label: 'Organization Name' }, onSearch: searchByOrganizationName },
        { option: { value: 'organizationId', label: 'Organization Id' }, onSearch: searchByOrganizationId },
        { option: { value: 'publicId', label: 'Organization Public Id' }, onSearch: searchByOrganizationPublicId }
    ];

    const onCurrentPageChange: (page: number, pageSize: number | undefined) => void = (page, pageSize) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    useEffect(() => {
        if (isFirst) {
            setDefaultValues();
            setIsFirstToFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SearchWrapper>
                <SearchSelectInput items={searchItems}></SearchSelectInput>
            </SearchWrapper>

            <InfoTitle title="Total: ">{totalRecords !== -1 ? totalRecords : 0}</InfoTitle>

            <Section minHeight="calc(100vh - 220px)">{children}</Section>
            <Section alignCenter justifyCenter marginTop="40px">
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Section>
        </>
    );
};
