import { CloseButton } from 'components/common/buttons/CloseButton';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper/styles';
import { useNonScrolledBackground } from 'hooks/nonScrolledBackground';
import React, { FC } from 'react';
import { Sizes, Visibility } from 'types/interfaces';
import { NoopType } from 'types/types';
import { ModalBackground, ModalContentWrapper } from './styles';

interface Props extends Visibility, Sizes {
    onClose?: NoopType;
}

export const ModalWrapper: FC<Props> = ({ children, visible, onClose, width, height }) => {
    useNonScrolledBackground(visible);

    if (!visible) return null;

    return (
        <>
            <ModalBackground onClick={onClose} />
            <ModalContentWrapper height={height} width={width}>
                <AbsoluteWrapper right="22px" top="21px" zIndex="100">
                    <CloseButton onClick={onClose} />
                </AbsoluteWrapper>

                {children}
            </ModalContentWrapper>
        </>
    );
};
