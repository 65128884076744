import { TableHeadInterface } from 'types';

export const unionPayoutsStatisticsTableColumns: TableHeadInterface[] = [
    {
        title: 'Name',
        dataIndex: 'nameParam',
        key: 'nameParam',
        style: {
            className: 'text-start'
        }
    },
    {
        title: 'Count',
        dataIndex: 'count',
        key: 'count'
    },
    {
        title: 'Sum (WOM)',
        dataIndex: 'sum',
        key: 'sum'
    },
    {
        title: 'Percent',
        dataIndex: 'percent',
        key: 'percent'
    }
];
