import { errorColor, inProgressColor, successColor } from 'constants/styles';
import { TransactionStatus } from 'types/service';

export const statusColors = {
    [TransactionStatus.Error]: errorColor,
    [TransactionStatus.InProgress]: inProgressColor,
    [TransactionStatus.Success]: successColor,
    [TransactionStatus.None]: null
};

export const statusTitle = {
    [TransactionStatus.Error]: 'Error',
    [TransactionStatus.InProgress]: 'in Progress',
    [TransactionStatus.Success]: 'Success',
    [TransactionStatus.None]: 'None'
};
