import { Span } from 'components/common/typography/Span';
import styled from 'styled-components';

export const StyledSpan = styled(Span)<{
    active?: boolean;
}>`
    margin-right: 10px;
    cursor: pointer;
    padding-bottom: 4px;
    color: ${({ active }) => (active ? '#fff' : 'rgba(255, 255, 255, 0.6)')};
    border-bottom: 1px solid ${({ active }) => (active ? '#9B49CD' : 'transparent')};
`;
