import { DataRangePicker } from 'components/common/inputs/DataPicker';
import { Span } from 'components/common/typography/Span';
import { InfoTitle } from 'components/common/typography/InfoTitle';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { Pagination } from 'components/layouts/Pagination';
import { defaultLimit, defaultPage, defaultPerformancePaymentsValues } from 'constants/defaults';
import { grey5 } from 'constants/styles';
import { useStore } from 'effector-react';
import { useQueryParams } from 'hooks/queryParams';
import { Moment } from 'moment';
import React, { FC, useEffect } from 'react';
import { Col } from 'reactstrap';

import { performancePaymentsEvents, performancePaymentsStores } from 'stores/performancePayments';
import { TotalRecords } from 'types';
import { DataPickerWrapper, PageTitleWrapper } from './styles';

const { updateValues, setIsFirstToFalse, setDefaultValues } = performancePaymentsEvents;

const updateQueryValues = (values: PerformancePaymentsQueryParams) => {
    updateValues(values);
};

interface PerformancePaymentsQueryParams extends Partial<WOM.PerformancePaymentsQueryRequestValues> {}

interface PerformancePaymentsFilterLayoutProps extends TotalRecords {}

export const PerformancePaymentsFilterLayout: FC<PerformancePaymentsFilterLayoutProps> = ({
    totalRecords,
    children
}) => {
    const { pageIndex, limit, dateFrom, dateTo } = useStore(performancePaymentsStores.values);
    const isFirst = useStore(performancePaymentsStores.isFirst);

    const [queryParams, setQueryParams] = useQueryParams<PerformancePaymentsQueryParams>(updateQueryValues);

    const onDateRangeClick = ([dateFrom, dateTo]: [Moment, Moment]) =>
        dateFrom && dateTo
            ? updateValues({
                  dateFrom: dateFrom?.format(),
                  dateTo: dateTo.format(),
                  pageIndex: defaultPage
              })
            : updateValues({
                  dateFrom: defaultPerformancePaymentsValues.dateFrom,
                  dateTo: defaultPerformancePaymentsValues.dateTo,
                  pageIndex: defaultPage
              });

    // const onResetClick = () => {
    //     setQueryParams({});
    //     document.location.reload();
    // };

    const onCurrentPageChange: (page: number, pageSize: number | undefined) => void = (page, pageSize) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    useEffect(() => {
        if (isFirst && !queryParams.dateTo && !queryParams.dateFrom) {
            setDefaultValues();
            setIsFirstToFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setQueryParams({
            pageIndex,
            limit,
            dateFrom,
            dateTo
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, limit, dateFrom, dateTo]);

    return (
        <>
            <PageTitleWrapper>
                <Span color={grey5} fontSize="13px" lineHeight="19px">
                    Transaction
                </Span>
            </PageTitleWrapper>

            <DataPickerWrapper>
                <DataRangePicker
                    value={[new Date(dateFrom || ''), new Date(dateTo || '')]}
                    onChange={onDateRangeClick}
                />
            </DataPickerWrapper>

            <InfoTitle title="Total: ">{totalRecords !== -1 ? totalRecords : 0}</InfoTitle>

            <Section minHeight="calc(100vh - 270px)">
                <Col>{children}</Col>
            </Section>
            <Section alignCenter justifyCenter>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Section>
        </>
    );
};
