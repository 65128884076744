import axios, { CancelTokenSource } from 'axios';
import history from 'browserHistory';
import { defaultTransactionsValues } from 'constants/defaults/transactions';
import { transactionsLink } from 'constants/routes';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'services';
import { notificationEvents } from './notification';

let cancelToken: CancelTokenSource | undefined;

const updateInitialLoading = createEvent();
const setInitialLoading = createEvent<boolean>();

const initialLoading = createStore<boolean>(false)
    .on(updateInitialLoading, state => !state)
    .on(setInitialLoading, (_, state) => state);

const updateLoading = createEvent();
const loading = createStore<boolean>(false).on(updateLoading, loading => !loading);

const getItemById = createEffect({
    handler: async (id: string) => {
        try {
            updateLoading();
            const data = await API.transactions.getItemById({ transactionId: id });
            updateLoading();

            return data;
        } catch {
            updateLoading();
            return {};
        }
    }
});

const getIdAndRedirectToSinglePage = createEffect({
    handler: async (id: string) => {
        try {
            updateLoading();
            const data = await API.transactions.getItemById({ transactionId: id });
            updateLoading();

            data?.transactionId
                ? history.push(transactionsLink + '/' + data.transactionId)
                : notificationEvents.setNotification({
                      place: 'tc',
                      message: "Such transaction doesn't exist",
                      type: 'danger',
                      icon: 'tim-icons icon-bell-55',
                      autoDismiss: 5
                  });

            return data;
        } catch {
            notificationEvents.setNotification({
                place: 'tc',
                message: "Such transaction doesn't exist",
                type: 'danger',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            });

            updateLoading();
            return {};
        }
    }
});

const getItems = createEffect({
    handler: async (values: WOM.ContentQueryRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateInitialLoading();
            const data = await API.transactions.getItems(values, cancelToken.token);
            updateInitialLoading();

            return data ? data : {};
        } catch {
            updateInitialLoading();
            return {};
        }
    }
});

// const singleItem = createStore<WOM.ContentItemResponse>({}).on(getSingleItemById.doneData, (_, newState) => newState);
const item = createStore<WOM.TransactionResponse>({}).on(getItemById.doneData, (_, newState) => newState);
const items = createStore<WOM.TransactionQueryResponse>({})
    .on([getItems.doneData], (_, newState) => newState)
    .on(getItemById.doneData, (prevState, newState) => ({ ...prevState, items: [newState] }));

const updateValues = createEvent<WOM.TransactionQueryRequestValues>();
const overrideValues = createEvent<WOM.TransactionQueryRequestValues>();
const setDefaultValues = createEvent();

const updateIsFirst = createEvent();
const setIsFirstToTrue = createEvent();

const isFirst = createStore<boolean>(true)
    .on(updateIsFirst, state => !state)
    .on(setIsFirstToTrue, () => true);

// values store keeps request values,
// after updating or removing some fields of the values,
// watcher initiate getItems request due the new values
// (old fields of values are not removed if they are not pointed as remove values in removeAndUpdateValues event)
const values = createStore<WOM.TransactionQueryRequest>(defaultTransactionsValues)
    .on(updateValues, (state, values: WOM.TransactionQueryRequestValues) => ({ ...state, ...values }))
    .on(overrideValues, (_, values: WOM.TransactionQueryRequestValues) => ({
        ...defaultTransactionsValues,
        ...values
    }))
    .on(setDefaultValues, () => defaultTransactionsValues);
forward({
    from: values,
    to: getItems
});
values.watch(setDefaultValues, state => getItems(state));

const setId = createEvent<string>();
const getRequestId = createStore<string>('').on(setId, (_, id) => id);

const transactionsEvents = {
    updateValues,
    setDefaultValues,
    overrideValues,
    updateIsFirst,
    setIsFirstToTrue,
    setId
};
const transactionsEffects = { getItemById, getIdAndRedirectToSinglePage, getItems };
const transactionsStores = { items, item, initialLoading, loading, isFirst, getRequestId, values };

export { transactionsEvents, transactionsEffects, transactionsStores };
