import { set, sub } from 'date-fns';

export const defaultEarningsStatisticsQuery: WOM.EarningsStatisticsQueryRequest = {
    dateFrom: sub(
        set(new Date(), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        }),
        { days: 7 }
    ).toISOString(),
    dateTo: set(new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 0 }).toISOString(),
    groupByWeek: false
};

export const defaultTransactionSummary = {
    dateFrom: sub(
        set(new Date(), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        }),
        { days: 7 }
    ).toISOString(),
    dateTo: set(new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 0 }).toISOString()
};

export const defaultUserStatisticsQuery: WOM.EarningsStatisticsQueryRequest = {
    dateFrom: sub(
        set(new Date(), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        }),
        { months: 1 }
    ).toISOString(),
    dateTo: new Date().toISOString(),
    groupByWeek: false
};
