import React from 'react';
import { addIcon, minusIcon, avatarBULLZIcon, avatarWOMIcon, avatarYEAYIcon } from 'assets/img';
import { usersLink } from 'constants/routes';
import { purple } from 'constants/styles';
import { CustomImg, Span, InternalCopyLink } from 'components/common';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { ClickableWrapper } from 'components/grid/wrappers/ClickableWrapper';

export const getAirdropTableContentData = (
    users: WOM.GetUserResponse[],
    checkedUsersForAdded: WOM.GetUserResponse[],
    onCheckUser: (user: WOM.GetUserResponse, isChecked: boolean) => void
) =>
    users.map(user => {
        const { remoteIssuer, remoteUsername, username, userId, womUserId } = user;
        const avatarSrc =
            remoteIssuer === 'yeay-services'
                ? avatarYEAYIcon
                : remoteIssuer === 'bullz-services'
                ? avatarBULLZIcon
                : avatarWOMIcon;

        return {
            data: {
                platforms: <CustomImg alt="avatar" src={avatarSrc} width="43px" />,
                username: <Span>{remoteUsername || username || '-'}</Span>,
                userId: userId ? (
                    <Row width="230px">
                        <InternalCopyLink
                            color={purple}
                            href={`${usersLink}/${userId}`}
                            subject={userId}
                            success="You successfully copied user id"
                        >
                            {userId}
                        </InternalCopyLink>
                    </Row>
                ) : (
                    <Span>-</Span>
                ),
                womUserId: womUserId ? (
                    <Row width="230px">
                        <InternalCopyLink
                            color={purple}
                            href={`${usersLink}/${womUserId}`}
                            subject={womUserId}
                            success="You successfully copied user id"
                        >
                            {womUserId}
                        </InternalCopyLink>
                    </Row>
                ) : (
                    <Span>-</Span>
                ),
                addButton: (
                    <Row alignCenter justifyCenter width="50px">
                        {!checkedUsersForAdded.find(user => user.userId === userId) ? (
                            <ClickableWrapper onClick={() => onCheckUser(user, true)}>
                                <CustomImg alt="add icon" src={addIcon} width="12px" />
                            </ClickableWrapper>
                        ) : (
                            <ClickableWrapper height="12px" onClick={() => onCheckUser(user, false)}>
                                <CustomImg alt="remove icon" src={minusIcon} width="12px" />
                            </ClickableWrapper>
                        )}
                    </Row>
                )
            }
        };
    });
