import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const SearchWrapper = styled.div`
    max-width: 731px;

    @media ${device.laptop} {
        max-width: 640px;
        margin-top: 29px;
        margin-bottom: 24px;
    }

    @media ${device.mobile} {
        max-width: 100%;
        margin-bottom: 15px;
    }
`;
