import Chart from 'chart.js';
import { Span } from 'components/common/typography/Span';
import { Tooltip } from 'components/modals/Tooltip';
import { grey1, white, xs } from 'constants/styles';
import React, { FC } from 'react';
// react plugin used to create charts
import { ChartData, Line } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Color, Title } from 'types/interface';
import { formatEngagementStatisticsValues } from 'utils/usefulFunctions';
import { v4 as uuid4 } from 'uuid';

const DescriptionIconSvg: FC<Color> = ({ color }) => (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="8.75" stroke={color} strokeWidth="0.5" />
        <path
            d="M8.9999 11.7C9.35475 11.7 9.64275 11.9592 9.64275 12.2785C9.64275 12.5979 9.35475 12.8571 8.9999 12.8571C8.64504 12.8571 8.35704 12.5979 8.35704 12.2785C8.35704 11.9592 8.64504 11.7 8.9999 11.7ZM8.9999 5.14282C10.4202 5.14282 11.5713 6.17885 11.5713 7.45711C11.5713 8.29218 11.2486 8.72611 10.4253 9.35598C9.59947 9.98739 9.42847 10.2717 9.42847 10.9285H8.57132C8.57132 9.97428 8.90861 9.50332 9.87032 8.76815C10.5205 8.27097 10.7142 8.01022 10.7142 7.45711C10.7142 6.60468 9.94704 5.91425 8.9999 5.91425C8.05275 5.91425 7.28561 6.60468 7.28561 7.45711V7.84282H6.42847V7.45711C6.42847 6.17885 7.57961 5.14282 8.9999 5.14282Z"
            fill={color}
        />
    </svg>
);

interface GraphCardProps extends Title, Color {
    total: number;
    dataGraph: ChartData<Chart.ChartData>;
    options: Chart.ChartOptions;
    description?: string;
}

export const GraphCard = ({ title, total, dataGraph, options, color, description }: GraphCardProps) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    return (
        <Card className="card-chart">
            <CardHeader>
                <Row className="no-gutters align-items-center">
                    <Col className="col-auto mr-2">
                        <Span
                            color={grey1}
                            fontSize={isMobile ? '10px' : '12px'}
                            lineHeight={isMobile ? '15px' : '18px'}
                        >
                            {title}
                        </Span>
                    </Col>
                    {description && (
                        <Col className="col-auto description-icon">
                            <Tooltip id={`chart_desc_${uuid4()}`} title={description}>
                                <DescriptionIconSvg color={color} />
                            </Tooltip>
                        </Col>
                    )}
                </Row>

                <Span
                    color={white}
                    fontSize={isMobile ? '12px' : '22px'}
                    lineHeight={isMobile ? '18px' : '33px'}
                    opacity={0.8}
                >
                    {formatEngagementStatisticsValues(total)}
                </Span>
            </CardHeader>
            <CardBody>
                <div className="chart-area">
                    <Line data={dataGraph} options={options} />
                </div>
            </CardBody>
        </Card>
    );
};
