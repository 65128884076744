import classNames from 'classnames';
import { Text } from 'components/common/typography/Text';
import { Row } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { pagination, paginationLimit, sizeValues } from 'components/layouts/Pagination/constants';
import { defaultLimit } from 'constants/defaults';
import React, { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';
import Select from 'react-select';
import { Col, Input, Pagination as PaginationLayout, PaginationItem, PaginationLink } from 'reactstrap';
import { SelectOptionType } from 'types';

interface PagerProps {
    activeIndex: number;
    total: number;
    onChange: (current: number) => void;
}

const SmallPager = ({ activeIndex, total, onChange }: PagerProps) => (
    <>
        {pagination
            // * first and last cells are rendered in parent element
            // * so we need exclude them from enumeration (total - 2)
            .filter((_, i) => i < total - 2)
            .map((_, i) => (
                <PaginationItem key={i.toString()} className={classNames({ active: i + 2 === activeIndex })}>
                    <PaginationLink onClick={() => onChange(i + 2)}>{i + 2}</PaginationLink>
                </PaginationItem>
            ))}
    </>
);

const BigPager = ({ activeIndex, total, onChange }: PagerProps) => (
    <>
        {activeIndex <= paginationLimit
            ? pagination.map((_, i) => (
                  <PaginationItem key={i.toString()} className={classNames({ active: activeIndex === 2 + i })}>
                      <PaginationLink onClick={() => onChange(2 + i)}>
                          {i + 2 === paginationLimit + 1 ? '...' : 2 + i}
                      </PaginationLink>
                  </PaginationItem>
              ))
            : activeIndex > total - paginationLimit + 1
            ? pagination
                  .filter((_, i) => i !== paginationLimit - 1)
                  .map((_, i) => (
                      <PaginationItem
                          key={i.toString()}
                          className={classNames({ active: activeIndex === total - paginationLimit + i + 1 })}
                      >
                          <PaginationLink onClick={() => onChange(total - paginationLimit + i + 1)}>
                              {i + 2 === 2 ? '...' : total - paginationLimit + i + 1}
                          </PaginationLink>
                      </PaginationItem>
                  ))
            : pagination.map((_, i) => (
                  <PaginationItem
                      key={i.toString()}
                      className={classNames({ active: activeIndex === activeIndex - (paginationLimit - 1) / 2 + i })}
                  >
                      <PaginationLink onClick={() => onChange(activeIndex - (paginationLimit - 1) / 2 + i)}>
                          {i === 0 || i === paginationLimit - 1 ? '...' : activeIndex - (paginationLimit - 1) / 2 + i}
                      </PaginationLink>
                  </PaginationItem>
              ))}
    </>
);

interface PaginationProps {
    currentIndex: number;
    pagesLimit?: number;
    totalItems?: number;
    defaultSize?: number;
    onSizeChange: (current: number, size: number) => void;
}

export const Pagination = ({
    currentIndex,
    onSizeChange,
    totalItems = 0,
    defaultSize = defaultLimit,
    pagesLimit
}: PaginationProps) => {
    const total = useMemo(() => {
        if (totalItems === 0) return totalItems;

        const totalItemsValue = Math.trunc((totalItems - 1) / defaultSize + 1);

        return pagesLimit && totalItemsValue >= pagesLimit ? pagesLimit : totalItemsValue;
    }, [defaultSize, totalItems, pagesLimit]);

    const [valuePage, setValuePage] = useState('');
    const [size, setSize] = useState(defaultSize);

    const handlePageSet = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;
        if (!isNaN(parseInt(inputValue[inputValue.length - 1])) || !inputValue) {
            setValuePage(e.currentTarget.value);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSizeChange(parseInt(valuePage) - 1, size);
            setValuePage('');
        }
    };

    const onIndexChange = (index: number) => onSizeChange(index - 1, size);

    const onSizeAndIndexChange = (size: SelectOptionType | null) => {
        setSize(Number(size?.value));
        onSizeChange(0, Number(size?.value));
    };

    if (total === 0) return null;

    return (
        <Row alignCenter>
            <Col className="cmb-sm-2">
                <nav aria-label="Page navigation">
                    <PaginationLayout className="pagination-custom">
                        <PaginationItem disabled={currentIndex === 1}>
                            <PaginationLink aria-label="Previous" onClick={() => onIndexChange(currentIndex - 1)}>
                                <span aria-hidden>
                                    <i aria-hidden className="tim-icons icon-double-left" />
                                </span>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className={classNames({ active: currentIndex === 1 })}>
                            <PaginationLink onClick={() => onIndexChange(1)}>1</PaginationLink>
                        </PaginationItem>

                        {total - 2 <= paginationLimit ? (
                            <SmallPager activeIndex={currentIndex} total={total} onChange={onIndexChange} />
                        ) : (
                            <BigPager activeIndex={currentIndex} total={total} onChange={onIndexChange} />
                        )}

                        {total !== 1 && (
                            <PaginationItem className={classNames({ active: currentIndex === total })}>
                                <PaginationLink onClick={() => onIndexChange(total)}>{total}</PaginationLink>
                            </PaginationItem>
                        )}
                        <PaginationItem disabled={currentIndex === total}>
                            <PaginationLink aria-label="Next" onClick={() => onIndexChange(currentIndex + 1)}>
                                <span aria-hidden>
                                    <i aria-hidden className="tim-icons icon-double-right" />
                                </span>
                            </PaginationLink>
                        </PaginationItem>
                    </PaginationLayout>
                </nav>
            </Col>
            <Col className="cmb-sm-2" style={{ width: '150px' }}>
                <Select
                    // menuIsOpen
                    className="react-select info top"
                    classNamePrefix="react-select"
                    // menuPlacement="top"
                    name="pages"
                    options={sizeValues}
                    value={sizeValues.find(({ value }) => value === String(defaultSize))}
                    onChange={value => onSizeAndIndexChange(value)}
                />
            </Col>
            <Col className="col-auto go-to">
                <Row alignCenter justifyCenter>
                    <MarginWrapper marginLeft="16px" marginRight="16px">
                        <Text size="h5">Go to</Text>
                    </MarginWrapper>
                    <Col className="col-auto">
                        <Input
                            placeholder="Go to"
                            type="text"
                            value={valuePage}
                            onChange={handlePageSet}
                            onKeyDown={handleKeyDown}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
