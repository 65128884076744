import { Loader } from 'components/common/dynamic/Loader';
import { CardCatalogGrid } from 'components/grid/CatalogGrid/styles';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { Empty } from 'components/layouts/Empty';
import { MainLayout } from 'components/layouts/MainLayout';
import { useStore } from 'effector-react';
import { usersNotFoundMessage } from 'pages/Users/constants';
import { UserCard } from 'pages/Users/UserCard';
import { UsersFilterLayout } from 'pages/Users/UsersFilterLayout';
import React from 'react';
import { usersStores } from 'stores/users';

export const Users = () => {
    const { items, totalRecords } = useStore(usersStores.items);
    const loading = useStore(usersStores.initialLoading);

    return (
        <MainLayout>
            <UsersFilterLayout totalRecords={totalRecords}>
                {loading ? (
                    <Section alignCenter justifyCenter height="100%">
                        <Loader />
                    </Section>
                ) : (
                    <>
                        {items?.length ? (
                            <CardCatalogGrid>
                                {items.map(item => (
                                    <UserCard key={item.userId} {...item} />
                                ))}
                            </CardCatalogGrid>
                        ) : (
                            <Section alignCenter justifyCenter height="100%">
                                <Empty description={usersNotFoundMessage} />
                            </Section>
                        )}
                    </>
                )}
            </UsersFilterLayout>
        </MainLayout>
    );
};
