import { storageName } from 'constants/global';
import { errorDataMessage, errorNotEntryAllowed } from 'constants/notifications';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { loadingEffects } from 'stores/loading';
import { Auth } from 'types';
import { giveAccess, objectIsEmpty } from 'utils/usefulFunctions';
import { notificationEvents } from './notification';

const logout = createEvent();
const setAuth = createEvent<Auth>();

const loadToken = createEffect({
    handler: async (values: WOM.UserAuthChallengeEmailOrUsernameOrPhoneRequest) => {
        try {
            loadingEffects.updateLoading();
            const data = await API.user.authenticateUser(values);
            loadingEffects.updateLoading();

            localStorage.setItem(storageName, JSON.stringify(data));
            return data;
        } catch {
            loadingEffects.updateLoading();
            return {};
        }
    }
});

const user = createStore<WOM.UserJwtTokenResponse>(JSON.parse(localStorage.getItem(storageName) || '{}'))
    .on(loadToken.doneData, (_, newState) => newState)
    .on(logout, () => {
        localStorage.removeItem(storageName);
        return {};
    });

user.watch(state =>
    objectIsEmpty(state)
        ? setAuth({
              access: -1,
              authDenyReason: errorDataMessage
          })
        : giveAccess(state) !== -1
        ? setAuth({
              access: giveAccess(state),
              authDenyReason: ''
          })
        : setAuth({
              access: -1,
              authDenyReason: errorNotEntryAllowed
          })
);

const userStore = user.getState();
const auth = createStore<Auth>(
    objectIsEmpty(userStore)
        ? {
              access: -1,
              authDenyReason: errorDataMessage
          }
        : giveAccess(userStore) !== -1
        ? {
              access: giveAccess(userStore),
              authDenyReason: ''
          }
        : {
              access: -1,
              authDenyReason: errorNotEntryAllowed
          }
).on(setAuth, (_, auth) => auth);

auth.watch(
    setAuth,
    ({ authDenyReason }) =>
        authDenyReason &&
        notificationEvents.setNotification({
            place: 'tc',
            message: authDenyReason,
            type: 'danger',
            icon: 'tim-icons icon-bell-55',
            autoDismiss: 5
        })
);

const userEvents = { logout };
const userEffects = { loadToken };
const userStores = { user, auth };

export { userEffects, userStores, userEvents };
