import { sortTagsUsersValues } from 'constants/defaults';
import { capitalizeFirstLetter, convert } from 'utils/usefulFunctions';

export const copyUsernameMessage = 'You successfully copied Username!';
export const copyUserIdMessage = 'You successfully copied User id!';
export const copyWalletIdMessage = 'You successfully copied Wallet id!';
export const copyFacilitatorIdMessage = 'You successfully copied Facilitator id!';

export const parseAssignRoleDescription: (username: string, role: string) => string = (username, role) =>
    `Do you want to assign a user ${username ? username : 'anonymous'} a role ${role ? role : 'unknown'}?`;

export const parseAssignSuccessMessage = (role: string, name: string) =>
    'A role ' + role + ' was successfully assigned to a user ' + name;

export const parseRemoveRoleDescription: (username: string, role: string) => string = (username, role) =>
    `Do you want to remove a role ${role ? role : 'unknown'} from a user ${username ? username : 'anonymous'}?`;

export const parseRemoveRoleSuccessMessage = (role: string, name: string) =>
    'A role ' + role + ' was successfully removed from a user ' + name;

export const parseDisableDescription = (disabled: boolean, name: string) =>
    'Are you sure you want to ' + (disabled ? 'disable' : 'activate') + ' a user ' + name + '?';

export const parseDisableSuccessMessage = (disabled: boolean, name: string) =>
    'A user ' + name + ' has been successfully ' + (disabled ? 'disabled' : 'activated');

export const disableButtonText = (isDisabled?: boolean) => (isDisabled ? 'Activate' : 'Disable');

export const getRolesLabel = (roleValue: string) => {
    const role = sortTagsUsersValues.find(({ value }) => value === roleValue);
    return role ? role.label : roleValue;
};

export const getRole = (role: string) =>
    role === 'organizationmember' ? 'Organization Member' : convert(capitalizeFirstLetter(role));

export const ywbChooser = <T>(
    remoteIssuer: string | null | undefined,
    yeayChoice: T,
    bullzChoice: T,
    womChoice: T
): T => {
    switch (remoteIssuer) {
        case 'yeay-services': {
            return yeayChoice;
        }
        case 'bullz-services': {
            return bullzChoice;
        }
        default: {
            return womChoice;
        }
    }
};
