import React from 'react';
import { getUnionPayoutStatistics } from 'pages/PerformancePayments/PerformancePaymentsSingle/constants';
import { SimpleTable } from 'components/common/tables/SimpleTable';
import { unionPayoutsStatisticsTableColumns } from 'pages/PerformancePayments/PerformancePaymentsSingle/UnionPayoutsStatisticsTable/constants';
import { noContentMessage } from 'constants/notifications';
import { currencyToStandardForm } from 'utils/usefulFunctions';
import { defaultSortValue } from 'constants/messages';

interface UnionPayoutsStatisticsTableProps {
    items: ReturnType<typeof getUnionPayoutStatistics>;
}

export const UnionPayoutsStatisticsTable = ({ items }: UnionPayoutsStatisticsTableProps) => {
    const unionPayoutsStatisticsData = items.map(({ nameParam, count, sum, percent }) => {
        const isTotal = nameParam === 'Total';

        return {
            data: {
                nameParam: (isTotal ? <b>{nameParam}</b> : nameParam) || <span>{noContentMessage}</span>,
                count: {
                    jsx: isTotal ? (
                        <b>{count ? currencyToStandardForm(count) : defaultSortValue}</b>
                    ) : (
                        <>{count ? currencyToStandardForm(count) : defaultSortValue}</>
                    ),
                    value: count || defaultSortValue
                },
                sum: {
                    jsx: isTotal ? (
                        <b>{sum ? currencyToStandardForm(sum) : defaultSortValue}</b>
                    ) : (
                        <>{sum ? currencyToStandardForm(sum) : defaultSortValue}</>
                    ),
                    value: sum || defaultSortValue
                },
                percent: {
                    jsx: isTotal ? (
                        <b>{percent ? currencyToStandardForm(percent) : defaultSortValue} %</b>
                    ) : (
                        <>{percent ? currencyToStandardForm(percent) : defaultSortValue} %</>
                    ),
                    value: percent || defaultSortValue
                }
            }
        };
    });

    return <SimpleTable responsive tbody={unionPayoutsStatisticsData} thead={unionPayoutsStatisticsTableColumns} />;
};
