import React, { FC, useState } from 'react';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { Pagination } from 'components/layouts/Pagination';
import { defaultLimit, defaultPage } from 'constants/defaults';
import {
    getTransactionsStatusLabel,
    getTransactionsStatusValue,
    getTransactionsTypeLabel,
    getTransactionsTypeValue,
    sortTagsTransactionsByValue,
    sortTagsTransactionsByValueLabels,
    sortTagsTransactionsTypeLabels,
    sortTransactionsStatusLabels
} from 'constants/filters';
import { useStore } from 'effector-react';
import Select from 'react-select';
import { userTransactionsEvents, userTransactionsStores } from 'stores/userTransactions';
import { SelectOptionType, TotalRecords } from 'types';
import { StyledColumn, StyledSection } from './styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Text } from 'components/common/typography/Text';

const { updateValues, sortAscending, sortDescending, sortByDate } = userTransactionsEvents;

interface UserTransactionsFilterLayoutProps extends TotalRecords {}

export const UserTransactionsFilterLayout: FC<UserTransactionsFilterLayoutProps> = ({ totalRecords, children }) => {
    const { pageIndex, limit, narrativeType, status } = useStore(userTransactionsStores.values);
    const [valueSortType, setValueSortType] = useState(sortTagsTransactionsByValueLabels[0]);

    const onCurrentPageChange: (page: number, pageSize: number | undefined) => void = (page, pageSize) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    const onSortByStatusChange = (option: SelectOptionType | null) => {
        updateValues({
            status: getTransactionsStatusValue(option),
            pageIndex: defaultPage
        });
    };

    const onSortByTypeChange = (option: SelectOptionType | null) => {
        updateValues({
            narrativeType: getTransactionsTypeValue(option),
            pageIndex: defaultPage
        });
    };

    const onSortByValue = (option: SelectOptionType | null) => {
        switch (option?.value) {
            case sortTagsTransactionsByValue[0]:
                sortByDate();
                setValueSortType(option);
                break;
            case sortTagsTransactionsByValue[1]:
                sortAscending();
                setValueSortType(option);
                break;
            case sortTagsTransactionsByValue[2]:
                sortDescending();
                setValueSortType(option);
                break;
        }
    };

    return (
        <>
            <StyledSection>
                <StyledColumn>
                    <MarginWrapper marginBottom="16px">
                        <Text size="h5">Filter by status</Text>
                    </MarginWrapper>
                    <Select
                        className="react-select info w-100"
                        classNamePrefix="react-select"
                        name="sorts"
                        options={sortTransactionsStatusLabels}
                        value={getTransactionsStatusLabel(status)}
                        onChange={value => onSortByStatusChange(value)}
                    />
                </StyledColumn>
                <StyledColumn>
                    <MarginWrapper marginBottom="16px">
                        <Text size="h5">Filter by type</Text>
                    </MarginWrapper>
                    <Select
                        className="react-select info w-100"
                        classNamePrefix="react-select"
                        name="sorts"
                        options={sortTagsTransactionsTypeLabels}
                        value={getTransactionsTypeLabel(narrativeType)}
                        onChange={value => onSortByTypeChange(value)}
                    />
                </StyledColumn>
                <StyledColumn>
                    <MarginWrapper marginBottom="16px">
                        <Text size="h5">Sort by value</Text>
                    </MarginWrapper>
                    <Select
                        className="react-select info w-100"
                        classNamePrefix="react-select"
                        name="sorts"
                        options={sortTagsTransactionsByValueLabels}
                        value={valueSortType}
                        onChange={value => onSortByValue(value)}
                    />
                </StyledColumn>
            </StyledSection>

            <Section>{children}</Section>

            <Section alignCenter justifyCenter>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Section>
        </>
    );
};
