// * only odd whole non zero number
import { SelectOptionType } from 'types';

export const paginationLimit = 5;

export const pagination = Array.from({ length: paginationLimit }, () => 1);

export const sizeValues: SelectOptionType[] = [
    { value: '12', label: '12' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' }
];
