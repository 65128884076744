import logo from 'assets/wom_logo.png';
import { LogoInterface } from 'types';
import { AlertOptions } from 'react-notification-alert';

export const logoApplication: LogoInterface = {
    outerLink: '/',
    text: 'WASY',
    imgSrc: logo
};

export const sidebarNotifyMessage = () =>
    'Sidebar mini ' + (document.body.classList.contains('sidebar-mini') ? 'deactivated' : 'activated');

export const sidebarNotificationOptions: () => AlertOptions = () => ({
    place: 'tc',
    message: sidebarNotifyMessage(),
    type: 'primary',
    icon: 'tim-icons icon-bell-55',
    autoDismiss: 7
});
