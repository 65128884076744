import { CopyButton } from 'components/common/buttons/CopyButton';
import { DataRangePicker } from 'components/common/inputs/DataPicker';
import { Span } from 'components/common/typography/Span';
import { Text } from 'components/common/typography/Text';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { GraphCard } from 'components/layouts/cards/GraphCard';
import { MainLayout } from 'components/layouts/MainLayout';
import { sub } from 'date-fns';
import { useStore } from 'effector-react';
import moment, { Moment } from 'moment';
import { DashboardTab } from 'pages/Dashboard';
import { charts, graphsColor } from 'pages/Dashboard/constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Card, Col, CustomInput, Row } from 'reactstrap';
import { potsEffects, potsStores } from 'stores/pots';
import GoBackIcon from './assets/back.svg';
import { ReactComponent as WomIcon } from './assets/wom.svg';
import { positiveTypes, transactionTypesNames } from './constants';
import {
    BackWrapper,
    Balance,
    DataHeader,
    DataItem,
    DatesRow,
    DateTitle,
    GraphsWrapper,
    Header,
    StyledSpan,
    WalletField,
    WalletLabel,
    WalletValue
} from './styles';

const { queryPotsFx, getWalletTransactionsFx } = potsEffects;
const { pots, walletTransactions } = potsStores;

export const Pot = () => {
    const history = useHistory();

    const { id } = useParams<{
        id: string;
    }>();

    const potsData = useStore(pots);
    const transactionsData = useStore(walletTransactions);
    const transactionsMap = transactionsData.reduce((acc: any, curr) => {
        if (curr.type) {
            if (acc[curr.type]) {
                acc[curr.type].push(curr);
            } else {
                acc[curr.type] = [curr];
            }
        }
        return acc;
    }, {});

    const potItemData = useMemo(() => {
        if (id && potsData?.items && potsData?.items[0].items) {
            return potsData.items[0].items.find(item => item.walletId === id);
        } else {
            return null;
        }
    }, [potsData, id]);

    const [dateFrom, setDateFrom] = useState(moment(sub(new Date(), { days: 7 })).toISOString(true));
    const [dateTo, setDateTo] = useState(moment(new Date()).toISOString(true));
    const [groupByWeek, setGroupByWeek] = useState(false);

    useEffect(() => {
        queryPotsFx({
            limit: 100,
            dateFromInclusive: dateFrom,
            dateToInclusive: dateTo,
            groupByWeek
        });

        if (id) {
            getWalletTransactionsFx({
                walletId: id,
                dateFromInclusive: dateFrom,
                dateToInclusive: dateTo
            });
        }
    }, [id, dateFrom, dateTo, groupByWeek]);

    const graphsData = Object.entries(transactionsMap).map(([type, item]) => {
        const data: number[] = (item as any).map(({ value }: any) => value || 0).reverse();

        return {
            // @ts-ignore
            title: transactionTypesNames[type],
            key: type,
            data,
            // @ts-ignore
            labels: item.map(({ utcCreated }) => moment(utcCreated).format('DD-MM-YYYY')).reverse(),
            total: data.reduce((acc: number, curr) => acc + (curr || 0), 0)
        };
    });

    const onDateRangeChange = ([dateFrom, dateTo]: [Moment, Moment]) => {
        if (dateFrom.isSameOrAfter(dateTo, 'day')) {
            setDateFrom(dateFrom.toISOString());
            setDateTo(dateTo.add(7, 'day').toISOString());
        } else {
            setDateFrom(dateFrom.toISOString());
            setDateTo(dateTo.toISOString());
        }
    };

    const onBack = () => {
        history.push(`/dashboard?tab=${DashboardTab.Pots}`);
    };

    return (
        <MainLayout>
            <MarginWrapper marginBottom="30px">
                <StyledSpan fontSize="14px" lineHeight="20px" onClick={() => history.push('/')}>
                    Transaction
                </StyledSpan>
                <StyledSpan active fontSize="14px" lineHeight="20px">
                    Company Pots
                </StyledSpan>
            </MarginWrapper>
            <BackWrapper onClick={onBack}>
                <img alt="" src={GoBackIcon} /> Back
            </BackWrapper>
            <div>
                <Header>
                    <Text color="#fff" size="h4">
                        {potItemData?.title}
                    </Text>
                    <Balance>{potItemData?.balance?.toFixed(2)}</Balance>
                    <WomIcon height={24} width={20} />
                </Header>
                <WalletField>
                    <WalletLabel>wallet iD:</WalletLabel>
                    <WalletValue>{potItemData?.walletId}</WalletValue>
                    <CopyButton subject={potItemData?.walletId} success="You successfully copied Wallet id!" />
                </WalletField>
                {/* TODO segmentId is not the wallet address, change this to real wallet address when backend made it */}
                {/* <WalletField>
                    <WalletLabel>wallet Address:</WalletLabel>
                    <WalletValue>{potItemData?.segmentId}</WalletValue>
                    <CopyButton subject={potItemData?.segmentId} success="You successfully copied Wallet Address!" />
                </WalletField> */}
            </div>
            <div>
                <DateTitle>Select Date Range</DateTitle>
                <DatesRow>
                    <Col md="3">
                        <DataRangePicker
                            disableJustifyBetween
                            value={[new Date(dateFrom), new Date(dateTo)]}
                            onChange={onDateRangeChange}
                        />
                    </Col>
                    <Col md="3">
                        <Row className="w-100">
                            <CustomInput
                                checked={groupByWeek}
                                id="groupByWeek"
                                label="Group By Week"
                                type="switch"
                                onChange={e => {
                                    setGroupByWeek(e.target.checked);
                                }}
                            />
                        </Row>
                    </Col>
                </DatesRow>
            </div>
            <div>
                <DataHeader>
                    <span>Name</span>
                    <span>Value(WOM)</span>
                </DataHeader>
                {Object.entries(transactionsMap).map(([type, item]) => (
                    <DataItem key={type}>
                        {/*  @ts-ignore */}
                        <span>{transactionTypesNames[type]}</span>
                        <span>
                            {/* @ts-ignore */}
                            {positiveTypes[type] ? '+' : '-'}
                            {(item as any).reduce((acc: number, { value }: { value: number }) => acc + value, 0)}
                        </span>
                    </DataItem>
                ))}
                <DataHeader>
                    <span>Total</span>
                    <span>
                        {graphsData.reduce((acc, curr) => {
                            // @ts-ignore
                            if (positiveTypes[curr.key]) {
                                return acc + curr.total;
                            } else {
                                return acc - curr.total;
                            }
                        }, 0)}
                    </span>
                </DataHeader>
            </div>
            <GraphsWrapper>
                <Row>
                    {graphsData?.length ? (
                        graphsData?.map(({ data, total, title, labels, key = '' }, index) => {
                            const colorIndex = index;
                            const color = graphsColor[colorIndex]
                                ? graphsColor[colorIndex].pointColor
                                : graphsColor[0].pointColor;
                            const description = '';

                            const { data: dataGraph, options } = charts(
                                { data, labels, label: title },
                                graphsColor[colorIndex] || graphsColor[0]
                            );

                            return (
                                <Col key={key} lg="6">
                                    <GraphCard
                                        color={color}
                                        dataGraph={dataGraph}
                                        description={description}
                                        options={options}
                                        title={title}
                                        total={total}
                                    />
                                </Col>
                            );
                        })
                    ) : (
                        <Col>
                            <Card body style={{ height: '266px' }}>
                                <Section alignCenter justifyCenter height="100%">
                                    <Span color="rgba(255, 255, 255, 0.15)" fontSize="30px" lineHeight="45px">
                                        Nothing found
                                    </Span>
                                </Section>
                            </Card>
                        </Col>
                    )}
                </Row>
            </GraphsWrapper>
        </MainLayout>
    );
};
