import {
    iconWrapperVerticalPadding,
    inputFontSize,
    inputVerticalPadding,
    inputWrapperHeight
} from 'components/common/inputs/TextInput/constants';
import { RelativeWrapper } from 'components/grid/wrappers/RelativeWrapper';
import { textDisabled, white } from 'constants/styles';
import { disableDefaultInputStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import {
    BorderProperties,
    BorderRadius,
    Color,
    Opacity,
    Padding,
    Sizes,
    TextProperties
} from 'types/interfaces/styles';

export interface InputProps extends TextProperties, Opacity, Sizes, BorderProperties, BorderRadius, Padding, Color {
    disabled?: boolean;
}

export const InputWrapper = styled(RelativeWrapper)<InputProps>`
    outline: none;
    height: ${({ height }) => (height ? height : inputWrapperHeight)};
    width: ${({ width }) => width || '100%'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
    ${({ border }) => border && `border: ${border}`};
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`}
`;

export const IconWrapper = styled.div`
    padding: ${iconWrapperVerticalPadding} 0px;
`;

export const Input = styled.input<InputProps>`
    ${disableDefaultInputStyleMixin};
    font-size: ${({ fontSize }) => fontSize || inputFontSize};
    font-weight: ${({ fontWeight }) => fontWeight || '400'};
    font-style: normal;
    line-height: ${({ lineHeight }) => lineHeight || 'normal'};
    letter-spacing: 0em;
    text-align: left;
    padding: ${({ padding }) => (padding ? padding : `${inputVerticalPadding} 5px ${inputVerticalPadding} 0px`)};
    background-color: transparent;
    outline: none;
    width: 100%;
    color: ${({ color }) => (color ? color : white)};
    ${({ opacity }) => opacity && `opacity: ${opacity}`};

    ::placeholder {
        font-size: ${inputFontSize};
        font-style: normal;
        line-height: normal;
        color: rgba(196, 196, 196, 0.2);
    }

    :focus::placeholder {
        color: transparent;
    }

    &:disabled {
        color: ${textDisabled};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

export const ButtonsChangeCount = styled.div`
    position: absolute;
    top: 50%;
    right: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 4px;
    transform: translateY(-50%);
`;
