import { Loader } from 'components/common/dynamic/Loader';
import { Text } from 'components/common/typography/Text';
import { Column, Section } from 'components/grid/wrappers/flexWrapper';
import { Empty } from 'components/layouts/Empty';
import { UserTransactionsFilterLayout } from 'pages/Users/User/TransactionsHistory/UserTransactionsFilterLayout';
import React from 'react';
import { TotalRecords } from 'types';
import { TimelineWraper } from './style';
import { UserTimeline } from 'components/common/tables/UserTimeline';

interface TransactionsHistoryProps extends TotalRecords, Pick<WOM.TransactionQueryResponse, 'items'> {
    loading?: boolean;
}

export const TransactionsHistory = ({ totalRecords, loading, items }: TransactionsHistoryProps) => (
    <Column noWrap width="100%">
        <Section marginBottom="16px">
            <Text size="h4">Transactions history</Text>
        </Section>
        <Section alignCenter>
            <UserTransactionsFilterLayout totalRecords={totalRecords}>
                {loading ? (
                    <Section alignCenter justifyCenter height="100%">
                        <Loader />
                    </Section>
                ) : items?.length ? (
                    <Column noWrap width="100%">
                        <TimelineWraper>
                            {(items as WOM.TransactionResponse[]).map(item => (
                                <UserTimeline key={item.transactionId} {...item} />
                            ))}
                        </TimelineWraper>
                    </Column>
                ) : (
                    <Empty description="Transactions not found" />
                )}
            </UserTransactionsFilterLayout>
        </Section>
    </Column>
);
