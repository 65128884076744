import React, { FC, useEffect, useMemo } from 'react';
import { PropertyBlock } from 'components/common/dividers/PropertyBlock';
import { useStore } from 'effector-react';
import { Col, Row } from 'reactstrap';
import { walletEffects, walletStores } from 'stores/wallet';
//import { isEthAddress, isId } from 'constants/regexp';
//import { notificationEvents } from 'stores/notification';
//import { incorrectInputMessage } from './constants';

const { /*getIdAndRedirectToSinglePage,*/ getItem } = walletEffects;
//const { setNotification } = notificationEvents;

export const WalletFilterLayout: FC = ({ children }) => {
    const { walletId, items } = useStore(walletStores.item);

    const womAddress = useMemo(() => (items?.length && items[0]?.address !== null ? items[0]?.address : ''), [items]);
    const rpAddress = useMemo(() => (items?.length && items[1]?.address !== null ? items[1]?.address : ''), [items]);

    // const onAddressSearch = (name: string) =>
    //     name && isEthAddress(name)
    //         ? getIdAndRedirectToSinglePage({ publicAddress: name })
    //         : setNotification({
    //               message: incorrectInputMessage
    //           });

    // const onIdSearch = (id: string) =>
    //     id && isId(id)
    //         ? getIdAndRedirectToSinglePage({ walletId: id })
    //         : setNotification({
    //               message: incorrectInputMessage
    //           });

    // const onResetClick = () => {
    //     document.location.reload();
    // };

    useEffect(() => {
        getItem();
    }, []);

    return (
        <>
            <Row className="mb-5">
                <Col className="mb-2" md={4} sm={12}>
                    <PropertyBlock
                        copyable
                        backgroundColor="secondary"
                        subtitle={walletId}
                        success="Wallet ID is successfully copied"
                        title="Your Wallet ID"
                    />
                </Col>
                <Col className="mb-2" md={4} sm={12}>
                    <PropertyBlock
                        copyable
                        backgroundColor="secondary"
                        subtitle={womAddress}
                        success="WOM Address is successfully copied"
                        title="Your WOM Address"
                    />
                </Col>
                <Col className="mb-2" md={4} sm={12}>
                    <PropertyBlock
                        copyable
                        backgroundColor="secondary"
                        subtitle={rpAddress}
                        success="RP Address is successfully copied"
                        title="Your RP Address (currently not used)"
                    />
                </Col>
            </Row>
            <Row style={{ minHeight: 'calc(100vh - 220px)' }}>
                <Col>{children}</Col>
            </Row>
        </>
    );
};
