import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { absoluteCenterAlignmentMixin, grey2 } from 'constants/styles';
import styled from 'styled-components';

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 31, 46, 0.8);
    padding: 220px 80px 100px;
    z-index: 1000;
    overflow: auto;
`;

export const ModalContentWrapper = styled(ContentWrapper)`
    ${absoluteCenterAlignmentMixin};
    position: fixed;
    display: flex;
    flex-direction: column;
    min-width: 430px;
    max-width: 532px;
    max-height: 630px;
    padding: 24px 16px;
    background: ${grey2};
    overflow: 'auto';
    z-index: 1001;
`;
