import React, { FC } from 'react';
import { CloseIcon, StyledLink, StyledTag } from './styles';
import { TagColorType } from './types';

interface Props {
    link?: string;
    color?: TagColorType;
    onRemove?: (value: string) => void;
}

export const Tag: FC<Props> = ({ children, link, color = 'info', onRemove }) => (
    <StyledTag color={color}>
        {link ? <StyledLink to={link}>{children}</StyledLink> : children}
        {onRemove && <CloseIcon onClick={() => onRemove(children as string)} />}
    </StyledTag>
);
