import { white, xs } from 'constants/styles';
import styled from 'styled-components';
import { MarginBottom, MarginRight } from 'types';

export const SelectTitle = styled.span`
    font-size: 13px;
    line-height: 19px;
    opacity: 0.8;
    color: ${white};
`;

interface FilterWrapperProps extends MarginRight, MarginBottom {}

export const FilterWrapper = styled.div<FilterWrapperProps>`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    ${({ marginBottom }) => marginBottom && `margin-bottom:${marginBottom}`}
`;

export const FilterSection = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: ${xs}) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;
