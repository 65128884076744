import React, { FC } from 'react';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { endedReasonValue } from 'pages/Videos/VideoCard/constants';
import { Text } from 'components/common/typography/Text';
import { statisticsTextColor, pink } from 'constants/styles';
import { Column } from 'components/grid/wrappers/flexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';

interface ActivityProps
    extends Pick<WOM.ContentItemResponse, 'womQualityScore'>,
        Pick<WOM.ValidationStateResponse, 'endedReason'> {}

export const Activity: FC<ActivityProps> = ({ womQualityScore, endedReason }) => {
    const isValidationFailed = endedReason !== 1;

    return (
        <AbsoluteWrapper right="16px" top="0">
            {isValidationFailed ? (
                <Text color={pink} size="p">
                    {endedReasonValue[endedReason || 0]}
                </Text>
            ) : (
                <Column>
                    <Text color={statisticsTextColor} size="h6">
                        Authenticity
                    </Text>
                    <MarginWrapper marginBottom="16px">
                        <Text color={statisticsTextColor} size="h6">
                            {womQualityScore?.authenticity?.toFixed(2) || '0.0'}
                        </Text>
                    </MarginWrapper>

                    <Text color={statisticsTextColor} size="h6">
                        Creativity
                    </Text>
                    <MarginWrapper marginBottom="16px">
                        <Text color={statisticsTextColor} size="h6">
                            {womQualityScore?.creativity?.toFixed(2) || '0.0'}
                        </Text>
                    </MarginWrapper>

                    <Text color={statisticsTextColor} size="h6">
                        Positivity
                    </Text>
                    <MarginWrapper marginBottom="16px">
                        <Text color={statisticsTextColor} size="h6">
                            {womQualityScore?.positivity?.toFixed(2) || '0.0'}
                        </Text>
                    </MarginWrapper>
                </Column>
            )}
        </AbsoluteWrapper>
    );
};
