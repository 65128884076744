import { useMediaQuery } from '@material-ui/core';
import { Button, CloseButton, HorizontalLine, Loader, SearchSelectInput, SimpleTable, Span } from 'components/common';
import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { Column, Row, Section } from 'components/grid/wrappers/flexWrapper';
import { Pagination } from 'components/layouts/Pagination';
import { defaultLimit } from 'constants/defaults';
import { xs } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { airdropEvent, airdropStores } from 'stores/airdrop';
import { usersEvents, usersStores } from 'stores/users';
import { airdropAddUserTableColumns, infoMessage, searchOptions } from './constants';
import { ButtonWrapper, ModalBackground, ModalWrapper, TableWrapper } from './styles';
import { AirdropSelectModalProps } from './types';
import { getAirdropTableContentData } from './utils';

const { overrideValues, setDefaultValues, updateValues } = usersEvents;

export const AirdropSelectModal: FC<AirdropSelectModalProps> = ({ visible = false, onClose }) => {
    const { items: foundUsers, totalRecords } = useStore(usersStores.items);
    const { pageIndex, limit } = useStore(usersStores.values);
    const addedUsers = useStore(airdropStores.users);
    const loading = useStore(usersStores.initialLoading);
    const isMobile = useMediaQuery(`(max-width: ${xs} )`);
    const [foundUsersFilteredByAddedUsers, setFoundUsersFilteredByAddedUsers] = useState<WOM.GetUserResponse[]>(
        foundUsers || []
    );
    const [checkedUsersForAdded, setCheckedUsersForAdded] = useState<WOM.GetUserResponse[]>(addedUsers);

    const handleClickButtonAddUsers = () => {
        airdropEvent.addUsers(checkedUsersForAdded);
        setCheckedUsersForAdded([]);
    };

    const handleClickCloseModal = () => {
        setCheckedUsersForAdded([]);
        onClose?.();
    };

    const onCheckUser = (user: WOM.GetUserResponse, isChecked: boolean) => {
        if (isChecked) {
            setCheckedUsersForAdded(checkedUsersForAdded => [...checkedUsersForAdded, user]);
        } else {
            setCheckedUsersForAdded(checkedUsersForAdded =>
                checkedUsersForAdded.filter(({ userId }) => userId !== user.userId)
            );
        }
    };

    const airdropTableContentData = foundUsersFilteredByAddedUsers
        ? getAirdropTableContentData(foundUsersFilteredByAddedUsers, checkedUsersForAdded, onCheckUser)
        : undefined;

    const SearchItems = [
        {
            option: searchOptions[0],
            onSearch: (value: string) => overrideValues({ userName: value })
        },
        {
            option: searchOptions[1],
            onSearch: (value: string) => overrideValues({ userIds: [value] })
        },
        {
            option: searchOptions[2],
            onSearch: (value: string) => overrideValues({ mobileNumber: value })
        },
        {
            option: searchOptions[3],
            onSearch: (value: string) => overrideValues({ email: value })
        },
        {
            option: searchOptions[4],
            onSearch: (value: string) => overrideValues({ walletIds: [value] })
        }
    ];

    useEffect(
        () => () => {
            setDefaultValues();
        },
        []
    );

    useEffect(() => {
        if (foundUsers) {
            setFoundUsersFilteredByAddedUsers(
                foundUsers.filter(foundUser => !addedUsers.some(({ userId }) => foundUser.userId === userId))
            );
        }
    }, [addedUsers, foundUsers]);

    const onCurrentPageChange: (page: number, pageSize: number | undefined) => void = (page, pageSize) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    if (!visible) return null;

    return (
        <>
            <ModalBackground onClick={handleClickCloseModal} />
            <ModalWrapper>
                <ContentWrapper height="100%" padding="24px 24px 20px">
                    <Column justifyBetween height="100%" width="100%">
                        <Column width="100%">
                            <Row justifyBetween marginBottom="24px" width="100%">
                                <Span fontSize="24px" fontWeight="400" lineHeight="36px">
                                    Add user
                                </Span>

                                <CloseButton width="auto" onClick={handleClickCloseModal} />
                            </Row>

                            <Row marginBottom="24px" width="100%">
                                <SearchSelectInput wide items={SearchItems} />
                            </Row>

                            <TableWrapper justifyAround={isMobile} marginBottom="16px" width="100%">
                                {loading ? (
                                    <Section alignCenter justifyCenter height="100%">
                                        <Loader />
                                    </Section>
                                ) : (
                                    <SimpleTable
                                        withoutMarginBottom
                                        infoMessage={infoMessage}
                                        tbody={airdropTableContentData}
                                        thead={airdropAddUserTableColumns}
                                    />
                                )}
                            </TableWrapper>
                        </Column>

                        {!loading && (
                            <Column width="100%">
                                <Pagination
                                    currentIndex={pageIndex + 1}
                                    defaultSize={limit}
                                    totalItems={totalRecords}
                                    onSizeChange={onCurrentPageChange}
                                />
                            </Column>
                        )}

                        <Column width="100%">
                            <Row marginBottom="20px">
                                <HorizontalLine />
                            </Row>

                            <ButtonWrapper>
                                <Button buttonType="navy" size="small" onClick={handleClickCloseModal}>
                                    Cancel
                                </Button>

                                <Button
                                    buttonType="blue"
                                    disabled={!checkedUsersForAdded.length}
                                    size="small"
                                    onClick={handleClickButtonAddUsers}
                                >
                                    Add
                                </Button>
                            </ButtonWrapper>
                        </Column>
                    </Column>
                </ContentWrapper>
            </ModalWrapper>
        </>
    );
};
