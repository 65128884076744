import { Loader } from 'components/common/dynamic/Loader';
import { Span } from 'components/common/typography/Span';
import { Section } from 'components/grid/wrappers/flexWrapper';
import { Empty } from 'components/layouts/Empty';
import { MainLayout } from 'components/layouts/MainLayout';
import { useStore } from 'effector-react';
import React from 'react';
import { useHistory } from 'react-router';
import { usersEffects, usersStores } from 'stores/users';
import { WithdrawalCheckFilterLayout } from './WithdrawalCheckFilterLayout';
import { WithdrawalCheckTable } from './WithdrawalCheckTable';

export const WithdrawalCheck = () => {
    const { items } = useStore(usersStores.withdrawalCheck);
    const loading = useStore(usersEffects.getWithdrawalByAddressTo.pending);
    const { location } = useHistory();
    const addressFrom = location.search.split('=')[1];

    return (
        <MainLayout>
            <Section marginBottom="20px">
                <Span>Checking withdrawals for: {addressFrom}</Span>
            </Section>
            <WithdrawalCheckFilterLayout totalRecords={items?.length}>
                {loading ? (
                    <Section alignCenter justifyCenter height="100%">
                        <Loader />
                    </Section>
                ) : items?.length ? (
                    <WithdrawalCheckTable items={items} />
                ) : (
                    <Section alignCenter justifyCenter height="100%" marginTop="48px">
                        <Empty description="Withdrawal check not found" />
                    </Section>
                )}
            </WithdrawalCheckFilterLayout>
        </MainLayout>
    );
};
