import { CancelToken } from 'axios';
import axios from './axios';

export const getItems = (data: WOM.QueryOrganizationRequest, cancelToken?: CancelToken) =>
    axios<WOM.OrganizationsResponse>({
        url: '/organization/query',
        cancelToken,
        data
    });

export const updateOrganizationById = (data: WOM.UpdateOrganizationRequest, cancelToken?: CancelToken) =>
    axios<WOM.OrganizationsResponse>({
        url: '/organization/update',
        cancelToken,
        data
    });
