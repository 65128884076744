import { CancelToken } from 'axios';
import axios from './axios';

export const getCards = (data: YEAY.QueryAllVideosRequest) =>
    axios<YEAY.QueryAllVideosResponse>({
        url: '/admin/video/query',
        data
    });

export const getCardById = (data: YEAY.GetVideoRequest) =>
    axios<YEAY.AdminGetVideoResponse>({
        url: '/admin/video/get',
        data
    });

export const getVideosByProductId = (data: YEAY.QueryVideosByProductIdRequest) =>
    axios<YEAY.QueryAllVideosResponse>({
        url: '/admin/video/query-by-product',
        data
    });

export const editVideoInfo = (data: YEAY.UpdateVideoRequest) =>
    axios<YEAY.AdminGetVideoResponse>({
        url: '/admin/video/update',
        data
    });

export const getItemById = (data: WOM.ContentGetRequest, cancelToken?: CancelToken) =>
    axios<WOM.ContentItemResponse>({
        url: '/catalogue/get',
        cancelToken,
        data
    });

export const getItems = (data: WOM.ContentQueryRequest, cancelToken?: CancelToken) =>
    axios<WOM.ContentQueryResponse>({
        url: '/catalogue/query',
        cancelToken,
        data
    });
